import { atomFamily } from "jotai/utils";
import { atomWithSuspenseQuery } from "jotai-tanstack-query";
import { isNil } from "lodash";

import { ngEpgApiAtom } from "@sunrise/backend-ng-epg";
import { queryKeys } from "@sunrise/backend-types";
import { AssetId } from "@sunrise/backend-types-core";
import { currentLanguageAtom } from "@sunrise/i18n";
import { Nullable } from "@sunrise/utils";

import { MappedEpg } from "../types";
import { mapBackendEpgSimpleNg } from "./map-backend-epg.ng";

export const episodesEpgsBySeriesAssetIdNgAtom = atomFamily(
  (assetId: Nullable<AssetId>) =>
    atomWithSuspenseQuery<Nullable<MappedEpg[]>>((get) => {
      const ngApi = get(ngEpgApiAtom);
      const language = get(currentLanguageAtom);

      return {
        enabled: !isNil(assetId),
        queryKey: queryKeys.episodesByAssetId(assetId, language),
        queryFn: async () => {
          if (!assetId) return null;

          const entries =
            await ngApi.epg.getSeriesAssetEpisodesEpgV1AssetsAssetIdEpisodesGet(
              assetId,
            );

          return entries.data.items.map(mapBackendEpgSimpleNg);
        },
        staleTime: Infinity,
        cacheTime: Infinity,
      };
    }),
);
