import {
  isLinearPlayRequest,
  selectPlayerCurrentPlayRequest,
  selectPlayerState,
} from "@sunrise/player";
import { UpsellError } from "@sunrise/yallo-upsell";
import { freeUsageAtom } from "@sunrise/yallo-user";
import { atom } from "jotai";

const CONSIDERED_PLAYING_STATES = ["playing", "loaded", "paused"];

export const shouldPlayerStopAtom = atom(async (get) => {
  const { usageLimitReached } = await get(freeUsageAtom);
  const state = get(selectPlayerState);
  const pr = get(selectPlayerCurrentPlayRequest);

  if (
    usageLimitReached &&
    CONSIDERED_PLAYING_STATES.includes(state) &&
    isLinearPlayRequest(pr)
  ) {
    return new UpsellError("UPSELL_EXCEEDED_STREAM_DURATION_LIMIT");
  }

  return false;
});
