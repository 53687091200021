import {
  ArrowPressHandler,
  getCurrentFocusKey,
} from "@noriginmedia/norigin-spatial-navigation";
import { playerCurrentEpgItemAtom } from "@sunrise/player";
import { useRecordProgram } from "@sunrise/yallo-recordings";
import { useAtomValue } from "jotai";
import { ReactElement, type ReactNode, useCallback } from "react";

import { FocusIconButton } from "@/components/buttons/focus-icon-button/focus-icon-button";
import * as styles from "@/features/player-controls/player-controls.css";

import { PlayerButton } from "./player-controls.types";
import type { EPGEntry } from "@sunrise/backend-types";

type RecordButtonProps = {
  focusKey: string;
  onArrowPress: ArrowPressHandler;
};

export function RecordButton(props: RecordButtonProps): ReactNode {
  const epgEntry = useAtomValue(playerCurrentEpgItemAtom);

  if (!epgEntry) {
    return null;
  }

  return <RecordButtonInternal {...props} epgEntry={epgEntry} />;
}

function RecordButtonInternal({
  focusKey,
  onArrowPress,
  epgEntry,
}: RecordButtonProps & { epgEntry: EPGEntry }): ReactElement {
  const {
    record,
    deleteRecording,
    status: recordingStatus,
  } = useRecordProgram({ epg: epgEntry, getCurrentFocusKey });

  const getRecordingButton = useCallback((): PlayerButton => {
    switch (recordingStatus) {
      case "recorded": {
        return {
          key: "record",
          iconName: "recorded",
          onEnterPress: () => deleteRecording(),
        };
      }
      case "planned": {
        return {
          key: "record",
          iconName: "recording",
          onEnterPress: () => deleteRecording(),
        };
      }
      default: {
        return {
          key: "record",
          iconName: "record",
          onEnterPress: () => void record(),
        };
      }
    }
  }, [deleteRecording, record, recordingStatus]);

  const button = getRecordingButton();

  return (
    <FocusIconButton
      key={button.key}
      focusKey={`${focusKey}.${button.key}`}
      data-testid={`player-controls.button.${button.key}`}
      className={styles.button}
      iconName={button.iconName}
      disabled={button.disabled}
      onEnterPress={button.onEnterPress}
      onArrowPress={onArrowPress}
    />
  );
}
