import clsx from "clsx";

import { Icon } from "../icon";
import * as styles from "./lock.css";

type LockProps = CommonProps & {
  radius?: number;
  focusBorder?: number;
  focused?: boolean;
};

export function Lock({
  focused,
  radius = 24,
  focusBorder = 4,
  "data-testid": dataTestId = "lock",
  className,
}: LockProps): JSX.Element {
  return (
    <div
      style={{
        borderTopLeftRadius: radius,
        top: focused ? -focusBorder : 0,
        left: focused ? -focusBorder : 0,
      }}
      className={clsx([styles.lockWrapper, className])}
      data-testid={dataTestId}
    >
      <Icon
        name="lock"
        color="white"
        className={styles.lock}
        data-testid={`${dataTestId}.icon`}
      />
    </div>
  );
}
