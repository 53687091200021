import { ReactNode, useCallback, useMemo } from "react";
import { useAtomValue } from "jotai";
import { loadable, useAtomCallback } from "jotai/utils";

import {
  selectableChannelGroupsAtom,
  selectedChannelGroupIdAtom,
} from "@sunrise/yallo-channel-group";

import { globalFocusKey } from "@/config/focus-key";
import { FocusContainer } from "@/utils/focus-container";

import {
  actionChannelListExpandChannels,
  channelListAtom,
} from "../channel-list.store";
import { channelGroupIdToFocusId } from "./channel-group-id-to-focus-id";
import * as styles from "./channel-groups.css";
import { ChannelGroupsList } from "./channel-groups-list";

export const loadableSelectedIdAtom = loadable(selectedChannelGroupIdAtom);
const loadableChannelGroupsAtom = loadable(selectableChannelGroupsAtom);

export function ChannelGroups({
  "data-testid": testId = "channel-groups",
}: {
  "data-testid"?: string;
}): ReactNode {
  const loadableSelectedId = useAtomValue(loadableSelectedIdAtom);
  const selectedId =
    loadableSelectedId.state === "hasData"
      ? loadableSelectedId.data
      : undefined;

  const preferredChildFocusKey = useMemo(
    () => (selectedId ? channelGroupIdToFocusId(selectedId) : undefined),
    [selectedId],
  );
  const goBack = useAtomCallback(
    useCallback((_get, set) => {
      set(channelListAtom, actionChannelListExpandChannels());
    }, []),
  );
  const combinedChannelGroups = useAtomValue(loadableChannelGroupsAtom);

  if (combinedChannelGroups.state !== "hasData") {
    return null;
  }

  return (
    <FocusContainer
      // we need to make sure this container pulls focus when it is shown.
      shouldFocus
      boundary
      preferredChildFocusKey={preferredChildFocusKey}
      className={styles.focusGroupsContainer}
      focusKey={globalFocusKey.channelGroups}
      onBack={goBack}
      onLeft={goBack}
      data-testid={`${testId}.focus-container}`}
    >
      {(directions) => (
        <ChannelGroupsList
          directions={directions}
          combinedChannelGroups={combinedChannelGroups.data}
          selectedId={selectedId}
        />
      )}
    </FocusContainer>
  );
}
