import { selectAtom } from "jotai/utils";

import { debouncedQueryAtoms } from "./search.debounce.atom";

/**
 * The search query is always the debounced value. In order to avoid confusion best to always use this selector.
 */
export const selectCurrentSearchQuery = selectAtom(
  debouncedQueryAtoms.debouncedValueAtom,
  (v) => v.trim(),
);
