import {
  endpoints,
  type ContinueWatchingItem,
  type FullyWatchedItem,
  type PagedResponse,
} from "@sunrise/backend-types";
import type { PrivateApiClient } from "@sunrise/http-client";

export async function fetchContinueWatching(
  api: PrivateApiClient,
  host: string,
  page = 1,
  pageSize = 500,
) {
  const url = new URL(endpoints.continueWatching(host));
  url.searchParams.set("page", page.toString());
  url.searchParams.set("pagesize", pageSize.toString());

  const response = api.get<PagedResponse<ContinueWatchingItem>>(url.href);

  return (await response).data;
}

export async function fetchFullyWatched(api: PrivateApiClient, host: string) {
  const url = new URL(endpoints.fullyWatched(host));

  const response = api.get<FullyWatchedItem[]>(url.href);

  return (await response).data;
}
