import {
  isArrowDownKey,
  isArrowLeftKey,
  isArrowRightKey,
  isArrowUpKey,
} from "./navigation";

export function listNavigationArrowPress(
  length: number,
  columnCount: number,
  currentIndex: number,
  direction: string,
  options?: {
    onLeftBoundry?: () => void;
    onTopBoundry?: (columnIndex: number) => void;
  },
): number | undefined {
  if (isArrowLeftKey(direction)) {
    const isFirstInRow = currentIndex % columnCount === 0;

    if (isFirstInRow && options?.onLeftBoundry) {
      options.onLeftBoundry();
    } else {
      return currentIndex - 1;
    }
  } else if (isArrowRightKey(direction)) {
    const isLastInRow = currentIndex % columnCount === columnCount - 1;
    if (!isLastInRow) {
      return currentIndex + 1;
    }
  } else if (isArrowDownKey(direction)) {
    const rowIdx = Math.floor(currentIndex / columnCount);
    const isLastFullRow = rowIdx === Math.floor(length / columnCount) - 1;

    if (!isLastFullRow) {
      return currentIndex + columnCount;
    } else {
      const next = currentIndex + columnCount;
      if (next < length) {
        return next;
      }

      return length - 1;
    }

    // eslint-disable-next-line sonarjs/elseif-without-else
  } else if (isArrowUpKey(direction)) {
    const rowIdx = Math.floor(currentIndex / columnCount);
    const isFirstRow = rowIdx === 0;
    const columnIdx = currentIndex % columnCount;

    if (isFirstRow) {
      if (options?.onTopBoundry) {
        options.onTopBoundry(columnIdx);
      }
    } else {
      return currentIndex - columnCount;
    }
  }
}
