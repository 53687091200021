import { type Language } from "@sunrise/backend-types-core";
import { type Locale } from "date-fns";

export async function getDateFnsLocaleForLanguage(
  language: Language,
): Promise<Locale> {
  switch (language) {
    case "en":
      return (await import(`date-fns/locale/en-US`)).enUS;
    case "fr":
      return (await import(`date-fns/locale/fr`)).fr;
    case "it":
      return (await import(`date-fns/locale/it`)).it;
    case "de":
    default:
      return (await import(`date-fns/locale/de`)).de;
  }
}
