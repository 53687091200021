import type {
  AssetId,
  EPGEntryId,
  RecordingGroupId,
} from "@sunrise/backend-types-core";

// TODO: extract, test and make generic.
export const routeParam = {
  details: {
    epgId: "" as EPGEntryId,
    assetId: "" as AssetId,
  } as const,
  detailsSeries: {
    assetId: "" as AssetId,
    recordingGroupId: "" as RecordingGroupId,
  },
  featureFlag: {
    id: "" as string,
  },
} as const;

function makeRawParam<T extends Record<string, string>>(params: T): string {
  const param = Object.keys(params);
  // TODO: when a param is nullable, add it as a query param.
  return param.map((v) => `:${v}`).join("/");
}

function replaceInUrl<T extends Record<string, string>>(
  url: string,
  params: T,
): string {
  return Object.entries(params).reduce((acc, [key, value]) => {
    return acc.replace(`:${key}`, value);
  }, url);
}

export const protectedRoute = {
  details: makeProtectedRoute(`/details/${makeRawParam(routeParam.details)}`),
  detailsSeries: makeProtectedRoute(
    `/details-series/${makeRawParam(routeParam.detailsSeries)}`,
  ),
  home: makeProtectedRoute("/home"),
  search: makeProtectedRoute("/search"),
  tv: makeProtectedRoute("/tv"),
  guide: makeProtectedRoute("/guide"),
  recordings: makeProtectedRoute("/recordings"),
  settings: makeProtectedRoute("/settings"),
  settingsProfile: makeProtectedRoute("/settings/profile"),
  settingsDeveloper: makeProtectedRoute("/settings/developer"),
  settingsDeveloperFeatureFlags: makeProtectedRoute(
    "/settings/developer/feature-flags",
  ),
  settingsDeveloperFeatureFlagsDetails: makeProtectedRoute(
    `/settings/developer/feature-flags/${makeRawParam(routeParam.featureFlag)}`,
  ),
  designSystem: makeProtectedRoute("/design-system"),
} satisfies Record<string, string | Record<string, string>>;

export function makeProtectedRoute(path: string): string {
  const targetChar = "/";
  const str = path
    .replace(new RegExp(`^\\${targetChar}`), "")
    .replace(new RegExp(`\\${targetChar}$`), "");

  return `/protected/${str}`;
}

export const rawRoute = {
  ...protectedRoute,
  landing: "/",
  login: "/login",
  register: "/register",
} satisfies Record<string, string | Record<string, string>>;

export const route = {
  details: {
    /**
     * NOTE: We may also need the AssetId here.
     *       For now let's see how far we get with just the EpgEntryId.
     */
    root: (params: typeof routeParam.details) => {
      return replaceInUrl<typeof routeParam.details>(
        protectedRoute.details,
        params,
      );
    },
  },
  detailsSeries: {
    root: (params: typeof routeParam.detailsSeries) => {
      return replaceInUrl<typeof routeParam.detailsSeries>(
        protectedRoute.detailsSeries,
        params,
      );
    },
  },
  home: {
    root: () => rawRoute.home,
  },
  search: {
    root: () => rawRoute.search,
  },
  tv: {
    root: () => rawRoute.tv,
  },
  guide: {
    root: () => rawRoute.guide,
  },
  recordings: {
    root: () => rawRoute.recordings,
  },
  settings: {
    root: () => rawRoute.settings,
    developerTools: () => rawRoute.settingsDeveloper,
    featureFlagsRoot: () => rawRoute.settingsDeveloperFeatureFlags,
    featureFlagsDetails: (params: typeof routeParam.featureFlag) => {
      return replaceInUrl<typeof routeParam.featureFlag>(
        rawRoute.settingsDeveloperFeatureFlagsDetails,
        params,
      );
    },
    profile: () => rawRoute.settingsProfile,
  },
  landing: {
    root: () => rawRoute.landing,
  },
  login: {
    root: () => rawRoute.login,
  },
  register: {
    root: () => rawRoute.register,
  },
  designSystem: {
    root: () => rawRoute.designSystem,
  },
} satisfies Record<
  string,
  // the type accepts function with any number of arguments
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Record<string, (...params: any[]) => string>
>;
