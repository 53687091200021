import { baseGap } from "@/styles/theme.css";

export const GUIDE_GRID_CHANNELS_COLUMN_WIDTH_IN_PX = 260;

export const GUIDE_GRID_TIME_INDICATOR_BAR_HEIGHT_IN_PX = 50;

export const NOW_BAR_WIDTH_IN_PX = 4;

export const MINUTES_BUFFER_IN_GRID = 30;

export const GUIDE_PROGRAM_PREVIEW_HEIGHT_IN_PX = 466;

export const GUIDE_GRID_CHANNEL_HEIGHT_IN_PX = 100;

export const GUIDE_PROGRAM_ITEM_PADDING = baseGap * 3.75;

// NOTE: 40 seems like a good value which is enough to show 1 letter and "...", else hide completely
export const GUIDE_PROGRAM_MIN_RENDER_WIDTH = 40;

export const GUIDE_THROTTLE_ON_LONG_PRESS_IN_MS = 300;
