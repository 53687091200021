import { atomWithReducer, selectAtom } from "jotai/utils";

/**
 * Key used for navigation inside the app
 */
export type InAppKeyboardKeyId =
  | "Back"
  | "Exit"
  | "ChannelDown"
  | "ChannelUp"
  | "Enter"
  | "Info"
  | "Guide"
  | "Caption"
  | "MediaAdvance"
  | "MediaFastForward"
  | "MediaPause"
  | "MediaPlay"
  | "MediaPlayPause"
  | "MediaRewind"
  | "ProgrammableF0"
  | "ProgrammableF1"
  | "ProgrammableF2"
  | "ProgrammableF3"
  | "ArrowLeft"
  | "ArrowRight"
  | "ArrowDown"
  | "ArrowUp"
  | "0"
  | "1"
  | "2"
  | "3"
  | "4"
  | "5"
  | "6"
  | "7"
  | "8"
  | "9";

type KeyboardNavigationAtomState = Record<InAppKeyboardKeyId, string[]>;

export function makeKeyboardNavigationAtomDefaultState(
  state: Partial<KeyboardNavigationAtomState> = {},
): KeyboardNavigationAtomState {
  return {
    Back: ["Escape"],
    Exit: [],
    ChannelDown: ["PageDown"],
    ChannelUp: ["PageUp"],
    Enter: ["Enter"],
    Info: [],
    Caption: [],
    Guide: [],
    MediaAdvance: [],
    MediaFastForward: [],
    MediaPause: [],
    MediaPlay: [],
    MediaPlayPause: [],
    MediaRewind: [],
    ProgrammableF0: [],
    ProgrammableF1: [],
    ProgrammableF2: [],
    ProgrammableF3: [],
    ArrowLeft: ["ArrowLeft"],
    ArrowRight: ["ArrowRight"],
    ArrowDown: ["ArrowDown"],
    ArrowUp: ["ArrowUp"],
    "0": ["0"],
    "1": ["1"],
    "2": ["2"],
    "3": ["3"],
    "4": ["4"],
    "5": ["5"],
    "6": ["6"],
    "7": ["7"],
    "8": ["8"],
    "9": ["9"],
    ...state,
  };
}

type ActionSetKeyCodes = {
  type: "keyboard-navigation/register-multiple-codes";
  payload: Partial<KeyboardNavigationAtomState>;
};

type ActionRegisterKeyCode = {
  type: "keyboard-navigation/register-key-code";
  payload: {
    keyCode: string;
    keyId: InAppKeyboardKeyId;
  };
};

type ActionDeregisterKeyCode = {
  type: "keyboard-navigation/deregister-key-code";
  payload: {
    keyCode: string;
    keyId: InAppKeyboardKeyId;
  };
};

/**
 * Action for testing purpose **only**
 */
type ActionTestSetState = {
  type: "keyboard-navigation/test/set-state";
  payload: KeyboardNavigationAtomState;
};

type KeyboardNavigationAction =
  | ActionSetKeyCodes
  | ActionRegisterKeyCode
  | ActionDeregisterKeyCode
  | ActionTestSetState;

export const keyboardNavigationAtom = atomWithReducer<
  KeyboardNavigationAtomState,
  KeyboardNavigationAction
>(makeKeyboardNavigationAtomDefaultState(), keyboardNavigationAtomReducer);

export function keyboardNavigationAtomReducer(
  ps: KeyboardNavigationAtomState,
  action: KeyboardNavigationAction,
): KeyboardNavigationAtomState {
  switch (action.type) {
    case "keyboard-navigation/register-multiple-codes": {
      // NOTE: this is an unsafe workaround for TS not being able to infer the type of Object.entries
      const entries = Object.entries(action.payload) as [
        keyId: InAppKeyboardKeyId,
        keyIds: string[],
      ][];

      return entries.reduce(
        (acc, [keyId, codes]) => {
          return {
            ...acc,
            [keyId]: acc[keyId].concat(codes),
          };
        },
        { ...ps },
      );
    }

    case "keyboard-navigation/register-key-code": {
      const { keyCode, keyId } = action.payload;
      const nextState = { ...ps };
      nextState[keyId].push(keyCode);
      return nextState;
    }

    case "keyboard-navigation/deregister-key-code": {
      const { keyCode, keyId } = action.payload;
      const nextState = { ...ps };
      nextState[keyId] = nextState[keyId].filter((kc) => kc !== keyCode);
      return nextState;
    }

    case "keyboard-navigation/test/set-state": {
      return action.payload;
    }
  }
}

/*
 *
 * ACTIONS
 *
 */

export function actionKeyboardNavigationRegisterMultipleKeyCodes(
  payload: Partial<KeyboardNavigationAtomState>,
): ActionSetKeyCodes {
  return { type: "keyboard-navigation/register-multiple-codes", payload };
}

export function actionKeyboardNavigationRegisterKeyCode(payload: {
  keyId: InAppKeyboardKeyId;
  keyCode: string;
}): ActionRegisterKeyCode {
  return {
    type: "keyboard-navigation/register-key-code",
    payload,
  };
}

export function actionKeyboardNavigationDeregisterKeyCode(payload: {
  keyId: InAppKeyboardKeyId;
  keyCode: string;
}): ActionDeregisterKeyCode {
  return {
    type: "keyboard-navigation/deregister-key-code",
    payload,
  };
}

/*
 *
 * SELECTORS
 *
 */

export const selectKeyboardNavigationKeyCodeKeyIdDict = selectAtom(
  keyboardNavigationAtom,
  (s) => {
    const entries = Object.entries(s) as [
      keyId: InAppKeyboardKeyId,
      val: string[],
    ][];

    const dict: Record<string, InAppKeyboardKeyId> = {};
    for (const [keyId, keyCodes] of entries) {
      for (const keyCode of keyCodes) {
        dict[keyCode] = keyId;
      }
    }
    return dict;
  },
);
