import { AxiosInstance } from "axios";
import { atom } from "jotai";

import { Nullable } from "@sunrise/utils";

import { PrivateApiClient } from "./private-api-client";

export const httpClientAtom = atom<{
  privateApi: Nullable<InstanceType<typeof PrivateApiClient>>;
  publicApi: Nullable<AxiosInstance>;
}>({
  privateApi: null,
  publicApi: null,
});
