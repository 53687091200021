import { hostsAtom, httpClientAtom } from "@sunrise/http-client";
import { selectJwtUserToken } from "@sunrise/jwt";
import { isNil } from "@sunrise/utils";
import { atomWithMutation, queryClientAtom } from "jotai-tanstack-query";

import { flushRecordingsState } from "./flush-recordings-state";
import { recordingByRecordingIdAtom } from "./query-atoms/recording-by-recording-id.query.atom";
import { recordingByEpgIdAtom } from "./recording-by-epg-id.atom";
import { recordingStatusByEpgIdAtom } from "./recording-status-by-epg-id.atom";
import { deleteSingleRecording } from "./recordings.service";
import type { EPGEntryId, RecordingId } from "@sunrise/backend-types-core";
import { queryKeys, type RecordingStatus } from "@sunrise/backend-types";

export const deleteSingleRecordingMutationAtom = atomWithMutation<
  void,
  {
    epgEntryId: EPGEntryId;
    recordingId: RecordingId;
  },
  unknown
>((get) => {
  const host = get(hostsAtom).api;
  if (isNil(host)) throw new Error("Host is not set");

  const privateApi = get(httpClientAtom).privateApi;
  if (isNil(privateApi)) throw new Error("Private api is not set");

  const queryClient = get(queryClientAtom);
  const queryKey = queryKeys.recordingsStatus(get(selectJwtUserToken));

  return {
    mutationKey: ["delete-recording"],
    mutationFn: ({ recordingId }) =>
      deleteSingleRecording(host, privateApi, recordingId),
    onMutate: ({ epgEntryId, recordingId }) => {
      // NOTE: temporarily remove status
      let statuses = (queryClient.getQueryData(queryKey) ||
        []) as RecordingStatus[];
      statuses = statuses.filter((s) => s.id !== recordingId);

      // TODO find better way to clear recordings
      recordingStatusByEpgIdAtom.remove(epgEntryId);
      recordingByEpgIdAtom.remove(epgEntryId);
      recordingByRecordingIdAtom.remove(recordingId);
      queryClient.setQueryData(queryKey, statuses);
    },
    onSettled: function () {
      flushRecordingsState(queryClient, get);
    },
  };
});
