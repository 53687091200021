import { type TranslateFn } from "@sunrise/i18n";

import { programIsPlayingAtTime } from "./program-is-playing-at-time";
import { getDayOfWeekTranslation } from "@sunrise/time";
import type { Language } from "@sunrise/backend-types-core";

export async function makeRelativeDayOfWeekTranslation(
  start: Date,
  end: Date,
  now: Date,
  language: Language,
  t: TranslateFn,
): Promise<string> {
  if (programIsPlayingAtTime({ startTime: start, endTime: end }, now)) {
    return t("live");
  }

  return getDayOfWeekTranslation(start, now, language, t);
}
