import {
  FocusContext,
  useFocusable,
} from "@noriginmedia/norigin-spatial-navigation";
import { currentLanguageAtom, getLocalizedValue } from "@sunrise/i18n";
import { useTranslator } from "@sunrise/translator";
import { isNil } from "@sunrise/utils";
import { recommendationsDataAtom } from "@sunrise/yallo-recommendations";
import { disableAnimationsAtom } from "@sunrise/yallo-settings";
import { useVirtualizer } from "@tanstack/react-virtual";
import clsx from "clsx";
import { useAtomValue, useSetAtom } from "jotai";
import { ReactElement, useEffect, useMemo, useRef } from "react";

import { recommendationRowSize } from "@/config/size";
import { SCREEN_HEIGHT_IN_PX, SCREEN_WIDTH_IN_PX } from "@/core/constants";
import {
  actionRecommendationsSetCoordinates,
  actionRecommendationsSetOffsetRow,
  recommendationsAtom,
  selectRecommendationsCoordinates,
  selectRecommendationsOffsetRow,
} from "@/features/recommendations";
import { itemAtRecommendationCoordinatesAtom } from "@/features/recommendations/item-at-recommendation-coordinates.atom";
import { useRecommendationsNavigation } from "@/features/recommendations/use-recommendations-nevigation";
import { VirtualListRow } from "@/features/recommendations/virtual-list-row";
import { canPullFocusAtom } from "@/modules/ui/can-pull-focus.atom";

import * as styles from "./recommendations.css";

export type RecommendationsProps = CommonProps & {
  focusKey: string;
};

export function Recommendations({
  "data-testid": dataTestId = "Recommendations",
  ...props
}: RecommendationsProps): ReactElement {
  const parentRef = useRef<HTMLDivElement>(null);
  const t = useTranslator();
  const data = useAtomValue(recommendationsDataAtom);
  const dispatchFocusedItem = useSetAtom(recommendationsAtom);
  const coordinates = useAtomValue(selectRecommendationsCoordinates);
  const language = useAtomValue(currentLanguageAtom);

  // Contains the rows and their translated titles.
  // This should be a pretty stable reference. It will only change when we reload the backend data.
  const rows = useMemo(() => {
    return data
      .map((row) => {
        const height = recommendationRowSize[row.kind].height;

        if (isNil(height) || height === 0) {
          return null;
        }

        return {
          data: {
            ...row,
            title:
              typeof row.title === "string"
                ? t(row.title)
                : getLocalizedValue(row.title, language),
          },
          height,
        };
      })
      .filter((row) => !isNil(row) && row.data.items.length > 0);
  }, [t, data, language]);

  const virtualizer = useVirtualizer({
    overscan: 1,
    count: rows.length,
    // Gets the initial offset as stored.
    // Whenever we scroll to another row, we also update this offset.
    initialOffset: useAtomValue(selectRecommendationsOffsetRow),
    initialRect: {
      width: SCREEN_WIDTH_IN_PX,
      height: SCREEN_HEIGHT_IN_PX,
    },
    getScrollElement: () => parentRef.current,
    estimateSize: (idx) => {
      const row = rows[idx];
      if (isNil(row)) throw new Error(`row with idx ${idx} no longer found.`);
      return row.height;
    },
  });

  const { onEnter, onArrow } = useRecommendationsNavigation();

  const focusable = useFocusable({
    isFocusBoundary: true,
    focusKey: props.focusKey,
    onArrowPress: onArrow,
    onEnterPress: onEnter,
  });

  const canPullFocus = useAtomValue(canPullFocusAtom);
  const { focusSelf } = focusable;
  useEffect(
    function delegateFocus() {
      if (!canPullFocus) return;
      focusSelf();
    },
    [canPullFocus, focusSelf],
  );

  /**
   * Try to re-select a new item when the current one is not available.
   */
  const itemAtCoordinates = useAtomValue(itemAtRecommendationCoordinatesAtom);
  useEffect(() => {
    const hasValidSelection = !!itemAtCoordinates(coordinates);

    if (!hasValidSelection && focusable.focused) {
      const suggested = { rowIndex: coordinates.rowIndex, colIndex: 0 };
      const hasSuggested = !!itemAtCoordinates(suggested);

      dispatchFocusedItem(
        actionRecommendationsSetCoordinates(
          hasSuggested
            ? { column: suggested.colIndex, row: suggested.rowIndex }
            : {
                row: 0,
                column: 0,
              },
        ),
      );
    }
  }, [dispatchFocusedItem, focusable.focused, coordinates, itemAtCoordinates]);

  // Scroll to the focused row whenever the index changes.
  // We also make sure to store the offset.
  const disableAnimations = useAtomValue(disableAnimationsAtom);
  useEffect(() => {
    if (isNil(coordinates)) {
      return;
    }

    const [offset] = virtualizer.getOffsetForIndex(coordinates.rowIndex);

    parentRef.current?.scrollTo({
      top: offset,
      behavior: disableAnimations ? "auto" : "smooth",
    });
    dispatchFocusedItem(actionRecommendationsSetOffsetRow(offset));
  }, [coordinates, virtualizer, disableAnimations, dispatchFocusedItem]);

  return (
    <FocusContext.Provider value={focusable.focusKey}>
      <div
        data-testid={dataTestId}
        ref={focusable.ref}
        className={clsx([styles.root, props.className])}
      >
        {/* The scrollable element for your list */}
        <div ref={parentRef} className={styles.scrollableContent}>
          {/* The large inner element to hold all of the items */}
          <div
            className={styles.columnItems}
            style={{
              height: `${virtualizer.getTotalSize()}px`,
            }}
            data-testid={`${dataTestId}.rows`}
          >
            {/* Only the visible items in the virtualizer, manually positioned to be in view */}
            {virtualizer.getVirtualItems().map((virtualItem) => {
              const maybeItem = rows[virtualItem.index];
              if (isNil(maybeItem)) return null;

              const isRowFocused =
                focusable.focused && coordinates.rowIndex === virtualItem.index;
              const focusedIndex = isRowFocused ? coordinates.colIndex : null;

              return (
                <VirtualListRow
                  key={virtualItem.key}
                  className={styles.virtualRow}
                  size={virtualItem.size}
                  start={virtualItem.start}
                  data-testid={`${dataTestId}.rows`}
                  result={maybeItem.data}
                  idx={virtualItem.index}
                  focusedIndex={focusedIndex ?? null}
                />
              );
            })}
          </div>
        </div>
      </div>
    </FocusContext.Provider>
  );
}
