import type {
  RecordingStatusFilter,
  RecordingTypeFilter,
} from "@sunrise/backend-types";
import { atom } from "jotai";

/**
 * Holds the current recordings filter type.
 */
export const currentRecordingsTypeFilterAtom = atom<RecordingTypeFilter>("all");
/**
 * Holds the current recordings filter status.
 */
export const currentRecordingsStatusFilterAtom =
  atom<RecordingStatusFilter>("all");

/**
 * Holds the current recordings filter translations key (combined status and type).
 */
export const currentRecordingsFilterAtom = atom((get) => {
  const type = get(currentRecordingsTypeFilterAtom);
  const status = get(currentRecordingsStatusFilterAtom);

  if (type === "all" && status === "all") return "recordings_filter_button_all";

  switch (type) {
    case "movies":
      switch (status) {
        case "recorded":
          return "recordings_filter_recorded_movies";
        case "planned":
          return "recordings_filter_planned_movies";
        default:
          return "recordings_filter_button_movies";
      }
    case "series":
      switch (status) {
        case "recorded":
          return "recordings_filter_recorded_series";
        case "planned":
          return "recordings_filter_planned_series";
        default:
          return "recordings_filter_button_series";
      }
    case "all":
      switch (status) {
        case "recorded":
          return "recordings_filter_button_recorded";
        case "planned":
          return "recordings_filter_button_planned";
      }
      break;
  }

  return "recordings_filter_button_all";
});
