import {
  continueWatchingByEpgIdAtom,
  isFullyWatchedByEpgIdAtom,
} from "@sunrise/yallo-continue-watching";
import { useAtomValue, useSetAtom } from "jotai";
import { useCallback, useMemo } from "react";

import {
  actionPlayerManagerPlayRecording,
  actionPlayerManagerPlayReplay,
  playerManagerAtom,
} from "../player-manager.atom";
import type { ChannelId, EPGEntryId } from "@sunrise/backend-types-core";

/**
 * A hook which will return if we can resume the corrent item or not and a function to resume it.
 * It needs the recordingId if it is available as well as a channelId to not overcomplicate the logic here.
 * They could be made optional in the future and load the data themselves as needed.
 * For now this hook is called only in usePlayProgram so we can just pass in the data from there.
 */
export function useResumeProgram({
  epgId,
  channelId,
}: {
  epgId: EPGEntryId;
  channelId: ChannelId;
}): { canResume: boolean; resume: () => void } {
  const dispatchPlayerManager = useSetAtom(playerManagerAtom);

  const isFullyWatched = useAtomValue(isFullyWatchedByEpgIdAtom({ epgId }));

  const continueWatchingItem = useAtomValue(
    continueWatchingByEpgIdAtom({
      epgId: epgId,
    }),
  );

  const canResume = !!continueWatchingItem && !isFullyWatched;
  const resume = useCallback(() => {
    if (!canResume) {
      return;
    }

    switch (continueWatchingItem.type) {
      case "replay":
        dispatchPlayerManager(
          actionPlayerManagerPlayReplay(
            continueWatchingItem.epg_entry.id,
            continueWatchingItem.channel.id ?? channelId,
            new Date(continueWatchingItem.moment),
          ),
        );
        break;
      case "recording":
        dispatchPlayerManager(
          actionPlayerManagerPlayRecording(
            continueWatchingItem.recording.id,
            channelId,
            continueWatchingItem.play_position_second,
          ),
        );
        break;
    }
  }, [
    continueWatchingItem,
    dispatchPlayerManager,
    epgId,
    channelId,
    canResume,
  ]);

  return useMemo(() => ({ canResume, resume }), [canResume, resume]);
}
