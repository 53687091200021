import { jwtAtom, selectIsLoggedIn } from "@sunrise/jwt";
import { actionLocationNavigate, locationAtom } from "@sunrise/location";
import { useAtom, useAtomValue } from "jotai";
import { useEffect } from "react";

import { makeProtectedRoute, route } from "@/config/route";

export function RedirectionGuard(): null {
  const [location, dispatchLocation] = useAtom(locationAtom);
  const isLoggedIn = useAtomValue(selectIsLoggedIn);
  const jwtData = useAtomValue(jwtAtom);
  const isProtected = location.pathname?.startsWith(makeProtectedRoute(""));
  const shouldStartOnHomePage =
    jwtData.decodedPayload?.feature_set.features.start_on_home_page;

  useEffect(() => {
    if (isLoggedIn) {
      if (isProtected) return;
      dispatchLocation(
        actionLocationNavigate(
          shouldStartOnHomePage ? route.home.root() : route.tv.root(),
        ),
      );
    } else {
      dispatchLocation(actionLocationNavigate(route.landing.root()));
    }
  }, [dispatchLocation, isLoggedIn, isProtected, shouldStartOnHomePage]);
  return null;
}
