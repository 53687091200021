import {
  ngChannelApiAtom,
  type HTTPValidationError,
  type PageBaseChannelSchema,
} from "@sunrise/backend-ng-channel";
import type { ChannelGroupId, Language } from "@sunrise/backend-types-core";
import { currentLanguageAtom } from "@sunrise/i18n";
import { selectJwtUserToken } from "@sunrise/jwt";
import type { InfiniteData } from "@tanstack/react-query";
import { atomWithInfiniteQuery } from "jotai-tanstack-query";
import { atomFamily } from "jotai/utils";

const PAGE_SIZE = 30;

export const channelsForGivenChannelGroupIdQueryNgAtom = atomFamily(
  (channelGroupId: ChannelGroupId) => {
    const inner = atomWithInfiniteQuery<
      PageBaseChannelSchema,
      HTTPValidationError,
      InfiniteData<PageBaseChannelSchema>,
      [string | null, Language, string, string],
      number
    >((get) => {
      const ngApi = get(ngChannelApiAtom);
      const language = get(currentLanguageAtom);

      return {
        initialPageParam: 1,
        getNextPageParam: (lastPage) => {
          if (!lastPage) return undefined;
          const { page, pages } = lastPage;
          if (!pages || !page) return undefined;

          return page < pages ? page + 1 : undefined;
        },
        queryKey: [
          get(selectJwtUserToken),
          language,
          "channel-list-channels",
          channelGroupId,
        ],
        queryFn: async ({ pageParam }) => {
          return (
            await ngApi.channel.channelListDetailChannelsChannelV1ChannelListsChannelListIdChannelsGet(
              channelGroupId,
              {
                page: pageParam,
                size: PAGE_SIZE,
              },
            )
          ).data;
        },
      };
    });

    inner.debugLabel = `channelsForGivenChannelGroupIdQueryNgAtom(${channelGroupId})`;

    return inner;
  },
);
