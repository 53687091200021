import { hostsAtom, httpClientAtom } from "@sunrise/http-client";
import { type PlayRequestToStream } from "@sunrise/player-manager";
import { type Store } from "@sunrise/store";
import { nowSecondAtom } from "@sunrise/time";
import { epgDetailsForEpgEntryId } from "@sunrise/yallo-epg";
import { recordingByRecordingIdAtom } from "@sunrise/yallo-recordings";
import { getReplayStartTime } from "@sunrise/yallo-replay";
import { StreamNotFoundError, fetchStreamRequest } from "@sunrise/yallo-stream";
import { isAxiosError } from "axios";
import { isNil } from "lodash";

import type {
  RecordingStreamResponse,
  ReplayStream,
  SimpleStream,
  Stream,
} from "@sunrise/backend-types";
import type { ChannelId } from "@sunrise/backend-types-core";
import type { PlayRequest } from "@sunrise/yallo-player-types";
import { drmEnabledAtom } from "./drm-enabled.atom";
import { channelByIdAtom } from "@sunrise/yallo-channel-group";

/**
 * Converts a PlayRequest to a Stream in the context of yallo.
 *
 * This is a naive implementation since we only have live playout at the moment.
 *
 * This function when invoked with the necessary dependencies will return a function fo the `PlayRequestToStream` type.
 * This function will eventually return another function. Let's call this the `StreamResolveFunction`.
 *
 * When the first function is invoked, we will check with the backend what stream we need to start building
 * but most importantly if we can actually play it for the current user.
 * If we can't play it for the current user it needs to throw some sort of URL so that the PlayerManager can abort playout of the ads.
 *
 * The StreamResolveFunction is in place for preflight.
 * Once preflight is added, the first step will need to do the preflight request. (That will basically check if there are any reasons other than adblocker why the user can't stream it.)
 * The second step will then be called to return the final stream after the ads have played out. (Which will then run an additional check that the user did play out ads as required.)
 *
 * So for now, we need to ask for the final stream in the first step so we know before ad playout that the user can't actually play the stream.
 */
export function createPlayRequestToStream(
  store: Store,
): PlayRequestToStream<PlayRequest> {
  /**
   * Naive implementation since we only have live playout atm.
   *
   * Will check with the other modules what stream we need to request for the given PlayRequest.
   * If it can't figure out a stream, it will throw an error.
   *
   * If we have a stream URL the backend is contacted.
   * The backend can then decide to block the stream request and an error will be thrown.
   *
   * @throws {StreamNotFoundError}
   *   When we can't figure out a stream for the given PlayRequest.
   * @throws {StreamBlockedByBackendError}
   *   The stream was found but the backend is actively blocking the request with a known code.
   * @throws {Error}
   *   We can also have an unknown error from the backend. Or when we did not yet implement a stream.
   */
  return async (request: PlayRequest) => {
    const { url, offset } = await getStreamUrlForPlayRequest(store, request);

    const host = store.get(hostsAtom).api;
    if (isNil(host)) throw new Error("missing host");

    const { privateApi } = store.get(httpClientAtom);
    if (!privateApi) throw new Error("missing privateApi");

    // NOTE: For non-preflight requests, we need to make sure to ask the backend for the stream in the first phase.
    if (request.type === "recording") {
      const recordingRes = await fetchStreamRequest<RecordingStreamResponse>(
        privateApi,
        host,
        url,
      );

      return (): Promise<Stream> => {
        const stream: SimpleStream = {
          licenseUrl: recordingRes.license_url,
          type: recordingRes.stream_type,
          url: recordingRes.play_url,
          provider: recordingRes.provider,
        };

        return Promise.resolve(stream);
      };
    }

    const response = await fetchStreamRequest(privateApi, host, url);

    return (): Promise<Stream> => {
      const stream: SimpleStream = {
        licenseUrl: response.license_url,
        type: response.stream_type,
        url: response.url,
        provider: response.provider,
      };

      if (offset) {
        return Promise.resolve({
          ...stream,
          offset,
          markers: response.markers,
        } satisfies ReplayStream);
      }

      return Promise.resolve(stream);
    };
  };
}

async function getStreamUrlForPlayRequest(
  store: Store,
  request: PlayRequest,
): Promise<{ url: string; offset?: number }> {
  switch (request.type) {
    case "live": {
      const url = await getChannelStreamUrl(store, request.channelId);
      return {
        url: [
          url,
          new URLSearchParams({
            ...(!store.get(drmEnabledAtom) ? { stream_type: "dash" } : {}),
          }).toString(),
        ].join("?"),
      };
    }
    case "replay": {
      // load epg details. Determine actual start of the epg stream.
      // Even though we have a start time, we should also be allowed to start the replay if the program is starting before but ending after the replay window start.
      const [epg, url] = await Promise.all([
        store.get(epgDetailsForEpgEntryId({ epgId: request.epgId })),
        getChannelStreamUrl(store, request.channelId),
      ]);

      const now = store.get(nowSecondAtom);
      const start = getReplayStartTime(epg, now);

      return {
        url: [
          url,
          new URLSearchParams({
            timepoint: start.toISOString(),
            ...(!store.get(drmEnabledAtom) ? { stream_type: "dash" } : {}),
          }).toString(),
        ].join("?"),
        offset: start.getTime(),
      };
    }
    case "recording": {
      const { recordingId } = request;
      try {
        const { data } = await store.get(
          recordingByRecordingIdAtom(recordingId),
        );

        if (isNil(data)) {
          throw new StreamNotFoundError({
            type: "recording_id_missing",
            recordingId,
          });
        }

        if (isNil(data?.stream_url)) {
          throw new StreamNotFoundError({
            type: "recording_missing_stream_url",
            recordingId,
          });
        }

        // NOTE: recordings cannot be explicitly requested without DRM (stream_type param not supported)
        return {
          url: data.stream_url,
        };
      } catch (err) {
        if (isAxiosError(err) && err.code === "ERR_BAD_REQUEST") {
          throw new StreamNotFoundError({
            type: "recording_id_missing",
            recordingId,
          });
        }

        throw err;
      }
    }
  }
}

async function getChannelStreamUrl(
  store: Store,
  channelId: ChannelId,
): Promise<string> {
  const channel = await store.get(channelByIdAtom(channelId));

  if (!channel) {
    throw new StreamNotFoundError({
      type: "missing-channel-info",
      channelId,
    });
  }
  const url = channel.stream;

  if (isNil(url)) {
    throw new StreamNotFoundError({
      type: "missing-stream-url",
      channelId,
    });
  }

  return url;
}
