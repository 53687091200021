import { forwardRef } from "react";
import clsx from "clsx";

import type { Nullable } from "@sunrise/utils";

import * as styles from "./program-box.css";
import { Kind } from "./types";

export type ProgramBoxProps = CommonProps & {
  kind: Kind;
  children: React.ReactNode;
  focused?: boolean;
  startDate?: Nullable<Date>;
  endDate?: Nullable<Date>;
  index?: number;
  "data-position"?: number;
};

export const ProgramBoxWrapper = forwardRef<HTMLDivElement, ProgramBoxProps>(
  function ForwardRefProgramBox(
    {
      "data-testid": dataTestId = "box-wrapper",
      "data-focused": dataFocused,
      "data-position": dataPosition,
      index,
      startDate,
      endDate,
      kind,
      children,
      className,
      focused,
    },
    ref,
  ) {
    return (
      <div
        data-focused={dataFocused}
        data-testid={dataTestId}
        data-position={dataPosition}
        data-start-date={startDate?.toISOString()}
        data-end-date={endDate?.toISOString()}
        data-grid-index={index}
        className={clsx(
          styles.root,
          styles[kind],
          className,
          focused && styles.focused,
        )}
        ref={ref}
      >
        {children}
      </div>
    );
  },
);
