import { type CSSProperties, forwardRef, type ReactNode } from "react";

import type {
  ChannelResultItem,
  ChannelSearchEntry,
} from "@sunrise/backend-types";

import { ListChannelItem } from "@/components";

import * as styles from "./channel-result.css";

export type ChannelResultProps = {
  channel: ChannelSearchEntry;
  focused: boolean;
  style?: CSSProperties;
  "data-position"?: number;
};

export const ChannelResult = forwardRef<HTMLDivElement, ChannelResultProps>(
  function ChannelResult(
    { channel, focused, "data-position": dataPosition, style },
    ref,
  ): ReactNode {
    const item: ChannelResultItem = {
      type: "channel",
      details: {
        type: "regular",
        id: channel.id,
      },
    };

    return (
      <ListChannelItem
        ref={ref}
        item={item}
        data-position={dataPosition}
        style={style}
        className={styles.channelItem}
        focused={focused}
        posterUrl={null}
        key={item.details.id}
      />
    );
  },
);
