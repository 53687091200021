import type { Nullable } from "@sunrise/utils";
import { recordingStatusByEpgIdAtom } from "@sunrise/yallo-recordings";
import { useAtomValue } from "jotai";
import { ReactNode } from "react";

import { DetailsTags } from "./details-tags";
import type { AssetId, EPGEntryId } from "@sunrise/backend-types-core";

export type DetailsTagsRecordingProps = {
  assetId: Nullable<AssetId>;
  tags: string[];
  epgId: EPGEntryId;
} & CommonProps;

export function DetailsTagsRecording({
  assetId,
  tags,
  epgId,
  "data-testid": testId,
}: DetailsTagsRecordingProps): ReactNode {
  const recordingStatus = useAtomValue(recordingStatusByEpgIdAtom(epgId));

  return (
    <DetailsTags
      assetId={assetId}
      tags={tags}
      recordingStatus={recordingStatus?.status}
      data-testid={testId}
    />
  );
}
