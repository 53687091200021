import { type ReactNode, useCallback } from "react";
import { atom, useAtomValue } from "jotai";
import { useAtomCallback } from "jotai/utils";

import { playerAtom } from "@sunrise/player";
import { useTranslator } from "@sunrise/translator";
import {
  forceHideWhatIsNextSideBarAtom,
  whatIsNextEffect,
} from "@sunrise/what-is-next";
import { whatIsNextAtom } from "@sunrise/yallo-recordings";

import { SideBar } from "@/components/side-bar/side-bar";
import { useKeyboardNavigation } from "@/modules/keyboard-navigation";

import { shouldShowWhatIsNextSideBarAtom } from "../what-is-next.atom";
import * as styles from "./what-is-next-side-bar.css";
import { WhatIsNextSideBarContentWith } from "./what-is-next-side-bar-content-with";
import { WhatIsNextSideBarContentWithout } from "./what-is-next-side-bar-content-without";

const localWidgetFocusKey = {
  root: "what-is-next-side-bar.container",
};

const NO_DATA_ATOM = atom(null);

export function WhatIsNextSideBar(): ReactNode {
  const shouldShow = useAtomValue(shouldShowWhatIsNextSideBarAtom);

  useAtomValue(whatIsNextEffect);

  const { playRequest } = useAtomValue(playerAtom);

  const t = useTranslator();

  const isRecording = playRequest?.type === "recording";
  const recordingId = isRecording ? playRequest.recordingId : null;
  const nextRecording = useAtomValue(
    recordingId ? whatIsNextAtom(recordingId) : NO_DATA_ATOM,
  );

  const doHide = useAtomCallback(
    useCallback((_, set) => {
      set(forceHideWhatIsNextSideBarAtom, true);
    }, []),
  );

  useKeyboardNavigation({
    onBack: doHide,
    isEnabled: shouldShow,
  });

  if (!shouldShow) {
    return null;
  }

  return (
    <SideBar
      shouldShow={shouldShow}
      focusKey={localWidgetFocusKey.root}
      data-testid="what-is-next-side-bar"
    >
      {(handlers) => (
        <>
          <div className={styles.title}>{t("up_next_title")}</div>

          {nextRecording ? (
            <WhatIsNextSideBarContentWith
              next={nextRecording}
              onArrowPress={handlers.onArrowPress("left")}
            />
          ) : (
            <WhatIsNextSideBarContentWithout
              onArrowPress={handlers.onArrowPress("left")}
            />
          )}
        </>
      )}
    </SideBar>
  );
}
