export function pixelsToTime(
  pixels: number,
  startTime: Date,
  endTime: Date,
  width: number,
): Date {
  const timeGap = endTime.getTime() - startTime.getTime();
  const dateGap = (pixels * timeGap) / width;
  return new Date(startTime.getTime() + dateGap);
}
