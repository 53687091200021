import type {
  AssetId,
  ChannelId,
  EPGEntryId,
  Language,
  RecordingGroupId,
  TimeDay,
  UserToken,
} from "@sunrise/backend-types-core";
import { type Nullable } from "@sunrise/utils";

import type { StaffType } from "./asset.types";
import type {
  RecordingGroupSort,
  RecordingsSort,
  RecordingStatusFilter,
  RecordingTypeFilter,
} from "./recordings.types";
import { SearchQuery } from "./search.types";

const getUserTokenQueryKey = (at: Nullable<UserToken>) => ["at", at] as const;

const recordingsBase = (
  at: Nullable<UserToken>,
  ng: boolean,
  language: "all" | Language,
) =>
  [
    ...getUserTokenQueryKey(at),
    ng ? "ng" : "legacy",
    "recordings",
    language,
  ] as const;

/**
 * When knowing the latest state relies on polling for the data use this as the base key.
 */
const recordingsPullBase = (
  at: Nullable<UserToken>,
  ng: boolean,
  language: "all" | Language,
) => [...recordingsBase(at, ng, language), "pull"] as const;

/**
 * We initially do a pull but then the state is pushed to us through the websocket.
 * So we should not flush it on the same interval as the "pull-based" recordings.
 */
const recordingsPushBase = (
  at: Nullable<UserToken>,
  ng: boolean,
  language: "all" | Language,
) => [...recordingsBase(at, ng, language), "push"] as const;

export const queryKeys = {
  assetByIdLegacy: (assetId: Nullable<AssetId>) => ["assets", assetId],
  assetById: (assetId: Nullable<AssetId>, language?: Language) => [
    "assets",
    assetId,
    language,
  ],
  assetStaffById: (assetId: Nullable<AssetId>, types: StaffType[] = []) => [
    "assets",
    assetId,
    "staff",
    types.join(","),
  ],
  assetRatingById: (assetId: Nullable<AssetId>) => [
    "assets",
    assetId,
    "rating",
  ],

  epgById: (epgId: Nullable<EPGEntryId>, language: Language) => [
    "epg",
    epgId,
    language,
  ],

  episodesByAssetId: (assetId: Nullable<AssetId>, language: Language) => [
    "episodes-by-asset-id",
    assetId,
    language,
  ],

  userToken: getUserTokenQueryKey,

  user: (at: Nullable<UserToken>) =>
    [...getUserTokenQueryKey(at), "user"] as const,

  channelPackages: (at: Nullable<UserToken>) =>
    [...getUserTokenQueryKey(at), "channel-packages"] as const,

  channelGroups: (at: Nullable<UserToken>) =>
    [...getUserTokenQueryKey(at), "channel-groups"] as const,

  channelEpgCollection: (
    channelId: Nullable<ChannelId>,
    language: Language,
    day: TimeDay,
  ) => ["channel", channelId, language, day],

  // recordings
  recordingsBase,
  recordingsPullBase,
  recordingStats: (at: Nullable<UserToken>, ng: boolean) =>
    [
      // NOTE: It's only temporary that NG uses the pull base.
      //       As soon as the NG websocket is implemented we should wire it up to work through push again.
      ...(ng ? recordingsPullBase : recordingsPushBase)(at, ng, "all"),
      "recording-stats",
    ] as const,

  recordingByRecordingId: (
    at: Nullable<UserToken>,
    ng: boolean,
    language: Language | "all",
    recordingId: string,
  ) =>
    [
      ...recordingsPullBase(at, ng, language),
      "recordings-per-id",
      recordingId,
    ] as const,
  recordingsStatus: (at: Nullable<UserToken>) =>
    [...recordingsPushBase(at, false, "all"), "recordings-status"] as const,
  recordingsOverviewLegacy: (
    at: Nullable<UserToken>,
    sort: RecordingsSort,
    language: string,
    typeFilter: RecordingTypeFilter,
    statusFilter: RecordingStatusFilter,
  ) =>
    [
      ...recordingsPullBase(at, false, "all"),
      "recordings-overview",
      sort,
      language,
      statusFilter,
      typeFilter,
    ] as const,
  /**
   * NOTE: This is a separate query key since the filter & sorting types are not the same as the legacy one.
   */
  recordingsOverview(
    at: Nullable<UserToken>,
    language: Language,
    sort: string,
    filter: string,
  ) {
    return [
      ...recordingsPullBase(at, true, language),
      language,
      "recordings-overview",
      sort,
      filter,
    ] as const;
  },
  haveRecordingSchedules: (at: Nullable<UserToken>, assetId: string) =>
    [
      ...recordingsPullBase(at, false, "all"),
      "have-recording-schedules",
      assetId,
    ] as const,
  recordingGroupItems: (
    at: Nullable<UserToken>,
    assetId: string,
    sort: RecordingGroupSort,
    traverseChild: boolean,
  ) =>
    [
      ...recordingsPullBase(at, false, "all"),
      "recording-group-items",
      assetId,
      sort.field,
      sort.direction,
      traverseChild ? "traverse" : "no-traverse",
    ] as const,
  recordingItemsByAssetId: (at: Nullable<UserToken>, assetId: string) =>
    [
      ...recordingsPullBase(at, false, "all"),
      "recording-items-by-asset-id",
      assetId,
    ] as const,
  // TODO: doublecheck if that is still used ...
  haveRecordings: (at: Nullable<UserToken>, assetId: string) =>
    [
      ...recordingsPullBase(at, false, "all"),
      "have-recordings",
      assetId,
    ] as const,
  recordingGroupsByRecordingGroupId: (
    at: Nullable<UserToken>,
    recordingGroupId: RecordingGroupId,
  ) =>
    [
      ...recordingsPullBase(at, false, "all"),
      "recording-groups-by-recording-group-id",
      recordingGroupId,
    ] as const,

  recordingByRecordingIdNg: (
    at: Nullable<UserToken>,
    recordingId: string,
    language: Language,
  ) =>
    [
      ...recordingsPullBase(at, true, language),
      "recordings-per-id",
      recordingId,
    ] as const,

  continueWatching: (at: Nullable<UserToken>) =>
    [...getUserTokenQueryKey(at), "continue-watching-paged"] as const,
  fullyWatched: (at: Nullable<UserToken>) =>
    [...getUserTokenQueryKey(at), "fully-watched"] as const,

  searchEpgItems: (filter: SearchQuery["filter"], query: string) =>
    ["search-epg", filter, query] as const,
  searchChannels: (query: string) => ["search-channels", query] as const,
  searchHistory: (at: Nullable<UserToken>) =>
    [...getUserTokenQueryKey(at), "search-history"] as const,
};
