import { type Coordinates } from "@sunrise/yallo-guide";
import { atomWithReducer } from "jotai/utils";

import {
  isArrowDownKey,
  isArrowLeftKey,
  isArrowRightKey,
  isArrowUpKey,
} from "@/utils/navigation";

import {
  ActionGuideChangeOffset,
  ActionGuideResetOffset,
  ActionGuideSetOffset,
  GridStateAction,
  GuideGridState,
} from "./types";

export const DEFAULT_COORDINATES_STATE: Readonly<Coordinates> = {
  x: -1,
  y: -1,
};

export function makeGuideGridOffsetDefaultState(
  coordinates?: Partial<Readonly<Coordinates>>,
): Readonly<Coordinates> {
  return {
    ...DEFAULT_COORDINATES_STATE,
    ...coordinates,
  };
}

function gridOffsetAtomReducer(
  current: Coordinates,
  action:
    | ActionGuideChangeOffset
    | ActionGuideResetOffset
    | ActionGuideSetOffset,
): Coordinates {
  switch (action.type) {
    case "guide/change-offset": {
      const { direction, offset } = action;
      const { x, y } = current;

      const updatedX = isArrowLeftKey(direction)
        ? bottomOut(x - offset)
        : x + (isArrowRightKey(direction) ? offset : 0);
      const updatedY = isArrowUpKey(direction)
        ? bottomOut(y - offset)
        : y + (isArrowDownKey(direction) ? offset : 0);

      return { x: updatedX, y: updatedY };
    }
    case "guide/reset-offset": {
      return makeGuideGridOffsetDefaultState();
    }
    case "guide/set-offset": {
      return {
        x: action.payload.x,
        y: action.payload.y,
      };
    }
  }
}

export const DEFAULT_GRID_STATE: Readonly<GuideGridState> = {
  coordinates: DEFAULT_COORDINATES_STATE,
  offsetPriority: "grid",
  selection: null,
  isFocused: false,
  jumpToDate: null,
};

export function makeDefaultGridState(
  state?: Partial<GuideGridState>,
): GuideGridState {
  return {
    ...DEFAULT_GRID_STATE,
    ...state,
  };
}

export function gridStateReducer(
  current: GuideGridState,
  action: GridStateAction,
): GuideGridState {
  switch (action.type) {
    case "guide/change-offset":
    case "guide/reset-offset": {
      return {
        ...current,
        jumpToDate: null,
        // Delegate the main logic for the coordinates to the coordinates reducer.
        coordinates: gridOffsetAtomReducer(current.coordinates, action),
        offsetPriority: "grid",
      };
    }
    case "guide/reset-selection": {
      return {
        ...current,
        selection: null,
        offsetPriority: "grid",
      };
    }
    case "guide/set-offset": {
      return {
        ...current,
        // Delegate the main logic for the coordinates to the coordinates reducer.
        coordinates: gridOffsetAtomReducer(current.coordinates, action),
        offsetPriority: action.payload.offsetPriority,
      };
    }
    case "guide/set-selection": {
      return {
        ...current,
        jumpToDate: null,
        offsetPriority: "selection",
        selection: action.payload,
      };
    }
    case "guide/set-grid-focus": {
      return {
        ...current,
        isFocused: action.payload.isFocused,
      };
    }
    case "guide/jump-to-date": {
      return {
        ...current,
        jumpToDate: action.payload.date,
        offsetPriority: "select-date",
        selection: null,
      };
    }
    case "guide/reset": {
      return {
        ...DEFAULT_GRID_STATE,
      };
    }
  }
}

export const gridStateAtom = atomWithReducer(
  DEFAULT_GRID_STATE,
  gridStateReducer,
);

function bottomOut(number: number): number {
  if (number < 0) {
    return 0;
  }

  return number;
}
