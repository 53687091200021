import { ComponentProps } from "react";
import { JSX } from "react";
import {
  ArrowPressHandler,
  useFocusable,
} from "@noriginmedia/norigin-spatial-navigation";

import { IconButton } from "../icon-button/icon-button";

type IconButtonProps = ComponentProps<typeof IconButton> & {
  onEnterPress: () => void;
  onArrowPress?: ArrowPressHandler;
  focusKey?: string;
};

export function FocusIconButton(props: IconButtonProps): JSX.Element {
  if (props.disabled) {
    return <IconButton {...props} />;
  }

  return <FocusIconButtonWithFocusable {...props} />;
}

/**
 * This wraps the generic button but adds focus functionality.
 */
function FocusIconButtonWithFocusable({
  onEnterPress,
  focusKey,
  onArrowPress,
  "data-testid": dataTestId,
  ...rest
}: IconButtonProps): JSX.Element {
  const { ref, focused, focusSelf } = useFocusable({
    onEnterPress,
    focusKey,
    onArrowPress,
  });

  return (
    <IconButton
      {...rest}
      ref={ref}
      focused={focused}
      data-focused={focused}
      data-testid={dataTestId ?? focusKey}
      onMouseEnter={focusSelf}
      onMouseClick={onEnterPress}
    />
  );
}
