import type { MemoryLevelConfig } from "@/modules/memory/types";

export const MEMORY_LEVELS: Record<string, MemoryLevelConfig> = {
  low: {
    value: 0,
    severity: "info",
    breadcrumb: true,
  },
  mid: {
    value: 50,
    severity: "info",
    breadcrumb: true,
  },
  high: {
    value: 80,
    severity: "warning",
    breadcrumb: true,
  },
  critical: {
    value: 95,
    severity: "warning",
    breadcrumb: true,
  },
  full: {
    value: 100,
    severity: "error",
  },
};

export const MEMORY_UPDATE_INTERVAL = 15_000;
