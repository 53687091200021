import { type ReactNode, useEffect, useState } from "react";
import clsx from "clsx";
import { format } from "date-fns";
import { atom } from "jotai";
import { useAtomValue } from "jotai";

import { currentLanguageAtom } from "@sunrise/i18n";
import { nowAtom } from "@sunrise/time";
import { useTranslator } from "@sunrise/translator";
import { makeRelativeDayOfWeekTranslation } from "@sunrise/yallo-epg";
import {
  selectorGuideSelection,
  selectorIsGridFocused,
} from "@sunrise/yallo-guide";
import { recordingStatusByEpgIdAtom } from "@sunrise/yallo-recordings";

import { RowProgramBox } from "@/components/boxes";
import { GUIDE_THROTTLE_ON_LONG_PRESS_IN_MS } from "@/features/guide/constants";

import * as styles from "./selected-program.css";

const NULL_ATOM = atom(null);

type SelectedProgramProps = CommonProps;

/**
 * Dummy implementation.
 * But it's enough to make the test pass and to give people an idea what is selected and how the full version will work.
 */
export function SelectedProgram({
  "data-testid": dataTestId,
  className,
}: SelectedProgramProps): ReactNode {
  const selection = useAtomValue(selectorGuideSelection);
  const isFocused = useAtomValue(selectorIsGridFocused);
  const now = useAtomValue(nowAtom);
  const language = useAtomValue(currentLanguageAtom);
  const t = useTranslator();

  const [relativeDay, setRelativeDay] = useState<string>();

  const recState = useAtomValue(
    selection ? recordingStatusByEpgIdAtom(selection.epgId) : NULL_ATOM,
  );

  useEffect(() => {
    if (selection) {
      const doAsync = async (): Promise<void> => {
        setRelativeDay(
          await makeRelativeDayOfWeekTranslation(
            selection.startTime,
            selection.endTime,
            now,
            language,
            t,
          ),
        );
      };

      void doAsync();
    }
  }, [language, now, selection, t]);

  if (!selection || !isFocused) {
    return null;
  }

  const start = format(selection.startTime, "HH:mm");
  const end = format(selection.endTime, "HH:mm");

  return (
    <div
      className={clsx([className, styles.container])}
      data-testid={`${dataTestId}.selected-program`}
      data-channel-id={selection.channelId}
      data-epg-id={selection.epgId}
      data-start-date={selection.startTime}
      data-end-date={selection.endTime}
    >
      <RowProgramBox
        throttleForPreviewInMs={GUIDE_THROTTLE_ON_LONG_PRESS_IN_MS}
        title={selection.title ?? ""}
        subtitle={selection.subtitle ?? ""}
        description={selection.plot ?? ""}
        start={start}
        end={end}
        durationInMinutes={selection.durationInMinutes}
        airTime={relativeDay ?? ""}
        logoUrl={""}
        coverImageUrl={selection.image}
        recordingState={recState?.status}
      />
    </div>
  );
}
