import { ReactNode } from "react";

import { ProgramBox } from "@/components/boxes";

export function DebugProgramBoxes(): ReactNode {
  const longTitle =
    "Very long program box title for a very long time LONG long title very long lots of words Very long program box title for a very long time LONG long title very long lots of words";
  const longSubtitle =
    "Very long subtitle for a very long time LONG long subtitle very long lots of words Very long subtitle for a very long time LONG long subtitle very long lots of words";
  const shortTitle = "Short title";
  const shortSubtitle = "short subtitle";
  const airTime = "Yesterday";
  const start = "20:00";
  const end = "21:00";
  const liveProgress = 68;
  const replayProgress = 43;
  const coverImageUrl =
    "https://t4.ftcdn.net/jpg/03/76/25/05/360_F_376250596_JSflOKCNDwwYOmQ6VfuUU7rfO5KlPugZ.jpg";
  const logoUrl =
    "https://media.idownloadblog.com/wp-content/uploads/2018/01/Netflix-Logo.png";
  return (
    <div style={{ margin: "3%" }}>
      <div>
        <h2>Normal program boxes</h2>
        <div style={{ display: "flex" }}>
          <ProgramBox
            kind="normal"
            title={longTitle}
            subtitle={longSubtitle}
            airTime={airTime}
            start={start}
            end={end}
            liveProgress={liveProgress}
            replayProgress={replayProgress}
            logoUrl={logoUrl}
            coverImageUrl={coverImageUrl}
          />
          <ProgramBox
            kind="normal"
            title={shortTitle}
            subtitle={longSubtitle}
            airTime={airTime}
            start={start}
            end={end}
            liveProgress={liveProgress}
            logoUrl={logoUrl}
            coverImageUrl={coverImageUrl}
            active
          />
          <ProgramBox
            kind="normal"
            title={longTitle}
            subtitle={shortSubtitle}
            airTime={airTime}
            start={start}
            end={end}
            liveProgress={liveProgress}
            replayProgress={replayProgress}
            logoUrl={logoUrl}
            coverImageUrl={coverImageUrl}
            focused
          />
          <ProgramBox
            kind="normal"
            title={shortTitle}
            subtitle={shortSubtitle}
            airTime={airTime}
            start={start}
            end={end}
            liveProgress={liveProgress}
            replayProgress={replayProgress}
            logoUrl={logoUrl}
            coverImageUrl={coverImageUrl}
          >
            {"lock here"}
          </ProgramBox>
        </div>
      </div>
      <div>
        <h2>Large program boxes</h2>
        <div style={{ display: "flex" }}>
          <div>
            <ProgramBox
              kind="large"
              title={longTitle}
              subtitle={longSubtitle}
              airTime={airTime}
              start={start}
              end={end}
              liveProgress={liveProgress}
              replayProgress={replayProgress}
              logoUrl={logoUrl}
              coverImageUrl={coverImageUrl}
            />
            <ProgramBox
              kind="large"
              title={shortTitle}
              subtitle={longSubtitle}
              airTime={airTime}
              start={start}
              end={end}
              liveProgress={liveProgress}
              logoUrl={logoUrl}
              coverImageUrl={coverImageUrl}
              active
            />
          </div>
          <div>
            <ProgramBox
              kind="large"
              title={longTitle}
              subtitle={shortSubtitle}
              airTime={airTime}
              start={start}
              end={end}
              liveProgress={liveProgress}
              replayProgress={replayProgress}
              logoUrl={logoUrl}
              coverImageUrl={coverImageUrl}
              focused
            />
            <ProgramBox
              kind="large"
              title={shortTitle}
              subtitle={shortSubtitle}
              airTime={airTime}
              start={start}
              end={end}
              liveProgress={liveProgress}
              replayProgress={replayProgress}
              logoUrl={logoUrl}
              coverImageUrl={coverImageUrl}
            >
              {"lock here"}
            </ProgramBox>
          </div>
        </div>
      </div>
    </div>
  );
}
