import { addDays, set, subDays } from "date-fns";
import { atom } from "jotai";

import { nowAtom } from "./now.atom";

const DAYS_IN_PAST = 7;
const DAYS_IN_FUTURE = 7;

export const pastDaysRangeAtom = atom((get) => {
  const now = get(nowAtom);
  const start = subDays(now, DAYS_IN_PAST);
  const end = now;

  return { start, end };
});

export const datesRangeAtom = atom((get) => {
  const { start } = get(pastDaysRangeAtom);
  const now = get(nowAtom);

  const dateRange = Array.from(
    { length: DAYS_IN_PAST + DAYS_IN_FUTURE + 1 },
    (_, index) => {
      return set(addDays(start, index), {
        hours: now.getHours(),
        minutes: now.getMinutes(),
      });
    },
  );

  return dateRange;
});
