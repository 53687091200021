import { TranslateFn } from "@sunrise/i18n";
import {
  PlayerCurrentContent,
  playerCurrentContentShownAtom,
} from "@sunrise/player";
import { nowAtom } from "@sunrise/time";
import { type Nullable } from "@sunrise/utils";
import {
  programIsPlayingAtTime,
  useReadableEpgSchedulingInfo,
} from "@sunrise/yallo-epg";
import { useAtomValue } from "jotai";
import { loadable } from "jotai/utils";
import { useEffect, useState } from "react";

type UsePlayerPlayingInfoOutput = {
  /**
   * Contains the title of the EPG content. For VOD this will be the title of the VOD channel.
   */
  title: Nullable<string>;
  /**
   * Contains the channel logo.
   */
  channelLogo: Nullable<string>;
  /**
   * Contains the channel name.
   */
  channelName: Nullable<string>;
  /**
   * For VOD content this will be the actual title of the content.
   */
  subtitle: Nullable<string>;
  /**
   * Contains the relative day the EPG content is scheduled.
   */
  status: Nullable<string>;
  /**
   * Contains the timing information for EPG content.
   * {
   *  formattedStartEnd: "12:00 – 13:00",
   *  formattedStart: "12:00",
   *  formattedEnd: "13:00",
   *  start: "Mon Sep 16 2024 12:00:00 GMT+0200 (Central European Summer Time)",
   *  end: "Mon Sep 16 2024 12:00:00 GMT+0200 (Central European Summer Time)"
   * }
   */
  schedule: {
    formattedStartEnd: Nullable<string>;
    formattedStart: Nullable<string>;
    formattedEnd: Nullable<string>;
    start: Nullable<Date>;
    end: Nullable<Date>;
  };

  /**
   * Boolean to indicate if the program is currently playing
   */
  isLive: boolean;
};

/**
 * This hook returns title, channelLog and subtitle. The subtitle is basically the EPG timing info.
 */
export function usePlayerPlayingInfo(
  t: TranslateFn,
): UsePlayerPlayingInfoOutput {
  // TODO: I wonder if there's a re-usable pattern for this.
  // We are basically keeping the stable data from the output.
  // We don't want the data to hide when we are loading again.
  // We do the same thing for the PlayerControls seekbar.
  const data = useAtomValue(loadable(playerCurrentContentShownAtom));

  const [currentContent, setCurrentContent] =
    useState<Nullable<PlayerCurrentContent>>(null);

  useEffect(() => {
    if (data.state === "hasData") {
      setCurrentContent(data.data);
    }
  }, [data]);

  const storeTime = useAtomValue(nowAtom);

  const { status, schedule, formattedEnd, formattedStart } =
    useReadableEpgSchedulingInfo(currentContent?.schedule, t);
  const isLive =
    (currentContent?.schedule &&
      programIsPlayingAtTime(currentContent?.schedule, storeTime)) ??
    false;

  return {
    title: currentContent?.title ?? null,
    channelLogo: currentContent?.channelLogo ?? null,
    channelName: currentContent?.channelName ?? null,
    subtitle: currentContent?.subtitle ?? null,
    isLive,
    status,
    schedule: {
      formattedEnd,
      formattedStart,
      formattedStartEnd: schedule,
      start: currentContent?.schedule?.startTime ?? null,
      end: currentContent?.schedule?.endTime ?? null,
    },
  };
}
