import { type Nullable, isDefined } from "@sunrise/utils";
import {
  actionPlayerManagerPlayLiveChannelId,
  playerManagerAtom,
} from "@sunrise/yallo-common-player-manager";
import { atom } from "jotai";
import { atomEffect } from "jotai-effect";

import type { ChannelId } from "@sunrise/backend-types-core";
import { playableChannelsInCurrentChannelGroupAtom } from "@sunrise/yallo-channel-group";

const DEBOUNCE_TIME = 4000;
const INSTANT_ZAPPING_TIME = 200;

/**
 * This atom is updated when the numeric zapping value changes.
 */
export const numericZappingValueAtom = atom<string>("");
/**
 * This atom is updated according to possibility to skip debounce.
 */
const debounceTimeAtom = atom<number>(DEBOUNCE_TIME);
/**
 * This atom is updated to show the user that they can or can't enter the next number.
 */
export const waitingForNextInputAtom = atom<boolean>(true);
/**
 * This atom is updated to show they entered wrong number.
 */
export const numericZappingErrorAtom = atom(false);

/**
 * This atom effect checks if zapping can happen.
 * If the channel is out of range, it sets the error state to true and doesn't allow to zap.
 * If the channel is in range, it zaps to the channel after debounce time OR
 * instantly zaps if the number is at max length.
 */
const zappingEffect = atomEffect((get, set) => {
  const zapToValue = get(numericZappingValueAtom);
  if (!zapToValue.length) return;

  // handle edge cases like error state and instant zapping
  const handleZappingConditions = async (): Promise<void> => {
    const channels = await get.peek(playableChannelsInCurrentChannelGroupAtom);
    // don't zap if the channel is out of range
    const isOutOfRange = channels.length < Number(zapToValue);
    if (isOutOfRange) {
      set(numericZappingErrorAtom, true);
      set(waitingForNextInputAtom, false);
      return;
    }

    // zap instantly if the number is at max length (i.e. for channel list with 3 digits length, zap instantly when 3 digits are entered)
    const maxNumLength = channels.length.toString().length;
    const currentNumLength = zapToValue.toString().length;
    const isNumAtMaxLength = currentNumLength === maxNumLength;

    if (isNumAtMaxLength) {
      set(waitingForNextInputAtom, false);
      set.recurse(debounceTimeAtom, INSTANT_ZAPPING_TIME);
    } else {
      set.recurse(debounceTimeAtom, DEBOUNCE_TIME);
    }

    return;
  };

  void handleZappingConditions();

  const zap = async (): Promise<void> => {
    const channels = await get.peek(playableChannelsInCurrentChannelGroupAtom);
    if (channels.length === 0) return;

    const nextChannelId: Nullable<ChannelId> =
      channels[Number(zapToValue) - 1]?.id;

    if (isDefined(nextChannelId)) {
      set(
        playerManagerAtom,
        actionPlayerManagerPlayLiveChannelId(nextChannelId),
      );
    }
  };

  const debounceTime = get(debounceTimeAtom);

  const debounce = setTimeout(() => {
    void zap();
    set.recurse(numericZappingValueAtom, "");
    set(numericZappingErrorAtom, false);
    set(waitingForNextInputAtom, true);
  }, debounceTime);

  return () => {
    clearTimeout(debounce);
  };
});

const numericZappingVisibleAtom = atom((get) => {
  const zappingNumber = get(numericZappingValueAtom);

  return zappingNumber.length > 0;
});

/**
 * Exports all the information needed for the NumericZapping UI component.
 */
export const numericZappingAtom = atom<{
  isVisible: boolean;
  channelNumber: string;
  zappingError: boolean;
  waitingForInput: boolean;
}>((get) => {
  get(zappingEffect);

  const isVisible = get(numericZappingVisibleAtom);
  const channelNumber = get(numericZappingValueAtom);
  const zappingError = get(numericZappingErrorAtom);
  const waitingForInput = get(waitingForNextInputAtom);

  return {
    isVisible,
    channelNumber,
    zappingError,
    waitingForInput,
  };
});
