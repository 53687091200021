import { atom } from "jotai";

import { playerAtom } from "@sunrise/player";
import { type Nullable } from "@sunrise/utils";
import { recordingByRecordingIdAtom } from "@sunrise/yallo-recordings";

export type RecordingSeekbarProgressResult = {
  duration: Nullable<number>;
  currentTime: Nullable<number>;
  /**
   * If we are seeking this is the seekTime, else it's the currentTime.
   */
  time: Nullable<number>;
  seekTime: Nullable<number>;
  isSeeking: boolean;
  paddingStartTimeInMinutes: Nullable<number>;
  paddingEndTimeInMinutes: Nullable<number>;
};

/**
 * Exposes on-demand like data of the player's duration and progress in the current stream.
 * It does take seeking into account. So when you seek it will impact the progress.
 */
export const recordingSeekbarProgressAtom = atom<
  Promise<Nullable<RecordingSeekbarProgressResult>>
>(async (get) => {
  const playerState = get(playerAtom);

  if (
    !playerState.playRequest ||
    !playerState.duration ||
    playerState.playRequest.type !== "recording" ||
    typeof playerState.currentTime !== "number"
  ) {
    return null;
  }

  const recording = await get(
    recordingByRecordingIdAtom(playerState.playRequest.recordingId),
  );

  return {
    isSeeking: typeof playerState.seekTime === "number",
    duration: playerState.duration, // NOTE: milliseconds
    seekTime: playerState.seekTime,
    currentTime: playerState.currentTime,
    time:
      typeof playerState.seekTime === "number"
        ? playerState.seekTime
        : playerState.currentTime,
    paddingStartTimeInMinutes:
      recording?.type !== "group" ? recording?.paddingStartMinutes ?? 0 : 0,
    paddingEndTimeInMinutes:
      recording?.type !== "group" ? recording?.paddingEndMinutes ?? 0 : 0,
  };
});
recordingSeekbarProgressAtom.debugLabel = "recordingSeekbarProgressAtom";
