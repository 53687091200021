import { actionDialogClose, dialogAtom, useListDialog } from "@sunrise/dialogs";
import { Translatable } from "@sunrise/i18n";
import { useAtomCallback } from "jotai/utils";
import { useCallback } from "react";

import { recordingsSortAtom } from "./recordings-sort.atom";
import type { AscDescSort, RecordingsSort } from "@sunrise/backend-types";

export const sortOptions: {
  value: RecordingsSort;
  label: Translatable;
  sort: AscDescSort;
}[] = [
  {
    value: "dateDesc",
    sort: "desc",
    label: { key: "recording_sort_newest_aired" },
  },
  {
    value: "dateAsc",
    sort: "asc",
    label: { key: "recording_sort_oldest_aired" },
  },
  {
    value: "titleAsc",
    sort: "asc",
    label: { key: "recording_sort_title_asc" },
  },
  {
    value: "titleDesc",
    sort: "desc",
    label: { key: "recording_sort_title_desc" },
  },
];

type UseSortRecordingsProps = {
  onClose?: () => void;
};

type UseSortRecordingsReturn = {
  openSortDialog: () => void;
};

const SORT_RECORDINGS_ID = "sort-recordings";

/**
 * Can be used for the sorting of the recordings overview page.
 */
export function useSortRecordings({
  onClose,
}: UseSortRecordingsProps): UseSortRecordingsReturn {
  const { showDialog } = useListDialog();

  const openSortDialog = useAtomCallback(
    useCallback(
      async (get, set) => {
        const activeOption = get(recordingsSortAtom);

        showDialog({
          id: SORT_RECORDINGS_ID,
          title: { key: "recording_sort_title" },
          sections: [
            {
              options: sortOptions.map((option) => ({
                value: option.value,
                label: option.label,
              })),
            },
          ],
          activeOptions: [activeOption],
          buttonTextAlign: "left",
          radioButtons: true,
          actionButtonLabel: { key: "recordings_done_button" },
          onButtonClick: (value) => {
            set(recordingsSortAtom, value as RecordingsSort);

            set(dialogAtom, actionDialogClose({ id: SORT_RECORDINGS_ID }));
            onClose?.();
          },
        });
      },
      [onClose, showDialog],
    ),
  );

  return {
    openSortDialog,
  };
}
