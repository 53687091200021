import { videoAdsAtom } from "@sunrise/ads";
import { selectIsDialogOpen } from "@sunrise/dialogs";
import { atom } from "jotai";

/**
 * We are not allowed to have the menu be open / visible / interactable when
 * - the video ads are playing.
 * - a dialog is opened.
 */
export const isMenuAllowedAtom = atom((get) => {
  const videoAds = get(videoAdsAtom);
  const isDialogOpen = get(selectIsDialogOpen);

  return !videoAds.isPlaying && !isDialogOpen;
});

isMenuAllowedAtom.debugLabel = "isMenuAllowed";
