import { featureAtom } from "@sunrise/feature-flags";

/**
 * Indicates whether network connection error should be shown or not (connected to GB).
 */
export const networkConnectionErrorEnabledAtom = featureAtom(
  "network-connection-error-enabled",
  true,
  {
    description: `Indicates whether network connection error should be shown or not.`,
  },
);
