import { forwardRef, useEffect } from "react";
import clsx from "clsx";

import * as styles from "./page-spinner.css";
import { Spinner } from "./spinner";

export type PageSpinnerProps = Omit<CommonProps, "data-focused"> & {
  onVisible?: (visible: boolean) => void;
};

/**
 * A spinner that takes up the full size of the page.
 * In the future this can be expanded to contain a back button if the loading takes too long.
 * The component should just serve to give a similar visual UX to all the pages.
 */
export const PageSpinner = forwardRef<HTMLDivElement, PageSpinnerProps>(
  (
    { "data-testid": dataTestId = "page-spinner", onVisible, className, style },
    ref,
  ) => {
    useEffect(() => {
      onVisible?.(true);
      return () => onVisible?.(false);
    }, [onVisible]);

    return (
      <div style={style} className={clsx(className, styles.root)} ref={ref}>
        <Spinner data-testid={dataTestId} />
      </div>
    );
  },
);

PageSpinner.displayName = "PageSpinner";
