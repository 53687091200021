import { forwardRef, memo, type ReactNode } from "react";
import areEqual from "fast-deep-equal";

import type { AssetId } from "@sunrise/backend-types-core";
import { type Nullable } from "@sunrise/utils";
import type { GenericRecording } from "@sunrise/yallo-recordings";

import { RecordingItemGroup } from "./recording-item-group";
import { RecordingItemSingle } from "./recording-item-single";

export type RecordingItemProps = CommonProps & {
  seriesAssetId: Nullable<AssetId>;
  id: GenericRecording["id"];
  focused: boolean;
  recording: GenericRecording;
  active?: boolean;
  index?: number;
};

/**
 * Program box specifically for recordings.
 *
 * This does not mean that the data shown on it is actually from the recording.
 */
export const RecordingItem = memo(
  forwardRef<HTMLDivElement, RecordingItemProps>(function RecordingItem(
    {
      "data-testid": dataTestId = "RecordingItem",
      className,
      recording,
      ...props
    },
    ref,
  ): ReactNode {
    if (recording.type === "single" || recording.type === "episode") {
      return (
        <RecordingItemSingle
          ref={ref}
          data-testid={dataTestId}
          className={className}
          recording={recording}
          {...props}
        />
      );
    }

    return (
      <RecordingItemGroup
        data-testid={dataTestId}
        ref={ref}
        recording={recording}
        {...props}
      />
    );
  }),
  areEqual,
);
