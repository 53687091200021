import type { StreamModel } from "@sunrise/backend-types";
import type { PlayRequest } from "@sunrise/yallo-player-types";

/**
 * Simple mapping to know what stream model to use for each PlaRequest.type.
 *
 * @param playRequestType
 * @returns StreamModel
 */
export function getStreamModelForPlayRequest(
  playRequestType: PlayRequest["type"],
): StreamModel {
  switch (playRequestType) {
    case "live":
    case "replay":
      return "linear";
    case "recording":
      return "on-demand";
  }
}
