import { ReactNode, Ref, forwardRef } from "react";

import { Button } from "../button";

type RadioButtonProps = CommonProps & {
  text: string;
  focused: boolean;
  disabled?: boolean;
  checked: boolean;
  active?: boolean;
};

export const RadioButton = forwardRef<HTMLButtonElement, RadioButtonProps>(
  function RadioButton(
    {
      text,
      focused,
      disabled,
      checked,
      active,
      "data-testid": dataTestId = "radioButton",
    }: RadioButtonProps,
    ref: Ref<HTMLButtonElement>,
  ): ReactNode {
    return (
      <Button
        text={text}
        focused={focused}
        iconRight={checked ? "radio-on" : "radio-off"}
        disabled={disabled}
        textAlign="left"
        block
        data-testid={dataTestId}
        ref={ref}
        variant="ghost"
        short
        typography={{ size: "h7", weight: focused ? "bold" : "regular" }}
        active={active}
      ></Button>
    );
  },
);
