import { getLocalizedValue } from "@sunrise/i18n";
import { type GuideProgram } from "@sunrise/yallo-guide";
import { millisecondsToMinutes } from "date-fns";

import type { EPGEntry } from "@sunrise/backend-types";
import type { Language } from "@sunrise/backend-types-core";
import { getPrefixAndSubtitle } from "@sunrise/asset";

export function epgEntryToGuideProgram(
  entry: EPGEntry,
  language: Language,
): GuideProgram {
  const startTime = new Date(entry.actual_start);
  const endTime = new Date(entry.actual_end);

  const prefixAndSubtitle = getPrefixAndSubtitle(
    entry.asset,
    entry.asset.subtitle,
    undefined,
    language,
  );

  return {
    id: entry.id,
    assetId: entry.asset.id,
    startTime,
    endTime,
    durationInMinutes: Math.floor(
      millisecondsToMinutes(endTime.getTime() - startTime.getTime()),
    ),
    title: getLocalizedValue(entry.asset.title, language),
    subtitle: prefixAndSubtitle,
    plot: entry.asset.plot && getLocalizedValue(entry.asset.plot, language),
    image: entry.asset.poster_url
      ? getLocalizedValue(entry.asset.poster_url, language)
      : null,
  };
}
