import { MappedAsset } from "@sunrise/asset";
import { EpgEntrySchema, GeneratedApi } from "@sunrise/backend-ng-epg";
import {
  AssetId,
  ChannelId,
  EPGEntryId,
  TimeISOString,
} from "@sunrise/backend-types-core";

import { MappedEpg } from "../types";

export function mapBackendEpgNg(
  epg: Awaited<
    ReturnType<
      GeneratedApi["epg"]["epgEntryDetailEpgV1EpgEntriesEpgEntryIdGet"]
    >
  >["data"],
): MappedEpg {
  return {
    id: epg.id as EPGEntryId,
    actualStart: epg.start as TimeISOString,
    actualEnd: epg.end as TimeISOString,
    channel: {
      id: epg.channel.id as ChannelId,
      logo: epg.channel.logo,
      name: epg.channel.name,
    },
    asset: {
      id: epg.asset.id as AssetId,
      type: epg.asset.type as MappedAsset["type"],
      title: epg.asset.title,
      subtitle: epg.asset.subtitle,
      episodeNumber: epg.asset.episode_number,
      seasonNumber: epg.asset.season_number,
      posterBlurHash: epg.asset.poster_blurhash,
      posterPath: epg.asset.poster_url,
      ageRecomendation: null,
      productionYear: null,
      genres: [],
      plot: null,
      seasons: null,
      cast: [],
      directors: [],
      seriesId: null,
    },
    streamUrl: epg.stream_service_url,
  };
}

export function mapBackendEpgSimpleNg(epg: EpgEntrySchema): MappedEpg {
  return {
    id: epg.id as EPGEntryId,
    actualStart: epg.start as TimeISOString,
    actualEnd: epg.end as TimeISOString,
    channel: {
      id: epg.channel.id as ChannelId,
      logo: epg.channel.logo,
      name: epg.channel.name,
    },
    asset: {
      id: epg.asset.id as AssetId,
      type: epg.asset.type as MappedAsset["type"],
      title: epg.asset.title,
      subtitle: epg.asset.subtitle,
      episodeNumber: epg.asset.episode_number,
      seasonNumber: epg.asset.season_number,
      posterBlurHash: epg.asset.poster_blurhash,
      posterPath: epg.asset.poster_url,
      ageRecomendation: null,
      productionYear: null,
      genres: [],
      plot: null,
      seasons: null,
      cast: [],
      directors: [],
      seriesId: null,
    },
    streamUrl: epg.stream_service_url,
  };
}
