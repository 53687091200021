import {
  type ChannelGroup,
  type ChannelGroupsResponse,
  endpoints,
} from "@sunrise/backend-types";
import { PrivateApiClient } from "@sunrise/http-client";

/**
 * @deprecated We now have a channel-list endpoint that also contains the user channel groups.
 */
export async function fetchUserChannelGroupsLegacy(
  privateApi: PrivateApiClient,
  host: string,
): Promise<ChannelGroup[]> {
  const response = await privateApi.get<ChannelGroupsResponse>(
    endpoints.userChannelGroups(host),
    {
      params: {
        group_type: "user",
      },
    },
  );

  return response.data.result;
}
