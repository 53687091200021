import { DeepPartial } from "ts-essentials";

import type { Stream } from "@sunrise/backend-types";
import { Nullable } from "@sunrise/utils";
import type { PlayRequest } from "@sunrise/yallo-player-types";

import type {
  FairPlayConfiguration,
  PlayerDelayedBufferSettings,
  PlayerLiveBufferSettings,
} from "../player.types";

/**
 * https://shaka-player-demo.appspot.com/docs/api/tutorial-network-and-buffering-config.html
 *
 * Configures shaka for the passed Stream and optional PlayRequest.
 */
export function getShakaConfigForPlayRequestAndStream(
  stream: Stream,
  request: Nullable<PlayRequest>,
  settings: Nullable<PlayerLiveBufferSettings | PlayerDelayedBufferSettings>,
  allowParallelPrefetch: boolean,
  trackKnownBandwidth: boolean,
  /**
   * The available bandwidth that is known to the player in bits/sec.
   */
  knownBandwidth: number,
  fairPlayConfiguration?: FairPlayConfiguration,
): DeepPartial<shaka.extern.PlayerConfiguration> {
  const delay = settings ? ("delay" in settings ? settings.delay ?? 0 : 0) : 0;

  return {
    drm: getDrmConfigForStream(stream, fairPlayConfiguration),
    abr: getABRConfig(knownBandwidth),
    streaming: getStreamingConfig(
      settings,
      allowParallelPrefetch,
      trackKnownBandwidth,
    ),
    manifest: getManifestForPlayRequest(request, {
      delay,
    }),
  };
}

function getABRConfig(
  knownBandwidth: number,
): DeepPartial<shaka.extern.AbrConfiguration> | undefined {
  if (!knownBandwidth) {
    return;
  }

  return {
    defaultBandwidthEstimate: knownBandwidth,
    // TODO: map this to low memory devices?
    // clearBufferSwitch: true
    // safeMarginSwitch: 3
    // TODO: look into stall protection
  };
}

const RETRY_PARAMS: Partial<shaka.extern.RetryParameters> = {
  maxAttempts: 3,
  timeout: 50_000,
  connectionTimeout: 20_000,
};

function getStreamingConfig(
  settings: Nullable<PlayerLiveBufferSettings | PlayerDelayedBufferSettings>,
  allowParallelPrefetch: boolean,
  trackKnownBandwidth: boolean,
): DeepPartial<shaka.extern.StreamingConfiguration> {
  return {
    /**
     * This is the minimum buffer.
     * It means we will attempt to load at least 2s of content in a stream before we attempt playout.
     */
    rebufferingGoal: settings?.min ?? 2,
    /**
     * This is the maximum buffer. It will attempt to full 30s of buffer behind the player's current point.
     * So if we lose the connection for 29s we should still not interrupt the user.
     *
     * it should not be set too high since it's useless to unnecessarily increase memory usage + download.
     * If the user zaps through the channels it will always fill up 30s pretty quickly.
     */
    bufferingGoal: settings?.max ?? 10,
    /**
     * NOTE: When bufferBehind is not set, it seems to ignore the bufferingGoal setting.
     *       So the bufferingGoal is allowed to grow to 1 minute.
     */
    bufferBehind: 2,
    /**
     * We may speed up the initial load of we can do a parallel fetch of the segments.
     */
    segmentPrefetchLimit: allowParallelPrefetch ? 5 : undefined,
    observeQualityChanges: trackKnownBandwidth ? true : undefined,
    /**
     * We will attempt to load stream twice before we return fail.
     */
    retryParameters: RETRY_PARAMS,
    /**
     * Required for DRM https://shaka-player-demo.appspot.com/docs/api/shaka.extern.html#.PlayerConfiguration
     */
    preferNativeHls: true, // previously useNativeHlsOnSafari
  };
}

function getDrmConfigForStream(
  stream: Stream,
  fairPlayConfiguration?: FairPlayConfiguration,
): DeepPartial<shaka.extern.DrmConfiguration> {
  const configuration = {
    retryParameters: RETRY_PARAMS,
  };

  if (stream.type === "dash_widevine" && stream.licenseUrl) {
    return {
      ...configuration,
      servers: {
        "com.widevine.alpha": stream.licenseUrl,
      },
    };
  } else if (stream.type === "hls7_fairplay") {
    return {
      ...configuration,
      servers: {
        "com.apple.fps.1_0": stream.licenseUrl ?? undefined,
      },
      advanced: {
        "com.apple.fps.1_0": {
          serverCertificate: fairPlayConfiguration?.fairplayServerCertificate,
        },
      },
      initDataTransform: fairPlayConfiguration?.fairplayInitDataTransform,
    };
  }

  return {};
}

function getManifestForPlayRequest(
  request: Nullable<PlayRequest>,
  options: Nullable<Pick<PlayerLiveBufferSettings, "delay">>,
): DeepPartial<shaka.extern.ManifestConfiguration> {
  if (!options) {
    return {};
  }

  if (request?.type === "live") {
    return {
      /**
       * This only makes sense for live content. Here we are saying we need to go a bit more behind live than normal.
       * It means that we can load in 7s of buffer pretty quickly since the buffers should already exist in the backend.
       * It is recommended to have this value to be higher than the rebufferingGoal.
       * Else the player has to wait for time to progress before the buffer is even available to download.
       *
       * The stream can still override this presentationDelay for other streams that are time-sensitive like sports channels.
       *
       * This setting is only used when the stream does not contain a value by default. Which doesn't seem to be the case for yallo.
       */
      defaultPresentationDelay: options.delay ?? 0,
    };
  }

  return {};
}
