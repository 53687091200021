import {
  actionDialogOpen,
  dialogAtom,
  useConfirmationDialog,
} from "@sunrise/dialogs";
import { errorAtom } from "@sunrise/error";
import { jwtAtom } from "@sunrise/jwt";
import { nowAtom } from "@sunrise/time";
import { isAfter, isBefore } from "date-fns";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import { useAtomCallback } from "jotai/utils";
import { isNil } from "lodash";
import { useCallback, useMemo } from "react";

import { deleteSingleRecordingMutationAtom } from "./delete-single-recording.mutation.atom";
import { isAtMaxRecordingCapacityAtom } from "./is-at-max-recording-capacity.atom";
import {
  deleteRecordingScheduleMutationAtom,
  recordingSchedulesMutationAtom,
} from "./query-atoms/recording-schedules.query.atom";
import { recordingByEpgIdAtom } from "./recording-by-epg-id.atom";
import { recordingStatusByEpgIdAtom } from "./recording-status-by-epg-id.atom";
import type {
  EPGEntry,
  RecordingScheduleType,
  RecordingState,
} from "@sunrise/backend-types";
import {
  channelByIdAtom,
  isChannelLockedAtom,
} from "@sunrise/yallo-channel-group";

const NOOP = () => {
  // noop
};

/**
 * This interface accepts the details of an EPGEntry.
 *
 * This is done because we have endpoints like recommendations or recordings
 * where we receive all the necessary data to determine the outcome of this hook.
 */
export function useRecordProgram({
  epg,
  getCurrentFocusKey,
}: {
  epg: EPGEntry;
  getCurrentFocusKey: () => string;
}): {
  record: () => Promise<void>;
  deleteRecording: () => void;
  /**
   * Indicates if the program should be recordable if the user would have recordings permissions.
   */
  canRecord: boolean;
  status?: RecordingState;
} {
  const channel = useAtomValue(channelByIdAtom(epg.channel.id));
  if (isNil(channel)) throw new Error("Undefined channel");

  const now = useAtomValue(nowAtom);
  const recordingStatus = useAtomValue(recordingStatusByEpgIdAtom(epg.id));
  const recording = useAtomValue(recordingByEpgIdAtom(epg.id))?.data;

  const [{ mutate: mutateCreateRecordingSchedule }] = useAtom(
    recordingSchedulesMutationAtom,
  );

  const [{ mutateAsync: mutateDeleteRecordingSchedule }] = useAtom(
    deleteRecordingScheduleMutationAtom,
  );

  const [{ mutateAsync: mutateDeleteRecording }] = useAtom(
    deleteSingleRecordingMutationAtom,
  );

  const features = useAtomValue(jwtAtom).decodedPayload?.feature_set.features;

  const isInPast = isBefore(epg.actual_end, now);
  const isInFuture = isAfter(epg.actual_start, now);
  const isLive = !isInPast && !isInFuture;
  const hasNoRecording = !recordingStatus?.status;
  const canRecord =
    (hasNoRecording &&
      ((isInFuture && features?.can_record) ||
        ((isInPast || isLive) && features?.can_record_in_past))) ??
    false;
  const isChannelLocked = useAtomValue(isChannelLockedAtom(channel.id));

  const canRecordSeries = features?.can_record_type_asset;

  const isSeriesEpisode = epg.asset.type === "seriesepisode";
  const { showDialog } = useConfirmationDialog();
  const dispatchDialog = useSetAtom(dialogAtom);
  const dispatchError = useSetAtom(errorAtom);
  const onError = useCallback(
    (error: unknown) => {
      if (error instanceof Error) {
        dispatchError(error);
      }
    },
    [dispatchError],
  );

  const record = useAtomCallback(
    useCallback(
      async function (get) {
        if (isChannelLocked) {
          dispatchDialog(
            actionDialogOpen({
              type: "actions",
              actions: [
                {
                  label: { key: "button_ok" },
                  action: NOOP,
                  key: "close",
                },
              ],
              title: { key: "playback_locked" },
              description: { key: "player_error_locked_content" },
              lastFocusKey: getCurrentFocusKey(),
              id: "channel-locked",
              technicalErrorName: "player_error_missing_mysports_subscription",
            }),
          );
          return;
        }

        if (!canRecord) {
          dispatchDialog(
            actionDialogOpen({
              type: "actions",
              actions: [
                {
                  label: { key: "button_ok" },
                  action: NOOP,
                  key: "close",
                },
              ],
              title: { key: "title_upsell_record_past_or_live_program" },
              description: {
                key: "description_upsell_record_past_or_live_program",
              },
              lastFocusKey: getCurrentFocusKey(),
              id: "cant-record-past-or-live",
              technicalErrorName: "upsell_record_single",
            }),
          );
          return;
        }

        const atCapacity = await get(isAtMaxRecordingCapacityAtom);
        if (atCapacity) {
          showDialog({
            title: { key: "recording_capacity_full_title" },
            description: { key: "recording_capacity_full_dialog_startup" },
            confirmationLabel: { key: "button_ok" },
            lastFocusKey: getCurrentFocusKey(),
            id: "recording-capacity-full",
            technicalErrorName: "error_recording_capacity_reached",
          });
          return;
        }

        const createRecording = (type: RecordingScheduleType) => {
          mutateCreateRecordingSchedule(
            {
              epgEntryId: epg.id,
              providerName: channel.providerName,
              type,
            },
            { onError },
          );
        };

        const showCantRecordSeriesDialog = () => {
          dispatchDialog(
            actionDialogOpen({
              type: "actions",
              title: { key: "title_upsell_record_series" },
              description: { key: "description_upsell_record_series" },
              actions: [
                {
                  label: { key: "button_ok" },
                  action: NOOP,
                  key: "close",
                  initialFocus: true,
                },
              ],
              lastFocusKey: getCurrentFocusKey(),
              id: "cant-record-series",
              technicalErrorName: "upsell_record_series",
            }),
          );
          return;
        };

        const showCreateRecordingDialogForSeries = (
          canRecordSeries: boolean,
        ) => {
          dispatchDialog(
            actionDialogOpen({
              type: "actions",
              title: { key: "dialog_start_recording_title" },
              description: { key: "dialog_start_recording_subtitle" },
              actions: [
                {
                  label: { key: "dialog_start_recording_series" },
                  action: () =>
                    canRecordSeries
                      ? createRecording("asset")
                      : showCantRecordSeriesDialog(),
                  key: "series",
                },
                {
                  label: { key: "dialog_start_recording_single" },
                  action: () => createRecording("single"),
                  key: "single",
                },
                {
                  label: { key: "dialog_button_cancel" },
                  action: NOOP,
                  key: "cancel",
                  initialFocus: true,
                },
              ],
              lastFocusKey: getCurrentFocusKey(),
              id: "create-series-recording",
            }),
          );
        };

        if (isSeriesEpisode) {
          showCreateRecordingDialogForSeries(canRecordSeries ?? false);
          return;
        } else {
          createRecording("single");
        }
      },
      [
        canRecord,
        recordingStatus?.status,
        isSeriesEpisode,
        canRecordSeries,
        getCurrentFocusKey,
      ],
    ),
  );

  const cancelPlannedRecordingInternal = useCallback(
    async function () {
      await mutateDeleteRecordingSchedule({
        epgEntryId: epg.id,
      });
    },
    [epg.id],
  );

  const deleteRecordingInternal = useCallback(
    async function () {
      if (recordingStatus?.status === "recorded") {
        // NOTE: Deleting series will probably not be done on an epg program level.
        if (!recording) {
          return;
        }

        await mutateDeleteRecording({
          epgEntryId: epg.id,
          recordingId: recording.id,
        });
      }
    },
    [recordingStatus?.status, recording, epg.id],
  );

  const deleteRecording = useCallback(() => {
    if (recordingStatus?.status === "planned") {
      showDialog({
        title: { key: "dialog_cancel_recording_title" },
        description: { key: "dialog_cancel_recording_subtitle" },
        confirmationLabel: { key: "button_yes" },
        rejectionLabel: { key: "button_no" },
        onConfirm: () => void cancelPlannedRecordingInternal(),
        lastFocusKey: getCurrentFocusKey(),
        focusReject: true,
        id: "cancel-recording",
      });
    } else if (recordingStatus?.status === "recorded") {
      showDialog({
        title: { key: "dialog_remove_recording_title" },
        description: { key: "dialog_remove_recording_subtitle" },
        confirmationLabel: { key: "button_recordings_delete" },
        rejectionLabel: { key: "dialog_button_cancel" },
        onConfirm: () => void deleteRecordingInternal(),
        lastFocusKey: getCurrentFocusKey(),
        focusReject: true,
        id: "remove-recording",
      });
    }
  }, [
    getCurrentFocusKey,
    deleteRecordingInternal,
    cancelPlannedRecordingInternal,
    recordingStatus?.status,
  ]);

  return useMemo(
    () => ({
      record,
      deleteRecording,
      canRecord,
      status: recordingStatus?.status,
    }),
    [record, deleteRecording, canRecord, recordingStatus?.status],
  );
}
