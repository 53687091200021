import { useQrCodeLogin } from "@sunrise/qr-code-login";
import clsx from "clsx";
import { Suspense } from "react";

import { QrCode, Spinner } from "@/components";

import * as styles from "./qr-code-login.css";

export type QrCodeLoginProps = CommonProps;

export function QrCodeLogin({
  className,
  "data-testid": dataTestId = QrCodeLogin.name,
}: QrCodeLoginProps): JSX.Element {
  return (
    <div className={clsx(styles.root, className)} data-testid={dataTestId}>
      <Suspense fallback={<Spinner data-testid={dataTestId} />}>
        <ConnectedQrCode />
      </Suspense>
    </div>
  );
}

function ConnectedQrCode(): JSX.Element {
  const { pin } = useQrCodeLogin();

  return (
    <QrCode
      size={428}
      qrCodeUrl={pin.qr_code_url}
      link={pin.approve_url}
      textColor="yellow"
    />
  );
}
