export function timeToPixels(
  date: Date,
  startTime: Date,
  endTime: Date,
  width: number,
): number {
  const timeGap = endTime.getTime() - startTime.getTime();
  const dateGap = date.getTime() - startTime.getTime();
  return (dateGap * width) / timeGap;
}
