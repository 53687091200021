import type { InfiniteData } from "@tanstack/react-query";
import { atomWithInfiniteQuery } from "jotai-tanstack-query";

import type { PageWithItems } from "@sunrise/backend-ng-core";
import { queryKeys } from "@sunrise/backend-types";
import { currentLanguageAtom } from "@sunrise/i18n";
import { selectJwtUserToken } from "@sunrise/jwt";
import { isNil } from "@sunrise/utils";

import { backendRecordingToNgRecording } from "../helpers/backend-recording-to-ng-recording";
import { ngRecordingsApiAtom } from "../ng-recordings-api.atom";
import { recordingsStaleTimeAtom } from "../recordings-stale-time.atom";
import type { NgRecording, NgRecordingGroup } from "../types";
import { recordingsOverviewFilterAtom } from "./recordings-overview-filter.atom";
import { recordingsOverviewSortAtom } from "./recordings-overview-sort.atom";

/**
 * The user's recordings with filtering & sorting applied.
 *
 * TODO: Try to extract a helper to backend-ng-core. So we are not rebuilding the paging logic all the time.
 *       Since all the backend paging will work the exact same.
 */
export const recordingsOverviewQueryAtom = atomWithInfiniteQuery<
  PageWithItems<NgRecording | NgRecordingGroup>,
  unknown,
  InfiniteData<PageWithItems<NgRecording | NgRecordingGroup>>,
  ReturnType<typeof queryKeys.recordingsOverview>,
  number
>((get) => {
  const api = get(ngRecordingsApiAtom);

  const filter = get(recordingsOverviewFilterAtom);
  const sort = get(recordingsOverviewSortAtom);
  const language = get(currentLanguageAtom);

  const queryKey = queryKeys.recordingsOverview(
    get(selectJwtUserToken),
    language,
    sort,
    filter,
  );

  return {
    initialPageParam: 1,
    queryKey,
    queryFn: async ({ pageParam = 1 }) => {
      const { data } =
        await api.recording.recordingItemsListRecordingV1RecordingsGet({
          sort_by: sort,
          item_type: filter,
          page: pageParam,
          size: 20,
        });

      return {
        ...data,
        items: data.items.map(backendRecordingToNgRecording),
      };
    },
    getNextPageParam: (lastPage) => {
      if (isNil(lastPage) || !lastPage.pages || !lastPage.page)
        return undefined;

      const { page, pages } = lastPage;
      return page < pages ? page + 1 : undefined;
    },
    staleTime: get(recordingsStaleTimeAtom),
    gcTime: get(recordingsStaleTimeAtom),
  };
});
