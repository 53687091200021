import type { ArrowPressHandler } from "@noriginmedia/norigin-spatial-navigation";
import { useTranslator } from "@sunrise/translator";
import { forceHideWhatIsNextSideBarAtom } from "@sunrise/what-is-next";
import { playerManagerAtom } from "@sunrise/yallo-common-player-manager";
import { actionPlayerManagerPlayRecording } from "@sunrise/yallo-common-player-manager";
import { minutesToSeconds } from "date-fns";
import { useAtomCallback } from "jotai/utils";
import { type ReactNode, useCallback } from "react";

import { FocusButton } from "@/components";

import { WhatIsNextRecording } from "../what-is-next-recording";
import * as styles from "./what-is-next-side-bar.css";
import type { SingleRecording } from "@sunrise/backend-types";

export type WhatIsNextSideBarContentWithProps = {
  next: SingleRecording;
  onArrowPress: ArrowPressHandler;
};

export function WhatIsNextSideBarContentWith({
  next,
  onArrowPress,
}: WhatIsNextSideBarContentWithProps): ReactNode {
  const t = useTranslator();
  const doHide = useAtomCallback(
    useCallback((_, set) => {
      set(forceHideWhatIsNextSideBarAtom, true);
    }, []),
  );
  const doOpenNext = useAtomCallback(
    useCallback(
      (_, set) => {
        const nextChannelId = next.epg_entry.channel?.id;

        if (nextChannelId) {
          set(forceHideWhatIsNextSideBarAtom, true);
          set(
            playerManagerAtom,
            actionPlayerManagerPlayRecording(
              next.id,
              nextChannelId,
              minutesToSeconds(next.padding_start_minutes ?? 0),
            ),
          );
        }
      },
      [next],
    ),
  );

  return (
    <>
      <WhatIsNextRecording
        data={next}
        className={styles.nextRecording}
        onEnterPress={doOpenNext}
        onArrowPress={onArrowPress}
        focusKey="what-is-next-side-bar.next_recording"
        data-testid="what-is-next-side-bar.next_recording"
      />
      <FocusButton
        className={styles.button}
        text={t("hide")}
        onArrowPress={onArrowPress}
        onEnterPress={doHide}
        focusKey="what-is-next-side-bar.hide"
        data-testid="what-is-next-side-bar.hide"
      />
    </>
  );
}
