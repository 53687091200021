import { type JSX, ReactElement, useEffect } from "react";
import {
  FocusContext,
  useFocusable,
} from "@noriginmedia/norigin-spatial-navigation";
import clsx from "clsx";
import { useAtomValue, useStore } from "jotai";

import { useTranslator } from "@sunrise/translator";
import {
  isBulkDeletionModeAtom,
  isFetchingInitialDataAtom,
  recordingItemsWithPagingAtom,
} from "@sunrise/yallo-recordings-overview";
import { selectedRecordingReferenceAtom } from "@sunrise/yallo-recordings-overview";

import { PageSpinner } from "@/components";
import { canPullFocusAtom } from "@/modules/ui/can-pull-focus.atom";
import { typography } from "@/styles/typography.css";
import { isArrowLeftKey } from "@/utils/navigation";

import { List } from "./list";
import * as styles from "./recordings-list.css";

const localWidgetFocusKey = {
  list: (rootFocusKey: string) => rootFocusKey + ".list",
  column: (rootFocusKey: string, rowIdx: number, colIdx: number) =>
    rootFocusKey + ".item." + rowIdx + "." + colIdx,
};

export type ConnectedRecordingsProps = CommonProps & {
  focusKey: string;
  onExitUp: (column: number) => void;
  onExitLeft: () => void;
  hasContent: boolean;
};

export function RecordingsList({
  "data-testid": dataTestId = "RecordingsList",
  focusKey,
  onExitUp,
  onExitLeft,
  hasContent,
}: ConnectedRecordingsProps): JSX.Element {
  const isFetchingInitialData = useAtomValue(isFetchingInitialDataAtom);

  if (isFetchingInitialData) {
    return <PageSpinner data-testid="recordings-spinner" />;
  }

  return hasContent ? (
    <RecordingsListInternal
      onExitLeft={onExitLeft}
      focusKey={focusKey}
      onExitUp={onExitUp}
      data-testid={dataTestId}
    />
  ) : (
    <EmptyInformation data-testid={dataTestId} />
  );
}

type EmptyInformationProps = CommonProps;

function EmptyInformation({
  "data-testid": dataTestId,
  className,
  ...props
}: EmptyInformationProps): JSX.Element {
  const t = useTranslator();
  return (
    <p
      {...props}
      className={clsx([className, styles.empty, typography.h6.regular])}
      data-testid={`${dataTestId}.no-recordings`}
    >
      {t("recordings_nothing_found")}
    </p>
  );
}

function RecordingsListInternal({
  "data-testid": dataTestId,
  focusKey,
  onExitLeft,
  onExitUp,
}: Omit<ConnectedRecordingsProps, "hasContent">): ReactElement {
  const focusable = useFocusable({
    focusKey,
    focusable: true,
    autoRestoreFocus: true,
    onArrowPress: (direction) => {
      if (isArrowLeftKey(direction)) {
        onExitLeft();
        return false;
      }
      return true;
    },
  });
  const focusSelf = focusable.focusSelf;

  /**
   * This should only pull focus to itself after the content is done loading.
   * So that means on initial mount.
   *
   * TODO: Should be corrected so that the list itself pulls focus to itself ONLY when it goes from no items to items.
   *       This recordings-list Focusable should not even exist as it has no real use.
   */
  const store = useStore();
  useEffect(() => {
    if (store.get(canPullFocusAtom)) {
      focusSelf();
    }
  }, [store, focusSelf]);

  const isBulkDeletionMode = useAtomValue(isBulkDeletionModeAtom);

  return (
    <FocusContext.Provider value={focusable.focusKey}>
      <div ref={focusable.ref} className={styles.listContainer}>
        <List
          isBulkDeletionMode={isBulkDeletionMode}
          onExitLeft={onExitLeft}
          selectedAtom={selectedRecordingReferenceAtom}
          focusKey={localWidgetFocusKey.list(focusKey)}
          data-testid={dataTestId}
          queryAtom={recordingItemsWithPagingAtom}
          onExitUp={onExitUp}
        />
      </div>
    </FocusContext.Provider>
  );
}
