// TODO: taken from YALLOTV-12804 WIP PR - replace it as soon as the PR is merged with the playerProgressAtom. Therefore also no tests for this atom
import {
  playerAtom,
  playerCurrentDateTimeAtom,
  playerCurrentDateTimeShownAtom,
  playerCurrentEpgItemShownAtom,
  selectPlayerCurrentPlayRequest,
  selectPlayerInLinearSeekConfirmation,
  selectPlayerIsPlaying,
} from "@sunrise/player";
import { getLiveProgress, nowSecondAtom } from "@sunrise/time";
import { type Nullable } from "@sunrise/utils";
import { atom } from "jotai";
import { isNil } from "lodash";

import { SeekbarProgressResult } from "./types";
import type { EPGEntry } from "@sunrise/backend-types";

const atomCurrentEpgItemWithSeekTimings = atom(async (get) => {
  const item = await get(playerCurrentEpgItemShownAtom);

  return getTimingsFromEpgItem(item);
});

/**
 * Contains the current player progress for EPG content.
 *
 * It returns null when the player has no currentTime set or when the player has no EPG timings.
 *
 * It will keep the seeking into account. So when you seek it will re-evaluate the current EPG item and adjust the timings (start/end/live/replay) accordingly.
 * If you don't seek it will just change EPG item whenever the time moves into the next EPG item as time passes by.
 * If you pause, time stops progressing.
 *
 * When we are playing live content and the stream is not paused, we will assume that the player's current time is the live time.
 * So that we do not lag behind the live time visually.
 */
export const epgSeekbarProgressAtom = atom<
  Promise<Nullable<SeekbarProgressResult>>
>(async (get) => {
  const currentTimePlayer = get(playerCurrentDateTimeAtom);
  const currentTimePlayerWithSeek = get(playerCurrentDateTimeShownAtom);

  const timings = await get(atomCurrentEpgItemWithSeekTimings);
  // for epg seekbarProgressAtom we allow only Date type for currentTime
  if (
    !timings ||
    isNil(currentTimePlayer) ||
    isNil(currentTimePlayerWithSeek)
  ) {
    return null;
  }

  const state = get(playerAtom);
  const isSeeking = !!state.seekTime;
  const isPlaying = get(selectPlayerIsPlaying);
  const isLive = get(selectPlayerCurrentPlayRequest)?.type === "live";

  const seekConfirmationDate = get(selectPlayerInLinearSeekConfirmation);

  // When we are lying about the seekTime, we no longer want to pretend that the liveTime is the player's currentTime.
  // This is because otherwise the live time would be clipped to where we seeked. While in reality the live time should flow to now.
  const liveTime =
    isPlaying && isLive && currentTimePlayer && !seekConfirmationDate
      ? currentTimePlayer
      : get(nowSecondAtom);
  const areProgressesAllLive =
    isPlaying && isLive && !isSeeking && !seekConfirmationDate;

  const liveProgress = getLiveProgress(
    timings.start,
    timings.end,
    liveTime,
    true,
  );

  return {
    isSeeking,
    startTime: timings.start,
    endTime: timings.end,
    currentTime: currentTimePlayerWithSeek,
    durationLeft: timings.end.getTime() - currentTimePlayerWithSeek.getTime(),
    replayProgress: areProgressesAllLive
      ? liveProgress
      : getLiveProgress(timings.start, timings.end, currentTimePlayerWithSeek),
    liveProgress,
    progress: areProgressesAllLive
      ? liveProgress
      : getLiveProgress(
          timings.start,
          timings.end,
          currentTimePlayerWithSeek,
          true,
        ),
    progressWithoutSeek: areProgressesAllLive
      ? liveProgress
      : getLiveProgress(timings.start, timings.end, currentTimePlayer, true),
    currentTimeWithoutSeek: currentTimePlayer,
    durationLeftWithoutSeek:
      timings.end.getTime() - (currentTimePlayer?.getTime() ?? 0),
  };
});

epgSeekbarProgressAtom.debugLabel = "epgSeekbarProgressAtom";

function getTimingsFromEpgItem(
  item: Nullable<EPGEntry>,
): Nullable<{ start: Date; end: Date }> {
  if (!item) return null;

  return {
    start: new Date(item.actual_start),
    end: new Date(item.actual_end),
  };
}
