import type { ArrowPressHandler } from "@noriginmedia/norigin-spatial-navigation";
import { playerAtom } from "@sunrise/player";
import { useTranslator } from "@sunrise/translator";
import { forceHideWhatIsNextSideBarAtom } from "@sunrise/what-is-next";
import { usePlayChannel } from "@sunrise/yallo-common-player-manager";
import { useAtomValue } from "jotai";
import { useAtomCallback } from "jotai/utils";
import { type ReactNode, useCallback } from "react";

import { FocusButton } from "@/components";
import { useRoutes } from "@/features/routing/use-routes";

import * as styles from "./what-is-next-side-bar.css";

export type WhatIsNextSideBarContentWithoutProps = {
  onArrowPress: ArrowPressHandler;
};

export function WhatIsNextSideBarContentWithout({
  onArrowPress,
}: WhatIsNextSideBarContentWithoutProps): ReactNode {
  const routes = useRoutes();

  const { playRequest } = useAtomValue(playerAtom);
  const channelId = playRequest?.channelId;
  const t = useTranslator();
  const { play: playLive } = usePlayChannel({ channelId });
  const doHide = useAtomCallback(
    useCallback((_, set) => {
      set(forceHideWhatIsNextSideBarAtom, true);
    }, []),
  );
  const openRecordings = (): void => {
    routes.recordings.root();
  };

  return (
    <>
      <FocusButton
        className={styles.button}
        text={t("button_see_recordings")}
        focusKey="what-is-next-side-bar.recordings"
        onEnterPress={openRecordings}
        onArrowPress={onArrowPress}
        data-testid="what-is-next-side-bar.recordings"
      />
      <FocusButton
        className={styles.button}
        text={t("button_go_to_live")}
        focusKey="what-is-next-side-bar.live"
        onEnterPress={playLive}
        onArrowPress={onArrowPress}
        data-testid="what-is-next-side-bar.live"
      />
      <FocusButton
        className={styles.button}
        text={t("hide")}
        focusKey="what-is-next-side-bar.hide"
        onEnterPress={doHide}
        onArrowPress={onArrowPress}
        data-testid="what-is-next-side-bar.hide"
      />
    </>
  );
}
