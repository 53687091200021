import { forwardRef } from "react";

import { type Nullable } from "@sunrise/utils";

import {
  ProgramSubtitle,
  ProgramTime,
  ProgramTitle,
} from "../box-text-elements/box-text";
import * as styles from "../program-box.css";
import { ProgramBoxWrapper } from "../program-box-wrapper";
import {
  ProgramCover,
  type RecordingState,
} from "../program-cover/program-cover";
import { BoxProps } from "../types";

export type ProgramBoxProps = CommonProps &
  BoxProps & {
    title: Nullable<string>;
    airTime: Nullable<string>;
    start: Nullable<string>;
    end: Nullable<string>;
    startDate?: Nullable<Date>;
    endDate?: Nullable<Date>;
    kind: "normal" | "large";
    subtitle?: Nullable<string>;
    active?: boolean;
    liveProgress?: Nullable<number>;
    replayProgress?: Nullable<number>;
    recordingState?: Nullable<RecordingState>;
    "data-position"?: number;
  };

export const ProgramBox = forwardRef<HTMLDivElement, ProgramBoxProps>(
  function ForwardRefProgramBox(
    {
      "data-testid": dataTestId = "program-box",
      "data-focused": dataFocused,
      "data-position": dataPosition,
      className,
      kind,
      airTime,
      start,
      startDate,
      end,
      endDate,
      subtitle,
      title,
      logoUrl,
      coverImageUrl,
      focused,
      liveProgress,
      recordingState,
      replayProgress,
      active,
      children,
    },
    ref,
  ) {
    return (
      <ProgramBoxWrapper
        ref={ref}
        className={className}
        kind={kind}
        data-testid={dataTestId}
        data-focused={dataFocused}
        data-position={dataPosition}
        startDate={startDate}
        endDate={endDate}
        focused={focused}
      >
        {children ? <div className={styles.lock}>{children}</div> : null}

        <ProgramTime
          airTime={airTime}
          start={start}
          end={end}
          className={styles.airTime}
          data-testid={`${dataTestId}.time`}
          spaceBetween
        />
        <ProgramCover
          data-testid={`${dataTestId}.cover`}
          logoUrl={logoUrl}
          coverImageUrl={coverImageUrl}
          focused={focused}
          kind={kind}
          liveProgress={liveProgress}
          replayProgress={replayProgress}
          recordingState={recordingState}
        />
        <div className={styles.text}>
          {title && (
            <ProgramTitle
              title={title}
              active={active}
              data-testid={`${dataTestId}.title`}
            />
          )}
          {subtitle && (
            <ProgramSubtitle
              subtitle={subtitle}
              data-testid={`${dataTestId}.subtitle`}
            />
          )}
        </div>
      </ProgramBoxWrapper>
    );
  },
);
