import type { Nullable } from "@sunrise/utils";
import { atom } from "jotai";
import { isLegacyBackendAtom } from "@sunrise/backend-core";
import { allChannelsQueryLegacyAtom } from "./legacy/queries/all-channels.query.legacy.atom";
import type { ChannelId } from "@sunrise/backend-types-core";
import { currentLanguageAtom, getLocalizedValue } from "@sunrise/i18n";

/**
 * An atom to get all the channels. This will autopage on them.
 *
 * NOTE: Try to NOT use this. Since this is heavy and not needed. It's there because yallo-embedded-player uses it just in test to fetch a channel by name.
 */
export const allChannelsAtom = atom<
  Promise<Nullable<{ id: ChannelId; name: string; aliases?: string[] }[]>>
>(async (get) => {
  if (get(isLegacyBackendAtom)) {
    const language = get(currentLanguageAtom);
    return (await get(allChannelsQueryLegacyAtom)).data?.result.map(
      (channel) => ({
        id: channel.id,
        name:
          getLocalizedValue(channel.long_name, language) ?? channel.short_name,
        aliases: channel.alias_name
          .concat(channel.short_name)
          .concat(Object.values(channel.long_name)),
      }),
    );
  }

  return null;
});
