import { subMinutes } from "date-fns/fp";
import { atom } from "jotai";

import type { EPGEntry } from "@sunrise/backend-types";
import { getEpgEntryPlayingAtTimeOnChannel } from "@sunrise/yallo-epg";

import { selectPlayerCurrentPlayRequest } from "./player.atom";
import { playerCurrentEpgItemAtom } from "./player-current-epg-item.atom";

/**
 * Returns the EPG item that was playing before the currently playing inside the player.
 * Works for EPG/linear-based streams.
 *
 * This does NOT take seeking into account.
 */
export const playerPreviousEpgItemAtom = atom<Promise<EPGEntry | null>>(
  async (get) => {
    const playRequest = get(selectPlayerCurrentPlayRequest);
    const currentEpgItem = await get(playerCurrentEpgItemAtom);
    if (!playRequest || !playRequest.channelId) return null;

    const currentStartTime = currentEpgItem?.actual_start
      ? new Date(currentEpgItem?.actual_start)
      : null;
    if (!currentStartTime) return null;

    // get the EPG item playing one minute before the current one
    const previousEpgTime = subMinutes(1)(currentStartTime);
    if (!previousEpgTime) return null;

    return get(
      getEpgEntryPlayingAtTimeOnChannel({ channelId: playRequest.channelId }),
    )(previousEpgTime);
  },
);
