import type {
  AdAlternativeAdsType,
  AdTag,
  VideoAdTag,
} from "@sunrise/backend-types";

export function isReplayAdTag(
  tag: VideoAdTag,
): tag is AdTag<AdAlternativeAdsType> {
  return "replayalternativevideoadstartevent" in tag.tracking;
}
