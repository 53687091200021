import deepEqual from "fast-deep-equal";
import { atom } from "jotai";
import { atomFamily } from "jotai/utils";

import type { EPGEntryId } from "@sunrise/backend-types-core";
import type { Nullable } from "@sunrise/utils";
import {
  preferRecordingsAtom,
  recordingStatusByEpgIdAtom,
} from "@sunrise/yallo-recordings";

import { continueWatchingByEpgIdOrRecordingIdAtom } from "./continue-watching-by-epg-id-or-recording-id.atom";

/**
 * Returns the resume state for a specific epgId.
 * It will also keep in mind if we prefer recordings or not.
 * When we do not want to play out recordings, we just seek in the EPG CW items.
 * Else, we prefer seeking via the recordingId. When we do seek on recordings, we only care about recordings that are already recorded.
 * We are not supposed to have CW items on planned / partially recorded recordings.
 */
export const continueWatchingByEpgIdAtom = atomFamily(
  ({ epgId }: { epgId: Nullable<EPGEntryId> }) => {
    const innerAtom = epgId
      ? atom(async (get) => {
          const preferRecordings = get(preferRecordingsAtom);

          const recordingStatus = preferRecordings
            ? await get(recordingStatusByEpgIdAtom(epgId))
            : null;

          const isRecordingPlayable = recordingStatus
            ? recordingStatus.status === "recorded"
            : false;

          return await get(
            continueWatchingByEpgIdOrRecordingIdAtom({
              epgId: epgId,
              recordingId:
                isRecordingPlayable && preferRecordings && recordingStatus?.id
                  ? recordingStatus.id
                  : null,
            }),
          );
        })
      : atom(() => Promise.resolve(null));

    innerAtom.debugLabel = `continueWatchingByEpgIdAtom(${epgId})`;

    return innerAtom;
  },
  deepEqual,
);
