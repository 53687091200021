import { useTranslator } from "@sunrise/translator";
import {
  preferredChannelGroupIdAtom,
  SelectableChannelGroupItem,
} from "@sunrise/yallo-channel-group";
import { useVirtualizer } from "@tanstack/react-virtual";
import { useAtomCallback } from "jotai/utils";
import { ReactNode, useCallback, useMemo, useRef } from "react";

import { button } from "@/config/size";
import { FocusContainerCallbackHandlers } from "@/utils/focus-container";

import { channelGroupIdToFocusId } from "./channel-group-id-to-focus-id";
import { ChannelGroupsButton } from "./channel-groups-button";
import * as styles from "./channel-groups.css";
import type { ChannelGroupId } from "@sunrise/backend-types-core";

export type ChannelGroupsListProps = {
  "data-testid"?: string;
  directions: FocusContainerCallbackHandlers;
  combinedChannelGroups: SelectableChannelGroupItem[];
  selectedId: ChannelGroupId | undefined;
};

export function ChannelGroupsList({
  "data-testid": testId = "channel-groups",
  directions,
  combinedChannelGroups,
  selectedId,
}: ChannelGroupsListProps): ReactNode {
  const t = useTranslator();
  const groups = useMemo(() => {
    return combinedChannelGroups.map((group) => ({
      ...group,
      focusKey: channelGroupIdToFocusId(group.id),
    }));
  }, [combinedChannelGroups]);

  const scrollRef = useRef<HTMLDivElement>(null);

  const channelGroupsVirtualizer = useVirtualizer({
    count: groups.length,
    estimateSize: () => button.default.height,
    getScrollElement: () => scrollRef.current,
    overscan: 2,
  });

  const virtualItems = channelGroupsVirtualizer.getVirtualItems();
  const onItemEnterPress = useAtomCallback(
    useCallback((_get, set, group: SelectableChannelGroupItem): void => {
      set(preferredChannelGroupIdAtom, group.id);
    }, []),
  );

  return (
    <div data-testid={testId} className={styles.channelGroupsContainer}>
      <div
        className={styles.channelGroupsHeader}
        data-testid={`${testId}.title`}
      >
        {t("channelListExtendedTitle")}
      </div>
      <div
        className={styles.innerScroll}
        ref={scrollRef}
        data-testid={`${testId}.list`}
      >
        {groups.map((channelGroup, idx) => {
          const virtualItem = virtualItems.find((item) => item.index === idx);
          return (
            <div key={channelGroup.id} className={styles.channelGroupButton}>
              {/* This small padding is needed for the navigation to work properly.
                        If all buttons are stuck to each other then it will skip over buttons.
                     */}
              <ChannelGroupsButton
                data-testid={`${testId}.button.${idx}`}
                focusKey={channelGroup.focusKey}
                active={selectedId === channelGroup.id}
                text={channelGroup.name}
                onArrowPress={directions.onArrowPress("left")}
                onEnterPress={() => onItemEnterPress(channelGroup)}
                onScreen={!!virtualItem}
                scrollRef={scrollRef}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
}
