import deepEqual from "fast-deep-equal";
import { atom } from "jotai";
import { atomFamily } from "jotai/utils";

import { assetDetailsByIdAtom } from "@sunrise/asset";
import type { AssetId, RecordingGroupId } from "@sunrise/backend-types-core";
import { recordingGroupQueryAtom } from "./query-atoms/recording-group.query.atom";

/**
 * This returns a total of recordings + a list of seasons mapped to the recording groups.
 * But it also contains the correct season number which we need to load from another part of the backend.
 */
export const recordingSeasonsByRecordingGroupId = atomFamily(
  ({
    recordingGroupId,
    seriesAssetId,
  }: {
    recordingGroupId: RecordingGroupId;
    seriesAssetId: AssetId;
  }) => {
    const inner = atom(async (get) => {
      const raw = await get(recordingGroupQueryAtom(recordingGroupId));

      const { data: asset } = await get(assetDetailsByIdAtom(seriesAssetId));

      const assetSeasons = asset?.type === "series" ? asset.seasons : [];
      const seasons = raw.data.child_groups
        .map((season) => ({
          seasonNumber:
            assetSeasons.find((s) => s.id === season.asset.id)?.season ?? null,
          seasonId: season.id,
          recordingsCount: season.episode_count,
        }))
        .sort((a, b) => (a.seasonNumber ?? 0) - (b.seasonNumber ?? 0));

      return {
        recordingsCount: raw.data.episode_count,
        seasons,
      };
    });

    inner.debugLabel = `recordingSeasonsByRecordingGroupId(${recordingGroupId})`;

    return inner;
  },
  deepEqual,
);
