import { type Atom, atom } from "jotai";

/**
 * TODO: To be extracted to a `device` package or something like that.
 *
 * TODO: Change deviceId atom to be part of yallo-settings and just have it be a string.
 */
export const deviceIdAtom = atom<Atom<Promise<string | undefined>> | undefined>(
  undefined,
);
