import type {
  AdAlternativeAdsType,
  AdEventType,
  FFWDAdEventType,
  PauseAdEventType,
  PauseAdJsonConfig,
  PauseAnalyticsEventType,
} from "@sunrise/backend-types";
import { type Nullable } from "@sunrise/utils";

export const VIDEO_ADS_ELEMENT_ID = "video-ads";

export const ALTERNATIVE_AD_MAPPING: Record<AdEventType, AdAlternativeAdsType> =
  {
    empty: "replayalternativevideoademptyevent",
    end: "replayalternativevideoadendevent",
    error: "replayalternativevideoaderrorevent",
    skipped: "replayalternativevideoadskippedevent",
    start: "replayalternativevideoadstartevent",
  };

export const FFWD_AD_MAPPING: Record<AdEventType, FFWDAdEventType> = {
  empty: "fastforwardalternativevideoademptyevent",
  end: "fastforwardalternativevideoadendevent",
  error: "fastforwardalternativevideoaderrorevent",
  skipped: "fastforwardalternativevideoadskippedevent",
  start: "fastforwardalternativevideoadstartevent",
};

export const PAUSE_ADS_EVENT_MAPPING = {
  "10s": {
    internal: "pausealternativeimagead10secevent",
    external: "overlayViewDuration",
  },
  "120s": {
    internal: "pausealternativeimagead120secevent",
    external: "otherAdInteraction",
  },
  empty: {
    internal: "pausealternativeimageademptyevent",
    external: null,
  },
  error: {
    internal: "pausealternativeimageaderrorevent",
    external: null,
  },
  show: {
    internal: "pausealternativeimageadstartevent",
    external: "creativeView",
  },
  end: {
    internal: "pausealternativeimageadendevent",
    external: "close",
  },
} satisfies Record<
  PauseAnalyticsEventType,
  {
    internal: PauseAdEventType;
    external: Nullable<keyof PauseAdJsonConfig["tracking"]>;
  }
>;
