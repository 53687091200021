import { ReactNode } from "react";

import { Button, IconButton } from "@/components";

export function DebugButtons(): ReactNode {
  const longText = "Lorem ipsum dolor sit amet";
  const shortText = "Lorem ipsum";
  const spaceBtw = "space-between";

  return (
    <div
      style={{
        margin: "2%",
      }}
    >
      <div style={{ margin: "3%", fontSize: "24px" }}>
        Buttons in states: default, focused, active (if there is no active it
        means it does not exist on that type of button){" "}
      </div>
      <h2>Primary buttons</h2>
      <div
        style={{
          margin: "2%",
          display: "flex",
          justifyContent: spaceBtw,
          alignItems: "center",
        }}
      >
        <Button text={longText} />
        <Button text={longText} focused />
        <Button text={shortText} active />
        <Button text={"short button"} short />
        <Button text={shortText} short focused />
        <Button text={shortText} short active />
        <Button text={shortText} variant="light" short active />
      </div>
      <h2>Buttons with icon</h2>
      <div
        style={{
          margin: "2%",
          display: "flex",
          justifyContent: spaceBtw,
          alignItems: "center",
        }}
      >
        <Button text={longText} iconLeft="edit" />
        <Button text={longText} iconRight="dropdown-down" />
        <Button text={longText} iconLeft="edit" focused />
        <Button text={longText} iconRight="dropdown-down" focused />
      </div>
      <h2>Block buttons 600px width</h2>
      <div
        style={{
          width: "600px",
          height: "300px",
          display: "flex",
          flexDirection: "column",
          justifyContent: spaceBtw,
          margin: "2%",
        }}
      >
        <Button text={longText} block />
        <Button text={longText} block focused />
        <Button text={longText} block active />
      </div>
      <h2>Ghost buttons</h2>
      <div
        style={{
          margin: "2%",
          display: "flex",
          justifyContent: spaceBtw,
          alignItems: "center",
        }}
      >
        <Button text={longText} block variant="ghost" />
        <Button text={longText} block variant="ghost" focused />
        <Button text={longText} block variant="ghost" active />
      </div>

      <h2>Secondary buttons</h2>
      <div
        style={{
          margin: "2%",
          display: "flex",
          justifyContent: spaceBtw,
          alignItems: "center",
        }}
      >
        <Button text={longText} block variant="secondary" iconLeft="guide" />
        <Button
          text={longText}
          block
          variant="secondary"
          iconLeft="home"
          focused
        />
        <Button
          text={longText}
          block
          variant="secondary"
          iconLeft="settings"
          active
        />
      </div>

      <h2>Outlined buttons</h2>
      <div
        style={{
          margin: "2%",
          display: "flex",
          justifyContent: spaceBtw,
          alignItems: "center",
        }}
      >
        <Button text={longText} variant={"outlined"} />
        <Button text={longText} focused variant={"outlined"} />
        <Button text={"short button"} short variant={"outlined"} />
        <Button text={shortText} short focused variant={"outlined"} />
      </div>

      <h2>Icon buttons</h2>
      <div
        style={{
          margin: "2%",
          display: "flex",
          justifyContent: spaceBtw,
          alignItems: "center",
          flexWrap: "wrap",
        }}
      >
        <IconButton iconName="add" />
        <IconButton iconName="close" />
        <IconButton iconName="done" />
        <IconButton iconName="dropdown-down" />
        <IconButton iconName="dropdown-left" />
        <IconButton iconName="dropdown-right" />
        <IconButton iconName="dropdown-up" />
        <IconButton iconName="edit" />
        <IconButton iconName="expand-channel-list" />
        <IconButton iconName="info" />
        <IconButton iconName="live" />
        <IconButton iconName="lock" />
        <IconButton iconName="more" />
        <IconButton iconName="next" />
        <IconButton iconName="next-program" />
        <IconButton iconName="next30" />
        <IconButton iconName="pause" />
        <IconButton iconName="play" />
        <IconButton iconName="play-circle" />
        <IconButton iconName="previous" />
        <IconButton iconName="previous-program" />
        <IconButton iconName="previous15" />
        <IconButton iconName="radio-off" />
        <IconButton iconName="radio-on" />
        <IconButton iconName="record" />
        <IconButton iconName="recorded" />
        <IconButton iconName="recording" />
        <IconButton iconName="remove" />
        <IconButton iconName="resume" />
        <IconButton iconName="search" />
        <IconButton iconName="select" />
        <IconButton iconName="sort" />
        <IconButton iconName="subtitles-and-audio" />
        <IconButton iconName="up" />
        <IconButton iconName="voice" />
        <IconButton iconName="warning" />
        <IconButton iconName="watch-live" />
      </div>
      <div
        style={{
          margin: "2%",
          display: "flex",
          justifyContent: spaceBtw,
          alignItems: "center",
          flexWrap: "wrap",
        }}
      >
        <h3 style={{ margin: "2%" }}>focused</h3>

        <IconButton iconName="add" focused />
        <IconButton iconName="close" focused />
        <IconButton iconName="done" focused />
        <IconButton iconName="dropdown-down" focused />
        <IconButton iconName="dropdown-left" focused />
        <IconButton iconName="dropdown-right" focused />
        <IconButton iconName="dropdown-up" focused />
        <IconButton iconName="edit" focused />
        <IconButton iconName="expand-channel-list" focused />
        <IconButton iconName="info" focused />
        <IconButton iconName="live" focused />
        <IconButton iconName="lock" focused />
        <IconButton iconName="more" focused />
        <IconButton iconName="next" focused />
        <IconButton iconName="next-program" focused />
        <IconButton iconName="next30" focused />
        <IconButton iconName="pause" focused />
        <IconButton iconName="play" focused />
        <IconButton iconName="play-circle" focused />
        <IconButton iconName="previous" focused />
        <IconButton iconName="previous-program" focused />
        <IconButton iconName="previous15" focused />
        <IconButton iconName="radio-off" focused />
        <IconButton iconName="radio-on" focused />
        <IconButton iconName="record" focused />
        <IconButton iconName="recorded" focused />
        <IconButton iconName="recording" focused />
        <IconButton iconName="remove" focused />
        <IconButton iconName="resume" focused />
        <IconButton iconName="search" focused />
        <IconButton iconName="select" focused />
        <IconButton iconName="sort" focused />
        <IconButton iconName="subtitles-and-audio" focused />
        <IconButton iconName="up" focused />
        <IconButton iconName="voice" focused />
        <IconButton iconName="warning" focused />
        <IconButton iconName="watch-live" focused />
      </div>
    </div>
  );
}
