import { type Nullable } from "@sunrise/utils";
import { isEqual } from "lodash";

import {
  type LinearSeekService,
  type LoadOptions,
  type OnDemandSeekService,
  type OriginatingAction,
  type PlayRequestSource,
  type PlayRequestToAdPlayout,
  type PlayRequestToStream,
  type PlayerDispatch,
  type PlayerManagerGuard,
} from "./player-manager.types";
import type {
  DateToNumberConverter,
  StreamModel,
} from "@sunrise/backend-types";

/**
 * This is the PlayerManager service.
 * It is responsible for managing the player through requests made by the user or the UI.
 *
 * It will know what to do when the user wants to load, play, pause, seek, etc.
 *
 * The goal of this service is to have a single point of contact for the users to intract with the player.
 * The internals of the PlayerManager should be kept to a minimum though and as much as possible should be delegated to other services.
 *
 * It's also preferred to not have the PlayerManager be wired up directly to the jotai store used elsewhere in the codebase.
 * Just so we can keep it as generic as possible should we later on swap out jotai for something else.
 *
 * The PlayerManager works on generic types and does not depend on any module from the app.
 * It is expected to instantiate a PlayerManager for your tenant through a dedicated module.
 * In the case of yallo this is done through the `yallo-common-player-manager` module.
 */
export class PlayerManager<PlayRequest> {
  constructor(
    protected readonly requestToStream: PlayRequestToStream<PlayRequest>,
    protected readonly requestToAds: PlayRequestToAdPlayout<PlayRequest>,
    protected readonly getPlayRequest: (
      source: PlayRequestSource,
    ) => Nullable<PlayRequest>,
    protected readonly playerDispatch: PlayerDispatch<PlayRequest>,
    protected readonly guard: PlayerManagerGuard<PlayRequest>,
    /**
     * Linear streams need to ensure that they can only seek in what is available for playout.
     * That means the replay window up to live.
     */
    protected readonly linearSeekService: LinearSeekService<PlayRequest>,
    /**
     * The player manager needs to handle on demand seeking differently.
     */
    protected readonly onDemandSeekService: OnDemandSeekService<PlayRequest>,

    /**
     * This is super unintuitive. We have a class that knows how to load a stream.
     * But somehow first needs to tell the store a stream is being loaded.
     * It's not intuitive and that's why I think the seekService should not be part of the PlayerManager.
     * We can have a SeekService that lives outside of the PlayerManager.
     * And we can just talk to that and then delegate to the PlayerManager as needed.
     */
    protected readonly setPlayRequest: (
      request: PlayRequest,
      options?: LoadOptions,
    ) => void,

    /**
     * For linear streams the seeking behaves differently.
     */
    protected readonly getStreamModel: (
      source: PlayRequestSource,
    ) => Nullable<StreamModel>,

    /**
     * For linear streams the seeking behaves differently.
     * We need to map dates to times in the player and the other way around.
     */
    protected readonly getLinearToNumberConverter: (
      source: PlayRequestSource,
    ) => Nullable<DateToNumberConverter>,
    private readonly getCurrentTime: () => Nullable<Date>,
    protected readonly linearSeekAds: (
      currentTime: Date,
      playRequest: PlayRequest,
      /**
       * When no time is passed, assume we are seeking to live.
       */
      seekTime?: Date,
    ) => Promise<Date | void>,
  ) {}

  /**
   * @throws
   *   Throws whatever requestToStream and requestToAds can throw.
   */
  async load(
    request: PlayRequest,
    options?: Nullable<LoadOptions>,
  ): Promise<void> {
    if (
      !(await this.guard.canPlay(request)) ||
      !isEqual(this.getPlayRequest("requested"), request)
    ) {
      return;
    }

    const [playoutAds, getStream] = await Promise.all([
      this.requestToAds(request, options),
      // For preflight and non-preflight, this will already throw an error if the user is blocked from streaming.
      // That way, we can abort the playout of the ads.
      this.requestToStream(request),
    ]);

    // We need to treat certain jumps as seeks in time.
    const currentTime = this.getCurrentTime();
    if (currentTime) {
      // We immediately play out an ad if we have one through seeking.
      await this.linearSeekAds(currentTime, request);
    }

    // We play out the ads. Once that is done, we will do the final load of the stream.
    await playoutAds();

    // When we changed request during ad playout, we can already abort.
    // But if we somehow changed to another request and then back to a new request for the original content, we would continue.
    if (!isEqual(this.getPlayRequest("requested"), request)) return;
    const stream = await getStream();

    // When we changed request during the resolving of the stream, we can abort.
    if (!isEqual(this.getPlayRequest("requested"), request)) return;

    // This pushes the stream and the request to the player.
    this.playerDispatch.load(request, stream, options);
  }

  /**
   * Assumes that a stream is already loaded and that the player is not errored. Else play will not do much.
   * If the stream is loaded and just paused, triggering this function will resume playing again.
   */
  async play(): Promise<void> {
    const request = this.getPlayRequest("current");

    if (request && !(await this.guard.canPlay(request))) {
      return;
    }

    this.playerDispatch.play();
  }

  /**
   * Assumes that a stream is loaded and playing in the player.
   * When called, this function will then pause the player.
   *
   * If the player is currently playing out a playRequest, it will guard against pausing it.
   */
  async pause(): Promise<void> {
    const request = this.getPlayRequest("current");

    if (request && !(await this.guard.canPause(request))) {
      return;
    }

    // Pausing if it's allowed.
    // Normally, if we load in a different playRequest, it should not start automatically but stay paused.
    this.playerDispatch.pause();

    // For linear content we may need to swap underlying streams.
    if (this.getStreamModel("current") === "linear") {
      // We need to cover that pausing may require a different playRequest to be loaded.
      // So we ask for it and if it differs, we load it alongside the date returned. This should be the date that we were able to pause at.
      const data = await this.linearSeekService.evaluatePauseSeekTime();

      if (data && !isEqual(request, data.playRequest)) {
        this.setPlayRequest(data.playRequest, {
          currentTime: data.date,
          ensurePaused: true,
        });
      }
    }
  }

  /**
   * Not every stream can seek to every point in time. Nor is every user allowed to do so.
   * This function should be called by whatever mechanism that attempts to seek.
   * It'll return the Date the user can currently seek to if they should choose to.
   * This will not trigger the seek yet. For that you need to use @see seekToInCurrentPlayRequest instead.
   *
   * It will look at the playRequest that is currently loaded in the player and determine the Date the user can seek to.
   * Not every stream can seek to every point in time. And we also need to clip the time to the lower and upper bounds of the stream.
   *
   * NOTE: Maybe we can expose the seekService elsewhere as a separate service.
   *
   * @param time
   *  The time a user may we want to seek to. This is always a number.
   *  For linear (replay/live) this has to be the seconds since epoch. For on demand this has to be seconds since stream start.
   *  In all cases, it has to be the number format that the player uses.
   *
   * @returns
   *   The time the user could seek up/down to if they wanted.
   */
  async couldSeekToInCurrentPlayRequest(
    time: number,
  ): Promise<Nullable<number>> {
    if (this.getStreamModel("current") === "on-demand") {
      return this.onDemandSeekService.evaluateSeekTime(time)?.progress;
    }

    const converter = this.getLinearToNumberConverter("current");
    if (!converter) {
      return null;
    }
    const date = converter.toDate(time);
    if (!date) {
      return null;
    }

    const result = await this.linearSeekService.evaluateSeekTime(date);
    if (!result) {
      return null;
    }

    return converter.fromDate(result.date);
  }

  /**
   * This may attempt to load a new PlayRequest as needed if we need to switch the user from live to replay for example.
   * If the current stream supports the new seektime then it will just ask the player to seek to it.
   *
   * @param time
   *  The time a user may we want to seek to. This is always a number.
   *  For linear (replay/live) this has to be the seconds since epoch. For on demand this has to be seconds since stream start.
   *  In all cases, it has to be the number format that the player uses.
   */
  async seekToInCurrentPlayRequest(
    time: number,
    originatingAction?: Nullable<OriginatingAction>,
  ): Promise<void> {
    const request = this.getPlayRequest("current");
    if (!request) {
      return;
    }

    if (this.getStreamModel("current") === "on-demand") {
      const data = this.onDemandSeekService.evaluateSeekTime(time);
      if (!data) {
        return;
      }

      this.playerDispatch.seek(data.progress);
    } else {
      const converter = this.getLinearToNumberConverter("current");
      if (!converter) {
        return;
      }

      const date = converter.toDate(time);
      if (!date) {
        return;
      }
      const data = await this.linearSeekService.evaluateSeekTime(date);
      if (!data) return;

      const currentTime = this.getCurrentTime();
      let seekAfterAdsDate: Date = data.date;
      if (currentTime) {
        // We play out an ad if we have one through seeking.
        const result = await this.linearSeekAds(
          currentTime,
          data.playRequest,
          data.date,
        );

        if (result) {
          // It's possible a new date is returned. Which should then override the original date to seek to.
          seekAfterAdsDate = result;
        }
      }

      // On seek to start we want to always trigger a new playrequest.
      // Because we may need to play out replay ads in this case.
      // There's a business requirement that even when I am already replaying, when I jump to the start, I need to play out pre-roll replay ads again.
      if (
        !isEqual(request, data.playRequest) ||
        originatingAction === "seek-to-start"
      ) {
        this.setPlayRequest(data.playRequest, {
          currentTime: seekAfterAdsDate,
          originatingAction,
        });
        return;
      }

      // NOTE: When we seek in the current play request, it is possible we need to play more ads.
      this.playerDispatch.seek(converter.fromDate(seekAfterAdsDate));
    }
  }

  async canPlay(playRequest: PlayRequest, silent: boolean): Promise<boolean> {
    return this.guard.canPlay(playRequest, silent);
  }
}
