import { isLegacyBackendAtom } from "@sunrise/backend-core";
import type { ChannelId } from "@sunrise/backend-types-core";
import { atom } from "jotai";
import { atomFamily } from "jotai/utils";
import { isChannelLockedLegacyAtom } from "./legacy/is-channel-locked.legacy.atom";
import { channelByIdNgAtom } from "./ng/channel-by-id.ng.atom";

export const isChannelLockedAtom = atomFamily((id: ChannelId) => {
  const innerAtom = atom(async (get) => {
    if (get(isLegacyBackendAtom)) {
      return get(isChannelLockedLegacyAtom(id));
    }

    const channel = await get(channelByIdNgAtom(id));
    return channel?.locked ?? true;
  });

  innerAtom.debugLabel = `isChannelLockedAtom(${id})`;

  return innerAtom;
});
