import { isAtMaxRecordingCapacityAtom } from "@sunrise/yallo-recordings";
import { useAtomValue } from "jotai";
import { loadable } from "jotai/utils";
import { Suspense } from "react";

import { Menu } from "@/features/menu/menu";
import styles from "@/transition-layer.module.css";

import { ChannelSelector } from "./features/channel-list";

const atCapacityLoadable = loadable(isAtMaxRecordingCapacityAtom);

export function TransitionLayer(): JSX.Element {
  const recordingsAtCapacity = useAtomValue(atCapacityLoadable);

  return (
    <div className={styles.root}>
      <Menu
        recordingsAtCapacity={
          recordingsAtCapacity.state === "hasData"
            ? recordingsAtCapacity.data
            : false
        }
      />
      <Suspense>
        <ChannelSelector data-testid="channel-selector" />
      </Suspense>
    </div>
  );
}
