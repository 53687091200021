import { readTizenPlatformVersion } from "@/modules/tizen/system.service";

/**
 * Makes record filled with tags with device information
 */
export function makeMonitoringTags(): Record<string, string> {
  return {
    tizenPlatformVersion: readTizenPlatformVersion(),
  };
}
