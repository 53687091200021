import { ReactNode, useEffect } from "react";
import {
  FocusContext,
  setFocus,
  useFocusable,
} from "@noriginmedia/norigin-spatial-navigation";
import clsx from "clsx";
import { useAtom } from "jotai";

import { actionQrCodeDialogClose, qrCodeDialogAtom } from "@sunrise/dialogs";

import { Dialog, FocusButton, QrCode } from "@/components";
import { typography } from "@/styles/typography.css";

import * as styles from "./qr-code-dialog.css";

type QrCodeDialogProps = CommonProps;

export function QrCodeDialog({
  className,
  "data-testid": testId = "qr-code-dialog",
}: QrCodeDialogProps): ReactNode {
  const [dialog, dispatchDialog] = useAtom(qrCodeDialogAtom);

  const { focusKey, focusSelf, ref } = useFocusable({
    isFocusBoundary: true,
    focusable: dialog.isOpen,
  });

  const preferredChildFocusKey = `${focusKey}.confirm}`;

  useEffect(() => {
    if (dialog.isOpen) setFocus(preferredChildFocusKey);

    return () => {
      setFocus(dialog.lastFocusKey);
    };
  }, [focusSelf, dialog.isOpen, preferredChildFocusKey, dialog.lastFocusKey]);

  const qrCode = (
    <div className={styles.qrCode}>
      <QrCode
        qrCodeUrl={dialog.qrCodeUrl}
        link={dialog.link}
        size={dialog.qrCodeSize}
      />
    </div>
  );

  return (
    <FocusContext.Provider value={focusKey}>
      <Dialog
        open={dialog.isOpen}
        data-testid={testId}
        className={className}
        ref={ref}
      >
        <div
          className={clsx([
            styles.content,
            dialog.flexDirectionRow && styles.rowContent,
          ])}
          data-testid={`${testId}.content`}
        >
          <div
            className={clsx([
              styles.column,
              dialog.flexDirectionRow && styles.leftColumn,
            ])}
            data-testid={`${testId}.column`}
          >
            <div
              className={clsx([
                styles.title,
                typography.h1.bold,
                dialog.flexDirectionRow && styles.row,
              ])}
              data-testid={`${testId}.title`}
            >
              {dialog.title}
            </div>
            <div
              className={clsx([
                styles.description,
                typography.h4.regular,
                dialog.flexDirectionRow && styles.row,
              ])}
              data-testid={`${testId}.description`}
            >
              {dialog.description}
            </div>

            {!dialog.flexDirectionRow && qrCode}

            <div
              className={clsx([
                styles.buttons,
                dialog.flexDirectionRow && styles.row,
              ])}
            >
              <FocusButton
                data-testid={`${testId}.button.confirm`}
                text={dialog.confirmButtonLabel}
                onEnterPress={() => {
                  dialog.onConfirm();
                  dispatchDialog(actionQrCodeDialogClose());
                }}
                className={styles.button}
                focusKey={preferredChildFocusKey}
                block
              />

              {dialog.rejectButtonLabel && dialog.onReject && (
                <FocusButton
                  data-testid={`${testId}.button.reject`}
                  text={dialog.rejectButtonLabel}
                  onEnterPress={() => {
                    dialog.onReject?.();
                    dispatchDialog(actionQrCodeDialogClose());
                  }}
                  className={styles.button}
                  block
                />
              )}
            </div>
          </div>
          {dialog.flexDirectionRow && qrCode}
        </div>
      </Dialog>
    </FocusContext.Provider>
  );
}
