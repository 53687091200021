import type { RecordingStatusUpdate } from "@sunrise/backend-types";
import { atomWithArrayUpdates } from "@sunrise/utils";

/**
 * An atom to represent the socket's knowledge of the recording statuses.
 * Whenever we write a status to it we will either add it or update it.
 *
 * The knowledge also contains recordingIds that have been deleted or cancelled.
 * We still want to emit that data because other atoms that depend on this will need
 * to know to remove that data from other sources as well.
 */
export const recordingStatusSocketAtom =
  atomWithArrayUpdates<RecordingStatusUpdate>([], {
    compareFn: (a, b) => a.id === b.id || a.epg_entry.id === b.epg_entry.id,
    debugLabel: "recordingStatusSocket",
  });
