import {
  backgroundBehaviourAtom,
  selectProcessIsBackgrounded,
} from "@sunrise/process-visibility";
import { atomEffect } from "jotai-effect";

import {
  actionPlayerSetSuspended,
  playerAtom,
  selectPlayerIsSuspended,
} from "../player.atom";

/**
 * When we background the app, we need to suspend the player.
 *
 * NOTE: This is in the player manager package but I guess it should be moved to the player package which then needs to know about the process-visibility package.
 */
export const suspendPlayerOnBackgroundEffect = atomEffect((get, set) => {
  const allowSuspension = get(backgroundBehaviourAtom) === "suspend-player";
  if (!allowSuspension) {
    return;
  }

  const isSuspended = get(selectPlayerIsSuspended);
  const shouldSuspend = get(selectProcessIsBackgrounded);

  if (shouldSuspend && !isSuspended) {
    set(playerAtom, actionPlayerSetSuspended());
  }
});
