import { useTranslator } from "@sunrise/translator";
import {
  isAtMaxRecordingCapacityAtom,
  recordingStatsAtom,
} from "@sunrise/yallo-recordings";
import { useAtomValue } from "jotai";
import type { ReactNode } from "react";

import { Button } from "@/components";

type RecordingsCapacityIndicatorProps = CommonProps;

export function RecordingsCapacityIndicator({
  "data-testid": dataTestId = "recordings-capacity-indicator",
}: RecordingsCapacityIndicatorProps): ReactNode {
  const capacity = useAtomValue(recordingStatsAtom);
  const atCapacity = useAtomValue(isAtMaxRecordingCapacityAtom);
  const t = useTranslator();

  if (atCapacity) {
    return (
      <Button
        text={t("button_disk_space_full")}
        iconLeft="warning"
        variant="outlineddanger"
        data-testid={dataTestId}
      />
    );
  }

  if (!capacity) return null;

  return (
    <div
      data-testid={dataTestId}
      data-capacity-used={capacity.used_recordings}
      data-capacity-total={capacity.capacity_recordings}
    >
      {t("recordings_used_tvos", [
        capacity.used_recordings,
        capacity.capacity_recordings,
      ])}
    </div>
  );
}
