import { AxiosError } from "axios";
import { atomFamily } from "jotai/utils";
import { atomWithSuspenseQuery } from "jotai-tanstack-query";
import { isNil } from "lodash";

import { ngRatingApiAtom } from "@sunrise/backend-ng-rating";
import { queryKeys } from "@sunrise/backend-types";
import { AssetId } from "@sunrise/backend-types-core";
import { type Nullable } from "@sunrise/utils";

export const assetRatingByIdNgAtom = atomFamily((assetId: Nullable<AssetId>) =>
  atomWithSuspenseQuery<Nullable<number>>((get) => {
    const api = get(ngRatingApiAtom);
    if (isNil(api)) {
      throw new Error("ngRatingApiAtom is not initialized");
    }

    return {
      enabled: !isNil(assetId),
      queryKey: queryKeys.assetRatingById(assetId),
      queryFn: async () => {
        if (!assetId) return null;

        try {
          const data = await api.rating.getRatingRatingV1AssetsAssetIdRatingGet(
            assetId,
          );
          return data.data.rating;
        } catch (err) {
          // If no rating is found, we don't want any errors logged
          if (err instanceof AxiosError && err?.response?.status === 404) {
            return null;
          }

          throw err;
        }
      },
      staleTime: Infinity,
      cacheTime: Infinity,
      retry: false,
    };
  }),
);
