import { type ReactNode, Suspense, useCallback, useEffect } from "react";
import {
  doesFocusableExist,
  setFocus,
  useFocusable,
} from "@noriginmedia/norigin-spatial-navigation";
import { useAtomValue, useSetAtom } from "jotai";

import { skipAd, videoAdsAtom } from "@sunrise/ads";
import { actionPlayerIsVisible, playerAtom } from "@sunrise/player";
import { useDebounceEffect } from "@sunrise/utils";
import { useAutostart } from "@sunrise/yallo-common-player-manager";
import { actionGuideReset, gridStateAtom } from "@sunrise/yallo-guide";
import {
  actionPlayerControlsShow,
  playerControlsAtom,
  shouldNotShowPlayerControlsAtom,
} from "@sunrise/yallo-player-controls";

import { PageSpinner } from "@/components";
import { globalFocusKey } from "@/config/focus-key";
import { Ads } from "@/features/ads/ads";
import { localWidgetFocusKey } from "@/features/ads/focus-keys";
import { PauseAds } from "@/features/ads/pause-ads";
import {
  actionChannelListExpandChannels,
  channelListAtom,
  isChannelListHiddenAtom,
} from "@/features/channel-list";
import { SkipFfwdAdsButton } from "@/features/ffwd-ads/skip-ffwd-ads-button";
import { FirstExperienceTv } from "@/features/first-experience/first-experience-tv";
import { useMenu } from "@/features/menu/use-menu";
import { PlayerControls, PlayerStats } from "@/features/player-controls";
import { WhatIsNext, WhatIsNextSideBar } from "@/features/what-is-next";
import { NumericZapping } from "@/features/zapping/numeric-zapping";
import { ZappingChannel } from "@/features/zapping/zapping-channel";
import { isChannelListAllowedAtom } from "@/modules/channel-group";
import { useKeyboardNavigation } from "@/modules/keyboard-navigation";
import { shouldPullFocusToTVAtom, tvIsFocusedAtom } from "@/modules/tv";
import { shouldShowPlayerLoadingAtom } from "@/modules/tv/should-show-player-loader.atom";
import { useTvScreenRemoteControls } from "@/modules/tv/use-tv-screen-remote-controls";
import {
  isArrowLeftKey,
  isArrowRightKey,
  isVertical,
} from "@/utils/navigation";

import * as styles from "./tv.css";

const SKIP_FFWD_ADS_FOCUS_KEY = "skip-ffwd-ads-button";

function ConnectedTv(): ReactNode {
  const loading = useAtomValue(shouldShowPlayerLoadingAtom);

  /**
   * The current business logic is that we should reset the selection on the guide whenever we navigate to the TV page.
   * If the reset needs to happen in more locations we can make a hook to handle it.
   */
  const dispatchGuideGrid = useSetAtom(gridStateAtom);
  useEffect(() => {
    dispatchGuideGrid(actionGuideReset());
  }, [dispatchGuideGrid]);

  const { isPlaying: isPlayingAds } = useAtomValue(videoAdsAtom);

  // We can always pull focus to the TV page when the SkipFfwdAdsButton has focus.
  const refocusTv = useCallback(() => {
    if (doesFocusableExist(globalFocusKey.tvPage)) {
      setFocus(globalFocusKey.tvPage);
    }

    return false;
  }, []);

  useAutostart();

  const { isVisible } = useAtomValue(playerControlsAtom);

  return (
    <>
      <FirstExperienceTv />
      {!isVisible && (
        <SkipFfwdAdsButton
          focusKey={SKIP_FFWD_ADS_FOCUS_KEY}
          className={styles.bottomRight}
          onArrowPress={refocusTv}
          onEnter={refocusTv}
        />
      )}
      {isPlayingAds ? (
        <Ads skipAd={skipAd} onBlur={refocusTv} />
      ) : (
        <>
          {import.meta.env.MODE !== "production" ? <PlayerStats /> : null}
          <WhatIsNextSideBar />
          <WhatIsNext />
          <PauseAds />
          {loading && <PageSpinner />}
          {isVisible && (
            <Suspense>
              <PlayerControls />
            </Suspense>
          )}
        </>
      )}
      {/* always allow zapping */}
      <ZappingChannel />
      <NumericZapping />
    </>
  );
}

export default function Tv(): ReactNode {
  const menu = useMenu({ shouldShowMenu: false, expandOnBack: false });

  const dispatchChannelList = useSetAtom(channelListAtom);
  const dispatchPlayer = useSetAtom(playerAtom);
  const dispatchPlayerControls = useSetAtom(playerControlsAtom);
  const shouldNotShowPlayerControls = useAtomValue(
    shouldNotShowPlayerControlsAtom,
  );
  const isChannelListAllowed = useAtomValue(isChannelListAllowedAtom);
  const isChannelListHidden = useAtomValue(isChannelListHiddenAtom);

  useEffect(
    function togglePlayerVisibility() {
      dispatchPlayer(actionPlayerIsVisible(true));
      return () => {
        dispatchPlayer(actionPlayerIsVisible(false));
      };
    },
    [dispatchPlayer],
  );

  const showPlayerControls = useCallback(() => {
    if (shouldNotShowPlayerControls || !isChannelListHidden) {
      return;
    }

    dispatchPlayerControls(actionPlayerControlsShow());
  }, [
    dispatchPlayerControls,
    isChannelListHidden,
    shouldNotShowPlayerControls,
  ]);

  const { focusSelf, ref, focused } = useFocusable({
    focusKey: globalFocusKey.tvPage,
    forceFocus: true,
    isFocusBoundary: true,
    onArrowPress: (direction: string) => {
      if (isArrowLeftKey(direction)) {
        menu.expand();
        return true;
      }

      if (isArrowRightKey(direction) && isChannelListAllowed) {
        dispatchChannelList(actionChannelListExpandChannels());
        return true;
      }

      if (isVertical(direction)) {
        // When the ffwd ad button is there we should focus on it when we press up or down.
        if (doesFocusableExist(SKIP_FFWD_ADS_FOCUS_KEY)) {
          setFocus(SKIP_FFWD_ADS_FOCUS_KEY);
          return true;
        }

        if (doesFocusableExist(localWidgetFocusKey.ads)) {
          setFocus(localWidgetFocusKey.ads);
          return true;
        }
      }

      return false;
    },
    onEnterPress: showPlayerControls,
  });

  /**
   * We pull focus to the TV with a small debounce. This is because we want the navigation library to have a chance to re-activate certain focus keys before we re-evaluate.
   */
  const shouldPullFocus = useAtomValue(shouldPullFocusToTVAtom);
  useDebounceEffect(
    () => {
      if (shouldPullFocus) {
        focusSelf();
      }
    },
    [focusSelf, shouldPullFocus],
    10,
  );

  // Keep the general focusing of the TV page in sync with an atom.
  const setTvIsFocused = useSetAtom(tvIsFocusedAtom);
  useEffect(() => {
    setTvIsFocused(focused);
  }, [focused, setTvIsFocused]);

  useKeyboardNavigation({
    onBack: useCallback(() => menu.expand(), [menu]),
    isEnabled: focused,
  });

  useTvScreenRemoteControls();

  return (
    <main
      ref={ref}
      tabIndex={0}
      data-testid="tv"
      data-focused={focused}
      className={styles.root}
      onClick={showPlayerControls}
    >
      <Suspense fallback={<PageSpinner />}>
        <ConnectedTv />
      </Suspense>
    </main>
  );
}
