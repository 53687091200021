import {
  type JSX,
  type ReactElement,
  type ReactNode,
  useCallback,
  useEffect,
  useRef,
} from "react";
import {
  FocusContext,
  useFocusable,
} from "@noriginmedia/norigin-spatial-navigation";
import { useVirtualizer } from "@tanstack/react-virtual";
import clsx from "clsx";
import { type Atom, type PrimitiveAtom, useAtom, useAtomValue } from "jotai";
import { useAtomCallback } from "jotai/utils";
import { isNil } from "lodash";

import { logButtonClickAtom } from "@sunrise/analytics";
import type { AssetId } from "@sunrise/backend-types-core";
import type { PageableItems } from "@sunrise/types";
import { type Nullable } from "@sunrise/utils";
import type { GenericRecording } from "@sunrise/yallo-recordings";
import type { SelectedRecordingReference } from "@sunrise/yallo-recordings-list";
import { recordingsMarkedForBulkDeletionAtom } from "@sunrise/yallo-recordings-overview";
import { disableAnimationsAtom } from "@sunrise/yallo-settings";

import { programBoxSize } from "@/config/size";
import { SCREEN_HEIGHT_IN_PX, SCREEN_WIDTH_IN_PX } from "@/core/constants";
import { listNavigationArrowPress } from "@/utils/grid-navigation-arrow-handler";
import { mergeRefs } from "@/utils/merge-refs";

import { useRoutes } from "../routing/use-routes";
import { RecordingItem } from "./recording-item";
import * as styles from "./recordings-list.css";

const COLUMNS_COUNT = 4;

type ListProps = CommonProps & {
  focusKey: string;
  /**
   * A bit of a generic interface so that the List can behave the same way for the recordings overview as well as the series detail page.
   */
  queryAtom: Atom<
    PageableItems<GenericRecording> | Promise<PageableItems<GenericRecording>>
  >;
  selectedAtom: PrimitiveAtom<Nullable<SelectedRecordingReference>>;
  isSubFolder?: boolean; // NOTE: used when displaying series episodes folder
  /**
   * When passed, we pass it along to the RecordingItem component.
   * Which can then derive the correct season and episode information.
   *
   * TODO: Remove this as soon as the backend returns all the necessary data on the recordings endpoint.
   *       Question was posed in YALLOTV-16059.
   */
  seriesAssetId?: Nullable<AssetId>;

  /**
   * When pressing up arrow on the first row, we can use this callback to focus something else for example.
   */
  onExitUp?: (column: number) => void;
  onExitLeft?: () => void;

  isBulkDeletionMode?: boolean;
};

export function List({
  isBulkDeletionMode,
  "data-testid": dataTestId,
  focusKey,
  selectedAtom,
  queryAtom,
  seriesAssetId,
  onExitLeft,
  onExitUp,
}: ListProps): JSX.Element {
  const parentRef = useRef<HTMLDivElement>(null);
  const areAnimationsDisabled = useAtomValue(disableAnimationsAtom);
  const routes = useRoutes();

  const [selected, dispatchSelected] = useAtom(selectedAtom);
  const { items, fetchNextPage } = useAtomValue(queryAtom);

  const handleBulkDeletionItemEnterPress = useAtomCallback(
    useCallback(
      (get, set) => {
        const selectedRecording = get(selectedAtom);
        if (!selectedRecording || !selectedRecording.id) {
          return;
        }

        set(recordingsMarkedForBulkDeletionAtom, {
          type: "toggle",
          value: {
            id: selectedRecording.id,
            type:
              selectedRecording.type === "group"
                ? "recordinggroup"
                : "recording",
          },
        });
      },
      [selectedAtom],
    ),
  );

  const handleGoToItem = useAtomCallback(
    useCallback(
      async (get) => {
        const currentlySelected = get(selectedAtom);
        const selectedId = currentlySelected?.id;
        const recording = items.find((item) => item.id === selectedId);

        if (!recording) {
          return;
        }

        const log = get(logButtonClickAtom);

        if (recording.type === "group") {
          await log.invoke({
            type: "to_series_recording",
            assetId: recording.seriesAssetId,
            recordingGroupId: recording.id,
          });

          // series folder (multiple recordings)
          routes.detailsSeries.root({
            assetId: recording.seriesAssetId,
            recordingGroupId: recording.id,
          });
          return;
        }

        if (!("epgEntryId" in recording) && !("assetId" in recording)) {
          return;
        }

        // TODO: Fix for NG. There we need to create a recordings page for the single episodes and one for the recording groups.
        if ("epgEntryId" in recording && "assetId" in recording) {
          // series episode (single recording)
          const { epgEntryId, assetId } = recording;
          if (isNil(epgEntryId) || isNil(assetId)) return;

          await log.invoke({
            type: "to_single_recording",
            assetId: recording.assetId,
            epgEntryId: epgEntryId,
            recordingId: recording.id,
          });

          routes.details.root({
            epgId: epgEntryId,
            assetId: recording.assetId,
          });
        }
      },
      [routes.details, routes.detailsSeries, selectedAtom, items],
    ),
  );

  const handleEnterPress = useAtomCallback(
    useCallback(() => {
      if (isBulkDeletionMode) {
        handleBulkDeletionItemEnterPress();
        return;
      }

      handleGoToItem();
      return false;
    }, [isBulkDeletionMode, handleGoToItem, handleBulkDeletionItemEnterPress]),
  );

  const virtualizer = useVirtualizer({
    count: Math.ceil(items.length / COLUMNS_COUNT),
    estimateSize: () => programBoxSize.recordings.height,
    getScrollElement: () => parentRef.current,
    initialOffset: (selected?.rowIdx ?? 0) * programBoxSize.recordings.height,
    initialRect: {
      width: SCREEN_WIDTH_IN_PX,
      height: SCREEN_HEIGHT_IN_PX,
    },
    overscan: 1,
  });

  const scrollToRow = useCallback(
    (rowIdx: number) => {
      const [nextItemOffset] = virtualizer.getOffsetForIndex(rowIdx, "start");
      virtualizer.scrollToOffset(nextItemOffset, {
        align: "start",
        behavior: areAnimationsDisabled ? "auto" : "smooth",
      });
    },
    [virtualizer, areAnimationsDisabled],
  );

  const setActive = useCallback(
    (recording: Nullable<GenericRecording>): void => {
      if (!recording) return;

      const idx = items.findIndex((item) => item.id === recording.id);
      const rowIdx = Math.floor(idx / COLUMNS_COUNT);
      const colIdx = idx % COLUMNS_COUNT;

      dispatchSelected({
        rowIdx,
        colIdx,
        id: recording.id,
        type: recording.type,
      });

      scrollToRow(rowIdx);
    },
    [dispatchSelected, scrollToRow, items],
  );

  const handleArrowPress = useAtomCallback(
    useCallback(
      (get, _, direction: string) => {
        const currentlySelected = get(selectedAtom);
        const selectedId = currentlySelected?.id;
        const currentIndex = items.findIndex((item) => item.id === selectedId);

        if (currentIndex === -1) {
          // It's a bit naive but let's just select the first item if nothing is selected at all.
          // Normally there's a hook to reselect something more appropriate than the first item.
          // So this should only happen in emergencies and it does offer a way out then.
          setActive(items[0]);
          return;
        }

        let nextRecording: Nullable<GenericRecording> = null;
        const nextIndex = listNavigationArrowPress(
          items.length,
          COLUMNS_COUNT,
          currentIndex,
          direction,
          {
            onLeftBoundry: onExitLeft,
            onTopBoundry: onExitUp,
          },
        );

        if (nextIndex !== undefined) {
          nextRecording = items[nextIndex];
        }

        if (nextRecording) {
          setActive(nextRecording);
        }
      },
      [selectedAtom, setActive, onExitLeft, onExitUp, items],
    ),
  );

  const isFocusable = items.length > 0;
  const focusable = useFocusable({
    focusKey: focusKey,
    focusable: isFocusable,
    isFocusBoundary: true,
    onEnterPress: handleEnterPress,
    onArrowPress: useCallback(
      (direction: string) => {
        handleArrowPress(direction);
        return false;
      },
      [handleArrowPress],
    ),
  });

  useEffect(() => {
    if (!selected) {
      return;
    }

    // When there is a selection, and it is near the end of the list, we should fetch the next page.
    const { rowIdx } = selected;
    const lastRowIndex = Math.floor(items.length / COLUMNS_COUNT);
    if (rowIdx >= lastRowIndex - 1) {
      fetchNextPage();
    }
  }, [fetchNextPage, selected, items.length]);

  // When nothing is selected or our selection disappears, we should select something again.
  const firstItem = items[0];
  const currentSelectedItem = selected
    ? items.find((item) => item.id === selected.id)
    : null;
  const isSelectedPresent = !!currentSelectedItem;

  useEffect(() => {
    if (!firstItem || isSelectedPresent) {
      return;
    }

    // If nothing is selected, we should select the first item.
    if (!selected) {
      setActive(firstItem);
      return;
    }

    let nextItem: Nullable<GenericRecording> = null;

    // We do have something selected but if it is no longer present, we should select something else.
    // When my recording on the same row and column is not present anymore, we should select the recording that is there now instead.
    const { rowIdx, colIdx } = selected;
    const idx = rowIdx * COLUMNS_COUNT + colIdx;
    if (items[idx]) {
      nextItem = items[idx];
    }

    if (!nextItem) {
      // If it is also not available, we need to select the last item.
      nextItem = items[items.length - 1];
    }

    setActive(nextItem ?? firstItem);
  }, [selected, firstItem, setActive, isSelectedPresent, items]);

  return (
    <FocusContext.Provider value={focusable.focusKey}>
      <div
        className={styles.scrollableWrapper}
        ref={mergeRefs(parentRef, focusable.ref)}
      >
        <div
          className={styles.scrollable}
          style={{
            height: `${virtualizer.getTotalSize()}px`,
          }}
          data-testid={`${dataTestId}.container`}
        >
          {virtualizer.getVirtualItems().map((virtualRow) => {
            const columns = items.slice(
              virtualRow.index * COLUMNS_COUNT,
              virtualRow.index * COLUMNS_COUNT + COLUMNS_COUNT,
            );

            return (
              <Row
                key={virtualRow.index}
                style={{
                  height: `${virtualRow.size}px`,
                  transform: `translateY(${virtualRow.start}px)`,
                  willChange: "transform",
                }}
                data-testid={`${dataTestId}.row.${virtualRow.index}`}
              >
                {columns.map((col, index) => {
                  return (
                    <RecordingItem
                      focused={focusable.focused && selected?.id === col.id}
                      recording={col}
                      seriesAssetId={seriesAssetId}
                      id={col.id}
                      key={col.id}
                      data-testid={`${dataTestId}.item.${col.id}`}
                      index={virtualRow.index * COLUMNS_COUNT + index}
                    />
                  );
                })}
              </Row>
            );
          })}
        </div>
      </div>
    </FocusContext.Provider>
  );
}

type RowProps = CommonProps & {
  children: ReactNode;
};

function Row({
  children,
  className,
  style,
  "data-testid": dataTestId,
}: RowProps): ReactElement {
  return (
    <div
      className={clsx(styles.row, className)}
      style={{
        display: "flex",
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        ...(style ?? {}),
      }}
      data-testid={dataTestId}
    >
      {children}
    </div>
  );
}
