import { type ReactNode, useCallback, useEffect } from "react";
import {
  doesFocusableExist,
  setFocus,
} from "@noriginmedia/norigin-spatial-navigation";
import { minutesToSeconds } from "date-fns";
import { atom, useAtom, useAtomValue, useSetAtom } from "jotai";

import { actionPlayerSetIdleIfStopped, playerAtom } from "@sunrise/player";
import { useTranslator } from "@sunrise/translator";
import { selectShouldShowWhatIsNext } from "@sunrise/what-is-next";
import {
  actionPlayerManagerPlayRecording,
  actionPlayerManagerReset,
  playerManagerAtom,
  usePlayChannel,
} from "@sunrise/yallo-common-player-manager";
import {
  actionPlayerControlsHide,
  playerControlsAtom,
} from "@sunrise/yallo-player-controls";
import { whatIsNextAtom } from "@sunrise/yallo-recordings";

import { FocusButton } from "@/components";
import { actionMenuExpand, menuAtom } from "@/modules/menu";
import { canPullFocusToWhatIsNextAtom } from "@/modules/what-is-next/can-pull-focus-to-what-is-next.atom";
import { FocusContainer } from "@/utils/focus-container";

import { useRoutes } from "../routing/use-routes";
import * as styles from "./what-is-next.css";
import { WhatIsNextRecording } from "./what-is-next-recording";

const localWidgetFocusKey = {
  root: "what-is-next.container",
};

const NO_DATA_ATOM = atom(null);

export function WhatIsNext(): ReactNode {
  const shouldShow = useAtomValue(selectShouldShowWhatIsNext);

  // NOTE: Not an issue here to depend on the full playerAtom.
  //       There won't be any progress in the player since the player should have been stopped already.
  const [{ playRequest }, dispatchPlayer] = useAtom(playerAtom);
  const dispatchPlayerManager = useSetAtom(playerManagerAtom);
  const t = useTranslator();
  const dispatchMenu = useSetAtom(menuAtom);

  useEffect(() => {
    const shouldHaveShown = shouldShow;
    return () => {
      // NOTE: Really important that we only trigger this when we were actually shown.
      //       Because if we were not shown then we have no right to reset the player or the player manager.
      if (shouldHaveShown) {
        // Make sure we put the player in an idle state again when we leave the component.
        // This will ensure the player kicks in again the next time it is shown.
        dispatchPlayer(actionPlayerSetIdleIfStopped());
        dispatchPlayerManager(actionPlayerManagerReset());
      }
    };
  }, [dispatchPlayer, dispatchPlayerManager, shouldShow]);

  const routes = useRoutes();

  const doOpenRecordings = (): void => {
    routes.recordings.root();
  };

  const expandMenu = useCallback(() => {
    dispatchMenu(actionMenuExpand());
  }, [dispatchMenu]);

  const isRecording = playRequest?.type === "recording";
  const recordingId = isRecording ? playRequest.recordingId : null;
  const nextRecording = useAtomValue(
    recordingId ? whatIsNextAtom(recordingId) : NO_DATA_ATOM,
  );
  const channelId = playRequest?.channelId;
  const { play: playLive } = usePlayChannel({ channelId });
  const dispatchPlayerControls = useSetAtom(playerControlsAtom);

  const canPullFocus = useAtomValue(canPullFocusToWhatIsNextAtom);

  const doOpenNext = (): void => {
    if (nextRecording) {
      dispatchPlayerManager(
        actionPlayerManagerPlayRecording(
          nextRecording.id,
          nextRecording.channelId ?? null,
          minutesToSeconds(nextRecording.paddingStartMinutes ?? 0),
        ),
      );
    }
  };

  useEffect(() => {
    if (doesFocusableExist(localWidgetFocusKey.root)) {
      setFocus(localWidgetFocusKey.root);
    }
  }, [canPullFocus]);

  useEffect(() => {
    if (shouldShow) {
      dispatchPlayerControls(actionPlayerControlsHide());
    }
  }, [shouldShow, dispatchPlayerControls]);

  if (!shouldShow) {
    return null;
  }

  return (
    <FocusContainer
      className={styles.container}
      forceFocus={shouldShow}
      shouldFocus={shouldShow}
      boundary
      data-testid="what-is-next.container"
      focusKey={localWidgetFocusKey.root}
      onLeft={expandMenu}
    >
      {(handlers) => (
        <div className={styles.inner}>
          <h1 className={styles.title}>{t("recording_whats_next")}</h1>
          {isRecording ? (
            <p className={styles.subtitle}>
              {t("recording_whats_next_subtitle")}
            </p>
          ) : null}

          <div className={styles.actions}>
            {nextRecording ? (
              <WhatIsNextRecording
                data={nextRecording}
                onEnterPress={doOpenNext}
                onArrowPress={handlers.onArrowPress("left")}
                focusKey="what-is-next.next_recording"
              />
            ) : null}

            {isRecording ? (
              <FocusButton
                text={t("button_see_recordings")}
                className={styles.button}
                focusKey="what-is-next.open_recordings"
                onEnterPress={doOpenRecordings}
                onArrowPress={handlers.onArrowPress("left")}
                block
                typography={{
                  size: "h6",
                  weight: "bold",
                }}
                data-testid="what-is-next.open_recordings"
              />
            ) : null}
            {channelId ? (
              <FocusButton
                text={t("button_go_to_live")}
                className={styles.button}
                focusKey="what-is-next.play_live"
                onEnterPress={playLive}
                onArrowPress={handlers.onArrowPress("left")}
                block
                typography={{
                  size: "h6",
                  weight: "bold",
                }}
                data-testid="what-is-next.play_live"
              />
            ) : null}
          </div>
        </div>
      )}
    </FocusContainer>
  );
}
