import { type Nullable } from "@sunrise/utils";
import { useAtomValue, useSetAtom } from "jotai";
import { useCallback, useMemo } from "react";

import { optimisticCurrentPlayRequestAtom } from "../optimistic-current-play-request.atom";
import {
  actionPlayerManagerPlayLiveChannelId,
  playerManagerAtom,
} from "../player-manager.atom";
import type { ChannelId } from "@sunrise/backend-types-core";

export function usePlayChannel({
  channelId,
  requiredToBeLive = false,
}: {
  channelId: Nullable<ChannelId>;
  requiredToBeLive?: boolean;
}): {
  play: (origin?: "autostart") => void;
  isActive: boolean;
} {
  const playRequest = useAtomValue(optimisticCurrentPlayRequestAtom);
  const dispatchPlayerManager = useSetAtom(playerManagerAtom);

  /**
   * Plays out the live content of the channel.
   */
  const play = useCallback(
    (origin?: "autostart") => {
      if (!channelId) return;
      dispatchPlayerManager(
        actionPlayerManagerPlayLiveChannelId(channelId, origin),
      );
    },
    [channelId, dispatchPlayerManager],
  );

  const isActive =
    !!channelId &&
    playRequest?.channelId === channelId &&
    (requiredToBeLive || playRequest?.type === "live");

  return useMemo(
    () => ({
      isActive,
      play,
    }),
    [isActive, play],
  );
}
