import { atom } from "jotai";

import { assetDetailsByIdAtom } from "@sunrise/details";

import { playerCurrentEpgItemShownAtom } from "./player-current-epg-item-shown.atom";
import { showProgramTagsInPlayerFeatureAtom } from "./player-show-program-tags-in-player-feature-flag";
import { PlayerCurrentAsset } from "./types";

const EMPTY: Readonly<PlayerCurrentAsset> = {
  genres: [],
  productionYear: null,
  ageRecommendation: null,
};

export const playerCurrentTagsShownInPlayerAtom = atom<
  Promise<PlayerCurrentAsset>
>(async (get) => {
  const showProgramTagsInPlayer = get(showProgramTagsInPlayerFeatureAtom);
  if (!showProgramTagsInPlayer) return EMPTY;

  const epg = await get(playerCurrentEpgItemShownAtom);
  if (!epg) return EMPTY;

  const asset = await get(assetDetailsByIdAtom(epg.asset.id));

  if (!asset) return EMPTY;

  return {
    genres: asset?.genres,
    productionYear: asset.productionYear?.toString(),
    ageRecommendation: asset.ageRecomendation,
  };
});
