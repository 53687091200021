import { isNil } from "@sunrise/utils";
import { atom } from "jotai";

import { selectRecommendationsCoordinates } from "@/features/recommendations";
import { itemAtRecommendationCoordinatesAtom } from "@/features/recommendations/item-at-recommendation-coordinates.atom";
import type { ChannelResult, ProgramResult } from "@sunrise/backend-types";

type ReturnType =
  | ChannelResult["items"][number]
  | ProgramResult["items"][number];

export const currentlySelectedItemAtom =
  atom<Promise<ReturnType | null> | null>(async (get) => {
    const coordinates = get(selectRecommendationsCoordinates);
    if (isNil(coordinates)) {
      return null;
    }

    return (await get(itemAtRecommendationCoordinatesAtom))(coordinates);
  });

currentlySelectedItemAtom.debugLabel = "currentlySelectedItemAtom";
