import { isNil, type Nullable } from "@sunrise/utils";
import {
  isAxiosError,
  type AxiosError,
  type AxiosInstance,
  type AxiosResponse,
  type InternalAxiosRequestConfig,
} from "axios";
import { LoggedOutError } from "../errors/logged-out.error";
import { BaseError } from "@sunrise/error";
import { getJWTState } from "@sunrise/jwt";
import { MissingTokensError } from "../errors/missing-tokens.error";
import { RefreshTokenFailedError } from "../errors/refresh-token-failed.error";

// Extend AxiosRequestConfig interface to include `isSecure:boolean` property
declare module "axios" {
  interface AxiosRequestConfig {
    /**
     * Indicates if the request is secure and requires a valid access token.
     *
     * Only interpreted when the RefreshTokenOnErrorInterceptor is enabled.
     *
     * When it is secure, we will send the Authorization header with the access token.
     * When an error occurs on a secured connection that indicates the user is not properly authenticated,
     * the interceptor will try to refresh the token and retry the same request.
     */
    iSecure?: boolean;
  }
}

export class RefreshTokenOnErrorInterceptor {
  private refreshRequest: Nullable<Promise<unknown>>;

  constructor(
    protected readonly instance: AxiosInstance,
    protected readonly getAccessToken: () => Nullable<string>,
    protected readonly getRefreshToken: () => Nullable<string>,
    protected readonly setTokens: (at: string, rt: string) => void,
    protected readonly refreshTokens: RefreshTokens,
    /**
     * Function is triggered when the privateApi deems it correct to reset the tokens and log out the user.
     * The error that caused the reset is still thrown.
     */
    protected readonly resetTokens: (error: BaseError) => void,
    protected readonly isNotKnownUserError?: (error: AxiosError) => boolean,
    protected readonly onRetry?: (error: AxiosError) => void,
    protected readonly shouldIgnoreWhenRequestIsNotSecure = false,
  ) {
    this.instance.interceptors.request.use(this.onAxiosRequest.bind(this));
    this.instance.interceptors.response.use(
      undefined,
      this.onAxiosError.bind(this),
    );
  }

  private async onAxiosError(error: AxiosError): Promise<AxiosResponse> {
    if (error.config && this.isNotKnownUserError?.(error)) {
      const err = new LoggedOutError("me_404_response");
      this.resetTokens(err);
      return Promise.reject(err);
    } else if (error.config && isNotAuthenticatedError(error)) {
      // When we errored (means we were probably the first request to be hit by the 401)
      // we should refresh.
      await this.refreshToken();
      // Notify that we will retry the earlier error.
      this.onRetry?.(error);
      // And then we should make sure to retry the request.
      return this.instance.request(error.config);
    }

    return Promise.reject(error);
  }

  private async onAxiosRequest(
    req: InternalAxiosRequestConfig,
  ): Promise<InternalAxiosRequestConfig> {
    if (this.shouldIgnoreWhenRequestIsNotSecure && !req.iSecure) {
      return req;
    }

    if (!isNil(this.refreshRequest)) {
      // When something already decided that we need to refresh the token, wait for that to complete before performing the rest.
      await this.refreshRequest;
    }

    const accessToken = this.getAccessToken();
    const atState = getJWTState(accessToken);
    // when it's valid -> go with it
    if (atState === "valid") {
      req.headers.Authorization = createBearerToken(accessToken);
      return req;
    }

    const refreshToken = this.getRefreshToken();
    const rtState = getJWTState(refreshToken);

    switch (rtState) {
      case "error": {
        const err = new LoggedOutError("refresh_token_broken");
        this.resetTokens(err);
        throw err;
      }
      case "expired": {
        const err = new LoggedOutError("refresh_token_expired", {
          errorCode: "session_expired",
        });
        this.resetTokens(err);
        throw err;
      }
      case "missing": {
        const err = new LoggedOutError("refresh_token_missing");
        this.resetTokens(err);
        throw err;
      }
    }

    // Refresh before we do the request (as we know there is no valid access token and we have a valid refresh token)
    await this.refreshToken();

    // When this succeeds it means our original request has received a new access & refresh token.
    req.headers.Authorization = createBearerToken(this.getAccessToken());
    return req;
  }

  async refreshToken(): Promise<void> {
    if (!this.refreshRequest) {
      // When we already have a refresh request, return that.
      this.refreshRequest = this.refreshTokenInternal();
    }

    await this.refreshRequest;
  }

  private async refreshTokenInternal(): Promise<void> {
    const currentRefreshToken = this.getRefreshToken();
    if (isNil(currentRefreshToken)) {
      const err = new LoggedOutError("refresh_token_missing");
      this.resetTokens(err);
      throw err;
    }

    try {
      const response = await this.refreshTokens(currentRefreshToken);
      const refreshToken = response.client_jwt_refresh_token;
      const newAccessToken = response.client_jwt_token;

      if (!refreshToken || !newAccessToken) {
        // There are some odd cases of users logging out in the backend somehow.
        // Maybe the API somehow doesn't return any tokens. In that case, we are tracking it like this.
        // This will not trigger a logout in our case. We are keeping the old tokens.
        throw new MissingTokensError("missing tokens in refresh response");
      }
      this.setTokens(newAccessToken, refreshToken);
    } catch (e) {
      if (isAxiosError(e) && isNotAuthenticatedError(e)) {
        const err = new LoggedOutError(
          `refresh_${e.response?.status ?? "no_response"}_received`,
          {
            errorCode: "error_user_logged_out",
          },
        );
        this.resetTokens(err);
        throw err;
      }

      if (e instanceof BaseError) {
        throw e;
      }

      throw new RefreshTokenFailedError("failed to refresh token");
    } finally {
      // Should reset the refreshing promise else it'll keep it cached.
      this.refreshRequest = undefined;
    }
  }
}

export type RefreshTokens = (refreshToken: string) => Promise<{
  client_jwt_refresh_token?: string;
  client_jwt_token?: string;
}>;

function createBearerToken(token: Nullable<string>): Nullable<string> {
  return token ? `Bearer ${token}` : null;
}

function isNotAuthenticatedError(error: AxiosError): boolean {
  return !!(
    error.response?.status && [401, 403].includes(error.response.status)
  );
}
