import { usePauseAds } from "@sunrise/ads";
import { selectIsDialogOpen } from "@sunrise/dialogs";
import { useAtomValue } from "jotai";
import { type ReactElement } from "react";

import * as styles from "./pause-ads.css";

export function PauseAds(): ReactElement | null {
  const { url, onShown, onError } = usePauseAds();
  const isDialogOpen = useAtomValue(selectIsDialogOpen);

  if (!url || isDialogOpen) {
    return null;
  }

  return (
    <div data-testid="pause_ad" className={styles.container}>
      <img
        data-testid="pause_ad.image"
        className={styles.image}
        src={url}
        onLoad={onShown}
        onError={onError}
      />
    </div>
  );
}
