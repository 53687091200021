import { forwardRef } from "react";

import type { RecordingState } from "@sunrise/backend-types";
import { Nullable, useStableValue } from "@sunrise/utils";

import { makeEpgImgUrl } from "@/utils/image";

import {
  ProgramDescription,
  ProgramSubtitle,
  ProgramTime,
  ProgramTitle,
} from "../box-text-elements/box-text";
import * as styles from "../program-box.css";
import { ProgramBoxWrapper } from "../program-box-wrapper";
import { ProgramCover } from "../program-cover/program-cover";
import { BoxProps, BoxTimeProps } from "../types";

export type RowProgramBoxProps = CommonProps &
  BoxTimeProps &
  BoxProps & {
    title: string;
    description: string;
    subtitle?: string;
    throttleForPreviewInMs?: number;
    recordingState?: Nullable<RecordingState>;
  };

export const RowProgramBox = forwardRef<HTMLDivElement, RowProgramBoxProps>(
  function ForwardRefProgramBox(
    {
      "data-testid": dataTestId = "row-program-box",
      "data-focused": dataFocused,
      coverImageUrl,
      start,
      end,
      focused,
      airTime,
      title,
      subtitle,
      description,
      logoUrl,
      durationInMinutes,
      throttleForPreviewInMs,
      recordingState,
    },
    ref,
  ) {
    const stableCoverImage = useStableValue(
      coverImageUrl,
      throttleForPreviewInMs,
    );

    return (
      <ProgramBoxWrapper
        kind="row"
        data-testid={dataTestId}
        ref={ref}
        data-focused={dataFocused}
      >
        <ProgramCover
          coverImageUrl={makeEpgImgUrl(stableCoverImage, 192, 108)}
          focused={focused}
          kind="row"
          logoUrl={logoUrl}
          recordingState={recordingState}
        />
        <div className={styles.rowText} data-testid={`${dataTestId}.text`}>
          <ProgramTitle
            title={title}
            maxLines={1}
            className={styles.rowTitle}
            data-testid={`${dataTestId}.title`}
          />

          {subtitle && (
            <ProgramSubtitle
              subtitle={subtitle}
              maxLines={1}
              className={styles.rowSubtitle}
              data-testid={`${dataTestId}.subtitle`}
            />
          )}
          <ProgramDescription
            description={description}
            data-testid={`${dataTestId}.description`}
          />
          <ProgramTime
            airTime={airTime}
            start={start}
            end={end}
            durationInMinutes={durationInMinutes}
            className={styles.rowTime}
            data-testid={`${dataTestId}.time`}
          />
        </div>
      </ProgramBoxWrapper>
    );
  },
);
