import {
  type ArrowPressHandler,
  useFocusable,
} from "@noriginmedia/norigin-spatial-navigation";
import clsx from "clsx";
import type { ReactNode } from "react";

import { RecordingItem } from "../recordings/recording-item";
import { nextRecording } from "./what-is-next.css";
import type { SingleRecording } from "@sunrise/backend-types";

export type WhatIsNextRecordingProps = {
  data: SingleRecording;
  onEnterPress: () => void;
  onFocusChange?: (focused: boolean) => void;
  onArrowPress?: ArrowPressHandler;
  focusKey?: string;
  className?: string;
};

export function WhatIsNextRecording({
  data,
  onEnterPress,
  focusKey,
  className,
  onArrowPress,
}: WhatIsNextRecordingProps): ReactNode {
  const { ref, focused } = useFocusable({
    onEnterPress,
    focusKey,
    onArrowPress,
  });

  return (
    <RecordingItem
      className={clsx(nextRecording, className)}
      ref={ref}
      focused={focused}
      recording={data}
      seriesAssetId={data.asset.id}
      id={data.id}
      key={data.id}
    />
  );
}
