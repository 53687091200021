import { PlayerError } from "./player.error";

/**
 * Throw this error for player issues that indicate a reload of the stream may be the best option.
 * Clients should see if an error like this is detected and then automatically reload the stream.
 */
export class CriticalPlayerError extends PlayerError {
  constructor(e: unknown) {
    super(e);
    this.name = "CriticalPlayerError";
  }

  override get shouldAutoRecover(): boolean {
    return true;
  }
}
