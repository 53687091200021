import { isEmptyObject } from "@sunrise/utils";
import { atom } from "jotai";

import { recordingsMarkedForDeletionAtom } from "./bulk-deletion/recordings-marked-for-deletion.atom";
import {
  selectHasRecordingsQueryFetchedFirstPage,
  selectRecordingsData,
} from "./query-atoms/recordings-selectors";
import { recordingsQueryAtom } from "./query-atoms/recordings.query.atom";

export const recordingsVisibleOnOverviewAtom = atom((get) => {
  const query = get(recordingsQueryAtom);
  const hasFirstPage = selectHasRecordingsQueryFetchedFirstPage(query);
  if (!hasFirstPage) {
    return null;
  }

  const recordings = selectRecordingsData(query);

  const instantlyDeletedRecordings = get(recordingsMarkedForDeletionAtom);
  if (isEmptyObject(instantlyDeletedRecordings)) {
    return recordings;
  }

  return recordings.filter((recording) => {
    return !instantlyDeletedRecordings[recording.id];
  });
});

export const hasRecordingsAtom = atom((get) => {
  const recordings = get(recordingsVisibleOnOverviewAtom);
  return recordings && recordings.length > 0;
});
