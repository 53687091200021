import { isSSR } from "@sunrise/utils";

export function getClientID(key = "client_id"): string | undefined {
  if (isSSR()) {
    return;
  }

  const existing = localStorage.getItem(key);

  if (existing) return existing;

  const id = crypto.randomUUID();
  localStorage.setItem(key, id);

  return id;
}
