import { ReactNode } from "react";

import { ChannelItem } from "@/components";
import { Lock } from "@/components/lock/lock";

export function DebugChannelItems(): ReactNode {
  const channelLogo =
    "https://media.idownloadblog.com/wp-content/uploads/2018/01/Netflix-Logo.png";

  const currentEpg = {
    start: "12:00",
    title: "The Simpsons",
  };

  const nextEpg = {
    start: "13:30",
    title: "Inception",
  };
  return (
    <div style={{ margin: "3%" }}>
      <h2 style={{ margin: "3%" }}>Channel items</h2>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          height: "1000px",
        }}
      >
        <ChannelItem
          channelLogo={channelLogo}
          currentEpg={currentEpg}
          nextEpg={nextEpg}
          channelNumber={13}
          liveProgress={47}
        />
        <ChannelItem
          channelLogo={channelLogo}
          currentEpg={currentEpg}
          nextEpg={nextEpg}
          channelNumber={999}
          liveProgress={87}
          replayProgress={34}
          focused
        />
        <ChannelItem
          channelLogo={channelLogo}
          currentEpg={currentEpg}
          nextEpg={nextEpg}
          channelNumber={2}
          liveProgress={47}
          active
        />
        <ChannelItem
          channelLogo={channelLogo}
          currentEpg={currentEpg}
          nextEpg={nextEpg}
          channelNumber={2}
          liveProgress={47}
        >
          <Lock />
        </ChannelItem>
        <ChannelItem
          channelLogo={channelLogo}
          currentEpg={currentEpg}
          nextEpg={nextEpg}
          channelNumber={76}
          liveProgress={47}
          zapping
        />
      </div>
    </div>
  );
}
