import { type ReactNode } from "react";

import {
  FocusContainer,
  type FocusContainerCallbackHandlers,
} from "@/utils/focus-container";

import * as styles from "./side-bar.css";

export type SideBarProps = {
  children: (handlers: FocusContainerCallbackHandlers) => ReactNode;
  shouldShow: boolean;
  focusKey: string;
  "data-testid"?: string;
  preferredChildFocusKey?: string;
};

export function SideBar({
  children,
  shouldShow,
  focusKey,
  "data-testid": dataTestId,
  preferredChildFocusKey,
}: SideBarProps): ReactNode {
  if (!shouldShow) {
    return null;
  }

  return (
    <div className={styles.container}>
      <FocusContainer
        className={styles.focusContainer}
        key={focusKey}
        forceFocus={shouldShow}
        shouldFocus={shouldShow}
        boundary
        data-testid={`${dataTestId}.container`}
        focusKey={focusKey}
        preferredChildFocusKey={preferredChildFocusKey}
      >
        {(handlers) => <div className={styles.inner}>{children(handlers)}</div>}
      </FocusContainer>
    </div>
  );
}
