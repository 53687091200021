import { atom } from "jotai";
import { GeneratedApi } from "./GeneratedApi";
import { ngHttpClientAtom } from "@sunrise/backend-ng-core";

export const ngChannelApiAtom = atom((get) => {
  const client = get(ngHttpClientAtom);

  if (!client) {
    throw new Error("ngHttpClientAtom is not initialized");
  }

  return new GeneratedApi(client);
});
