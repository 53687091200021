import { playerCurrentDateTimeAtom } from "@sunrise/player";
import type { Nullable } from "@sunrise/utils";
import { isWithinInterval } from "date-fns";
import { subSeconds } from "date-fns/subSeconds";
import { atom } from "jotai";
import { atomEffect } from "jotai-effect";

import { ffwdMarkersAtom } from "./ffwd-markers.atom";

/**
 * Returns the current fast-forward ad break that the player is in. If any.
 *
 * This atom is re-evaluated every second when we have markers present.
 */
const _ffwdCurrentAdBreakAtom = atom(async (get) => {
  const markers = await get(ffwdMarkersAtom);
  if (!markers || markers.length === 0) {
    return false;
  }

  const currentTime = get(playerCurrentDateTimeAtom);
  if (!currentTime) {
    return false;
  }

  return (
    markers.find((marker) =>
      isWithinInterval(currentTime, {
        start: marker.start,
        end: subSeconds(marker.end, 1),
      }),
    ) ?? null
  );
});

_ffwdCurrentAdBreakAtom.debugPrivate = true;

type AdBreak = {
  start: Date;
  end: Date;
};

const _ffwdCurrentAdBreakAtomInternal = atom<Nullable<AdBreak>>(undefined);
_ffwdCurrentAdBreakAtomInternal.debugPrivate = true;

const intervalUpdateEffect = atomEffect((get, set) => {
  const run = async () => {
    set(
      _ffwdCurrentAdBreakAtomInternal,
      (await get(_ffwdCurrentAdBreakAtom)) || null,
    );
  };

  const interval = setInterval(run, 1000);
  void run();

  return () => clearInterval(interval);
});

/**
 * This atom is used to get the current fast-forward ad break that the player is in.
 * It is stable since it updates through an atomEffect in the background.
 *
 * It will not update every second but the second that an ad break is entered or exited it will be updated.
 */
export const ffwdCurrentAdBreakAtom = atom<Nullable<AdBreak>>((get) => {
  get(intervalUpdateEffect);

  return get(_ffwdCurrentAdBreakAtomInternal);
});
