import { millisecondsToMinutes } from "date-fns";

import { getPrefixAndSubtitle } from "@sunrise/details";
import { MappedEpg } from "@sunrise/yallo-epg";

import { GuideProgram } from "../guide.types";

export function epgEntryToGuideProgram(entry: MappedEpg): GuideProgram {
  const startTime = new Date(entry.actualStart);
  const endTime = new Date(entry.actualEnd);

  const prefixAndSubtitle = getPrefixAndSubtitle(
    entry.asset.type,
    entry.asset.type === "seriesepisode" ? entry.asset.seasonNumber : null,
    entry.asset.type === "seriesepisode" ? entry.asset.episodeNumber : null,
    entry.asset.subtitle,
  );

  return {
    id: entry.id,
    assetId: entry.asset.id,
    startTime,
    endTime,
    durationInMinutes: Math.floor(
      millisecondsToMinutes(endTime.getTime() - startTime.getTime()),
    ),
    title: entry.asset.title,
    subtitle: prefixAndSubtitle.label,
    plot: entry.asset.plot,
    image: entry.asset.posterPath,
  };
}
