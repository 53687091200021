import { BaseError, type BaseErrorOptions } from "@sunrise/error";

export class ChannelPackagesError extends BaseError {
  constructor(message: string, options?: BaseErrorOptions) {
    super("ChannelPackagesError", message, options);
  }

  override get canSwallow(): boolean {
    return true;
  }
}
