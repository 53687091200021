import { atom } from "jotai";
import { atomWithReducer, selectAtom } from "jotai/utils";
import { atomEffect } from "jotai-effect";
import { isNil } from "lodash";
import md5 from "md5";

import type { UserToken } from "@sunrise/backend-types-core";
import { hasLocalStorage, isSSR, type Nullable } from "@sunrise/utils";

import { type JWTPayload } from "./jwt.types";
import { decodeJwt } from "./jwt.utils";

export type JWTAtomState = {
  accessToken: Nullable<string>;
  refreshToken: Nullable<string>;
  decodedPayload: Nullable<JWTPayload>;
};

export function makeJWTAtomDefaultState(): JWTAtomState {
  return {
    accessToken: null,
    refreshToken: null,
    decodedPayload: undefined,
  };
}

type ActionSetTokens = {
  type: "jwt/set-tokens";
  payload: {
    accessToken: string;
    refreshToken: string;
  };
};

type ActionSetDecodedPayload = {
  type: "jwt/set-decoded-payload";
  payload: JWTPayload;
};

type ActionClear = {
  type: "jwt/clear";
};

/**
 * Action for testing purpose **only**
 */
type ActionTestSetState = {
  type: "jwt/test/set-state";
  payload: Partial<JWTAtomState>;
};

type JWTAction =
  | ActionSetTokens
  | ActionSetDecodedPayload
  | ActionClear
  | ActionTestSetState;

export function jwtAtomReducer(
  state: JWTAtomState,
  action: JWTAction,
): JWTAtomState {
  switch (action.type) {
    case "jwt/set-tokens": {
      return {
        ...state,
        ...action.payload,
      };
    }
    case "jwt/set-decoded-payload": {
      return {
        ...state,
        decodedPayload: action.payload,
      };
    }
    case "jwt/clear": {
      return makeJWTAtomDefaultState();
    }
    case "jwt/test/set-state": {
      return {
        ...state,
        ...action.payload,
      };
    }
  }
}

const _jwtAtom = atomWithReducer<JWTAtomState, JWTAction>(
  isSSR()
    ? makeJWTAtomDefaultState()
    : {
        accessToken: localStorage.getItem("yallo:access_token"),
        refreshToken: localStorage.getItem("yallo:refresh_token"),
        decodedPayload: JSON.parse(
          localStorage.getItem("yallo:feature_set") ?? "null",
        ),
      },
  jwtAtomReducer,
);

_jwtAtom.debugLabel = "_jwtAtom";

export const jwtAtom = atom<JWTAtomState, [JWTAction], void>(
  (get) => {
    get(syncStorageEffect);
    get(setDecodedPayloadEffect);

    return get(_jwtAtom);
  },
  (_get, set, payload) => {
    set(_jwtAtom, payload);
  },
);

jwtAtom.debugLabel = "jwtAtom";

/*
 *
 * EFFECTS
 *
 */

const syncStorageEffect = atomEffect((get) => {
  if (!hasLocalStorage()) {
    return;
  }

  const { accessToken, refreshToken, decodedPayload } = get(_jwtAtom);

  if (accessToken) localStorage.setItem("yallo:access_token", accessToken);
  else localStorage.removeItem("yallo:access_token");

  if (refreshToken) localStorage.setItem("yallo:refresh_token", refreshToken);
  else localStorage.removeItem("yallo:refresh_token");

  if (decodedPayload) {
    localStorage.setItem("yallo:feature_set", JSON.stringify(decodedPayload));
  } else localStorage.removeItem("yallo:feature_set");
});

const setDecodedPayloadEffect = atomEffect((get, set) => {
  const { accessToken } = get(_jwtAtom);
  if (isNil(accessToken)) return;

  try {
    const decodedPayload = decodeJwt(accessToken);
    set(_jwtAtom, actionJWTSetDecodedPayload(decodedPayload));
    // eslint-disable-next-line no-empty
  } catch {}
});

/*
 *
 * Actions
 *
 */

export function actionJWTSetTokens(payload: {
  refreshToken: string;
  accessToken: string;
}): ActionSetTokens {
  return {
    type: "jwt/set-tokens",
    payload,
  };
}

export function actionJWTSetDecodedPayload(
  decodedPayload: JWTPayload,
): ActionSetDecodedPayload {
  return {
    type: "jwt/set-decoded-payload",
    payload: decodedPayload,
  };
}

export function actionJWTClear(): ActionClear {
  return {
    type: "jwt/clear",
  };
}

/*
 *
 * Selectors
 *
 */

export const selectIsLoggedIn = selectAtom(jwtAtom, (s) => {
  return !!(s.accessToken && s.refreshToken && s.decodedPayload);
});

function selectAccessTokenFn(s: JWTAtomState): JWTAtomState["accessToken"] {
  return s.accessToken;
}

export const selectAccessToken = selectAtom(jwtAtom, selectAccessTokenFn);

export const selectJwtUserToken = selectAtom<JWTAtomState, UserToken | null>(
  jwtAtom,
  (jwt) =>
    (jwt?.decodedPayload?.refresh_token_hash as UserToken) ??
    (jwt?.accessToken ? md5(jwt.accessToken) : null),
);

export function selectRefreshToken(
  s: JWTAtomState,
): JWTAtomState["refreshToken"] {
  return s.refreshToken;
}

export function selectDecodedPayload(
  s: JWTAtomState,
): JWTAtomState["decodedPayload"] {
  return s.decodedPayload;
}

export function selectPlanUpgradeAvailable(s: JWTAtomState): boolean {
  return s.decodedPayload?.feature_set.features.plan_upgrade_available ?? false;
}

export const selectPlanUpgradeAvailableAtom = selectAtom(
  jwtAtom,
  selectPlanUpgradeAvailable,
);

export const featureSetAtom = selectAtom(
  jwtAtom,
  (s) => s.decodedPayload?.feature_set.features,
);

export const selectLiveStreamingTimeLimitAtom = selectAtom(jwtAtom, (s) => {
  return s.decodedPayload?.feature_set.features.live_streaming_time_limit;
});

export const selectCanLogin = selectAtom(jwtAtom, (s) => {
  return s.decodedPayload?.feature_set.features.can_login ?? true;
});

export const selectCanLogout = selectAtom(jwtAtom, (s) => {
  return s.decodedPayload?.feature_set.features.can_logout;
});

/**
 * Returns the User ID if present inside the decoded JWT payload.
 */
export const selectCurrentUserId = selectAtom(jwtAtom, (s) => {
  return s.decodedPayload?.user_id ?? s.decodedPayload?.user?.id ?? null;
});

export const selectCanRecord = selectAtom(jwtAtom, (s) => {
  return s.decodedPayload?.feature_set.features.can_record ?? false;
});
