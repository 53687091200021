import { useCallback } from "react";
import { useSetAtom } from "jotai";

import { Translatable } from "@sunrise/i18n";
import { Nullable } from "@sunrise/utils";

import {
  actionDialogOpen,
  dialogAtom,
  ListDialog,
  ListDialogSection,
} from "./dialog.atom";

type UseListDialogProps = {
  title: Translatable;
  onButtonClick?: (
    value: Nullable<string>,
    sectionIdx: Nullable<number>,
  ) => void;
  lastFocusKey?: string;
  id: string;
  sections: ListDialogSection[];
  activeOptions: ListDialog["activeOptions"];
  buttonTextAlign?: ListDialog["buttonTextAlign"];
  radioButtons?: ListDialog["radioButtons"];
  actionButtonLabel?: Translatable;
  onActionButtonClick?: () => void;
};

export function useListDialog(): {
  showDialog: (props: UseListDialogProps) => void;
} {
  const dispatchConfirmationDialog = useSetAtom(dialogAtom);

  const showDialog = useCallback(
    function (props: UseListDialogProps) {
      // We just map the ConfirmationProps to the ListDialog.
      const dialog: ListDialog = {
        id: props.id,
        type: "list",
        lastFocusKey: props.lastFocusKey ?? "",
        title: props.title,
        buttonTextAlign: props.buttonTextAlign,
        onButtonClick: (
          value: Nullable<string>,
          sectionIdx: Nullable<number>,
        ) => props.onButtonClick?.(value, sectionIdx),
        sections: props.sections,
        activeOptions: props.activeOptions,
        radioButtons: props.radioButtons,
        actionButtonLabel: props.actionButtonLabel,
        onActionButtonClick: props.onActionButtonClick,
      };

      dispatchConfirmationDialog(actionDialogOpen(dialog));
    },
    [dispatchConfirmationDialog, actionDialogOpen],
  );

  return {
    showDialog,
  };
}
