import { useAtomCallback } from "jotai/utils";
import { useCallback } from "react";

import type { InAppKeyboardKeyId } from "@/modules/keyboard-navigation";
import {
  numericZappingErrorAtom,
  numericZappingValueAtom,
  waitingForNextInputAtom,
} from "@/modules/zapping/numeric-zapping.atom";

export function useNumericZapping(): {
  addNumber: (number: InAppKeyboardKeyId) => void;
} {
  const addNumber = useAtomCallback(
    useCallback(async (get, set, number: InAppKeyboardKeyId) => {
      const zappingError = get(numericZappingErrorAtom);
      if (zappingError) {
        set(numericZappingValueAtom, number);
        set(numericZappingErrorAtom, false);
        set(waitingForNextInputAtom, true);
      } else {
        set(numericZappingValueAtom, (prev) => prev + number);
      }
    }, []),
  );

  return {
    addNumber,
  };
}
