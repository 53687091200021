import type { Page } from "@sunrise/yallo-websocket";

export const tizenPaths = new Map<string, Page>();

tizenPaths.set("/protected/tv", "player_page");
tizenPaths.set("/protected/home", "home_page");
tizenPaths.set("/protected/guide", "tv_guide_page");
tizenPaths.set("/protected/recordings", "recordings_page");
tizenPaths.set("/protected/details", "detail_page");
tizenPaths.set("/protected/details-series", "series_recording_page");
tizenPaths.set("/protected/search", "search_page");
tizenPaths.set("/protected/settings", "settings");
