import { selectJwtUserToken } from "@sunrise/jwt";
import { socketAuthenticatedAtom } from "@sunrise/yallo-websocket";
import type { QueryClient } from "@tanstack/react-query";
import type { Getter } from "jotai/vanilla";
import { debounce } from "lodash";

import type { UserToken } from "@sunrise/backend-types-core";
import { queryKeys } from "@sunrise/backend-types";

export const RECORDING_INVALIDATION_DELAY = 5000;

function flushStateInternal(
  client: QueryClient,
  accessToken: UserToken,
  isWebsocketEnabled: boolean,
): void {
  if (isWebsocketEnabled) {
    client.invalidateQueries({
      queryKey: queryKeys.recordingsPullBase(accessToken),
    });
  } else {
    client.invalidateQueries({
      queryKey: queryKeys.recordingsBase(accessToken),
      exact: false,
    });
  }
}

const debounced = debounce(flushStateInternal, RECORDING_INVALIDATION_DELAY, {
  leading: false,
  trailing: true,
});

/**
 * When the websocket is enabled (meaning, we are authenticated on the websocket),
 * we will only invalidate parts of the query client cache that are not automatically pushed to us.
 *
 * If the websocket is not enabled, we will invalidate the whole cache for recordings so everything is refetched.
 *
 * @param client
 * @param get
 */
export function flushRecordingsState(client: QueryClient, get: Getter): void {
  const at = get(selectJwtUserToken);
  if (!at) {
    return;
  }

  debounced(client, at, get(socketAuthenticatedAtom));
}
