import {
  ArrowPressHandler,
  setFocus,
} from "@noriginmedia/norigin-spatial-navigation";
import {
  selectPlayerCurrentPlayRequest,
  selectPlayerIsPlaying,
} from "@sunrise/player";
import { useTranslator } from "@sunrise/translator";
import { usePlayChannel } from "@sunrise/yallo-common-player-manager";
import {
  canSeekInDirectionAtom,
  usePlayFromBeginning,
  useQuickSeek,
  useSubtitleAudioSettings,
  useTogglePlayPause,
} from "@sunrise/yallo-player-controls";
import { useAtomValue } from "jotai";
import { ReactNode, useCallback } from "react";

import { FocusIconButton } from "@/components/buttons/focus-icon-button/focus-icon-button";
import * as styles from "@/features/player-controls/player-controls.css";
import { FocusContainerCallbackHandlers } from "@/utils/focus-container";
import {
  isArrowLeftKey,
  isArrowRightKey,
  isArrowUpKey,
} from "@/utils/navigation";

import { playerControlsFocusKeys } from "./focus-keys";
import { InfoButton } from "./info-button";
import { PlayerButton } from "./player-controls.types";
import { RecordButton } from "./record-button";
import { SuspendButton } from "./suspended-button";

type PlayerButtonFn = (onArrowPress: ArrowPressHandler) => ReactNode;

export function PlayerControlButtons({
  handlers,
}: {
  handlers: FocusContainerCallbackHandlers;
}): ReactNode {
  const t = useTranslator();

  const { open: openSubtitleAndAudioSettings } = useSubtitleAudioSettings();

  const isPlaying = useAtomValue(selectPlayerIsPlaying);
  const currentChannelId = useAtomValue(
    selectPlayerCurrentPlayRequest,
  )?.channelId;
  const { toggle: togglePlayPause } = useTogglePlayPause();

  const live = usePlayChannel({ channelId: currentChannelId });

  const playFromBeginning = usePlayFromBeginning();
  const doPlayFromBeginning = useCallback(
    () => void playFromBeginning(),
    [playFromBeginning],
  );

  const { forward, backward } = useQuickSeek({
    forwardSeconds: 30,
    backwardSeconds: 15,
  });

  const canSeek = useAtomValue(canSeekInDirectionAtom);

  // TODO: We need to put all the logic for the buttons in actual button files.
  // Then every button needs a specific loader for it. Where it's disabled until it's loaded.
  // Then we can do away with the generic PlayerControls loader.

  const playerButtons: (PlayerButton | PlayerButtonFn)[] = [
    {
      key: "prev",
      iconName: "previous-program",
      onEnterPress: doPlayFromBeginning,
    },
    {
      key: "seek-back",
      iconName: "previous15",
      disabled: !canSeek.backwards,
      onEnterPress: backward,
    },
    {
      key: "toggle",
      iconName: isPlaying ? "pause" : "play",
      onEnterPress: togglePlayPause,
    },
    {
      key: "seek-forward",
      iconName: "next30",
      disabled: !canSeek.forwards,
      onEnterPress: forward,
    },
    // {
    //   key: "next",
    //   iconName: "next-program",
    //   onEnterPress: () => {},
    // },
    {
      key: "live",
      iconName: "live",
      disabled: live.isActive,
      onEnterPress: () => live.play(),
    },
    (onArrowPress) => (
      <SuspendButton iconName="record" key="record">
        <RecordButton
          key="record"
          onArrowPress={onArrowPress}
          focusKey={playerControlsFocusKeys.component}
        />
      </SuspendButton>
    ),
    (onArrowPress) => (
      <SuspendButton key="info" iconName="info">
        <InfoButton
          focusKey={`${playerControlsFocusKeys.component}.info`}
          onArrowPress={onArrowPress}
        />
      </SuspendButton>
    ),
    {
      key: "subtitles-audio",
      iconName: "subtitles-and-audio",
      onEnterPress: () => openSubtitleAndAudioSettings(t),
    },
  ];

  return (
    <div className={styles.buttonContainer}>
      {playerButtons.map((button, index) => {
        const onArrowPress: ArrowPressHandler = (direction) => {
          if (
            (isArrowLeftKey(direction) && index === 0) ||
            (isArrowRightKey(direction) && index === playerButtons.length - 1)
          ) {
            return false;
          }

          if (isArrowUpKey(direction)) {
            setFocus(playerControlsFocusKeys.seekbar);
            return false;
          }

          return handlers.onArrowPress()(direction);
        };

        if (typeof button === "function") {
          return button(onArrowPress);
        } else {
          return (
            <FocusIconButton
              key={button.key}
              focusKey={`${playerControlsFocusKeys.component}.${button.key}`}
              data-testid={`player-controls.button.${button.key}`}
              className={styles.button}
              iconName={button.iconName}
              disabled={button.disabled}
              onEnterPress={button.onEnterPress}
              onArrowPress={onArrowPress}
            />
          );
        }
      })}
    </div>
  );
}
