import { Atom, atom } from "jotai";
import { atomFamily } from "jotai/utils";

import type { RecordingStatus } from "@sunrise/backend-types";
import type { EPGEntryId } from "@sunrise/backend-types-core";
import { Nullable } from "@sunrise/utils";

import { recordingStatusAtom } from "./recording-status.atom";

/**
 * This returns the RecordingStatus for a specific EPGEntryId.
 * The RecordingStatus just indicates if there is a recording and if so if it is planned or recorded.
 */
export const recordingStatusByEpgIdAtom = atomFamily<
  EPGEntryId,
  Atom<Promise<Nullable<RecordingStatus>>>
>((epgId: EPGEntryId) => {
  const innerAtom = atom(async (get) => {
    const data = await get(recordingStatusAtom);
    return data.find((status) => status.epg_entry.id === epgId);
  });
  innerAtom.debugLabel = `recordingStatusByEpgIdAtom(${epgId})`;
  return innerAtom;
});
