import { type ReactNode, useCallback, useEffect, useState } from "react";

import { type RetryDialog } from "@sunrise/dialogs";
import { useTranslatable } from "@sunrise/translator";

import { FocusButton } from "@/components";
import type { DialogOptions } from "@/features/dialogs/types";

import * as styles from "../actions-dialog/actions-dialog.css";

/**
 * Will show a dialog where the user can retry a failed request.
 *
 * Can also show a loading state as long as the request is retrying.
 */
export function RetryDialog({
  dialog,
  "data-testid": testId,
  doClose,
}: DialogOptions & { dialog: RetryDialog }): ReactNode {
  const t = useTranslatable();

  const retryButtonFocusKey = `${testId}.retry-button`;
  const [loading, setLoading] = useState(false);

  const onRetried = useCallback(() => {
    doClose();
    // When there are multiple retries in a row, the id changes.
    // But the retry dialog stays mounted.
    // So we need to reset the loading state.
    setLoading(false);
  }, [doClose]);

  const { onClose } = dialog;

  useEffect(() => {
    return () => {
      // When it closes, we need to make sure to abort the retry so it is not left hanging.
      // Triggering it after we actually pressed enter already will do nothing.
      onClose();
    };
  }, [onClose]);

  return (
    <FocusButton
      focusKey={retryButtonFocusKey}
      disabled={loading}
      pullFocus
      key={testId}
      data-testid={retryButtonFocusKey}
      text={t(dialog.actionLabel) ?? "Retry"}
      onEnterPress={() => {
        setLoading(true);
        // When retry is done, close.
        dialog.onRetry().then(onRetried, onRetried);
      }}
      className={styles.button}
    />
  );
}
