import {
  ArrowPressHandler,
  useFocusable,
} from "@noriginmedia/norigin-spatial-navigation";
import { ComponentProps, useEffect } from "react";

import { useScrollOnFocus } from "@/utils/use-scoll-on-focus";

import { Button } from "../button/button";

export type FocusButtonProps = ComponentProps<typeof Button> & {
  onEnterPress: () => void;
  onFocusChange?: (focused: boolean) => void;
  onArrowPress?: ArrowPressHandler;
  focusKey?: string;
  /**
   * When you want the button to pull focus to itself when it is mounted.
   */
  pullFocus?: boolean;
  scrollOnFocus?: boolean;
};

/**
 * This wraps the generic button but adds focus functionality.
 */
export function FocusButton({
  onEnterPress,
  focusKey,
  onArrowPress,
  onFocusChange,
  scrollOnFocus = false,
  "data-testid": dataTestId,
  active = false,
  pullFocus,
  ...rest
}: FocusButtonProps): JSX.Element {
  const { ref, focused, focusSelf } = useFocusable({
    onEnterPress,
    focusKey,
    onArrowPress,
    focusable: !rest.disabled,
  });

  useEffect(() => {
    onFocusChange?.(focused);
  }, [onFocusChange, focused]);

  useEffect(() => {
    if (pullFocus) {
      focusSelf();
    }
  }, [pullFocus, focusSelf]);

  useScrollOnFocus({
    enabled: scrollOnFocus,
    focused,
    ref,
  });

  return (
    <Button
      {...rest}
      ref={ref}
      focused={focused}
      active={active}
      data-testid={dataTestId ?? focusKey}
    />
  );
}
