import { featureAtom } from "@sunrise/feature-flags";

export const showFirstExperienceForMs = featureAtom(
  "first-experience-ms",
  5000,
  {
    description: `Enable the first experience screen on the TV page for new users.
When 0 we do not show it. When higher it is interpreted as show it for X amount of milliseconds.`,
    values: [0, 5000, 10_000, 20_000],
  },
);
