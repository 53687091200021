import { MappedAsset } from "@sunrise/asset";
import { Translatable } from "@sunrise/i18n";
import { isDefined, Nullable } from "@sunrise/utils";

import { getSeasonEpisodePrefix } from "./get-season-episode-prefix";

export function getPrefixAndSubtitle(
  type: Nullable<MappedAsset["type"]>,
  seasonNumber: Nullable<number>,
  episodeNumber: Nullable<number>,
  subtitle: Nullable<string>,
): {
  accessibleLabel: Nullable<Translatable>;
  label: string;
} {
  const prefix =
    type === "seriesepisode"
      ? getSeasonEpisodePrefix(seasonNumber, episodeNumber)
      : null;

  return {
    accessibleLabel:
      isDefined(seasonNumber) && isDefined(episodeNumber)
        ? {
            key: "program_detail_page_seriesepisode_accessible_subtitle",
            params: [seasonNumber, episodeNumber, subtitle],
          }
        : null,
    label: [prefix, subtitle].filter(Boolean).join(": "),
  };
}
