import { type Nullable } from "@sunrise/utils";
import { atomWithReducer, selectAtom } from "jotai/utils";

type RecommendationsAtomState = {
  coordinates: {
    rowIndex: number;
    /**
     * Contains the column index per row.
     */
    colIndex: number[];
  };
  offset: {
    row: number;
    /**
     * Contains the column offset per row.
     */
    col: number[];
  };
};

export function makeRecommendationsAtomDefaultState(
  state?: Partial<RecommendationsAtomState>,
): RecommendationsAtomState {
  return {
    coordinates: state?.coordinates ?? {
      colIndex: [0],
      rowIndex: 0,
    },
    offset: state?.offset ?? { row: 0, col: [0] },
  };
}

type ActionReset = {
  type: "recommendations/reset";
};

type ActionSetCoordinates = {
  type: "recommendations/set-coordinates";
  payload: {
    coordinates: {
      row: number;
      column: number;
    };
  };
};

type ActionSetOffsetRow = {
  type: "recommendations/set-offset-row";
  payload: number;
};

type ActionSetOffsetColumn = {
  type: "recommendations/set-offset-column";
  payload: {
    rowIndex: number;
    offset: number;
  };
};

type MenuAction =
  | ActionSetCoordinates
  | ActionReset
  | ActionSetOffsetRow
  | ActionSetOffsetColumn;
const DEFAULT_STATE = makeRecommendationsAtomDefaultState();

export const recommendationsAtom = atomWithReducer<
  RecommendationsAtomState,
  MenuAction
>(DEFAULT_STATE, recommendationsAtomReducer);

export function recommendationsAtomReducer(
  ps: RecommendationsAtomState,
  action: Nullable<MenuAction>,
): RecommendationsAtomState {
  switch (action?.type) {
    case "recommendations/set-coordinates": {
      if (
        ps.coordinates.rowIndex === action.payload.coordinates.row &&
        ps.coordinates.colIndex[ps.coordinates.rowIndex] ===
          action.payload.coordinates.column
      ) {
        return ps;
      }

      const newColumnCoordinates = ps.coordinates.colIndex.slice();
      newColumnCoordinates[action.payload.coordinates.row] =
        action.payload.coordinates.column;

      return {
        ...ps,
        coordinates: {
          rowIndex: action.payload.coordinates.row,
          colIndex: newColumnCoordinates,
        },
      };
    }
    case "recommendations/set-offset-column": {
      if (ps.offset.col[action.payload.rowIndex] === action.payload.offset) {
        return ps;
      }

      const newColumnOffset = ps.offset.col.slice();
      newColumnOffset[action.payload.rowIndex] = action.payload.offset;

      return {
        ...ps,
        offset: {
          row: ps.offset.row,
          col: newColumnOffset,
        },
      };
    }
    case "recommendations/set-offset-row": {
      if (ps.offset.row === action.payload) {
        return ps;
      }

      return {
        ...ps,
        offset: {
          ...ps.offset,
          row: action.payload,
        },
      };
    }
    case "recommendations/reset": {
      return DEFAULT_STATE;
    }
    default: {
      return ps;
    }
  }
}

recommendationsAtom.debugLabel = "recommendationsAtom";

/*
 *
 * ACTIONS
 *
 */

export function actionRecommendationsSetCoordinates(coordinates: {
  row: number;
  column: number;
}): ActionSetCoordinates {
  return {
    type: "recommendations/set-coordinates",
    payload: {
      coordinates,
    },
  };
}

export function actionRecommendationsSetOffsetRow(
  offset: number,
): ActionSetOffsetRow {
  return {
    type: "recommendations/set-offset-row",
    payload: offset,
  };
}

export function actionRecommendationsSetOffsetColumn(
  rowIndex: number,
  offset: number,
): ActionSetOffsetColumn {
  return {
    type: "recommendations/set-offset-column",
    payload: {
      rowIndex,
      offset,
    },
  };
}

export function actionRecommendationsReset(): ActionReset {
  return {
    type: "recommendations/reset",
  };
}

/**
 *
 * SELECTORS
 *
 */

export const selectRecommendationsCoordinates = selectAtom(
  recommendationsAtom,
  (state: RecommendationsAtomState) => ({
    rowIndex: state.coordinates.rowIndex,
    colIndex: state.coordinates.colIndex[state.coordinates.rowIndex] ?? 0,
  }),
);

export const selectRecommendationsOffsetColumns = selectAtom(
  recommendationsAtom,
  (state: RecommendationsAtomState) => state.offset.col,
);

export const selectRecommendationsOffsetRow = selectAtom(
  recommendationsAtom,
  (state: RecommendationsAtomState) => state.offset.row,
);
