import type { ActionsDialog, RetryDialog } from "@sunrise/dialogs";
import { useTranslatable } from "@sunrise/translator";
import clsx from "clsx";
import { type ReactNode } from "react";

import { typography } from "@/styles/typography.css";

import * as styles from "../actions-dialog/actions-dialog.css";

/**
 * Renders the dialog contents in a container and also takes cre of the header & descriptions for the containers if they have any.
 *
 * @returns
 */
export function DialogContainer({
  dialog,
  children,
  "data-testid": testId,
}: {
  dialog: ActionsDialog | RetryDialog;
  children: ReactNode;
  ["data-testid"]: string;
}): ReactNode {
  const leftAlignment = dialog.textAlignment === "left";
  const translate = useTranslatable();

  const translatedTitle = translate(dialog.title);
  const description = dialog.description
    ? typeof dialog.description === "object" && "type" in dialog.description
      ? dialog.description.component
      : translate(dialog.description)
    : null;

  return (
    <div
      className={clsx([
        styles.content,
        leftAlignment && styles.contentLeftAligned,
      ])}
      data-testid={`${testId}.container`}
      data-dialog-id={dialog.id}
      data-dialog-type={dialog.type}
    >
      {dialog.bgImage ? (
        <img src={dialog.bgImage} className={styles.background} />
      ) : null}
      {dialog.logo ? <img src={dialog.logo} className={styles.logo} /> : null}
      {translatedTitle ? (
        <div
          className={clsx([
            styles.title,
            leftAlignment && styles.titleLeftAligned,
            typography.h1.bold,
          ])}
          data-testid={`${testId}.title`}
        >
          {translatedTitle}
        </div>
      ) : null}
      {description ? (
        <div
          className={clsx([
            styles.description,
            leftAlignment && styles.descriptionLeftAligned,
            leftAlignment ? typography.h6.regular : typography.h4.regular,
          ])}
          data-testid={`${testId}.description`}
        >
          {description}
        </div>
      ) : null}
      {children}
    </div>
  );
}
