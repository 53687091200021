import {
  playerCurrentDateTimeAtom,
  playerCurrentEpgItemAtom,
  playerDateTimeConverterAtom,
  playerPreviousEpgItemAtom,
  selectPlayerCurrentTime,
} from "@sunrise/player";
import { isDefined } from "@sunrise/utils";
import {
  actionPlayerManagerPlayReplay,
  currentlyRequestedPlayRequestAtom,
  getPlayerManager,
  playerManagerAtom,
} from "@sunrise/yallo-common-player-manager";
import type { ReplayPlayRequest } from "@sunrise/yallo-player-types";
import { recordingByRecordingIdAtom } from "@sunrise/yallo-recordings";
import { differenceInSeconds } from "date-fns/fp";
import { useAtomCallback } from "jotai/utils";
import { useCallback } from "react";

/**
 * This hook is used to play the current program from the beginning.
 * It will seek to the start of the program and play it.
 *
 * It works for EPG/linear-based streams and for recordings/on-demand streams.
 * In both cases it will check if first 5sec of stream has been played and if so, it will seek to the start of the program.
 * Else it will seek to the start of the previous program.
 *
 * For recordings it will also check if the recording has a padding start time and seek to that time if it exists.
 *
 * @returns A function that when called will play the program from the beginning.
 */

export function usePlayFromBeginning(): () => Promise<void> {
  return useAtomCallback(
    useCallback(async (get, set) => {
      const playRequest = get(currentlyRequestedPlayRequestAtom);
      if (!playRequest) return;

      let time;

      if (playRequest.type === "recording") {
        const currentTimeInSeconds = get(selectPlayerCurrentTime);
        const recording = await get(
          recordingByRecordingIdAtom(playRequest.recordingId),
        );

        const recordingPaddingStartSeconds =
          (recording.data?.padding_start_minutes &&
            recording.data?.padding_start_minutes * 60) ??
          0;
        const shouldSeekToPreviousEpg =
          isDefined(currentTimeInSeconds) &&
          currentTimeInSeconds - recordingPaddingStartSeconds < 5;
        const previousEpgEntry =
          shouldSeekToPreviousEpg && (await get(playerPreviousEpgItemAtom));

        if (shouldSeekToPreviousEpg && previousEpgEntry) {
          const replayRequest: ReplayPlayRequest = {
            type: "replay",
            epgId: previousEpgEntry.id,
            channelId: previousEpgEntry.channel.id,
          };
          // check if the previous program is in replay window, else show out of replay dialog
          const canPlayReplay = await getPlayerManager().canPlay(
            replayRequest,
            false,
          );
          if (canPlayReplay) {
            // change the stream to replay
            set(
              playerManagerAtom,
              actionPlayerManagerPlayReplay(
                previousEpgEntry.id,
                previousEpgEntry.channel.id,
                new Date(previousEpgEntry.actual_start),
                true,
              ),
            );
          }
          return;
        } else {
          // play from paddingStartTime in seconds if exists
          time = recordingPaddingStartSeconds ?? 0;
        }
      } else {
        // replay or live
        const converter = get(playerDateTimeConverterAtom);
        if (!converter) return;

        const epgEntry = await get(playerCurrentEpgItemAtom);
        if (!epgEntry) return;

        const currentTime = get(playerCurrentDateTimeAtom);

        // when the program has started, but is less than 5s in (duration * progress < 5s)
        const shouldSeekToPreviousEpg =
          currentTime &&
          differenceInSeconds(new Date(epgEntry.actual_start), currentTime) < 5;
        const previousEpgEntry =
          shouldSeekToPreviousEpg && (await get(playerPreviousEpgItemAtom));

        if (shouldSeekToPreviousEpg && previousEpgEntry) {
          time = converter.fromDate(new Date(previousEpgEntry.actual_start));
        } else {
          time = converter.fromDate(new Date(epgEntry.actual_start));
        }
      }

      await getPlayerManager().seekToInCurrentPlayRequest(
        time,
        "seek-to-start",
      );
    }, []),
  );
}
