import type { InfiniteData } from "@tanstack/react-query";
import { atomWithInfiniteQuery } from "jotai-tanstack-query";
import { isNil } from "lodash";

import type { PageWithItems } from "@sunrise/backend-ng-core";
import { recordingsStaleTimeAtom } from "@sunrise/backend-ng-recordings";
import { type LegacyRecording, queryKeys } from "@sunrise/backend-types";
import { canSwallowError } from "@sunrise/error";
import { hostsAtom, httpClientAtom } from "@sunrise/http-client";
import { currentLanguageAtom } from "@sunrise/i18n";
import { selectIsLoggedIn, selectJwtUserToken } from "@sunrise/jwt";
import { Nullable } from "@sunrise/utils";
import { convertLegacyPageFormatToNgPageFormat } from "@sunrise/yallo-recordings-list";

import {
  currentRecordingsStatusFilterAtom,
  currentRecordingsTypeFilterAtom,
} from "../current-recordings-filter.atom";
import { fetchRecordings } from "../recordings.service";
import {
  recordingsFiltersTypeMappingAtom,
  recordingsSortFieldMapping,
} from "../recordings-filters-type-mapping.atom";
import { recordingsSortOverviewLegacyAtom } from "../recordings-sort-overview.legacy.atom";

export const recordingsLegacyQueryAtom = atomWithInfiniteQuery<
  Nullable<PageWithItems<LegacyRecording>>,
  unknown,
  InfiniteData<PageWithItems<LegacyRecording>>,
  ReturnType<typeof queryKeys.recordingsOverviewLegacy>,
  number
>((get) => {
  const host = get(hostsAtom).api;
  if (isNil(host)) throw new Error("Host is not set");

  const { privateApi } = get(httpClientAtom);
  if (!privateApi) throw new Error("missing privateApi");

  const isLoggedIn = get(selectIsLoggedIn);

  const sort = get(recordingsSortOverviewLegacyAtom);
  const language = get(currentLanguageAtom);

  const statusFilter = get(currentRecordingsStatusFilterAtom);
  const typeFilter = get(currentRecordingsTypeFilterAtom);

  return {
    initialPageParam: 1,
    staleTime: get(recordingsStaleTimeAtom),
    gcTime: get(recordingsStaleTimeAtom),
    getNextPageParam: (lastPage) => {
      if (isNil(lastPage)) return undefined;

      const { page, pages } = lastPage;
      if (!page || !pages) return undefined;
      return page < pages ? page + 1 : undefined;
    },
    // NOTE: We still depend on the access token since we want the data to reload when the user's token changes.
    queryKey: queryKeys.recordingsOverviewLegacy(
      get(selectJwtUserToken),
      sort,
      language,
      typeFilter,
      statusFilter,
    ),
    queryFn: async ({ pageParam }) => {
      if (isNil(pageParam) || !isLoggedIn) return;

      const sortInformation = recordingsSortFieldMapping[sort];

      const recordingsTypeFilter = await get(recordingsFiltersTypeMappingAtom);

      const pageParams = {
        page: pageParam,
        pagesize: 20,
        [sortInformation.field]:
          sortInformation.field === "order_direction"
            ? sortInformation.direction
            : `${language}:${sortInformation.direction}`,
      };

      // append filters if any is set but not if it's set to "all"
      if (statusFilter && statusFilter !== "all") {
        pageParams["status"] = statusFilter;
      }

      if (recordingsTypeFilter && recordingsTypeFilter !== "all") {
        pageParams["asset_type"] = recordingsTypeFilter;
      }

      try {
        return convertLegacyPageFormatToNgPageFormat(
          (await fetchRecordings(host, privateApi, pageParams)).data,
        );
      } catch (e) {
        // When not explicitly set by the error, assume we can actually swallow the error.
        if (!canSwallowError(e, true)) throw e;
        return null;
      }
    },
    keepPreviousData: true,
    suspense: true,
  };
});
