import { useTranslator } from "@sunrise/translator";
import { settingsAtom } from "@sunrise/yallo-settings";
import { useAtomValue } from "jotai";
import { ReactElement, Suspense } from "react";
import logoImage from "static/icons/logo-extended.svg";

import { QrCode, Spinner } from "@/components";
import { useMenu } from "@/features/menu/use-menu";
import { useRoutes } from "@/features/routing/use-routes";
import { useKeyboardNavigation } from "@/modules/keyboard-navigation";
import { theme } from "@/styles/theme.css";

import styles from "./register.module.css";

export function Register(): ReactElement {
  useMenu({ hidden: true });
  const routes = useRoutes();

  useKeyboardNavigation({
    isEnabled: true,
    onBack: routes.back,
  });

  const t = useTranslator();

  return (
    <main
      className={styles.root}
      data-testid="register"
      style={{ backgroundColor: theme.color.purple[95] }}
    >
      <div className={styles.wrapper}>
        <img src={logoImage} className={styles.logo} alt="" />

        <div className={styles.content}>
          <div className={styles.copy}>
            <h1 className={styles.header}>{t("v2_pin_register_title")}</h1>
            <p className={styles.paragraph}>
              {t("v2_pin_register_description")}
            </p>
          </div>

          <ConnectedQrCode />
        </div>
      </div>
    </main>
  );
}

function ConnectedQrCode(): ReactElement {
  const data = useAtomValue(settingsAtom);

  return (
    <div className={styles.qrCodeContainer} data-testid="qr-code-register">
      <Suspense fallback={<Spinner data-testid="qr-code-register" />}>
        <QrCode
          qrCodeUrl={data.pin_login_links.qr_url}
          link={data.pin_login_links.web_url}
          size={428}
          textColor="yellow"
        />
      </Suspense>
    </div>
  );
}
