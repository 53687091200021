import type {
  Recording,
  RecordingsInfiniteQueryResult,
} from "@sunrise/backend-types";
import { isNil } from "lodash";

/*
 *
 * Selectors
 *
 */

export function selectHasRecordingsQueryFetchedFirstPage(
  value: RecordingsInfiniteQueryResult,
): boolean {
  const pages = value.data?.pages;
  if (isNil(pages)) return false;

  const pagesCount = pages.length ?? 0;
  const entriesCount =
    pages
      .flatMap((it) => it?.result.length ?? 0)
      .reduce((sum, val) => sum + val, 0) ?? 0;

  return pagesCount > 0 && entriesCount > 0;
}

export function selectRecordingsData(
  value: RecordingsInfiniteQueryResult,
): Recording[] {
  return value.data?.pages.flatMap((p) => p?.result ?? []) ?? [];
}

export function selectRecordingsIsFetchingInitialData(
  value: RecordingsInfiniteQueryResult,
): boolean {
  const pagesCount = value.data?.pages.length ?? 0;
  const isFetching = value.isFetching;
  return isFetching && pagesCount === 0;
}
