import { atom } from "jotai";

import { currentLanguageAtom, getLocalizedValue } from "@sunrise/i18n";
import { channelByIdAtom } from "@sunrise/yallo-channel-group";

import { selectPlayerCurrentPlayRequest } from "./player.atom";
import { playerCurrentEpgItemAtom } from "./player-current-epg-item.atom";
import { PlayerCurrentContent } from "./types";

const EMPTY: Readonly<PlayerCurrentContent> = {
  epgId: null,
  channelId: null,
  channelName: null,
  recordingId: null,
  title: null,
  subtitle: null,
  channelLogo: null,
  schedule: null,
};

/**
 * It does an EPG lookup to know what is playing.
 * It returns the actual EPG item that is playing.
 * When we are seeking or in seek confirmation mode, it will still just return whatever the player is actually playing.
 */
export const playerCurrentContentEpgAtom = atom<Promise<PlayerCurrentContent>>(
  async (get) => {
    const playRequest = get(selectPlayerCurrentPlayRequest);
    const language = get(currentLanguageAtom);

    if (!playRequest || playRequest.type === "recording") {
      return EMPTY;
    }

    const [channel, epg] = await Promise.all([
      get(channelByIdAtom(playRequest.channelId)),
      get(playerCurrentEpgItemAtom),
    ]);

    if (!epg || !channel) {
      return EMPTY;
    }

    return {
      epgId: epg.id,
      channelId: channel.id,
      channelName: channel.name,
      recordingId: null,
      title: getLocalizedValue(epg.asset.title, language),
      subtitle: getLocalizedValue(epg.asset.subtitle, language),
      channelLogo: channel.logo,
      schedule: {
        endTime: new Date(epg.actual_end),
        startTime: new Date(epg.actual_start),
      },
    };
  },
);
