import { getCurrentFocusKey } from "@noriginmedia/norigin-spatial-navigation";
import { actionDialogOpen, dialogAtom } from "@sunrise/dialogs";
import type { Store } from "@sunrise/store";

export function createHandleRetry(store: Store) {
  let i = 0;

  /**
   * @param done
   *  The promise that will be resolved when the retried request has resolved.
   *  Success or failure does not matter.
   */
  return function handleRetry(done: Promise<void>): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      let resolved = false;
      i++;
      store.set(
        dialogAtom,
        actionDialogOpen({
          type: "retry",
          onRetry: () => {
            if (resolved) return done;
            resolve(true);
            resolved = true;

            // On retry, we need to return a promise that resolves when the retry is done.
            // So that the dialog can now close itself.
            return done;
          },
          onClose: () => {
            if (resolved) return;
            resolve(false);
            resolved = true;
          },
          backBehaviour: "kill-app",
          // We need a new ID. Else, the new retry dialog will not pop up.
          // It will close the old one but also the new one since it has the same ID.
          id: `retry-dialog-${i}`,
          title: { key: "startup_retry_login_dialog_title" },
          description: { key: "startup_retry_login_dialog_description" },
          actionLabel: { key: "startup_retry_login_dialog_button" },
          lastFocusKey: getCurrentFocusKey(),
        }),
      );
    });
  };
}
