import {
  readTizenBuildDate,
  readTizenBuildId,
  readTizenBuildString,
  readTizenBuildVariant,
  readTizenCoreApiVersion,
  readTizenCpuArch,
  readTizenId,
  readTizenManufacturer,
  readTizenModelName,
} from "@/modules/tizen/system.service";

/**
 * Makes record filled with extra data with device information
 */
export function makeMonitoringExtraData(): Record<string, unknown> {
  return {
    tizenBuildDate: readTizenBuildDate(),
    tizenBuildId: readTizenBuildId(),
    tizenBuildVariant: readTizenBuildVariant(),
    tizenBuildString: readTizenBuildString(),
    tizenCoreApiVersion: readTizenCoreApiVersion(),
    tizenCpuArch: readTizenCpuArch(),
    tizenManufacturer: readTizenManufacturer(),
    tizenModelName: readTizenModelName(),
    tizenId: readTizenId(),
  };
}
