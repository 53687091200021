import { type ReactNode, Suspense, useEffect } from "react";
import { setFocus } from "@noriginmedia/norigin-spatial-navigation";
import { useAtomValue } from "jotai";

import { usePlayerControls } from "@sunrise/yallo-player-controls";

import { SkipFfwdAdsButton } from "@/features/ffwd-ads/skip-ffwd-ads-button";
import * as styles from "@/features/player-controls/player-controls.css";
import { PlayerSeekbar } from "@/features/player-controls/player-seekbar";
import { shouldPullFocusToPlayerControlsAtom } from "@/modules/tv/should-pull-focus-to-player-controls.atom";
import { FocusContainer } from "@/utils/focus-container";
import { isArrowDownKey, isHorizontal } from "@/utils/navigation";

import { playerControlsFocusKeys } from "./focus-keys";
import { PlayerControlButtons } from "./player-controls-buttons";
import { PlayerControlsPlayingInfo } from "./player-controls-playing-info";

export const PlayerControls = (): ReactNode => {
  const { doClose, isVisible } = usePlayerControls();

  useEffect(() => {
    return () => {
      doClose();
    };
  }, [doClose]);

  const shouldPullFocus = useAtomValue(shouldPullFocusToPlayerControlsAtom);
  useEffect(() => {
    if (shouldPullFocus) {
      setFocus(playerControlsFocusKeys.component);
    }
  }, [shouldPullFocus]);

  if (!isVisible) return null;

  return (
    <FocusContainer
      focusKey={playerControlsFocusKeys.component}
      onBack={doClose}
      className={styles.container}
      shouldFocus
      boundary
      preferredChildFocusKey={playerControlsFocusKeys.seekbar}
      data-testid="player-controls"
    >
      {(handlers) => (
        <>
          <Suspense>
            <PlayerControlsPlayingInfo />
          </Suspense>
          <SkipFfwdAdsButton
            className={styles.ffwdButton}
            onArrowPress={(direction) => {
              if (isHorizontal(direction)) {
                return false;
              }

              if (isArrowDownKey(direction)) {
                setFocus(playerControlsFocusKeys.seekbar);
                return false;
              }

              return handlers.onArrowPress()(direction);
            }}
          />
          <div className={styles.innerContainer}>
            <PlayerSeekbar
              focusKey={playerControlsFocusKeys.seekbar}
              onBlurRequest={() => {
                setFocus(playerControlsFocusKeys.toggle);
              }}
            />
            <PlayerControlButtons handlers={handlers} />
          </div>
        </>
      )}
    </FocusContainer>
  );
};
