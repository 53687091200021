import { useEffect } from "react";

export function useDebounceEffect(
  cb: () => void,
  deps: unknown[],
  delay: number,
) {
  useEffect(() => {
    const handler = setTimeout(() => {
      cb();
    }, delay);

    return () => {
      clearTimeout(handler);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
}
