import clsx from "clsx";
import { type ReactNode, Ref, forwardRef } from "react";

import { Icon, IconProps } from "@/components/icon";

import * as styles from "./icon-button.css";

type IconButtonProps = CommonProps & {
  focused?: boolean;
  disabled?: boolean;
  iconName: IconProps["name"];
};

export const IconButton = forwardRef<HTMLButtonElement, IconButtonProps>(
  function IconButton(
    {
      focused,
      disabled,
      className,
      iconName,
      "data-testid": dataTestId = "iconButton",
    }: IconButtonProps,
    ref: Ref<HTMLButtonElement>,
  ): ReactNode {
    const state = disabled ? "disabled" : focused ? "focused" : "nostate";

    const iconColor = focused && !disabled ? "dark" : "white";

    return (
      <button
        data-testid={dataTestId}
        className={clsx([styles.iconButtonStyle, styles[state], className])}
        ref={ref}
        data-focused={focused}
      >
        <Icon
          name={iconName}
          color={iconColor}
          data-testid={`${dataTestId}.icon`}
        />
      </button>
    );
  },
);
