import {
  type Asset,
  AssetRating,
  AssetStaff,
  endpoints,
  PagedResponse,
  Staff,
  StaffType,
} from "@sunrise/backend-types";
import { type AssetId } from "@sunrise/backend-types-core";
import { PrivateApiClient, publicApi } from "@sunrise/http-client";
import { Nullable } from "@sunrise/utils";
import { AxiosError } from "axios";

export async function fetchAsset(host: string, id: AssetId): Promise<Asset> {
  const { data } = await publicApi.get<Asset>(endpoints.asset(host, id));

  return data;
}

const staffLimit = 10;

export async function fetchAssetStaff(
  privateApi: PrivateApiClient,
  host: string,
  id: AssetId,
  types?: StaffType[],
): Promise<AssetStaff> {
  const url = new URL(endpoints.staff(host, id));
  if (types && types.length) {
    url.searchParams.set("types", types.join(","));
  }

  const { data } = await privateApi.get<PagedResponse<Staff>>(url.href);

  const actors = data?.result
    .filter((item) => item.type === "actor")
    .slice(0, staffLimit);
  const directors = data?.result
    .filter((item) => item.type === "director")
    .slice(0, staffLimit);

  return { actors, directors };
}

export async function fetchAssetRating(
  privateApi: PrivateApiClient,
  host: string,
  id: AssetId,
): Promise<Nullable<AssetRating>> {
  try {
    const { data } = await privateApi.get<AssetRating>(
      endpoints.rating(host, id),
    );
    return data;
  } catch (err) {
    // If no rating is found, we don't want any errors logged
    if (err instanceof AxiosError && err?.response?.status === 404) {
      return null;
    }

    throw err;
  }
}
