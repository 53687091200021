import {
  ngChannelApiAtom,
  type HTTPValidationError,
  type PageBaseChannelListSchema,
} from "@sunrise/backend-ng-channel";
import type { Language } from "@sunrise/backend-types-core";
import { currentLanguageAtom } from "@sunrise/i18n";
import { selectJwtUserToken } from "@sunrise/jwt";
import type { InfiniteData } from "@tanstack/react-query";
import { atomWithInfiniteQuery } from "jotai-tanstack-query";

const PAGE_SIZE = 50;

export const channelListsOverviewQueryNgAtom = atomWithInfiniteQuery<
  PageBaseChannelListSchema,
  HTTPValidationError,
  InfiniteData<PageBaseChannelListSchema>,
  [string | null, Language, string],
  number
>((get) => {
  const ngApi = get(ngChannelApiAtom);
  const language = get(currentLanguageAtom);

  return {
    initialPageParam: 1,
    getNextPageParam: (lastPage) => {
      if (!lastPage) return undefined;
      const { page, pages } = lastPage;
      if (!pages || !page) return undefined;

      return page < pages ? page + 1 : undefined;
    },
    queryKey: [get(selectJwtUserToken), language, "channel-list-overview"],
    queryFn: async ({ pageParam }) => {
      return (
        await ngApi.channel.channelListsChannelV1ChannelListsGet({
          page: pageParam,
          size: PAGE_SIZE,
        })
      ).data;
    },
  };
});
