import { selectHasRecordingsQueryFetchedFirstPage } from "@sunrise/yallo-recordings";
import { type Atom, useAtomValue } from "jotai";
import { selectAtom } from "jotai/utils";
import { ReactNode, useMemo } from "react";

import { Spinner } from "@/components";
import type { RecordingsInfiniteQueryResult } from "@sunrise/backend-types";

type RecordingsNetworkIndicatorProps = {
  dataAtom: Atom<RecordingsInfiniteQueryResult>;
} & CommonProps;

export function RecordingsNetworkIndicator({
  dataAtom,
  "data-testid": dataTestId = "recordings-network-indicator",
  className,
  style,
}: RecordingsNetworkIndicatorProps): ReactNode {
  const { isFetching } = useAtomValue(dataAtom);
  const hasFetchedFirstPageAtom = useMemo(() => {
    const inner = selectAtom(
      dataAtom,
      selectHasRecordingsQueryFetchedFirstPage,
    );
    inner.debugLabel = "hasFetchedFirstPageAtom";
    return inner;
  }, [dataAtom]);
  const hasFirstPage = useAtomValue(hasFetchedFirstPageAtom);

  return isFetching && hasFirstPage ? (
    <Spinner className={className} style={style} data-testid={dataTestId} />
  ) : null;
}
