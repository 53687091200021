import {
  selectPlayerCurrentPlayRequest,
  selectPlayerCurrentStreamModel,
} from "@sunrise/player";
import { atom } from "jotai";

export const canSeekInDirectionAtom = atom<{
  forwards: boolean;
  backwards: boolean;
}>((get) => {
  // determine in which mode we are.
  const model = get(selectPlayerCurrentStreamModel);

  switch (model) {
    case "on-demand":
      // in on demand we can always seek in both directions. (there's no player controls anyway when the player is stopped)
      return {
        forwards: true,
        backwards: true,
      };
    case "linear": {
      // in linear mode we can only seek forwards if we are not live.
      // we should always be able to seek backwards.
      const pr = get(selectPlayerCurrentPlayRequest);

      return {
        forwards: pr?.type !== "live",
        backwards: true,
      };
    }
    default:
      return {
        forwards: false,
        backwards: false,
      };
  }
});
