import { Nullable } from "@sunrise/utils";
import { formatDuration, intervalToDuration } from "date-fns";

// takes time in milliseconds by default or in seconds optionally and returns a formatted string
export function formatTime(
  time: Nullable<number>,
  isTimeInSeconds = false,
): string {
  const actualTime = Math.abs(time ?? 0);

  const duration = intervalToDuration({
    start: isTimeInSeconds ? actualTime * 1000 : actualTime,
    end: 0,
  });

  // intervalToDuration will return an object with only the fields that are non-zero but we need those nulls too
  const fullFormattedDuration = {
    hours: duration.hours ?? 0,
    minutes: duration.minutes ?? 0,
    seconds: duration.seconds ?? 0,
  };

  // format duration to H:mm:ss
  const formatted = formatDuration(fullFormattedDuration, {
    format: ["hours", "minutes", "seconds"],
    zero: true,
    delimiter: ":",
    locale: {
      // NOTE: only pad minutes and seconds with a 0, not the hours
      formatDistance: (token: string, count: number) => {
        const reverseCount = (-count).toString();
        return token !== "xHours"
          ? reverseCount.padStart(2, "0")
          : reverseCount;
      },
    },
  });

  return time && time < 0 ? `-${formatted}` : formatted;
}
