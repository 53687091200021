import type { RecordingId } from "@sunrise/backend-types-core";
import {
  selectPlayerCurrentTime,
  selectPlayerDuration,
  selectPlayerIsStopped,
  selectPlayerState,
} from "@sunrise/player";
import { type Nullable } from "@sunrise/utils";
import { selectRecordingId } from "@sunrise/yallo-common-player-manager";
import { recordingByRecordingIdAtom } from "@sunrise/yallo-recordings";
import { minutesToSeconds } from "date-fns";
import { atom } from "jotai";
import { atomEffect } from "jotai-effect";

const TIMEOUT_MS = 15000;

export const selectShouldShowWhatIsNext = selectPlayerIsStopped;

export const showWhatIsNextSideBarAtom = atom(false);

export const isAllowedToShowAtom = atom(false);

export const forceHideWhatIsNextSideBarAtom = atom(false);

export const whatIsNextTimeoutEffect = atomEffect((get, set) => {
  const shouldShow = get(showWhatIsNextSideBarAtom);
  let timeoutId: number | undefined;

  if (shouldShow) {
    timeoutId = window.setTimeout(() => {
      set(forceHideWhatIsNextSideBarAtom, true);
    }, TIMEOUT_MS);
  }

  return () => {
    window.clearTimeout(timeoutId);
  };
});

// keep track of the current recording we're playing, to know when to reset the forced hide
const currentRecordingIdAtom = atom<Nullable<RecordingId>>(null);

export const whatIsNextResetEffect = atomEffect((get, set) => {
  const currentRecordingId = get(currentRecordingIdAtom);
  const nextRecordingId = get(selectRecordingId);

  if (currentRecordingId === nextRecordingId) {
    return;
  }

  // reset the forced hide when we have a new request
  set(currentRecordingIdAtom, nextRecordingId);
  set(forceHideWhatIsNextSideBarAtom, false);
});

export const whatIsNextEffect = atomEffect((get, set) => {
  const forceHide = get(forceHideWhatIsNextSideBarAtom);
  const recordingId = get(selectRecordingId);
  const state = get(selectPlayerState);
  const current = get(selectPlayerCurrentTime) ?? 0;
  const duration = get(selectPlayerDuration) ?? 0;

  async function checkIfShouldShow(): Promise<void> {
    if (forceHide || !recordingId || state === "loading") {
      set(showWhatIsNextSideBarAtom, false);
      return;
    }

    // needs to be past the buffer time

    const recording = await get(recordingByRecordingIdAtom(recordingId));
    const padding = minutesToSeconds(recording.data?.padding_end_minutes ?? 0);

    const shouldShow = current > duration - padding;

    set(showWhatIsNextSideBarAtom, shouldShow);
  }

  void checkIfShouldShow();
});
