import { forwardRef, type ReactElement } from "react";
import { useAtomValue } from "jotai";

import type { ChannelResult } from "@sunrise/backend-types";
import { type Nullable } from "@sunrise/utils";
import { channelByIdAtom } from "@sunrise/yallo-channel-group";

import { ChannelBox } from "@/components/boxes";

export type ListChannelItemProps = CommonProps & {
  item: ChannelResult["items"][number];
  focused: boolean;
  posterUrl: Nullable<string>;
  className?: string;
  "data-position"?: number;
};

export const ListChannelItem = forwardRef<HTMLDivElement, ListChannelItemProps>(
  function ListChannelItem(
    {
      "data-testid": dataTestId = "column-item-channel",
      "data-position": dataPosition,
      focused,
      className,
      style,
      ...props
    },
    ref,
  ): ReactElement {
    const channel = useAtomValue(channelByIdAtom(props.item.details.id));

    return (
      <ChannelBox
        style={style}
        ref={ref}
        coverImageUrl={props.posterUrl}
        logoUrl={channel?.logo}
        focused={focused}
        className={className}
        data-testid={dataTestId}
        data-focused={focused}
        data-position={dataPosition}
      />
    );
  },
);
