import { featureAtom } from "@sunrise/feature-flags";

/**
 * This is a simple boolean to determine if we prefer recordings over replay.
 * Else it may become terribly difficult to test recordings.
 */
export const preferRecordingsAtom = featureAtom("prefer-recordings", true, {
  description: `This is a simple boolean to determine if we prefer recordings over replay.
    Else it may become terribly difficult to test recordings. In the end we decided to stick with recording playout over replays. Maybe we can remove this flag?`,
});
