import { type ReactNode, useEffect, useMemo } from "react";
import {
  FocusContext,
  setFocus,
  useFocusable,
} from "@noriginmedia/norigin-spatial-navigation";
import clsx from "clsx";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import { debounce } from "lodash";
import gradient from "static/gradients/menu-gradient.webp";
import logoUrl from "static/icons/logo.svg";

import { actionLocationNavigate, locationAtom } from "@sunrise/location";
import { searchEnabledAtom } from "@sunrise/search";
import { useTranslator } from "@sunrise/translator";

import { MenuButton } from "@/components";
import { IconProps } from "@/components/icon";
import { ICON_SIZE_36 } from "@/components/icon/icon.config";
import { globalFocusKey } from "@/config/focus-key";
import { route } from "@/config/route";
import { useCloseApp } from "@/features/routing/use-close-app";
import {
  actionMenuCollapse,
  actionMenuHide,
  isMenuAllowedAtom,
  menuAtom,
} from "@/modules/menu";
import { isMenuActiveAtom } from "@/modules/menu/is-menu-active.atom";
import { isArrowRightKey } from "@/utils/navigation";

import * as styles from "./menu.css";

// delay time when navigating in menu
const DEBOUNCE_TIME = 600;

type IconType = IconProps["name"];

type MenuItemData = {
  to: string;
  label: string;
  iconName: IconType;
  id: string;
};

const TOP_ITEMS: MenuItemData[] = [
  {
    to: route.home.root(),
    label: "menu_home",
    iconName: "home",
    id: "home",
  },
  {
    to: route.tv.root(),
    label: "menu_tv",
    iconName: "tv",
    id: "tv",
  },
  {
    to: route.guide.root(),
    label: "menu_guide",
    iconName: "guide",
    id: "guide",
  },
  {
    to: route.recordings.root(),
    label: "menu_recordings",
    iconName: "recordings",
    id: "recordings",
  },
];

const BOTTOM_ITEMS: MenuItemData[] = [
  {
    to: "/protected/settings",
    label: "menu_settings",
    iconName: "settings",
    id: "settings",
  },
];

if (
  import.meta.env.MODE !== "production" &&
  import.meta.env.VITE_SHOW_DESIGN_SYSTEM === "true"
) {
  BOTTOM_ITEMS.push({
    to: "/protected/design-system",
    label: "Design System",
    iconName: "search",
    id: "design-system",
  });
}

const localWidgetFocusKey = {
  button(name: string) {
    return `${globalFocusKey.mainMenu}.button.${name}`;
  },
};

export function Menu({
  recordingsAtCapacity,
}: {
  recordingsAtCapacity?: boolean;
}): ReactNode {
  const [location, dispatchLocation] = useAtom(locationAtom);

  const searchEnabled = useAtomValue(searchEnabledAtom);
  const topItems = useMemo(() => {
    const items = [...TOP_ITEMS];

    if (searchEnabled) {
      items.unshift({
        to: route.search.root(),
        label: "menu_search",
        iconName: "search",
        id: "search",
      });
    }

    if (recordingsAtCapacity) {
      return items.map((item) => {
        if (item.id === "recordings") {
          return {
            ...item,
            iconName: "warning",
          };
        }

        return item;
      });
    }

    return items;
  }, [searchEnabled, recordingsAtCapacity]) satisfies MenuItemData[];
  const preferredChildFocusKey = useMemo(
    () =>
      localWidgetFocusKey.button(
        topItems
          .concat(BOTTOM_ITEMS)
          .find((i) => location.pathname?.includes(i.to))?.id ?? "home",
      ),
    [location, topItems],
  );

  const [menu, dispatchMenu] = useAtom(menuAtom);
  const isAllowed = useAtomValue(isMenuAllowedAtom);
  const isMenuActive = useAtomValue(isMenuActiveAtom);

  const { focusKey, ref, hasFocusedChild, focused, focusSelf } = useFocusable({
    isFocusBoundary: true,
    focusKey: globalFocusKey.mainMenu,
    preferredChildFocusKey,
    focusable: isMenuActive,
    saveLastFocusedChild: false,
    trackChildren: true,
  });
  const isFocusOnMenuAccordingToHook = focused || hasFocusedChild;

  const t = useTranslator();

  useCloseApp({
    isEnabled: (hasFocusedChild || focused) && isAllowed,
    onClose: (showDialog) => {
      // NOTE: we cannot show a actions dialog when the menu is expanded
      dispatchMenu(actionMenuCollapse());

      // NOTE: wait for the previously active component to regain focus after menu is collapsed
      setTimeout(showDialog, 0);
    },
  });

  // We need to make sure to also focus ourself when we are expanded and the focus is not on us.
  useEffect(() => {
    if (isMenuActive && isAllowed && !isFocusOnMenuAccordingToHook) {
      focusSelf();
    }
  }, [isMenuActive, isFocusOnMenuAccordingToHook, focusSelf, isAllowed]);

  // Make sure to hide the menu when it's visible but not allowed to be visible.
  useEffect(() => {
    if ((menu.isVisible || menu.isExpanded) && !isAllowed) {
      dispatchMenu(actionMenuHide());
      dispatchMenu(actionMenuCollapse());
    }
  }, [menu.isVisible, menu.isExpanded, isAllowed, dispatchMenu]);

  // Required to set the active page as the focus key when the menu is no longer expanded.
  // Sometimes, a page will actively pull focus. Like the player when there are no controls on it.
  // But it is not a guarantee. So we need to pass the focus to the active page on close.
  useEffect(() => {
    if (isMenuActive || !isFocusOnMenuAccordingToHook) return;
    setFocus(globalFocusKey.activePage);
  }, [isMenuActive, isFocusOnMenuAccordingToHook]);

  const handleFocus = debounce(
    (to: string) => {
      dispatchLocation(actionLocationNavigate(to));
    },
    DEBOUNCE_TIME,
    {
      leading: true,
      trailing: true,
    },
  );

  const isRouteActive = (currentPath: string): boolean =>
    location.pathname === currentPath;

  return (
    <FocusContext.Provider value={focusKey}>
      <div
        data-testid="menu"
        data-expanded={menu.isExpanded}
        className={clsx(
          styles.root,
          menu.isExpanded ? undefined : styles.isCollapsed,
          menu.isVisible ? undefined : styles.isHidden,
        )}
      >
        <div className={clsx(styles.collapsedMenuBackground)} />
        <div
          className={clsx(styles.expandedMenuBackground)}
          style={{
            backgroundImage: `url(${gradient})`,
            backgroundRepeat: `repeat`,
            backgroundSize: "contain",
          }}
        />
        <div ref={ref}>
          <div className={styles.content}>
            <img className={styles.logo} src={logoUrl} />

            <div className={styles.list}>
              <div className={styles.listTopGroup}>
                {topItems.map((it) => (
                  <MenuButtonComponent
                    data-testid={`menu.button.${it.id}`}
                    key={it.id}
                    iconName={it.iconName}
                    id={it.id}
                    label={t(it.label)}
                    onFocus={() => handleFocus(it.to)}
                    isActive={isRouteActive(it.to)}
                  />
                ))}
              </div>
              <div className={styles.listBottomGroup}>
                {BOTTOM_ITEMS.map((it) => (
                  <MenuButtonComponent
                    data-testid={`menu.button.${it.id}`}
                    key={it.id}
                    iconName={it.iconName}
                    id={it.id}
                    label={t(it.label)}
                    onFocus={() => handleFocus(it.to)}
                    isActive={isRouteActive(it.to)}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </FocusContext.Provider>
  );
}

type MenuItem = Omit<MenuItemData, "to"> & {
  onFocus: () => void;
  isActive: boolean;
};

type MenuButtonProps = CommonProps & MenuItem;

export function MenuButtonComponent(props: MenuButtonProps): JSX.Element {
  const dispatchMenu = useSetAtom(menuAtom);
  const { ref, focused } = useFocusable({
    focusKey: localWidgetFocusKey.button(props.id),
    onFocus: props.onFocus,
    onArrowPress: (direction) => {
      if (isArrowRightKey(direction)) dispatchMenu(actionMenuCollapse());
      return true;
    },
    onEnterPress: () => dispatchMenu(actionMenuCollapse()),
  });

  const menu = useAtomValue(menuAtom);

  return (
    <MenuButton
      ref={ref}
      text={props.label}
      active={props.isActive}
      focused={focused}
      className={styles.button}
      data-testid={props["data-testid"]}
      icon={props.iconName}
      iconSize={ICON_SIZE_36}
      hideText={!menu.isExpanded}
    />
  );
}
