import { atomFamily } from "jotai/utils";
import { atomWithSuspenseQuery } from "jotai-tanstack-query";
import { isNil } from "lodash";

import { AssetStaff, queryKeys } from "@sunrise/backend-types";
import { AssetId } from "@sunrise/backend-types-core";
import { hostsAtom, httpClientAtom } from "@sunrise/http-client";
import { type Nullable } from "@sunrise/utils";

import { fetchAssetStaff } from "./queries/asset.service";

export const assetStaffByIdLegacyAtom = atomFamily(
  (assetId: Nullable<AssetId>) =>
    atomWithSuspenseQuery<Nullable<AssetStaff>>((get) => {
      const host = get(hostsAtom).api;
      if (isNil(host)) throw new Error("Host is not set");

      const privateApi = get(httpClientAtom).privateApi;
      if (isNil(privateApi)) throw new Error("Private API is not set");

      return {
        enabled: !isNil(assetId),
        queryKey: queryKeys.assetStaffById(assetId),
        queryFn: async () => {
          if (!assetId) return null;

          return fetchAssetStaff(privateApi, host, assetId);
        },
        staleTime: Infinity,
        cacheTime: Infinity,
        retry: false,
      };
    }),
);
