import { type Nullable } from "@sunrise/utils";
import { lastWatchedChannelIdAtom } from "@sunrise/yallo-channel";
import {
  channelByIdAtom,
  selectedChannelGroupWithPaginatedChannels,
} from "@sunrise/yallo-channel-group";
import { atom } from "jotai";
import { isNil } from "lodash";

import { selectStartUpChannelId, userAtom } from "./user.service";
import type { ChannelId } from "@sunrise/backend-types-core";

/**
 * Returns the channelId we should start playing for the user when the TV-page is loaded.
 * It will go through a set of fallbacks to find a channel to play.
 * - user's last watched channel
 * - user backend settings's startup channel id
 * - first channel in the user's channel list
 * - first channel in the full channel list
 *
 * All channelIds will only ever be returned if they actually exist in the list of channels.
 * If no channel can be found at all, we will return null.
 */
export const startupChannelIdAtom = atom(async (get) => {
  const lwcId = get(lastWatchedChannelIdAtom);

  async function isPresent(id: Nullable<ChannelId>): Promise<boolean> {
    if (!id) {
      return false;
    }

    return (await get(channelByIdAtom(id))) !== null;
  }

  if (await isPresent(lwcId)) {
    return lwcId;
  }

  const user = (await get(userAtom)).data;
  if (user) {
    const backendStartupChannelId = selectStartUpChannelId(user);
    if (await isPresent(backendStartupChannelId)) {
      return backendStartupChannelId;
    }
  }

  const channelList = await get(selectedChannelGroupWithPaginatedChannels);
  const firstChannelInList = channelList?.channels[0]?.id;
  if (await isPresent(firstChannelInList)) {
    return firstChannelInList;
  }

  return null;
});
