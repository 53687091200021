import type { BaseChannelSchema } from "@sunrise/backend-ng-channel";
import type { MappedChannel } from "../../types";
import type { ChannelId } from "@sunrise/backend-types-core";

export function ngChannelToSimpleChannel(
  channel: BaseChannelSchema,
  index: number,
): MappedChannel {
  return {
    id: channel.id as ChannelId,
    locked: !channel.is_playable,
    logo: channel.logo,
    name: channel.name,
    order: index + 1,
    stream: channel.stream_service_url,
    providerName: channel.stream_provider.provider_name,
  };
}
