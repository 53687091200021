/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

/** ChannelStreamProvider */
export interface ChannelStreamProvider {
  /**
   * Stream Provider ID
   * The stream provider id of a channel
   */
  provider_id: string;
  /**
   * Stream Provider Name
   * The stream provider name of a channel
   */
  provider_name: string;
}

/** EpgEntrySchema */
export interface EpgEntrySchema {
  /**
   * EPG ID
   * The id of the EPG
   */
  id: string;
  /**
   * Start
   * The start time of the epg
   * @format date-time
   */
  start: string;
  /**
   * End
   * The end time of the epg
   * @format date-time
   */
  end: string;
  asset: ReducedAssetSchema;
  channel: ReducedChannelSchema;
  /**
   * Replay Stream Service URL
   * The replay URL of the stream service
   */
  readonly stream_service_url: string | null;
}

/** HTTPValidationError */
export interface HTTPValidationError {
  /** Detail */
  detail?: ValidationError[];
}

/** Page[EpgEntrySchema] */
export interface PageEpgEntrySchema {
  /** Items */
  items: EpgEntrySchema[];
  /** Total */
  total: number | null;
  /** Page */
  page: number | null;
  /** Size */
  size: number | null;
  /** Pages */
  pages?: number | null;
}

/** ReducedAssetSchema */
export interface ReducedAssetSchema {
  /** Id of the asset */
  id: string;
  /**
   * Title
   * The title of the asset
   */
  title?: string;
  /**
   * Subtitle
   * The subtitle of the asset
   */
  subtitle?: string | null;
  /**
   * Poster
   * The poster image url of the asset
   */
  poster_url: string | null;
  /** The blurhash of the poster */
  poster_blurhash?: string | null;
  /**
   * Season Number
   * The season number of the series episode
   */
  season_number?: number | null;
  /**
   * Episode Number
   * The episode number of the series episode
   */
  episode_number?: number | null;
  /** Type */
  readonly type: string | null;
  /** Asset Recording Available */
  readonly asset_recording_available: boolean;
}

/** ReducedChannelSchema */
export interface ReducedChannelSchema {
  /**
   * Channel ID
   * The id of the channel
   */
  id: string;
  /**
   * Channel Name
   * The name of the channel
   */
  name: string;
  /**
   * Channel Logo
   * The logo of the channel
   */
  logo: string;
  /**
   * Live Stream Service URL
   * The live URL of the stream service
   */
  readonly stream_service_url: string | null;
}

/** ValidLanguages */
export enum ValidLanguages {
  En = "en",
  De = "de",
  Fr = "fr",
  It = "it",
}

/** ValidationError */
export interface ValidationError {
  /** Location */
  loc: (string | number)[];
  /** Message */
  msg: string;
  /** Error Type */
  type: string;
}

import { HttpClient, RequestParams } from "@sunrise/backend-ng-core";

/**
 * @title FastAPI
 * @version 0.1.0
 */
export class GeneratedApi {
  http: HttpClient;

  constructor(http: HttpClient) {
    this.http = http;
  }

  epg = {
    /**
     * No description
     *
     * @tags epg-entries
     * @name EpgEntryListEpgV1EpgEntriesGet
     * @summary Epg Entry List
     * @request GET:/epg/v1/epg-entries
     * @secure
     */
    epgEntryListEpgV1EpgEntriesGet: (
      query?: {
        /**
         * Page
         * Page number
         * @min 1
         * @default 1
         */
        page?: number;
        /**
         * Size
         * Page size
         * @min 1
         * @max 100
         * @default 50
         */
        size?: number;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<PageEpgEntrySchema, void | HTTPValidationError>({
        path: `/epg/v1/epg-entries`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags epg-entries
     * @name EpgEntryDetailEpgV1EpgEntriesEpgEntryIdGet
     * @summary Epg Entry Detail
     * @request GET:/epg/v1/epg-entries/{epg_entry_id}
     * @secure
     */
    epgEntryDetailEpgV1EpgEntriesEpgEntryIdGet: (epgEntryId: string, params: RequestParams = {}) =>
      this.http.request<EpgEntrySchema, void | HTTPValidationError>({
        path: `/epg/v1/epg-entries/${epgEntryId}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags epg-entries
     * @name NextEpgEntryDetailEpgV1EpgEntriesEpgEntryIdNextGet
     * @summary Next Epg Entry Detail
     * @request GET:/epg/v1/epg-entries/{epg_entry_id}/next
     * @secure
     */
    nextEpgEntryDetailEpgV1EpgEntriesEpgEntryIdNextGet: (epgEntryId: string, params: RequestParams = {}) =>
      this.http.request<EpgEntrySchema, void | HTTPValidationError>({
        path: `/epg/v1/epg-entries/${epgEntryId}/next`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags epg-entries
     * @name PreviousEpgEntryDetailEpgV1EpgEntriesEpgEntryIdPreviousGet
     * @summary Previous Epg Entry Detail
     * @request GET:/epg/v1/epg-entries/{epg_entry_id}/previous
     * @secure
     */
    previousEpgEntryDetailEpgV1EpgEntriesEpgEntryIdPreviousGet: (epgEntryId: string, params: RequestParams = {}) =>
      this.http.request<EpgEntrySchema, void | HTTPValidationError>({
        path: `/epg/v1/epg-entries/${epgEntryId}/previous`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags assets
     * @name GetEpgEntryByAssetEpgV1AssetsAssetIdAiringsGet
     * @summary Get Epg Entry By Asset
     * @request GET:/epg/v1/assets/{asset_id}/airings
     * @secure
     */
    getEpgEntryByAssetEpgV1AssetsAssetIdAiringsGet: (
      assetId: any,
      query?: {
        /**
         * Page
         * Page number
         * @min 1
         * @default 1
         */
        page?: number;
        /**
         * Size
         * Page size
         * @min 1
         * @max 100
         * @default 50
         */
        size?: number;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<PageEpgEntrySchema, void | HTTPValidationError>({
        path: `/epg/v1/assets/${assetId}/airings`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description You can pass either a series asset id or a series season asset id. If you try to pass an asset id of another type, then the response will be empty.
     *
     * @tags assets
     * @name GetSeriesAssetEpisodesEpgV1AssetsAssetIdEpisodesGet
     * @summary Get Series Asset Episodes
     * @request GET:/epg/v1/assets/{asset_id}/episodes
     * @secure
     */
    getSeriesAssetEpisodesEpgV1AssetsAssetIdEpisodesGet: (
      assetId: string,
      query?: {
        /**
         * Start
         * @format date-time
         */
        start?: string;
        /**
         * End
         * @format date-time
         */
        end?: string;
        /**
         * Page
         * Page number
         * @min 1
         * @default 1
         */
        page?: number;
        /**
         * Size
         * Page size
         * @min 1
         * @max 100
         * @default 50
         */
        size?: number;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<PageEpgEntrySchema, void | HTTPValidationError>({
        path: `/epg/v1/assets/${assetId}/episodes`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags channels
     * @name EpgEntryByChannelEpgV1ChannelsChannelIdEpgEntriesGet
     * @summary Epg Entry By Channel
     * @request GET:/epg/v1/channels/{channel_id}/epg-entries
     * @secure
     */
    epgEntryByChannelEpgV1ChannelsChannelIdEpgEntriesGet: (
      channelId: string,
      query?: {
        /**
         * Start
         * @format date-time
         */
        start?: string;
        /**
         * End
         * @format date-time
         */
        end?: string;
        /**
         * Page
         * Page number
         * @min 1
         * @default 1
         */
        page?: number;
        /**
         * Size
         * Page size
         * @min 1
         * @max 100
         * @default 50
         */
        size?: number;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<PageEpgEntrySchema, void | HTTPValidationError>({
        path: `/epg/v1/channels/${channelId}/epg-entries`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name HealthEpgV1HealthGet
     * @summary Health
     * @request GET:/epg/v1/health
     */
    healthEpgV1HealthGet: (params: RequestParams = {}) =>
      this.http.request<any, any>({
        path: `/epg/v1/health`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name RootEpgV1Get
     * @summary Root
     * @request GET:/epg/v1
     */
    rootEpgV1Get: (params: RequestParams = {}) =>
      this.http.request<any, any>({
        path: `/epg/v1`,
        method: "GET",
        format: "json",
        ...params,
      }),
  };
}
