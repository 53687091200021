import { type Nullable } from "@sunrise/utils";

export function semanticVersionToNumber(version: Nullable<string>): number {
  if (!version) {
    return 0;
  }

  const items = version
    .split(".")
    .map((v) => Number(v))
    .map((v) => (isNaN(v) ? 0 : v))
    .reverse();

  return items.reduce((acc: number, item, index) => {
    return acc + item * Math.pow(10, 3 * index);
  }, 0);
}
