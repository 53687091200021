import type { MemoryLevelConfig } from "@/modules/memory/types";

export function determineMemoryLevel(
  percentageUsed: number,
  levels: Record<string, MemoryLevelConfig>,
): [string, MemoryLevelConfig] | null {
  const sorted = Object.entries(levels).sort((a, b) => a[1].value - b[1].value);

  return (
    sorted.find(([_, config], i) => {
      const nextConfig = sorted[i + 1]?.[1];
      return (
        percentageUsed >= config.value &&
        (!nextConfig || percentageUsed < nextConfig.value)
      );
    }) ?? null
  );
}
