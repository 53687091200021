import type {
  AssetId,
  ChannelId,
  EPGEntryId,
} from "@sunrise/backend-types-core";
import { type GuideChannel, type GuideProgram } from "@sunrise/yallo-guide";
import { addMinutes } from "date-fns";

/**
 * @returns a function that will generate a program for the given index. When null is returned it means there will be no more programs.
 */
type ProgramCreatorFunction = (
  index: number,
  lastProgram: GuideProgram | null,
) => GuideProgram | null;

type ChannelConfigExact = {
  exactDuration: number;
};

type ChannelConfigRange = {
  minimumDurationInMinutes: number;
  averageDurationInMinutes: number;
};

type ChannelConfigProgramCreator = {
  programCreator: ProgramCreatorFunction;
};

type ChannelConfig = {
  name?: string;
} & (ChannelConfigExact | ChannelConfigRange | ChannelConfigProgramCreator);

/**
 * Will output channels and for every channel its programs.
 * Dummy code, please do not pay attention to this.
 */
export function generateDummyData({
  channelCount = 10,
  getChannelConfigForIndex,
  startTime,
  endTime,
}: {
  channelCount: number;
  startTime: Date;
  endTime: Date;
  getChannelConfigForIndex?: (index: number) => ChannelConfig | undefined;
} & Omit<ChannelConfig, "name">): GuideChannel[] {
  return new Array(channelCount).fill(null).map((_, channelIndex) => {
    const items: GuideProgram[] = [];
    let lastItem: GuideProgram | null = null;
    let programIndex = 0;

    const channelConfig = getChannelConfigForIndex?.(channelIndex) ?? {
      minimumDurationInMinutes: 20,
      averageDurationInMinutes: 60,
    };

    do {
      if ("programCreator" in channelConfig) {
        const newLastItem = channelConfig.programCreator(
          programIndex,
          lastItem,
        );
        lastItem = newLastItem;
      } else {
        const itemStartTime: Date = lastItem ? lastItem.endTime : startTime;

        const durationInMinutes = getDurationInMinutes(channelConfig);

        lastItem = {
          id: programIndex.toString() as EPGEntryId,
          assetId: `a-${programIndex}` as AssetId,
          title: `Program ${programIndex}`,
          plot: `Plot ${programIndex}`,
          startTime: itemStartTime,
          endTime: addMinutes(itemStartTime, durationInMinutes),
          durationInMinutes,
          image: null,
          subtitle: null,
        };
      }

      programIndex++;
      if (lastItem) {
        items.push(lastItem);
      }
    } while (lastItem && lastItem.endTime < endTime);

    return {
      id: (channelIndex + 1).toString() as ChannelId,
      name: channelConfig.name ?? `Channel ${channelIndex}`,
      channelNumber: channelIndex + 1,
      items,
      logo: null,
    };
  });
}

function getDurationInMinutes(
  channelConfig: ChannelConfigExact | ChannelConfigRange,
): number {
  if ("exactDuration" in channelConfig) {
    return channelConfig.exactDuration;
  } else {
    return (
      channelConfig.minimumDurationInMinutes +
      Math.floor(
        Math.random() *
          (channelConfig.averageDurationInMinutes -
            channelConfig.minimumDurationInMinutes),
      )
    );
  }
}
