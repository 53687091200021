import { atom, type Atom } from "jotai";

/**
 * This allows you to configure the atom to control when we should detach or not.
 * By default this logic is disabled since it's only something we would need for Tizen.
 */
export const playerShouldDetachConfigAtom = atom<Atom<boolean> | null>(null);

/**
 * The concept of detaching means that the player should unload itself and not attempt to do anything with the player.
 * For Tizen this is necessary since Tizen can only have a single video element at a time. Therefore SDK ads will cannibalize the video element.
 * As soon as Tizen loads, the player should be detached. When Tizen is done, the player should re-attach.
 */
export const playerShouldDetachAtom = atom((get) => {
  const configAtom = get(playerShouldDetachConfigAtom);
  if (!configAtom) {
    return false;
  }

  return !!get(configAtom);
});
