import { format } from "date-fns";

import type { EPGEntry } from "@sunrise/backend-types";
import type { Language } from "@sunrise/backend-types-core";
import { getLocalizedValue } from "@sunrise/i18n";
import { type Nullable } from "@sunrise/utils";

export function epgEntryToStartAndTitle(
  epgEntry: Nullable<EPGEntry>,
  language: Language,
): { start: string; title: string } {
  if (!epgEntry)
    return {
      start: "",
      title: "",
    };

  return {
    start: format(new Date(epgEntry.actual_start).getTime(), "HH:mm"),
    title: getLocalizedValue(epgEntry.asset.title, language) ?? "",
  };
}
