export function isArrowLeftKey(direction: string): direction is "left" {
  return direction === "left";
}

export function isArrowRightKey(direction: string): direction is "right" {
  return direction === "right";
}

export function isArrowUpKey(direction: string): direction is "up" {
  return direction === "up";
}

export function isArrowDownKey(direction: string): direction is "down" {
  return direction === "down";
}

export function isHorizontal(direction: string): direction is "left" | "right" {
  return isArrowLeftKey(direction) || isArrowRightKey(direction);
}

export function isVertical(direction: string): direction is "up" | "down" {
  return isArrowUpKey(direction) || isArrowDownKey(direction);
}
