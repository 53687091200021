import type { Nullable } from "@sunrise/utils";
import { atom } from "jotai";

import { playerCurrentStreamAtom } from "../player.atom";
import {
  Thumbnail,
  THUMBNAIL_POSITION_PADDING,
  ThumbnailGenerator,
} from "./thumbnail-generator.atom";
import type { Stream } from "@sunrise/backend-types";
import { nowAtom } from "@sunrise/time";

// ms - If the requested thumbnail is too close to the live edge, we need to add a padding to make sure the thumbnail is not blank.
const THUMBNAIL_LIVE_EDGE = 1_000;

export const dashManualThumbnailGeneratorAtom = atom<
  Promise<ThumbnailGenerator | null>
>(async (get) => {
  const stream = get(playerCurrentStreamAtom);

  if (!stream) {
    return null;
  }

  return createDashManualThumbnailGenerator(stream, () => get(nowAtom));
});

/**
 * This can accurately return thumbnails even for:
 * - live streams.
 * - part of the loaded replay stream.
 * - recorded streams.
 *
 * @param stream https://developer.samsung.com/smarttv/develop/specifications/web-engine-specifications.html
 * @param playRequest
 * @param getNowDate
 * @returns
 */
async function createDashManualThumbnailGenerator(
  stream: Nullable<Stream>,
  getNowDate: () => Date,
): Promise<ThumbnailGenerator | null> {
  if (!stream || (stream.type !== "dash" && stream.type !== "dash_widevine")) {
    return null;
  }

  try {
    const config = await parseManifestConfig(stream.url);

    if (!config) {
      return null;
    }

    const tileWidth = config.width / config.tilesX;
    const tileHeight = config.height / config.tilesY;
    const totalTiles = config.tilesX * config.tilesY;

    return {
      name: "dash-manual",
      generate: async (offsetPosition: number): Promise<Thumbnail | null> => {
        if (offsetPosition < 0) {
          // TODO: theoretically we could load the live manifest and then calculate the thumbnail from there.
          return null;
        }

        let position = offsetPosition * 1_000;

        // NOTE: Make sure the thumbnail at LIVE position will not be blank sometimes, else add position padding
        if (getNowDate().getTime() - position < THUMBNAIL_LIVE_EDGE) {
          position -= THUMBNAIL_LIVE_EDGE;
        } else {
          position += THUMBNAIL_POSITION_PADDING * 1_000;
        }

        const matrixPosition = position - (position % config.duration);

        const computedUrl = config.url.replace(
          "$Time$",
          matrixPosition.toString(),
        );

        const tileDuration = config.duration / totalTiles;
        const tilePosition = Math.floor(
          (position - matrixPosition) / tileDuration,
        );

        let tileX = 0;
        let tileY = 0;

        if (totalTiles > 1) {
          tileX = (tilePosition % config.tilesX) * tileWidth;
          tileY = Math.floor(tilePosition / config.tilesX) * tileHeight;
        }
        return {
          url: computedUrl,
          fullHeight: config.height,
          fullWidth: config.width,
          width: tileWidth,
          height: tileHeight,
          x: tileX,
          y: tileY,
        };
      },
    };
  } catch {
    return null;
  }
}

async function parseManifestConfig(manifestUrl: string) {
  try {
    const fullManifest = await (await fetch(manifestUrl)).text();
    const result =
      /<AdaptationSet.*contentType="image".*>((.|\n)+?)<\/AdaptationSet>/.exec(
        fullManifest,
      );
    const manifest = result?.[1] ?? "";
    const segmentTimeline = parseSegmentTimeline(manifest);
    if (!segmentTimeline) {
      return null;
    }

    const representation = parseRepresentation(manifest);
    if (!representation) {
      return null;
    }

    const uri = getUri(manifest);

    const baseManifestUrl = manifestUrl.substr(0, manifestUrl.lastIndexOf("/"));

    return {
      url: `${baseManifestUrl}/${uri}`
        .replace("$Bandwidth$", representation.bandwidth.toString())
        .replace("$RepresentationID$", representation.id),
      duration: segmentTimeline.duration,
      endTime: segmentTimeline.endTime,
      width: representation.width,
      height: representation.height,
      tilesX: representation.tilesX,
      tilesY: representation.tilesY,
    };
  } catch {
    return null;
  }
}

function parseSegmentTimeline(adaptationContent: string): {
  duration: number;
  endTime: number;
} {
  const segmentTimelineContent =
    /<SegmentTimeline.*>((.|\n)+?)<\/SegmentTimeline>/.exec(
      adaptationContent,
    )?.[1] ?? "";
  const duration = /d="(.*?)"/.exec(segmentTimelineContent)?.[1];
  const consecutive = /r="(.*?)"/.exec(segmentTimelineContent)?.[1];

  if (!duration || !consecutive) {
    // Greenstreams does not have consecutive or duration, so we need to return a default value.
    // Since Greenstreams doesn't need this info to get the thumbnail there is no harm to return 1. In the end it will
    // fallback to the position
    return {
      duration: 1,
      endTime: 1,
    };
  }

  return {
    duration: parseInt(duration),
    endTime: parseInt(duration) * parseInt(consecutive),
  };
}

function parseRepresentation(adaptationContent: string): {
  id: string;
  bandwidth: number;
  width: number;
  height: number;
  tilesX: number;
  tilesY: number;
} | null {
  const bandwidthMatch = /<Representation.*bandwidth="([^"]*)".*?>/.exec(
    adaptationContent,
  );
  const widthMatch = /<Representation.*width="([^"]*)".*?>/.exec(
    adaptationContent,
  );
  const heightMatch = /<Representation.*height="([^"]*)".*?>/.exec(
    adaptationContent,
  );
  const tileLayoutMatch = /<EssentialProperty.*value="(\d+x\d+)".*?>/.exec(
    adaptationContent,
  );
  const idMatch = /<Representation.*id="([^"]*)".*?>/.exec(adaptationContent);

  if (
    !idMatch?.[1] ||
    !bandwidthMatch?.[1] ||
    !widthMatch?.[1] ||
    !heightMatch?.[1] ||
    !tileLayoutMatch?.[1]
  ) {
    return null;
  }

  const bandwidth = parseInt(bandwidthMatch[1]);
  const width = parseInt(widthMatch[1]);
  const height = parseInt(heightMatch[1]);
  const tileLayout = tileLayoutMatch[1];
  const id = idMatch[1];

  const [tilesX, tilesY] = tileLayout.split("x").map((tile) => parseInt(tile));
  if (!tilesX || !tilesY) {
    return null;
  }

  return {
    id,
    bandwidth,
    width,
    height,
    tilesX,
    tilesY,
  };
}

function getUri(adaptationContent: string): string | null {
  const result = /<SegmentTemplate.*media="(.*?)".*?>/.exec(adaptationContent);

  const mediaUri = result && result[1];

  if (!mediaUri) {
    return null;
  }

  return mediaUri;
}
