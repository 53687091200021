import { atom } from "jotai";
import { atomWithLocation } from "jotai-location";

type Location = {
  pathname?: string;
  searchParams?: URLSearchParams;
};

const _locationAtom = atomWithLocation({
  // NOTE: Needs to be false in order for back support to work properly.
  //       The location atom is still updated correctly when we trigger navigation through the history API.
  //       Don't think we need the reducer wrapper around the _locationAtom.
  //       atomWithLocation handles that for us.
  replace: false,
});

type Action = { type: "location/navigate"; payload: { pathname: string } };

export const locationAtom = atom(
  (get) => {
    return get(_locationAtom);
  },
  (_get, set, action: Action) => {
    set(_locationAtom, (ps) => locationAtomReducer(ps, action));
  },
);

export function locationAtomReducer(ps: Location, action: Action): Location {
  return { ...ps, ...action.payload };
}

export function actionLocationNavigate(pathname: string): Action {
  return {
    type: "location/navigate",
    payload: {
      pathname,
    },
  };
}
