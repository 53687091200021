import { atom } from "jotai";
import { atomFamily } from "jotai/utils";

import type { EPGEntryId } from "@sunrise/backend-types-core";
import { isNil } from "@sunrise/utils";

import { recordingByRecordingIdAtom } from "./recording-by-recording-id.atom";
import { recordingStatusByEpgIdAtom } from "./recording-status-by-epg-id.atom";

/**
 * Returns the full recording data for a specific EPGEntryId.
 *
 * It's directly pulling the recording data for the backend.
 * So it does not require us to load all the recording data for every page to find the correct recording details.
 *
 * It does so by first looking up if there is a recording id for the passed epgId through the recording-status endpoint.
 * Then when a recordingId is found we will ask the details of that specific recordingId to the backend.
 */
export const recordingByEpgIdAtom = atomFamily((epgId: EPGEntryId) => {
  const innerAtom = atom(async (get) => {
    // First translate EPGEntryId to recordingId.
    // NOTE: If there can be multiple recordings for the same epgId, this for now just returns the first one.
    const status = await get(recordingStatusByEpgIdAtom(epgId));
    if (isNil(status) || !status?.id) {
      return null;
    }

    return get(recordingByRecordingIdAtom(status.id));
  });
  innerAtom.debugLabel = `recordingByEpgIdAtom(${epgId})`;
  return innerAtom;
});
