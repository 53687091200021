import {
  endpoints,
  type RecommendationsGetResponse,
} from "@sunrise/backend-types";
import type { Language } from "@sunrise/backend-types-core";
import { hostsAtom, publicApi } from "@sunrise/http-client";
import { currentLanguageAtom } from "@sunrise/i18n";
import { isNil } from "@sunrise/utils";
import { atomWithSuspenseQuery } from "jotai-tanstack-query";

const ONE_MINUTE = 60 * 1000;

/**
 * This is a basic type for the backend response.
 * With this we can figure out what kind of rows we receive and what type of items are on said row.
 * The goal is that we only return the stuff we have typed in our local types.
 */
type BackendRecommendations = {
  result: BackendRecommendationRow[];
};

type BackendRecommendationRow = {
  kind: string;
  items: BackendRecommendationsItem[];
} & Record<string, unknown>;

type BackendRecommendationsItem = {
  type: string;
} & Record<string, unknown>;

export async function fetchRecommendations(
  host: string,
  language: Language,
): Promise<RecommendationsGetResponse> {
  const { data } = await publicApi.get<BackendRecommendations>(
    endpoints.language(host, language),
  );

  // Filter out all types that are not known so we definitely do not lie to the type system.
  const rows = data.result.reduce<BackendRecommendationRow[]>((acc, item) => {
    switch (item.kind as RecommendationsGetResponse["result"][number]["kind"]) {
      case "channel":
      case "large":
      case "normal": {
        // Filter out all the possible types of items.
        const items = item.items.filter((rowItem) => {
          return rowItem.type === "epg" || rowItem.type === "channel";
        });

        if (items.length === 0) return acc;

        acc.push({ ...item, items });
      }
    }

    return acc;
  }, []);

  return {
    ...data,
    result: rows as RecommendationsGetResponse["result"],
  };
}

/**
 * NOTE: This query will automatically filter out unsupported row kinds and unsupported item types. If you are missing something, please make sure to add it in the query fn so it is not filtered out.
 */
export const recommendationsQueryResultAtom = atomWithSuspenseQuery<
  RecommendationsGetResponse,
  unknown,
  RecommendationsGetResponse,
  RecommendationsGetResponse,
  ["recommendations", Language]
>((get) => {
  const language = get(currentLanguageAtom);

  const host = get(hostsAtom).data;
  if (isNil(host)) throw new Error("Host is not set");

  return {
    suspense: true,
    queryKey: ["recommendations", language],
    queryFn: async () => {
      if (isNil(language)) return { result: [] };

      return fetchRecommendations(host, language);
    },
    refetchInterval: ONE_MINUTE,
    cacheTime: ONE_MINUTE,
    // We should not refetch it on mount because that leads to a lot of unneeded requests. We should just update it every minute since the file is only updated every minute anyway.
    refetchOnMount: false,
    // Do not remove old data while we are refetching. The old data is also relevant still and we do not want to have the UI flash to no content.
    keepPreviousData: true,
  };
});

recommendationsQueryResultAtom.debugLabel = "recommendationsQueryResultAtom";
