// TODO: Move to component specific styles
import { baseGap } from "@/styles/theme.css";

export const globalMenuSize = {
  width: 168,
};

export const searchProgramBoxSize = {
  normal: {
    height: 450,
    width: 416,
    inlinePadding: 3 * baseGap,
    image: {
      width: 368,
      height: 208,
    },
  },
};

export const programBoxSize = {
  normal: {
    height: 432,
    width: 416,
    inlinePadding: 3 * baseGap,
    image: {
      width: 368,
      height: 208,
    },
  },
  large: {
    height: 664,
    width: 752,
    inlinePadding: 3 * baseGap,
    image: {
      width: 752,
      height: 424,
    },
  },
  channel: {
    width: 400,
    height: 320,
    image: {
      width: 360,
      height: 160,
    },
  },
  row: {
    width: 1400,
    height: 208,
    image: {
      width: 368,
      height: 208,
    },
    textWidth: 1400 - 368,
  },
  recordings: {
    height: 500,
    width: 416,
    inlinePadding: 3 * baseGap,
    image: {
      width: 368,
      height: 208,
    },
  },
};

export const recommendationRowSize = {
  normal: {
    height: 600,
  },
  large: {
    height: 840,
  },
  channel: {
    height: 360,
  },
};

export const button = {
  default: {
    height: 80,
  },
};

export const channelList = {
  channels: {
    width: 700,
  },
  channelGroups: {
    width: 440,
  },
  separator: {
    width: 100,
  },
};

export const infoBox = {
  width: 630,
};
