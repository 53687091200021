import clsx from "clsx";
import { type ReactNode, Ref, forwardRef } from "react";

import * as styles from "./dialog.css";

type DialogProps = CommonProps & {
  children: ReactNode;
  open: boolean;
  "data-dialog-type"?: string;
};

export const Dialog = forwardRef<HTMLDivElement, DialogProps>(function Dialog(
  {
    children,
    className,
    open,
    "data-testid": dataTestId = "dialog",
    "data-dialog-type": dataDialogType,
  }: DialogProps,
  ref: Ref<HTMLDivElement>,
): ReactNode {
  return (
    <div
      data-testid={dataTestId}
      data-dialog-type={dataDialogType}
      className={clsx([
        className,
        open ? styles.open : styles.closed,
        styles.dialogWindow,
      ])}
      ref={ref}
    >
      {children}
    </div>
  );
});
