import type { ChannelId, RecordingId } from "@sunrise/backend-types-core";

type ReasonMissingChannelInfo = {
  type: "missing-channel-info";
  channelId: ChannelId;
};

type ReasonMissingStreamUrl = {
  type: "missing-stream-url";
  channelId: ChannelId;
};

type ReasonMissingRecordingId = {
  type: "recording_id_missing";
  recordingId: RecordingId;
};

type ReasonMissingRecordingStreamUrl = {
  type: "recording_missing_stream_url";
  recordingId: RecordingId;
};

type Reason =
  | ReasonMissingChannelInfo
  | ReasonMissingStreamUrl
  | ReasonMissingRecordingId
  | ReasonMissingRecordingStreamUrl;

export class StreamNotFoundError extends Error {
  constructor(public readonly reason: Reason) {
    super(`StreamNotFoundError: ${reason.type}`);
  }
}
