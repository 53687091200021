import { atom } from "jotai";

import { playerCurrentContentEpgAtom } from "./player-current-content-epg.atom";
import { playerCurrentContentRecordingAtom } from "./player-current-content-recordings.atom";
import { selectPlayerCurrentPlayRequest } from "./player.atom";
import { PlayerCurrentContent } from "./types";

const EMPTY: Readonly<PlayerCurrentContent> = {
  epgId: null,
  channelId: null,
  channelName: null,
  recordingId: null,
  title: null,
  subtitle: null,
  channelLogo: null,
  schedule: null,
};

/**
 * Atom to display what is currently playing in the player.
 * Will select data based on the PlayRequest type.
 *
 * I will only look at what is really playing out now and not look at what is seeked to.
 */
export const playerCurrentContentAtom = atom<Promise<PlayerCurrentContent>>(
  async (get) => {
    const playRequest = get(selectPlayerCurrentPlayRequest);

    if (!playRequest) {
      return EMPTY;
    }

    if (playRequest.type === "recording") {
      return get(playerCurrentContentRecordingAtom);
    } else {
      return get(playerCurrentContentEpgAtom);
    }
  },
);
