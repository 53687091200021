import { pageContentAtom } from "@sunrise/analytics";
import { locationAtom } from "@sunrise/location";
import { atomEffect } from "jotai-effect";

import { locationToPageContent } from "./utils/location-to-page-content";

export const setPageContentEffect = atomEffect((get, set) => {
  const location = get(locationAtom);
  set(pageContentAtom, locationToPageContent(location));
});
