import { FeatureResult } from "@growthbook/growthbook-react";
import areEqual from "fast-deep-equal";
import { type Atom, atom, type Getter, type WritableAtom } from "jotai";
import { atomEffect } from "jotai-effect";

import { hasLocalStorage } from "@sunrise/utils";

import { LOCAL_STORAGE_PREFIX } from "./constants";
import { growthbookAtom } from "./growthbook.atom";
import { parseEnvValueAsFeatureValue } from "./helpers/parse-env-value-as-feature-value";

type PossibleFeatureFlagValue = unknown;

type FlagAtom<T = PossibleFeatureFlagValue> = WritableAtom<
  T,
  [newValue: T | typeof RESET_FLAG | typeof CYCLE_FLAG],
  void
>;

type FlagConfig<T = PossibleFeatureFlagValue> = {
  value: T;
  /**
   * It is overridden when the value saved locally differs from the value it is supposed to have.
   * Let's say there is no growthbook. Then the value should be the initialValue. If it does not, it is overridden.
   * When growthbook is connected, the value should be the growthbook value. If it does not, it is overridden.
   */
  isOverridden: boolean;
  isControlledByGrowthbook: false | string;
  growthbookValue?: T | null;
  initialValue: T;
  /**
   * The value we are supposed to use for the feature by default, according to the code.
   */
  initialValueFromArgs: T | null;
  /**
   * The value we are supposed to use according to the ENV variable configuration.
   */
  envValue: T | null;
  /**
   * The name of the env variable should you want to override the flag at build time.
   */
  envName: string;
  cycleValues: T[] | null;
  description?: string;
};

type EmptyableValue<T = unknown> = T | typeof EMPTY_FLAG;

type WritableEmptyableAtom<T = unknown> = WritableAtom<
  EmptyableValue<T>,
  [newValue: EmptyableValue<T>],
  void
>;

export type FlagDefinition<T = PossibleFeatureFlagValue> = {
  name: string;
  flagValueAtom: FlagAtom<T>;
  configAtom: Atom<FlagConfig<T>>;
};

export const RESET_FLAG = Symbol("reset-flag");

export const EMPTY_FLAG = Symbol("empty-flag");

export const CYCLE_FLAG = Symbol("cycle-flag");

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const KNOWN_FLAGS: FlagDefinition<any>[] = [];

/**
 * I know this is ultra hacky. But it is the easiest way to let the app know which flags are available.
 * Without running all of them.
 *
 * I could make a family and see if I can somehow list all items in an atom family. (not possible by design)
 */
export const getKnownFlags = (): typeof KNOWN_FLAGS => {
  return KNOWN_FLAGS;
};

/**
 * This is an atom that can be used to create feature flags.
 *
 * You can write to it to change the current value of the atom.
 *
 * Write the RESET_FLAG or CYCLE_FLAG to reset or cycle the values.
 * Write any other supported value for the flag to override the value to that.
 *
 * The values are persisted in localStorage and will override the growthbook value.
 *
 * Some flags are not controlled by growthbook and they will have an indicator for that.
 *
 * When we have an env value with `VITE_FF_<flagname>`, it will override the initial value.
 */
export function featureAtom<T = PossibleFeatureFlagValue>(
  name: string,
  initialValueFromArgs: T,
  options?: {
    values?: T[];
    description?: string;
  },
) {
  const flagName = LOCAL_STORAGE_PREFIX + name;

  const envName = `VITE_FF_${name.replace(/-/g, "_").toUpperCase()}`;
  const envValue = parseEnvValueAsFeatureValue(process.env[envName]);
  const initialValue = envValue ?? initialValueFromArgs;

  const localRawValue = hasLocalStorage()
    ? localStorage.getItem(flagName)
    : null;

  const currentValueLocalStorage =
    typeof localRawValue === "string"
      ? JSON.parse(localRawValue) ?? null
      : null;

  /**
   * We set the internal value immediately to the localStorage value if we have any.
   * Else it is EMPTY_FLAG. So we can delegate to growthbook.
   */
  const internalAtom: WritableEmptyableAtom<T> = atom(
    localRawValue !== null ? currentValueLocalStorage : EMPTY_FLAG,
  );

  const localStorageAtom: WritableEmptyableAtom<T> = atom(
    currentValueLocalStorage ?? EMPTY_FLAG,
  );
  localStorageAtom.debugLabel = `localStorageAtom(${name})`;
  localStorageAtom.debugPrivate = true;

  const localStorageSyncEffect = atomEffect((get, set) => {
    const internal = get(internalAtom);
    const value = getValue<T>(
      get,
      initialValue,
      () => EMPTY_FLAG,
      featureAtomGrowthBook,
    );

    // TODO: Should not check w/ initialValue
    if (areEqual(internal, value) || internal === EMPTY_FLAG) {
      localStorage.removeItem(flagName);
      set(localStorageAtom, EMPTY_FLAG);
    } else {
      localStorage.setItem(flagName, JSON.stringify(internal));
      set(localStorageAtom, internal);
    }
  });

  const featureAtomGrowthBook = atom<FeatureResult<T | null> | null>((get) => {
    const growthbook = get(growthbookAtom);
    // Make sure to return null until we are "ready", aka we have loaded our flags.
    if (!growthbook || !growthbook.instance.ready) {
      return null;
    }

    return growthbook?.instance.evalFeature(name) ?? null;
  });
  featureAtomGrowthBook.debugLabel = `featureAtomGrowthBook(${name})`;
  featureAtomGrowthBook.debugPrivate = true;

  const configAtom = atom<FlagConfig<T>>((get) => {
    const gb = get(featureAtomGrowthBook);
    const ls = get(localStorageAtom);
    const internal = get(internalAtom);
    const value = getValue(
      get,
      initialValue,
      () => get(internalAtom),
      featureAtomGrowthBook,
    );

    const isControlledByGrowthbook =
      (gb?.source !== "unknownFeature" && gb?.source) ?? false;

    return {
      gb,
      ls,
      internal,
      initialValue,
      envValue,
      envName,
      initialValueFromArgs,
      cycleValues,
      value,
      growthbookValue: gb?.value ?? undefined,
      description: options?.description,
      isOverridden: !areEqual(
        value,
        isControlledByGrowthbook ? gb?.value : initialValue,
      ),
      isControlledByGrowthbook,
    };
  });

  const cycleValues = options?.values?.length
    ? options?.values
    : typeof initialValue === "boolean"
    ? [true as T, false as T]
    : null;

  const flagValueAtom = atom(
    (get) => {
      if (hasLocalStorage()) {
        get(localStorageSyncEffect);
      }

      return getValue(
        get,
        initialValue,
        () => get(internalAtom),
        featureAtomGrowthBook,
      );
    },
    (get, set, newValue: T | typeof RESET_FLAG | typeof CYCLE_FLAG) => {
      if (newValue === RESET_FLAG) {
        set(internalAtom, EMPTY_FLAG);
        return;
      }

      if (newValue === CYCLE_FLAG) {
        if (!cycleValues) {
          // When we want to cycle but we can't, don't bother.
          return;
        }

        const currentValue = get(flagValueAtom);
        const currentIndex = cycleValues.indexOf(currentValue);
        const nextIndex = (currentIndex + 1) % cycleValues.length;
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const nextValue = cycleValues[nextIndex]!;
        set(internalAtom, nextValue);

        return;
      } else {
        set(internalAtom, newValue);
      }
    },
  );

  flagValueAtom.debugLabel = `featureAtom(${name})`;

  KNOWN_FLAGS.push({
    name,
    configAtom,
    flagValueAtom,
  });

  return flagValueAtom;
}

function getValue<T>(
  get: Getter,
  initialValue: T,
  getInternalValue: () => T | typeof EMPTY_FLAG,
  growthbookAtom: Atom<FeatureResult<T | null> | null>,
): T {
  const internal = getInternalValue();
  if (internal !== EMPTY_FLAG) {
    return internal;
  }

  const gb = get(growthbookAtom);

  return gb?.value ?? initialValue;
}
