import { atomWithSuspenseQuery } from "jotai-tanstack-query";
import { DeviceInfo } from "webostvjs";

import type { Nullable } from "@sunrise/utils";

import { getWebOSDeviceInfo } from "@/modules/webos/system.service";

import { queryKeys } from "./query-keys";

export const webOSDeviceInfoAtom = atomWithSuspenseQuery<Nullable<DeviceInfo>>(
  () => {
    return {
      queryKey: queryKeys.deviceInfo,
      queryFn: async () => {
        try {
          return await getWebOSDeviceInfo();
        } catch {
          return null;
        }
      },
      cacheTime: Infinity,
      staleTime: Infinity,
      keepPreviousData: true,
    };
  },
);
webOSDeviceInfoAtom.debugLabel = "webOSProductInfoAtom";
