import { atom } from "jotai";

/**
 * Describes what to do when the app gets killed and how to kill the app.
 * If this is null it means the app can not be killed through our UI or automatically by us.
 * The user has to kill it manually.
 */
export const doKillAppAtom = atom<{
  perform: (instant?: boolean) => Promise<void>;
} | null>(null);
