import { featureAtom } from "@sunrise/feature-flags";

export const backgroundBehaviourAtom = featureAtom<
  "suspend-player" | "kill-app" | null
>("background-behaviour", null, {
  values: ["suspend-player", "kill-app", null],
  description: `The behaviour of the app when it goes into the background.
"suspend-player" suspends the player when the app goes into the background.
"kill-app" kills the app when it goes into the background.
If null, the app will continue to play in the background. On tizen-web we automatically override it with null so we do not constantly interrupt the player when changing tabs.`,
});
