import type {
  InfiniteData,
  InfiniteQueryObserverResult,
} from "@tanstack/react-query";
import { atom, type Atom } from "jotai";
import type { PageWithItems } from "./types";

/**
 * A helper atom that receives an infinite query-style atom which will then continue to load all the pages as necessary.
 * We should only use this in cases where the UI will never be paging through the data itself.
 */
export const fullyPagedAtom = function <
  TInner extends Atom<
    InfiniteQueryObserverResult<
      InfiniteData<PageWithItems<unknown>, unknown>,
      unknown
    >
  >,
>(
  innerAtom: TInner,
): Atom<
  Promise<
    TInner extends Atom<
      InfiniteQueryObserverResult<
        InfiniteData<PageWithItems<infer TItem>, unknown>,
        unknown
      >
    >
      ? TItem[]
      : never
  >
> {
  return atom(async (get) => {
    const data = get(innerAtom);

    if (data.hasNextPage && !data.isFetchingNextPage) {
      data.fetchNextPage();
    }

    return (data.data?.pages.flatMap((page) => page.items) ??
      []) as TInner extends Atom<
      InfiniteQueryObserverResult<
        InfiniteData<PageWithItems<infer TItem>, unknown>,
        unknown
      >
    >
      ? TItem[]
      : never;
  });
};
