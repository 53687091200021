import { nowAtom } from "@sunrise/time";
import {
  isFullyRecorded,
  preferRecordingsAtom,
  recordingByEpgIdAtom,
} from "@sunrise/yallo-recordings";
import { isWithinReplayWindow } from "@sunrise/yallo-replay";
import { isBefore } from "date-fns";
import { minutesToSeconds } from "date-fns/minutesToSeconds";
import { useAtomValue, useSetAtom } from "jotai";
import { useCallback, useMemo } from "react";

import {
  actionPlayerManagerPlayRecording,
  actionPlayerManagerPlayReplay,
  playerManagerAtom,
} from "../player-manager.atom";
import { getPlayerManager } from "../player-manager.init";
import { useResumeProgram } from "./use-resume-program";
import type { EPGEntry } from "@sunrise/backend-types";
import type { ReplayPlayRequest } from "@sunrise/yallo-player-types";

/**
 * This interface accepts the details of an EPGEntry.
 *
 * This is done because we have endpoints like recommendations or recordings
 * where we receive all the necessary data to determine the outcome of this hook.
 *
 * TODO: Extract something (either a hook or an atom) to determine if we will play out the recording or replay.
 *       Since we need the same logic for the CW / FW items. Because we need to know if we should look at the EPG or recording CW items.
 */
export function usePlayProgram({ epg }: { epg: EPGEntry }): {
  play: () => void;
  /**
   * Indicates if the program should be replayable if the user would have replay permissions.
   * When the user does not have permissions but the program is in the past and in the replay window, this should still be true.
   * This is because we want to trigger an upsell when a user without permissions attempts to play.
   */
  canPlay: boolean;
  canResume: boolean;
  resume: () => void;
} {
  const now = useAtomValue(nowAtom);
  const dispatchPlayerManager = useSetAtom(playerManagerAtom);

  const recordingData = useAtomValue(recordingByEpgIdAtom(epg.id))?.data;

  const { start, end } = useMemo(
    () => ({
      start: new Date(epg.actual_start),
      end: new Date(epg.actual_end),
    }),
    [epg],
  );
  const isRecordingPlayable =
    isFullyRecorded(recordingData) && recordingData?.type === "recording";

  const canPlay = useMemo(() => {
    return (
      isRecordingPlayable ||
      (isBefore(start, now) &&
        isWithinReplayWindow(start, now).isInReplayWindow)
    );
  }, [start, now, end, isRecordingPlayable]);

  const preferRecordings = useAtomValue(preferRecordingsAtom);

  const play = useCallback(async () => {
    if (!canPlay) {
      return;
    }

    const playerManager = getPlayerManager();

    const replayRequest: ReplayPlayRequest = {
      type: "replay",
      epgId: epg.id,
      channelId: epg.channel.id,
    };

    const canPlayReplay = await playerManager.canPlay(replayRequest, true);

    // If we have a fully recorded recording and it's url and we can't play replay (outside of replay window), then play recording, otherwise play replay.
    if (
      isRecordingPlayable &&
      recordingData.id &&
      (!canPlayReplay || preferRecordings)
    ) {
      dispatchPlayerManager(
        actionPlayerManagerPlayRecording(
          recordingData.id,
          epg.channel.id,
          recordingData.padding_start_minutes
            ? minutesToSeconds(recordingData.padding_start_minutes)
            : undefined,
        ),
      );
    } else {
      dispatchPlayerManager(
        actionPlayerManagerPlayReplay(epg.id, epg.channel.id, start, true),
      );
    }
  }, [
    canPlay,
    epg.id,
    epg.channel.id,
    recordingData?.id,
    dispatchPlayerManager,
    start,
    preferRecordings,
    isRecordingPlayable,
  ]);

  const { canResume, resume } = useResumeProgram({
    epgId: epg.id,
    channelId: epg.channel.id,
  });

  return useMemo(
    () => ({
      play,
      canPlay,
      canResume,
      resume,
    }),
    [play, canPlay, canResume, resume],
  );
}
