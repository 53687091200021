import { canSwallowError } from "@sunrise/error";
import { hostsAtom, httpClientAtom } from "@sunrise/http-client";
import { selectIsLoggedIn, selectJwtUserToken } from "@sunrise/jwt";
import { atomWithSuspenseQuery } from "jotai-tanstack-query";
import { isNil } from "lodash";

import { recordingsStaleTimeAtom } from "../recordings-stale-time.atom";
import { fetchRecordingStatuses } from "../recordings.service";
import { recordingStatsQueryAtom } from "./recording-stats-query.atom";
import { queryKeys, RecordingStatus } from "@sunrise/backend-types";

/**
 * This is an atom that returns the RecordingStatus for all recordings.
 * There should be no paging on this. It should always be complete.
 */
export const recordingStatusQueryAtom = atomWithSuspenseQuery<
  RecordingStatus[],
  unknown,
  RecordingStatus[],
  RecordingStatus[],
  ReturnType<typeof queryKeys.recordingsStatus>
>((get) => {
  const host = get(hostsAtom).api;
  if (isNil(host)) throw new Error("Host is not set");
  const { privateApi } = get(httpClientAtom);
  if (!privateApi) throw new Error("missing privateApi");

  const isLoggedIn = get(selectIsLoggedIn);

  return {
    // NOTE: We still depend on the access token since we want the data to reload when the user's token changes.
    queryKey: queryKeys.recordingsStatus(get(selectJwtUserToken)),
    queryFn: async () => {
      try {
        if (!isLoggedIn) {
          return [];
        }

        // We dynamically change the pagesize based on the amount of recordings the user can have.
        const stats = await get(recordingStatsQueryAtom);
        return (
          await fetchRecordingStatuses(
            host,
            privateApi,
            stats.data?.capacity_recordings,
          )
        ).result;
      } catch (e) {
        // We should only pretend there are no recording status when there's no known mechanism to handle this specific error.
        if (!canSwallowError(e)) throw e;
        return [];
      }
    },
    staleTime: get(recordingsStaleTimeAtom),
    keepPreviousData: true,
    suspense: true,
  };
});

recordingStatusQueryAtom.debugLabel = "recordingStatusQueryAtom";
