import { atom } from "jotai";
import { atomFamily } from "jotai/utils";
import { isNil } from "lodash";

import type { ChannelId } from "@sunrise/backend-types-core";
import { currentLanguageAtom } from "@sunrise/i18n";
import { getLiveProgress, nowAtom } from "@sunrise/time";
import { type Nullable } from "@sunrise/utils";
import {
  currentLiveEPGEntryAtom,
  nextLiveEPGEntryAtom,
} from "@sunrise/yallo-epg";

import { epgEntryToStartAndTitle } from "./epg-to-start-title";

/**
 * This is an atom designed specifically to show the channel item where
 * we are displaying the current & next program as well as a progress bar.
 */
export const simplifiedChannelInfoAtom = atomFamily(
  (channelId: Nullable<ChannelId>) => {
    const innerAtom = atom(async (get) => {
      if (isNil(channelId)) {
        return null;
      }
      const [current] = (await get(currentLiveEPGEntryAtom(channelId))) ?? [];
      const next = await get(nextLiveEPGEntryAtom(channelId));

      const progress = current
        ? getLiveProgress(
            new Date(current.actual_start),
            new Date(current.actual_end),
            get(nowAtom),
          )
        : 0;

      const language = get(currentLanguageAtom);

      return {
        currentEpg: epgEntryToStartAndTitle(current ?? null, language),
        nextEpg: epgEntryToStartAndTitle(next ?? null, language),
        progress,
      };
    });
    innerAtom.debugLabel = `simplifiedChannelInfoAtom(${channelId})`;
    return innerAtom;
  },
);
