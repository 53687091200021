import { setFocus } from "@noriginmedia/norigin-spatial-navigation";
import { adControlsHaveFocusAtom, videoAdsUIAtom } from "@sunrise/ads";
import { useTranslator } from "@sunrise/translator";
import { useDebounceEffect } from "@sunrise/utils";
import clsx from "clsx";
import { useAtomValue } from "jotai";
import { useAtomCallback } from "jotai/utils";
import { ReactNode, useCallback, useEffect } from "react";
import adsGradient from "static/gradients/ads-gradient.webp";

import { WhyAdsButton } from "@/features/ads/why-ads-button";
import { canPullFocusOnAdControlAtom } from "@/modules/ads/can-pull-focus-on-ad-control.atom";
import { typography } from "@/styles/typography.css";
import { FocusContainer } from "@/utils/focus-container";

import * as styles from "./ads.css";
import { localWidgetFocusKey } from "./focus-keys";
import { SkipButton } from "./skip-button";

type AdsProps = CommonProps & {
  skipAd: () => void;
  onBlur?: () => void;
};

export function Ads({
  skipAd,
  onBlur,
  "data-testid": dataTestId = "ads",
  className,
}: AdsProps): ReactNode {
  const t = useTranslator();

  const ui = useAtomValue(videoAdsUIAtom);

  const hasButtons = !!(ui && (ui.why?.visible ?? ui.skip?.visible));

  const adsHaveFocus = useAtomValue(adControlsHaveFocusAtom);

  const setFocused = useAtomCallback(
    useCallback((_, set, value: "self" | "child" | false) => {
      set(adControlsHaveFocusAtom, value === "child");
    }, []),
  );

  useEffect(() => {
    return () => setFocused(false);
  }, [setFocused]);

  /**
   * Only auto-focus the skip button if it is visible and the ad control can pull focus.
   * We do this with a tiny debounce because we want to make sure the navigation library has a chance to re-activate certain focus keys before we re-evaluate.
   * Since when we already have focus, we should not push the focus to the skip button.
   */
  const canPullFocus = useAtomValue(canPullFocusOnAdControlAtom);
  useDebounceEffect(
    function showSkipButton() {
      if (!ui?.skip?.visible || !canPullFocus || adsHaveFocus) return;

      setFocus(localWidgetFocusKey.skipButton);
    },
    [ui?.skip?.visible, canPullFocus, adsHaveFocus],
    10,
  );

  if (!ui) {
    return null;
  }

  if (!ui.counter && !ui.message) {
    return null;
  }

  const counter = ui.counter ? (
    <span data-testid={`${dataTestId}.counter`}>
      {t(ui.counter.key, ui.counter.args)}
    </span>
  ) : null;

  const isSingleButtonVisible = !ui.why?.visible || !ui.skip.visible;

  // They may not be visible but skip should still be rendered even if it is just available.
  const buttons =
    hasButtons || ui.skip?.available ? (
      <FocusContainer
        focusKey={localWidgetFocusKey.ads}
        onLeft={onBlur}
        onRight={onBlur}
        onTop={onBlur}
        onBottom={onBlur}
        onFocusChanged={setFocused}
      >
        {(o) => {
          // When we have just one button visible, any arrow press causes a blur.
          // When 2 buttons are visible, why handles the left edge and skip handles the right edge.
          // This would then allow navigation between the 2.
          const defaultArrowPressHandler = isSingleButtonVisible
            ? o.onArrowPress("down", "left", "right", "up")
            : null;

          return (
            <div
              className={styles.buttons}
              data-testid={`${dataTestId}.buttons`}
            >
              {ui.why?.visible ? (
                <WhyAdsButton
                  onArrowPress={
                    defaultArrowPressHandler ??
                    o.onArrowPress("left", "up", "down")
                  }
                />
              ) : null}
              {ui.skip?.available ? (
                <SkipButton
                  visible={ui.skip.visible}
                  skipAvailableAfter={ui.skip.countdownInSeconds ?? 0}
                  onSkip={skipAd}
                  onArrowPress={
                    defaultArrowPressHandler ??
                    o.onArrowPress("right", "up", "down")
                  }
                  className={styles.skipButton}
                  data-testid={`${dataTestId}.skip-button`}
                />
              ) : null}
            </div>
          );
        }}
      </FocusContainer>
    ) : null;

  return (
    <div
      className={clsx([styles.root, typography.h7.regular, className])}
      style={{
        backgroundImage: `url(${adsGradient})`,
        backgroundRepeat: "repeat",
        backgroundSize: "contain",
        backgroundPositionX: "left",
      }}
      data-testid={dataTestId}
    >
      <span className={styles.top} data-testid={`${dataTestId}.title`}>
        {ui.message ? t(ui.message) : null}
      </span>
      <div className={styles.bottom}>
        <div className={styles.counter}>{counter}</div>
        {buttons}
      </div>
    </div>
  );
}
