import { BaseError, type BaseErrorOptions } from "@sunrise/error";

/**
 * Generic error for whenever the user is supposedly logged out.
 */
export class LoggedOutError extends BaseError {
  constructor(message: string, private readonly _options?: BaseErrorOptions) {
    super("LoggedOut", message, _options);
  }

  /**
   * Error will be handled by logging out the user.
   */
  override get canSwallow(): boolean {
    return true;
  }

  override get errorCode(): string {
    return this._options?.errorCode ?? this.message;
  }

  /**
   * We can assume it's correct what the backend tells us and we do not need to retry.
   */
  override get shouldRetry(): boolean {
    return false;
  }
}
