import clsx from "clsx";
import { useAtomValue } from "jotai";

import { currentLanguageAtom } from "@sunrise/i18n";

import { showFirstExperienceTVPageAtom } from "@/modules/first-experience/show-first-experience-tv-page.atom";

import { hidden, image, visible } from "./first-experience-tv.css";
import de from "./images/tv-de.png";
import en from "./images/tv-en.png";
import fr from "./images/tv-fr.png";
import it from "./images/tv-it.png";

export function FirstExperienceTv() {
  const shouldShow = useAtomValue(showFirstExperienceTVPageAtom);
  const lang = useAtomValue(currentLanguageAtom);

  if (shouldShow === null) {
    return null;
  }

  const png = getImage(lang);

  return (
    <img
      data-testid="first-experience-tv"
      src={png}
      className={clsx(image, shouldShow ? visible : hidden)}
      alt="How to use the TV page. Left to open menu. Enter to open the player controls. Right to open the channel list."
    />
  );
}

function getImage(lang: string) {
  switch (lang) {
    case "en":
      return en;
    case "fr":
      return fr;
    case "it":
      return it;
    case "de":
      return de;
    default:
      return en;
  }
}
