import { type ReactNode } from "react";
import clsx from "clsx";
import { useAtom, useAtomValue } from "jotai";

import {
  CYCLE_FLAG,
  type FlagDefinition,
  getKnownFlags,
  growthbookAtom,
  RESET_FLAG,
} from "@sunrise/feature-flags";

import { FocusButton } from "@/components";
import { Icon } from "@/components/icon";
import { routeParam } from "@/config/route";
import { useMenu } from "@/features/menu/use-menu";
import { useRoutes } from "@/features/routing/use-routes";
import { useKeyboardNavigation } from "@/modules/keyboard-navigation";
import { FocusContainer } from "@/utils/focus-container";

import {
  attribute,
  container,
  description,
  featureFlagButton,
  iconGreen,
  iconRed,
  list,
  listItem,
  root,
  title,
  value,
} from "./developer-controls.css";

export function DeveloperFeatureFlags(): ReactNode {
  useMenu({ hidden: true });
  const routes = useRoutes();

  const features = getKnownFlags().sort((a, b) => a.name.localeCompare(b.name));

  return (
    <div className={root}>
      <div className={title}>Feature Flags</div>
      <GrowthbookStatus />
      <FocusContainer
        focusKey={"ff"}
        onBack={routes.back}
        className={container}
        shouldFocus
      >
        {() => (
          <div className={list}>
            {features.map((atomItem) => (
              <div key={atomItem.name} className={listItem}>
                <FeatureFlagButton item={atomItem} />
              </div>
            ))}
          </div>
        )}
      </FocusContainer>
    </div>
  );
}

function GrowthbookStatus(): ReactNode {
  const gb = useAtomValue(growthbookAtom);

  if (!gb) {
    return (
      <div className={description}>
        <div className={attribute}>Growthbook integration disabled.</div>
      </div>
    );
  }

  const isReady = gb.instance.ready;
  const attributes = gb.instance.getAttributes();

  return (
    <div className={description}>
      <div className={attribute}>
        Growthbook:{" "}
        {isReady ? (
          <Icon name="done" color="white" className={iconGreen} />
        ) : (
          <Icon name="close" color="white" className={iconRed} />
        )}{" "}
        <span className="attribute">
          <b>{JSON.stringify(attributes)}</b>
        </span>
      </div>
    </div>
  );
}

function FeatureFlagButton({ item }: { item: FlagDefinition }): ReactNode {
  const routes = useRoutes();
  const config = useAtomValue(item.configAtom);

  return (
    <FocusButton
      className={clsx(
        config.isOverridden ? "overridden" : undefined,
        featureFlagButton,
      )}
      iconLeft={config.isOverridden ? "edit" : undefined}
      text={`${item.name}: ${toString(config.value)}`}
      focusKey={`ff.${item.name}`}
      onEnterPress={() => {
        routes.featureFlag.id(item.name);
      }}
    />
  );
}

type DetailsPageProps = {
  params: typeof routeParam.featureFlag;
};
export function DeveloperFeatureFlagDetails({
  params: { id },
}: DetailsPageProps): ReactNode {
  const routes = useRoutes();

  useMenu({ hidden: true });
  useKeyboardNavigation({
    onBack: routes.back,
    isEnabled: true,
  });

  const item = getKnownFlags().find((v) => v.name === id);
  const isFound = !!item;

  if (!isFound) {
    return (
      <div className={root}>
        <div className={description}>
          Not sure how this is possible but the flag was once known and now it
          is no longer.
        </div>
      </div>
    );
  }

  return <FoundFlag item={item} />;
}

function FoundFlag({ item }: { item: FlagDefinition }): ReactNode {
  const config = useAtomValue(item.configAtom);
  const [value, setValue] = useAtom(item.flagValueAtom);
  const id = item.name;

  /**
   * We need:
   * - to know which system is controlling the flag
   * - to know if the flag is overridden
   */

  return (
    <FocusContainer focusKey={"ff-details"} shouldFocus className={root}>
      {() => (
        <div className={root}>
          <div className={description}>
            <div className={attribute}>
              id: <Value>{id}</Value>
            </div>
            <div className={attribute}>
              value: <Value>{toString(value)}</Value>
            </div>
            <FocusButton
              text="Cycle Values"
              focusKey="cycle"
              disabled={!config.cycleValues}
              className={featureFlagButton}
              onEnterPress={() => {
                setValue(CYCLE_FLAG);
              }}
            />
            <FocusButton
              text="Reset"
              focusKey="reset"
              disabled={!config.isOverridden}
              className={featureFlagButton}
              onEnterPress={() => {
                setValue(RESET_FLAG);
              }}
            />
            <div className={attribute}>{config.description}</div>
            <div className={attribute}>
              <div>
                initial value: <Value>{toString(config.initialValue)}</Value>
              </div>
              <div>
                initial value according to the code:{" "}
                <Value>{toString(config.initialValueFromArgs)}</Value>
              </div>
              <div>
                env override value: <Value>{toString(config.envValue)}</Value>
              </div>
              <div>
                env override name: <Value>{toString(config.envName)}</Value>
              </div>
            </div>
            <div className={attribute}>
              connected with growthbook:{" "}
              {config.isControlledByGrowthbook ? GREEN_ARROW : RED_CROSS}{" "}
              <Value>{config.isControlledByGrowthbook}</Value>
            </div>
            {config.isControlledByGrowthbook ? (
              <div className={attribute}>
                growthbook value:{" "}
                <Value>{toString(config.growthbookValue)}</Value>
              </div>
            ) : null}
          </div>
        </div>
      )}
    </FocusContainer>
  );
}

function Value({ children }: { children: ReactNode }): ReactNode {
  return <span className={value}>{children}</span>;
}

function toString(v: unknown): string {
  if (typeof v === "object") {
    return JSON.stringify(v);
  }

  return String(v);
}

const GREEN_ARROW = <Icon name="done" color="white" className={iconGreen} />;
const RED_CROSS = <Icon name="close" color="white" className={iconRed} />;
