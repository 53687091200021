import { atom } from "jotai";

import { recordingStatsAtom } from "./recording-stats.atom";

export const isAtMaxRecordingCapacityAtom = atom(async (get) => {
  const stats = await get(recordingStatsAtom);
  return (stats?.capacity_recordings ?? 0) > 0
    ? stats?.available_recordings === 0
    : false;
});
