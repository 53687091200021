import { useTranslator } from "@sunrise/translator";
import type { ReactNode } from "react";

import {
  channelCreationInfo,
  channelGroupContainer,
  channelGroupSeparator,
} from "../channel-selector.css";
import { ChannelGroups } from "./channel-groups";

export function ChannelGroupsContainer(): ReactNode {
  const t = useTranslator();

  return (
    <div className={channelGroupContainer}>
      <ChannelGroups />
      <div className={channelGroupSeparator} />
      <div className={channelCreationInfo}>
        {t("channel_list_creation_other_clients")}
      </div>
    </div>
  );
}
