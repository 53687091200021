import { useCallback, useEffect, type ReactNode } from "react";
import { FocusButton } from "@/components";
import type { ActionDialogAction } from "@sunrise/dialogs";
import * as styles from "./side-bar-actions-dialog.css";
import { useTranslatable } from "@sunrise/translator";
import { useIdle } from "@sunrise/utils";

export type SideBarActionsButtonProps = ActionDialogAction & {
  doClose: () => void;
  index: number;
};

export function SideBarActionsButton({
  action,
  label,
  index,
  closeDisabled,
  execAfterTimeout,
  doClose,
}: SideBarActionsButtonProps): ReactNode {
  const t = useTranslatable();
  const shouldExec = useIdle(execAfterTimeout);
  const performAction = useCallback(() => {
    void action();

    if (!closeDisabled) {
      doClose();
    }
  }, [doClose, closeDisabled, action]);

  useEffect(() => {
    if (shouldExec) {
      performAction();
    }
  }, [shouldExec, performAction]);

  return (
    <FocusButton
      className={styles.button}
      text={t(label) ?? `button ${index}`}
      focusKey={`side-bar-actions-dialog.button.${index}`}
      onEnterPress={performAction}
    />
  );
}
