import { atom } from "jotai";

import { menuAtom } from "@/modules/menu/menu.store";

/**
 * It should be open, visible and expanded.
 * But the isVisible should not be checked.
 */
export const isMenuActiveAtom = atom((get) => {
  const menu = get(menuAtom);
  return menu.isExpanded;
});
