import {
  backgroundBehaviourAtom,
  selectProcessIsBackgrounded,
} from "@sunrise/process-visibility";
import { atomEffect } from "jotai-effect";

import { doKillAppAtom } from "./do-kill-app.atom";

/**
 * When desired, we kill the app as soon as we background the app.
 * So that next time around, we get a fresh start.
 */
export const killOnBackgroundEffect = atomEffect((get) => {
  const doKill = get(doKillAppAtom);
  if (!doKill) {
    return;
  }
  const allowKill = get(backgroundBehaviourAtom) === "kill-app";
  if (!allowKill) {
    return;
  }

  const isBackgrounded = get(selectProcessIsBackgrounded);
  if (!isBackgrounded) {
    return;
  }

  doKill.perform(true);
});
