import { atomWithReducer } from "jotai/utils";

export type ScrollableDialogAtomState = {
  isOpen: boolean;
  title?: string;
  text: string;
  lastFocusKey: string;
  id: string;
};

export function makeScrollableDialogAtomDefaultState(
  state?: Partial<ScrollableDialogAtomState>,
): ScrollableDialogAtomState {
  return {
    isOpen: state?.isOpen ?? false,
    title: state?.title ?? "",
    text: state?.text ?? "",
    lastFocusKey: state?.lastFocusKey ?? "",
    id: state?.id ?? "",
  };
}

export const initialStateScrollableDialog =
  makeScrollableDialogAtomDefaultState();

type ActionOpen = {
  type: "dialog/open";
  payload: Omit<ScrollableDialogAtomState, "isOpen">;
};
type ActionClose = {
  type: "dialog/close";
};
type DialogAction = ActionOpen | ActionClose;

export const scrollableDialogAtom = atomWithReducer<
  ScrollableDialogAtomState,
  DialogAction
>(makeScrollableDialogAtomDefaultState(), scrollabledialogAtomReducer);

export function scrollabledialogAtomReducer(
  previousState: ScrollableDialogAtomState,
  action: DialogAction,
): ScrollableDialogAtomState {
  switch (action.type) {
    case "dialog/open": {
      return {
        ...previousState,
        ...action.payload,
        isOpen: true,
      };
    }
    case "dialog/close": {
      return initialStateScrollableDialog;
    }
  }
}

/*
 *
 * ACTIONS
 *
 */

export function actionScrollableDialogOpen(
  payload: ActionOpen["payload"],
): ActionOpen {
  return {
    type: "dialog/open",
    payload,
  };
}

export function actionScrollableDialogClose(): ActionClose {
  return {
    type: "dialog/close",
  };
}
