import { type CSSProperties, forwardRef, type ReactNode } from "react";

import type { EPGSearchEntry, ProgramResultItem } from "@sunrise/backend-types";

import { ListEpgItem } from "@/components";

export type EpgResultProps = {
  epg: EPGSearchEntry;
  focused: boolean;
  style?: CSSProperties;
  "data-position"?: number;
};

export const EpgResult = forwardRef<HTMLDivElement, EpgResultProps>(
  function EpgResult(
    { epg, focused, style, "data-position": dataPosition },
    ref,
  ): ReactNode {
    const item: ProgramResultItem = {
      type: "epg",
      details: epg as unknown as ProgramResultItem["details"], // TODO
    };

    return (
      <ListEpgItem
        ref={ref}
        data-position={dataPosition}
        style={style}
        item={item}
        kind="normal"
        focused={focused}
        key={item.details.id}
      />
    );
  },
);
