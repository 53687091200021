import { ReactElement, ReactNode } from "react";
import clsx from "clsx";

import { useTranslator } from "@sunrise/translator";
import { type Nullable } from "@sunrise/utils";

import { typography } from "@/styles/typography.css";

import * as styles from "./box-text.css";

type ProgramTitleProps = CommonProps & {
  title: string;
  active?: boolean;
  maxLines?: number;
};

type ProgramSubtitleProps = CommonProps & {
  subtitle: string;
  maxLines?: number;
};

type ProgramTimeProps = CommonProps & {
  airTime: Nullable<string>;
  start: Nullable<string>;
  end: Nullable<string>;
  spaceBetween?: boolean;
  durationInMinutes?: number;
};

type ProgramDescriptionProps = CommonProps & {
  description: string;
};

type RecordingDeleteWarningProps = CommonProps & {
  expiresIn: Nullable<number>;
};

export function ProgramTitle({
  title,
  active,
  className,
  maxLines,
  "data-testid": dataTestId = "program-title",
}: ProgramTitleProps): ReactElement {
  return (
    <p
      style={{ "--max-lines": maxLines }}
      className={clsx([
        styles.title,
        active && styles.activeTitle,
        typography.h6.bold,
        className,
      ])}
      data-testid={dataTestId}
    >
      {title}
    </p>
  );
}

export function ProgramSubtitle({
  subtitle,
  maxLines,
  "data-testid": dataTestId = "program-subtitle",
  className,
}: ProgramSubtitleProps): ReactElement {
  return (
    <p
      style={{ "--max-lines": maxLines }}
      className={clsx([styles.subtitle, typography.h7.regular, className])}
      data-testid={dataTestId}
    >
      {subtitle}
    </p>
  );
}

export function ProgramTime({
  airTime,
  start,
  end,
  spaceBetween,
  durationInMinutes,
  "data-testid": dataTestId = "program-time",
  className,
}: ProgramTimeProps): ReactElement {
  const t = useTranslator();

  if (!start || !end)
    return (
      <p
        className={clsx([styles.emptyTime])}
        data-testid={`${dataTestId}.airTime`}
      />
    );

  return (
    <p
      className={clsx([
        styles.time,
        spaceBetween && styles.timeSpaceBetweem,
        typography.h7.regular,
        className,
      ])}
      data-testid={`${dataTestId}.airTime`}
    >
      <span>{airTime}</span>
      {!spaceBetween && ", "}
      <span>
        <span data-testid={`${dataTestId}.time.start`}>{start}</span>
        {" - "}
        <span data-testid={`${dataTestId}.time.end`}>{end}</span>
      </span>
      {durationInMinutes && (
        <span>
          {" "}
          (
          <span data-testid={`${dataTestId}.duration`}>
            {durationInMinutes}
          </span>{" "}
          {t("minutes_short")})
        </span>
      )}
    </p>
  );
}

export function ProgramDescription({
  description,
  "data-testid": dataTestId = "program-description",
  className,
}: ProgramDescriptionProps): ReactElement {
  return (
    <p
      className={clsx([styles.description, typography.h7.regular, className])}
      data-testid={dataTestId}
    >
      {description}
    </p>
  );
}

export function RecordingDeleteWarning({
  expiresIn,
  "data-testid": dataTestId = "delete/warning",
  className,
}: RecordingDeleteWarningProps): ReactNode {
  const t = useTranslator();
  if (!expiresIn) return null;

  return (
    <p
      className={clsx([styles.deleteWarning, typography.h7.regular, className])}
      data-testid={dataTestId}
    >
      {expiresIn > 1
        ? t("recordings_deleted_days", [expiresIn])
        : t("recordings_deleted_day", [expiresIn])}
    </p>
  );
}
