import { type ReactNode } from "react";
import clsx from "clsx";
import { format } from "date-fns";
import { useAtomValue } from "jotai";

import { nowSecondAtom } from "@sunrise/time";

import * as styles from "./clock.css";

export function Clock({
  className,
  "data-testid": dataTestId = "clock",
  style,
}: CommonProps): ReactNode {
  const now = useAtomValue(nowSecondAtom);

  return (
    <time
      style={style}
      className={clsx([styles.root, className])}
      data-testid={dataTestId}
    >
      {format(now, "EEE dd MMM HH:mm")}
    </time>
  );
}
