import { setContext } from "@sentry/react";
import { jwtAtom } from "@sunrise/jwt";
import { atomEffect } from "jotai-effect";
import md5 from "md5";

/**
 * Ensure we md5-hash the tokens we send to sentry.
 */
export const sentrySyncTokensEffect = atomEffect((get) => {
  const { accessToken, refreshToken } = get(jwtAtom);

  const tokens: Partial<{ refresh: string; access: string }> = {};
  if (accessToken) {
    tokens.access = md5(accessToken);
  }
  if (refreshToken) {
    tokens.refresh = md5(refreshToken);
  }
  setContext("tokens", Object.keys(tokens).length ? tokens : null);
});
