import clsx from "clsx";
import { ReactNode } from "react";

import { typography } from "@/styles/typography.css";

import * as styles from "./info-box.css";

export type InfoBoxProps = {
  children: ReactNode;
} & CommonProps;

export function InfoBox({
  children,
  "data-testid": dataTestId,
}: InfoBoxProps): ReactNode {
  return (
    <div
      className={clsx(typography.h7.regular, styles.background)}
      data-testid={dataTestId}
    >
      {children}
    </div>
  );
}
