import clsx from "clsx";
import { ReactNode } from "react";

import { ICON_SIZE_30 } from "./icon.config";
import * as styles from "./icon.css";

export type IconColor = "white" | "yellow" | "dark";

export type IconProps = CommonProps & {
  name:
    | "add"
    | "close"
    | "directory"
    | "done"
    | "dropdown-down"
    | "dropdown-left"
    | "dropdown-right"
    | "dropdown-up"
    | "edit"
    | "expand-channel-list"
    | "guide"
    | "home"
    | "info"
    | "live"
    | "lock"
    | "more"
    | "next"
    | "next-program"
    | "next30"
    | "pause"
    | "play"
    | "play-circle"
    | "previous"
    | "previous-program"
    | "previous15"
    | "radio-off"
    | "radio-on"
    | "record"
    | "recorded"
    | "recording"
    | "recordings"
    | "remove"
    | "resume"
    | "search"
    | "search-small"
    | "select"
    | "settings"
    | "sort"
    | "subtitles-and-audio"
    | "tv"
    | "up"
    | "voice"
    | "warning"
    | "watch-live";

  color: IconColor;
  className?: string;
  size?: number;
};

export function Icon(props: IconProps): ReactNode {
  return (
    <div
      data-testid={props["data-testid"]}
      className={clsx([
        styles.ICON_CLASS,
        props.name,
        props.color,
        props.className,
      ])}
      style={{
        width: props.size ?? ICON_SIZE_30,
        height: props.size ?? ICON_SIZE_30,
        ...props.style,
      }}
    />
  );
}
