import { currentLanguageAtom } from "@sunrise/i18n";
import { selectAccessToken } from "@sunrise/jwt";
import { appVersionAtom } from "@sunrise/yallo-settings";
import { atom } from "jotai";
import { atomEffect } from "jotai-effect";

import { deviceIdAtom } from "./device-id.atom";
import { deviceVersionAtom } from "./device-version.atom";
import { socketAuthenticatedAtom } from "./socket-authenticated.atom";
import { socketConnectedAtom } from "./socket-connected.atom";
import { keepAliveIntervalInMsAtom } from "./socket-keep-alive-in-ms.atom";
import { socketUrlAtom } from "./socket-url.atom";
import { userAgentAtom } from "./user-agent.atom";
import { WebsocketService } from "./websocket.service";
import { Nullable } from "@sunrise/utils";

const socketInternalAtom = atom<Nullable<WebsocketService>>(null);
socketInternalAtom.debugPrivate = true;

export const socketAtom = atom<Nullable<WebsocketService>>((get) => {
  get(manageSocketEffect);
  get(handleLoggedInEffect);
  get(updateLanguageEffect);

  return get(socketInternalAtom);
});

const manageSocketEffect = atomEffect((get, set) => {
  const url = get(socketUrlAtom);

  if (!url) {
    return;
  }

  const keepaliveIntervalInMs = get(keepAliveIntervalInMsAtom);

  const onAuthenticationChange = (authenticated: boolean) => {
    set(socketAuthenticatedAtom, authenticated);
  };
  const onConnectionChange = (connected: boolean) => {
    set(socketConnectedAtom, connected);
  };

  const socket = new WebsocketService({
    url,
    keepaliveIntervalInMs,
    onAuthenticationChange,
    onConnectionChange,
  });

  set(socketInternalAtom, socket);

  return () => {
    socket.disconnect();
    set(socketInternalAtom, null);
  };
});

const NULL_ATOM = atom(null);

const handleLoggedInEffect = atomEffect((get) => {
  const socket = get(socketAtom);

  if (!socket) {
    return;
  }

  const accessToken = get(selectAccessToken);

  if (!accessToken) {
    socket.disconnect();
    return;
  }

  Promise.all([
    get(appVersionAtom),
    get(userAgentAtom),
    get(deviceVersionAtom),
    get(get(deviceIdAtom) ?? NULL_ATOM),
  ]).then(([clientVersion, userAgent, deviceVersion, deviceId]) => {
    socket.authenticate({
      token: accessToken,
      deviceId: deviceId ?? undefined,
      userAgent,
      deviceVersion: deviceVersion ?? undefined,
      clientVersion: clientVersion ?? undefined,
    });
  });

  return () => {
    socket.disconnect();
  };
});

const updateLanguageEffect = atomEffect((get) => {
  const language = get(currentLanguageAtom);
  const ws = get(socketAtom);

  if (!ws) {
    return;
  }

  ws.setLanguage(language);
});
