import {
  type PlayerAtomState,
  type PlayerCurrentContent,
} from "@sunrise/player";
import { Nullable } from "@sunrise/utils";
import {
  PlayerStateMessage,
  PlayerStateMessageField,
} from "@sunrise/yallo-websocket";
import throttle from "lodash/throttle";

import {
  LANG_ATTRIBUTES,
  SOURCE_ATTRIBUTES,
  hasMeaningfulChange,
} from "./player-state-helper";
import { PlayerAnalyticsState, PlayerStateField } from "./player-state-message";
import type { ChannelId } from "@sunrise/backend-types-core";
import type { MappedChannel } from "@sunrise/yallo-channel-group";

const THROTTLE_MS = 1 * 60 * 1000;
const REMOVE_KEYS_ON_NOT_STOP: PlayerStateField[] = [
  "player_position",
  "current_recording_progress_in_seconds",
  "buffer_interruption_count",
  "buffer_interruption_time",
];
const NEVER_REMOVE_KEYS: PlayerStateField[] = ["player_state"];

/**
 * A refactoring to work through effects would be super nice I think.
 */
export class PlayerAnalyticsService {
  private currentState!: PlayerAnalyticsState;
  private lastLoggedState: Nullable<PlayerAnalyticsState>;
  private playerStarted = false;

  private bufferCountBeforeContentChange = 0;
  private bufferTimeInMsBeforeContentChange = 0;

  constructor(
    private wsLogPlayerState: (message: PlayerStateMessage) => void,
    private getPlayerCurrentContent: () => Promise<PlayerCurrentContent>,
    private getPlayerState: () => PlayerAtomState,
    private getChannelById: (
      channelId: ChannelId,
    ) => Promise<Nullable<Omit<MappedChannel, "locked" | "order" | "logo">>>,
    private getPlayerCurrentDateTime: () => Nullable<Date>,
  ) {
    this.resetState();
  }

  public onPlayerStateUpdate(playerState: PlayerAtomState) {
    const state = playerState.state;
    const shouldSendStop = playerState.shouldReportAsStopped;
    const isPaused = state === "paused";
    const isPlaying = state === "playing";

    if (shouldSendStop) {
      this.playerStarted = false;
      void this.emitPossibleContentChanged({
        newState: { player_state: "stop" },
      });
    } else if (isPaused && this.currentState.player_state !== "pause") {
      if (!this.playerStarted) return;

      void this.emitPossibleContentChanged({
        newState: { player_state: "pause" },
      });
    } else if (isPlaying && this.currentState.player_state !== "play") {
      void this.emitPossibleContentChanged({
        newState: { player_state: "play" },
      });
    } else if (
      this.playerStarted &&
      !isPlaying &&
      !isPaused &&
      this.currentState.player_state !== "stop"
    ) {
      void this.emitPossibleContentChanged({
        newState: { player_state: "stop" },
      });
    } else if (isPlaying) {
      // just progress
      void this.emitPossibleContentChanged({
        newState: { player_state: "play" },
      });
    } else if (state === "idle" || state === "stopped") {
      this.playerStarted = false;
      this.resetState();
    }
  }

  public onVisibilityChange(visible: boolean) {
    // If app goes to background/app switch happens, we assume that the player gets stopped and handle it as instant stop
    if (!visible) {
      this.logAnalyticInstantStop();
    }
  }

  private async updatePlayerCurrentState() {
    const content = await this.getPlayerCurrentContent();

    const player = this.getPlayerState();
    const request = player.playRequest;
    const requestType = request?.type;

    // We need to update the buffer interruption count and time whenever the player state updates.
    // If we do it right before we send it will have already been reset.
    this.currentState.buffer_interruption_count =
      player.bufferInterruptions - this.bufferCountBeforeContentChange;
    this.currentState.buffer_interruption_time =
      player.bufferInterruptionTimeInMs -
      this.bufferTimeInMsBeforeContentChange;

    const channel = content.channelId
      ? await this.getChannelById(content.channelId)
      : undefined;

    const isLive = requestType === "live";
    const isReplay = requestType === "replay";
    const isRecording = requestType === "recording";

    if (isReplay) {
      this.currentState.channel_id = content.channelId;
      this.currentState.epg_entry_id = content.epgId;
      this.currentState.recording_id = null;
      this.currentState.player_content_type = "replay";
      this.currentState.current_recording_progress_in_seconds = null;

      // REPLAY is since beginning of EPG entry
      this.currentState.player_position =
        this.getPlayerCurrentDateTime()?.toISOString();
      this.currentState.current_recording_progress_in_seconds = undefined;
    } else if (isRecording) {
      this.currentState.channel_id = content.channelId;
      this.currentState.epg_entry_id = content.epgId;
      this.currentState.recording_id = content.recordingId;
      this.currentState.player_content_type = "recording";
      this.currentState.player_position = undefined;

      this.currentState.current_recording_progress_in_seconds = Math.floor(
        player.currentTime ?? 0,
      );
    } else if (isLive) {
      this.currentState.channel_id = content.channelId;
      this.currentState.epg_entry_id = content.epgId;
      this.currentState.recording_id = null;
      this.currentState.player_content_type = "live";
      this.currentState.current_recording_progress_in_seconds = null;

      // LIVE is since epoch in seconds
      this.currentState.player_position =
        this.getPlayerCurrentDateTime()?.toISOString();
    }

    // TODO: Write a test that ensures we prefer the stream's provider above the channel's stream provider
    this.currentState.player_content_provider =
      player.stream?.provider ?? channel?.providerName;
  }

  private sendAnalyticsThrottle = throttle(
    this.sendAnalyticsInstant.bind(this),
    THROTTLE_MS,
    {
      trailing: true,
      leading: false,
    },
  );

  private logAnalyticInstantStop(message?: PlayerAnalyticsState): void {
    this.sendAnalyticsInstant(message ?? this.currentState, {
      player_state: "stop",
    });
  }

  private sendAnalyticsInstant(
    message?: PlayerAnalyticsState,
    override?: Partial<PlayerAnalyticsState>,
  ) {
    const messageToLog = message ?? this.currentState;

    if (
      messageToLog.player_state === "not_initialized" ||
      !messageToLog.channel_id
    ) {
      return;
    }

    const newMessage: PlayerAnalyticsState = {
      ...messageToLog,
      ...override,
    };

    // Reset the counters so that next time we start off from 0 again for the new content.
    if (newMessage.player_state === "stop") {
      const player = this.getPlayerState();

      this.bufferCountBeforeContentChange = player.bufferInterruptions ?? 0;
      this.bufferTimeInMsBeforeContentChange =
        player.bufferInterruptionTimeInMs ?? 0;

      this.currentState.buffer_interruption_count = 0;
      this.currentState.buffer_interruption_time = 0;
    }

    // When the current message is inherently same as the previous, don't log.
    if (
      newMessage &&
      !hasMeaningfulChange(
        this.lastLoggedState,
        newMessage,
        this.lastLoggedState
          ? (Object.keys(
              this.lastLoggedState,
            ) as (keyof PlayerAnalyticsState)[])
          : undefined,
      )
    ) {
      return;
    }

    // Keep a copy for referencing if something critical changed.
    this.lastLoggedState = { ...newMessage } as PlayerAnalyticsState;

    // Build message to send
    const wireMessage: PlayerStateMessage = {
      ...newMessage,
      client_timestamp: Date.now(),
    };

    // Remove null keys from wireMessage, except player_state
    for (const [key, val] of Object.entries(wireMessage)) {
      // Ignore certain keys, never remove them
      if (NEVER_REMOVE_KEYS.indexOf(key as PlayerStateField) >= 0) continue;

      // When the player state is not stop, there are fields we do not want to send anymore.
      if (
        wireMessage.player_state !== "stop" &&
        REMOVE_KEYS_ON_NOT_STOP.includes(key as PlayerStateField)
      ) {
        delete wireMessage[key as PlayerStateMessageField];
      }

      if (val == null) delete wireMessage[key as PlayerStateMessageField];
    }

    this.wsLogPlayerState(wireMessage);
  }

  private resetState(): void {
    // Set initial state
    this.playerStarted = false;

    this.bufferCountBeforeContentChange = 0;
    this.bufferTimeInMsBeforeContentChange = 0;

    this.currentState = {
      player_state: "not_initialized",
      player_content_type: null,
      player_content_provider: null,
      channel_id: null,
      epg_entry_id: null,
      media_id: null,
      recording_id: null,
      vod_id: null,
      player_position: null,
      current_recording_progress_in_seconds: null,
      buffer_interruption_count: null,
      buffer_interruption_time: null,
      player_audio_language: null,
      player_subtitle_language: null,
      // stream_target: "local",
      muted: null,
    };
  }

  /**
   * This gets the current content, then updates the content and detects if there is a notable difference.
   * If so it instantly emits a stop for the previous content.
   * If there's still content in the next event, it also emits that if requested.
   */
  private async emitPossibleContentChanged({
    newState,
  }: {
    newState?: Partial<PlayerStateMessage>;
  }) {
    const backup = { ...this.currentState };

    const previousLoggedMessage = this.lastLoggedState;
    this.currentState = { ...this.currentState, ...newState };
    const initialMessage = this.currentState;

    try {
      await this.updatePlayerCurrentState();
    } catch (e) {
      console.error(e);
    }

    if (this.currentState.player_state === "play") {
      this.playerStarted = true;
    }

    let emit: undefined | "now" | "delayed";

    const hasSourceChanged = hasMeaningfulChange(
      this.lastLoggedState,
      this.currentState,
      SOURCE_ATTRIBUTES,
    );

    // don't send events for recordings without the recording id
    if (
      initialMessage.player_content_type === "recording" &&
      !initialMessage.recording_id
    ) {
      return;
    }

    // Changes from play to stop for the previous content are instantly logged as stop.
    if (
      backup.player_content_type &&
      this.lastLoggedState?.player_state !== "stop" &&
      (hasSourceChanged || !this.currentState.player_content_type)
    ) {
      this.logAnalyticInstantStop(backup);
    }

    const refChanged = previousLoggedMessage !== this.lastLoggedState;

    if (!this.lastLoggedState || hasSourceChanged) {
      emit = "now";
    }

    if (
      !emit &&
      hasMeaningfulChange(
        this.currentState,
        this.lastLoggedState,
        LANG_ATTRIBUTES,
      )
    ) {
      emit = "now";
    }

    // When we go from stop to play we want to instantly log as well.
    if (
      emit !== "now" &&
      ((!refChanged &&
        backup.player_state === "stop" &&
        this.currentState.player_state === "play") ||
        (this.lastLoggedState &&
          this.lastLoggedState.player_state !== "stop" &&
          this.currentState.player_state === "stop") ||
        (this.lastLoggedState &&
          this.lastLoggedState.player_state === "stop" &&
          initialMessage.player_state === "play"))
    ) {
      emit = "now";
    }

    if (
      !emit &&
      this.lastLoggedState &&
      this.lastLoggedState.player_state !== initialMessage.player_state
    ) {
      emit = "delayed";
    }

    const goingFromLiveToReplayPause =
      this.currentState.player_state === "pause" &&
      this.currentState.player_content_type === "replay" &&
      this.lastLoggedState?.player_state === "stop";

    const isSecondaryStop =
      this.lastLoggedState?.player_state === "stop" &&
      this.currentState.player_state === "stop";

    if (
      (this.lastLoggedState &&
        this.lastLoggedState.player_state === "stop" &&
        !this.currentState.player_content_type) ||
      goingFromLiveToReplayPause ||
      isSecondaryStop
    ) {
      emit = undefined;
    }

    if (emit) {
      emit === "now"
        ? this.sendAnalyticsInstant()
        : this.sendAnalyticsThrottle();
    } else {
      this.sendAnalyticsThrottle.cancel();
    }
  }
}
