import { format } from "date-fns";

import type { Language } from "@sunrise/backend-types-core";
import { TranslateFn } from "@sunrise/i18n";
import { HOUR_FORMAT } from "@sunrise/time";

import { makeRelativeDayOfWeekTranslation } from "./make-relative-day-of-week-translation";

export async function getRelativeTimeForEpg(
  start: Date,
  end: Date,
  now: Date,
  language: Language,
  t: TranslateFn,
): Promise<{
  status: string;
  schedule: string;
  formattedStart: string;
  formattedEnd: string;
}> {
  const status = await makeRelativeDayOfWeekTranslation(
    start,
    end,
    now,
    language,
    t,
  );

  const formattedStart = format(start, HOUR_FORMAT);
  const formattedEnd = format(end, HOUR_FORMAT);

  const schedule = `${formattedStart} – ${formattedEnd}`;

  return {
    status,
    schedule,
    formattedStart,
    formattedEnd,
  };
}
