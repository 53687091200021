import { featureAtom } from "@sunrise/feature-flags";

/**
 * Indicates whether the FFWD ads are enabled or not.
 * When they are enabled, we may still decide to show or not show the markers.
 */
export const ffwdAdsEnabledAtom = featureAtom<boolean>(
  "fast-forward-ads-enabled",
  false,
  {
    description:
      "Indicates whether the FFWD ads are enabled or not. When this is not enabled, we will also not show any markers.",
  },
);
