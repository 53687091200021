/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

/** BaseChannelListSchema */
export interface BaseChannelListSchema {
  /**
   * Channel List ID
   * The id of the channel list
   */
  id: string;
  /**
   * Type
   * The type of the channel list
   */
  type: string;
  /**
   * Name
   * The name of the channel list
   */
  name: string;
  /**
   * Order
   * The order number of the channel list
   */
  order: number;
}

/** BaseChannelSchema */
export interface BaseChannelSchema {
  /**
   * Channel ID
   * The id of a channel
   */
  id: string;
  /**
   * Mediapulse ID
   * The mediapulse id of a channel
   */
  mediapulse_id: string | null;
  /**
   * Name
   * The name of a channel
   */
  name: string;
  /**
   * Logo
   * The logo of the channel
   */
  logo: string;
  stream_provider: StreamProvider;
  /**
   * Stream Service URL
   * the url of the stream service
   */
  readonly stream_service_url: string;
  /**
   * Is Playable
   * Signalises if a user is allowed to watch this channel
   */
  readonly is_playable: boolean;
}

/** HTTPValidationError */
export interface HTTPValidationError {
  /** Detail */
  detail?: ValidationError[];
}

/** Page[BaseChannelListSchema] */
export interface PageBaseChannelListSchema {
  /** Items */
  items: BaseChannelListSchema[];
  /** Total */
  total: number | null;
  /** Page */
  page: number | null;
  /** Size */
  size: number | null;
  /** Pages */
  pages?: number | null;
}

/** Page[BaseChannelSchema] */
export interface PageBaseChannelSchema {
  /** Items */
  items: BaseChannelSchema[];
  /** Total */
  total: number | null;
  /** Page */
  page: number | null;
  /** Size */
  size: number | null;
  /** Pages */
  pages?: number | null;
}

/** StreamProvider */
export interface StreamProvider {
  /**
   * Stream Provider ID
   * The stream provider id of a channel
   */
  provider_id: string;
  /**
   * Stream Provider Name
   * The stream provider name of a channel
   */
  provider_name: string;
}

/** ValidLanguages */
export enum ValidLanguages {
  En = "en",
  De = "de",
  Fr = "fr",
  It = "it",
}

/** ValidationError */
export interface ValidationError {
  /** Location */
  loc: (string | number)[];
  /** Message */
  msg: string;
  /** Error Type */
  type: string;
}

import { HttpClient, RequestParams } from "@sunrise/backend-ng-core";

/**
 * @title FastAPI
 * @version 0.1.0
 */
export class GeneratedApi {
  http: HttpClient;

  constructor(http: HttpClient) {
    this.http = http;
  }

  channel = {
    /**
     * No description
     *
     * @name HealthChannelV1HealthGet
     * @summary Health
     * @request GET:/channel/v1/health
     */
    healthChannelV1HealthGet: (params: RequestParams = {}) =>
      this.http.request<any, any>({
        path: `/channel/v1/health`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags channels
     * @name ChannelsListChannelV1ChannelsGet
     * @summary Channels List
     * @request GET:/channel/v1/channels
     * @secure
     */
    channelsListChannelV1ChannelsGet: (
      query?: {
        /**
         * Page
         * Page number
         * @min 1
         * @default 1
         */
        page?: number;
        /**
         * Size
         * Page size
         * @min 1
         * @max 100
         * @default 50
         */
        size?: number;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<PageBaseChannelSchema, void | HTTPValidationError>({
        path: `/channel/v1/channels`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags channels
     * @name ChannelDetailChannelV1ChannelsChannelIdGet
     * @summary Channel Detail
     * @request GET:/channel/v1/channels/{channel_id}
     * @secure
     */
    channelDetailChannelV1ChannelsChannelIdGet: (channelId: string, params: RequestParams = {}) =>
      this.http.request<BaseChannelSchema, void | HTTPValidationError>({
        path: `/channel/v1/channels/${channelId}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags channel-lists
     * @name ChannelListsChannelV1ChannelListsGet
     * @summary Channel Lists
     * @request GET:/channel/v1/channel-lists
     * @secure
     */
    channelListsChannelV1ChannelListsGet: (
      query?: {
        /**
         * Page
         * Page number
         * @min 1
         * @default 1
         */
        page?: number;
        /**
         * Size
         * Page size
         * @min 1
         * @max 100
         * @default 50
         */
        size?: number;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<PageBaseChannelListSchema, void | HTTPValidationError>({
        path: `/channel/v1/channel-lists`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description This endpoint returns a single channel list item, just the basic attributes. Used by the clients to avoid having to implement traversing the pages of the channel lists endpoint / requesting the whole list just for loading a saved item
     *
     * @tags channel-lists
     * @name ChannelListDetailChannelV1ChannelListsChannelListIdGet
     * @summary Channel List Detail
     * @request GET:/channel/v1/channel-lists/{channel_list_id}
     * @secure
     */
    channelListDetailChannelV1ChannelListsChannelListIdGet: (channelListId: string, params: RequestParams = {}) =>
      this.http.request<BaseChannelListSchema, void | HTTPValidationError>({
        path: `/channel/v1/channel-lists/${channelListId}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description This endpoint returns only a list with the channels included in the channel list pointed by the passed channel list id
     *
     * @tags channel-lists
     * @name ChannelListDetailChannelsChannelV1ChannelListsChannelListIdChannelsGet
     * @summary Channel List Detail Channels
     * @request GET:/channel/v1/channel-lists/{channel_list_id}/channels
     * @secure
     */
    channelListDetailChannelsChannelV1ChannelListsChannelListIdChannelsGet: (
      channelListId: string,
      query?: {
        /**
         * Page
         * Page number
         * @min 1
         * @default 1
         */
        page?: number;
        /**
         * Size
         * Page size
         * @min 1
         * @max 100
         * @default 50
         */
        size?: number;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<PageBaseChannelSchema, void | HTTPValidationError>({
        path: `/channel/v1/channel-lists/${channelListId}/channels`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name RootChannelV1Get
     * @summary Root
     * @request GET:/channel/v1
     */
    rootChannelV1Get: (params: RequestParams = {}) =>
      this.http.request<any, any>({
        path: `/channel/v1`,
        method: "GET",
        format: "json",
        ...params,
      }),
  };
}
