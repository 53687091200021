import { forwardRef, ReactNode } from "react";
import clsx from "clsx";
import gradientChannel from "static/gradients/channel-box.webp";
import gradientLarge from "static/gradients/program-box-large.webp";
import gradientNormal from "static/gradients/program-box-normal.webp";

import { isNil, type Nullable } from "@sunrise/utils";

import { ChannelLogo } from "@/components/channel-logo/channel-logo";

import { ProgressBar } from "../..";
import { Icon } from "../../icon";
import { Tag } from "../../tag/tag";
import { Kind } from "../types";
import * as styles from "./program-cover.css";

export type RecordingState =
  | "recorded"
  | "planned"
  | "inactive"
  | "paused"
  | "recording";

export type ProgramCoverProps = CommonProps & {
  coverImageUrl: Nullable<string>;
  kind: Kind;
  focused?: boolean;
  liveProgress?: Nullable<number>;
  replayProgress?: Nullable<number>;
  logoUrl: Nullable<string>;
  isSeries?: boolean;
  recordingState?: Nullable<RecordingState>;
  isMarkedForDeletion?: boolean;
};

type ContentProps = {
  kind: ProgramCoverProps["kind"];
  logoUrl: Nullable<string>;
  liveProgress?: Nullable<number>;
  replayProgress?: Nullable<number>;
  isSeries?: boolean;
} & CommonProps;

function Content({
  "data-testid": dataTestId = "content",
  kind,
  logoUrl,
  liveProgress,
  replayProgress,
  isSeries,
}: ContentProps): ReactNode {
  const shouldShowLiveProgress = !isNil(liveProgress) || !isNil(replayProgress);
  if (kind === "channel")
    return (
      logoUrl && (
        <ChannelLogo
          url={logoUrl}
          className={styles.channelLogo}
          data-testid="channel-box-logo"
          big
        />
      )
    );
  else {
    return isSeries ? (
      <div className={styles.seriesIcon} data-testid="series-icon">
        <Icon name="directory" color="white" />
      </div>
    ) : (
      <div className={styles.content}>
        {logoUrl && (
          <ChannelLogo
            url={logoUrl}
            className={styles.logo}
            data-testid="cover-channel-logo"
          />
        )}
        {shouldShowLiveProgress && (
          <div className={styles.progressBar} data-testid="cover-progress-bar">
            <ProgressBar
              data-testid={`${dataTestId}-progress-bar`}
              liveProgress={
                replayProgress && !liveProgress ? 100 : liveProgress
              }
              replayProgress={replayProgress}
              height={8}
              radius={8}
            />
          </div>
        )}
      </div>
    );
  }
}

export const ProgramCover = forwardRef<HTMLDivElement, ProgramCoverProps>(
  function ForwardRefProgramBox(
    { "data-testid": dataTestId = "program-box", ...props },
    ref,
  ) {
    const gradients = {
      normal: gradientNormal,
      large: gradientLarge,
      row: null,
      channel: gradientChannel,
      recordings: gradientNormal,
    };
    const gradient = gradients[props.kind];

    const recordingIcon = getRecordingIcon(props.recordingState);

    return (
      <div
        data-testid={dataTestId}
        className={clsx(
          styles.cover,
          styles[props.kind],
          props.focused && styles.focused,
          props.className,
        )}
        ref={ref}
        style={{
          backgroundImage: `url(${gradient ?? ""}), url(${
            props.coverImageUrl
          })`,
          backgroundRepeat: `repeat, no-repeat`,
          backgroundSize: "contain, cover",
          backgroundPositionX: `left, center`,
        }}
      >
        {props.isMarkedForDeletion ? (
          <div className={styles.markedForDeletion} />
        ) : null}
        {recordingIcon && (
          <div className={styles.recordedIcon}>
            <Tag
              text="REC"
              icon={recordingIcon}
              data-testid={`${dataTestId}-recording-tag`}
            />
          </div>
        )}
        <Content
          data-testid={`${dataTestId}-content`}
          kind={props.kind}
          liveProgress={props.liveProgress}
          replayProgress={props.replayProgress}
          logoUrl={props.logoUrl}
          isSeries={props.isSeries}
        />
      </div>
    );
  },
);

function getRecordingIcon(
  recordingState: Nullable<RecordingState>,
): "recorded" | "recording" | null {
  switch (recordingState) {
    case "planned":
    case "recording":
      return "recording";
    case "recorded":
      return "recorded";
    default:
      return null;
  }
}
