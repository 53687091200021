import { useListDialog } from "@sunrise/dialogs";
import type { TranslateFn } from "@sunrise/i18n";
import {
  type TrackId,
  actionPlayerSelectAudio,
  actionPlayerSelectSubtitles,
  playerAtom,
  selectPlayerCurrentAudioTrackId,
  selectPlayerCurrentSubtitleTrackId,
  selectPlayerSubtitlesAndAudioSettings,
} from "@sunrise/player";
import type { Nullable } from "@sunrise/utils";
import { useAtomCallback } from "jotai/utils";
import { useCallback } from "react";

type UseSubtitleAudioSettingsProps = {
  lastFocusKey?: Nullable<string>;
  doClose?: () => void;
};

export function useSubtitleAudioSettings({
  lastFocusKey,
  doClose,
}: UseSubtitleAudioSettingsProps = {}): {
  open: (t: TranslateFn) => void;
} {
  const { showDialog } = useListDialog();

  const openSubtitleAndAudioSettings = useAtomCallback(
    useCallback(
      (get, set, t: TranslateFn) => {
        const { audioTracks, subtitleTracks } = get(
          selectPlayerSubtitlesAndAudioSettings,
        );

        const activeAudioTrackId = get(selectPlayerCurrentAudioTrackId);
        const activeSubtitleTrackId = get(selectPlayerCurrentSubtitleTrackId);
        doClose?.();

        const translateLabel = <Track extends { label: string }>(
          track: Track,
        ): Track => ({ ...track, label: t(track.label) });
        const subtitleOptions = subtitleTracks.map(translateLabel);
        const audioOptions = audioTracks.map(translateLabel);

        showDialog({
          id: "subtitle-audio-settings",
          buttonTextAlign: "left",
          onButtonClick: (
            value: Nullable<string>,
            sectionIdx: Nullable<number>,
          ) => {
            if (sectionIdx === 0) {
              set(
                playerAtom,
                actionPlayerSelectSubtitles(value as TrackId | null),
              );
              return;
            }

            if (sectionIdx === 1) {
              set(playerAtom, actionPlayerSelectAudio(value as TrackId | null));
            }
          },
          title: t("dialog_audio_settings_title"),
          sections: [
            {
              title: t("player_subtitles"),
              options: subtitleOptions,
            },
            {
              title: t("player_audio"),
              options: audioOptions,
            },
          ],
          activeOptions: [activeSubtitleTrackId, activeAudioTrackId],
          lastFocusKey: lastFocusKey ?? "",
          radioButtons: true,
        });
      },
      [doClose],
    ),
  );

  return {
    open: openSubtitleAndAudioSettings,
  };
}
