import type { InfiniteData } from "@tanstack/query-core";
import { atom } from "jotai";
import { atomWithInfiniteQuery } from "jotai-tanstack-query";
import uniqWith from "lodash/uniqWith";

import {
  type ContinueWatchingItem,
  type PagedResponse,
  queryKeys,
} from "@sunrise/backend-types";
import type { ContinueWatchingId } from "@sunrise/backend-types-core";
import { canSwallowError } from "@sunrise/error";
import { hostsAtom, httpClientAtom } from "@sunrise/http-client";
import { selectJwtUserToken } from "@sunrise/jwt";
import { isDefined, isNil, type Nullable } from "@sunrise/utils";

import { CONTINUE_WATCHING_PAGE_SIZE } from "./constants";
import { fetchContinueWatching } from "./continue-watching-service";
import { continueWatchingSocketUpdatesAtom } from "./continue-watching-socket-updates.atom";
import { continueWatchingStaleTimeAtom } from "./continue-watching-stale-time.atom";

/**
 * Loads the continue watching items pages.
 * It will halt loading followup pages as soon as 1 page fails to load.
 */
export const continueWatchingInfiniteQueryAtom = atomWithInfiniteQuery<
  Nullable<PagedResponse<ContinueWatchingItem>>,
  unknown,
  InfiniteData<PagedResponse<ContinueWatchingItem> | null>,
  ReturnType<typeof queryKeys.continueWatching>,
  number
>((get) => {
  const host = get(hostsAtom).api;
  if (isNil(host)) throw new Error("Host is not set");

  const { privateApi } = get(httpClientAtom);
  if (!privateApi) throw new Error("missing privateApi");

  return {
    initialPageParam: 1,
    staleTime: get(continueWatchingStaleTimeAtom),
    getNextPageParam: (lastPage) => {
      if (isNil(lastPage)) return undefined;

      const { page, pages } = lastPage;
      return page < pages ? page + 1 : undefined;
    },
    // NOTE: We still depend on the access token since we want the data to reload when the user's token changes.
    queryKey: queryKeys.continueWatching(get(selectJwtUserToken)),
    queryFn: async ({ queryKey: [, accessToken], pageParam }) => {
      if (isNil(pageParam) || isNil(accessToken)) return;

      try {
        return await fetchContinueWatching(
          privateApi,
          host,
          pageParam,
          CONTINUE_WATCHING_PAGE_SIZE,
        );
      } catch (e) {
        // When not explicitly set by the error, assume we can actually swallow the error.
        if (!canSwallowError(e, true)) throw e;
        return null;
      }
    },
    keepPreviousData: true,
    suspense: true,
  };
});

/**
 * A separate atom just to cache the flatMapped result a bit.
 */
const continueWatchingFetchedItemsAtom = atom((get) => {
  const items = get(continueWatchingInfiniteQueryAtom);

  return (
    items.data?.pages
      .filter(isDefined)
      .map((page) => page.result)
      .flat() ?? []
  );
});

/**
 * Known items from the continue watching infinite query.
 * This will make it a bit more performant when we query continue watching items for multiple programs.
 * They should use a cached version of the flattened results so we do not repeat that operation every time.
 *
 * It will also make sure to update the CW items through data delivered in the websocket.
 * This can be done by writing to the continueWatchingSocketUpdatesAtom.
 */
export const continueWatchingKnownItemsAtom = atom((get) => {
  const fetchedItems = get(continueWatchingFetchedItemsAtom);

  const [deletes, updates] = get(continueWatchingSocketUpdatesAtom).reduce(
    (acc, update) => {
      if (update.type === "delete") {
        acc[0].push(update.id);
      } else {
        acc[1].push(update);
      }

      return acc;
    },
    [[], []] as [ContinueWatchingId[], ContinueWatchingItem[]],
  );

  const fetchedItemsWithoutDeletes = fetchedItems
    .map((item) => {
      const deleted = deletes.some((id) => id === item.id);

      if (deleted) {
        return null;
      }

      return item;
    })
    .filter(isDefined);

  return uniqWith(
    [...updates, ...fetchedItemsWithoutDeletes],
    (a, b) =>
      a.id === b.id || (a.epg_entry.id === b.epg_entry.id && a.type === b.type),
  );
});
