import { atom } from "jotai";
import { selectAtom } from "jotai/utils";

import { gridStateAtom } from "./grid-state.atom";

export const selectorGridCoordinates = selectAtom(
  gridStateAtom,
  (state) => state.coordinates,
);

/**
 * The selection means the program that is shown at the top.
 * It is also automatically the program to focus on when the grid is focused.
 *
 * This does NOT mean that this is the currently playing item in the player.
 */
export const selectorGuideSelection = selectAtom(
  gridStateAtom,
  (state) => state.selection,
);

export const selectorIsGridFocused = selectAtom(
  gridStateAtom,
  (state) => state.isFocused,
);

export const selectorJumpToDate = selectAtom(
  gridStateAtom,
  (state) => state.jumpToDate,
);

export const currentlyVisibleDateAtom = atom((get) => {
  const selection = get(selectorGuideSelection);
  return selection?.startTime ?? get(selectorJumpToDate);
});
