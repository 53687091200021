import type { InfiniteData } from "@tanstack/react-query";
import { atomFamily } from "jotai/utils";
import { atomWithInfiniteQuery } from "jotai-tanstack-query";

import type { PageWithItems } from "@sunrise/backend-ng-core";
import {
  RECORDING_DATA_STALE_TIME_IN_MS,
  recordingsStaleTimeAtom,
} from "@sunrise/backend-ng-recordings";
import { LegacySingleRecording, queryKeys } from "@sunrise/backend-types";
import type { AssetId, RecordingGroupId } from "@sunrise/backend-types-core";
import { canSwallowError } from "@sunrise/error";
import { hostsAtom, httpClientAtom } from "@sunrise/http-client";
import { selectIsLoggedIn, selectJwtUserToken } from "@sunrise/jwt";
import { isNil, type Nullable } from "@sunrise/utils";
import { convertLegacyPageFormatToNgPageFormat } from "@sunrise/yallo-recordings-list";

import { fetchRecordingGroupItems } from "../recordings.service";
import { recordingsSortForSeriesAssetIdAtom } from "../recordings-sort-for-series-asset-id.atom";

type RecordingGroupItemsQueryAtomArgs = {
  recordingGroupId: RecordingGroupId;
  seriesAssetId: AssetId;
  traverseChildGroups: boolean;
};

/**
 * This is an atom that will ask the backend for all episodes recordings of a specific recordingGroupId (series).
 * It will cache the results through react-query.
 */
export const recordingGroupItemsLegacyQueryAtom = atomFamily(
  ({
    recordingGroupId,
    seriesAssetId,
    traverseChildGroups,
  }: RecordingGroupItemsQueryAtomArgs) => {
    const innerAtom = atomWithInfiniteQuery<
      Nullable<PageWithItems<LegacySingleRecording>>,
      unknown,
      InfiniteData<PageWithItems<LegacySingleRecording>>,
      ReturnType<typeof queryKeys.recordingGroupItems>,
      number
    >((get) => {
      const host = get(hostsAtom).api;
      if (isNil(host)) throw new Error("Host is not set");
      const { privateApi } = get(httpClientAtom);
      if (!privateApi) throw new Error("missing privateApi");

      const isLoggedIn = get(selectIsLoggedIn);

      const sort = get(recordingsSortForSeriesAssetIdAtom({ seriesAssetId }));
      const queryKey = queryKeys.recordingGroupItems(
        get(selectJwtUserToken),
        recordingGroupId,
        sort,
        traverseChildGroups,
      );

      return {
        initialPageParam: 1,
        staleTime: get(recordingsStaleTimeAtom),
        // We want the GC time to be not too high here since this is remembered per series. It needs to be garbage collected pretty quickly.
        gcTime: RECORDING_DATA_STALE_TIME_IN_MS,
        getNextPageParam: (lastPage) => {
          if (isNil(lastPage)) return undefined;

          const { page, pages } = lastPage;
          if (!page || !pages) return undefined;

          return page < pages ? page + 1 : undefined;
        },
        // NOTE: We still depend on the access token since we want the data to reload when the user's token changes.
        queryKey,
        queryFn: async ({ pageParam }) => {
          try {
            if (!isLoggedIn) {
              return null;
            }

            const data = await fetchRecordingGroupItems(
              host,
              privateApi,
              recordingGroupId,
              traverseChildGroups,
              sort,
              pageParam,
            );

            return data ? convertLegacyPageFormatToNgPageFormat(data) : null;
          } catch (e) {
            if (!canSwallowError(e, true)) throw e;
            console.error(e);
            return null;
          }
        },
        keepPreviousData: true,
        suspense: true,
      };
    });
    innerAtom.debugLabel = `recordingGroupItemsLegacyQueryAtom(${recordingGroupId},${seriesAssetId},${traverseChildGroups})`;
    return innerAtom;
  },
);
