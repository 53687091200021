import { useFocusable } from "@noriginmedia/norigin-spatial-navigation";
import { logButtonClickAtom } from "@sunrise/analytics";
import { searchEpgQueryAtom } from "@sunrise/search";
import { useAtom, useAtomValue } from "jotai";
import { useAtomCallback } from "jotai/utils";
import { Suspense, useCallback, useEffect, useRef } from "react";
import { useRoutes } from "@/features/routing/use-routes";
import { EPGSearchEntry } from "@sunrise/backend-types";
import { EpgResult } from "./epg-result";
import * as styles from "./program-search-results.css";
import { listNavigationArrowPress } from "@/utils/grid-navigation-arrow-handler";
import { selectedSearchItemAtom } from "./search-list.atom";
import { SCREEN_HEIGHT_IN_PX, SCREEN_WIDTH_IN_PX } from "@/core";
import { useVirtualizer } from "@tanstack/react-virtual";
import { programBoxSize } from "@/config/size";

const COLUMNS_COUNT = 4;

type ProgramSearchResultsProps = {
  exitLeft: () => void;
  focusKey: string;
  parentScrollRef: React.RefObject<HTMLDivElement>;
  epgs: EPGSearchEntry[];
};

export const ProgramSearchResults = ({
  exitLeft,
  focusKey,
  parentScrollRef,
}: Omit<ProgramSearchResultsProps, "epgs">) => {
  const epgsQuery = useAtomValue(searchEpgQueryAtom);
  const epgs = epgsQuery.isSuccess ? epgsQuery.data : undefined;
  if (!epgs || !epgs.result.length) {
    return null;
  }

  return (
    <ProgramSearchResultsInner
      exitLeft={exitLeft}
      focusKey={focusKey}
      epgs={epgs.result}
      parentScrollRef={parentScrollRef}
    />
  );
};

const ITEM_HEIGHT = programBoxSize.normal.height;

const ProgramSearchResultsInner = ({
  exitLeft,
  focusKey,
  parentScrollRef,
  epgs,
}: ProgramSearchResultsProps) => {
  const [current, setCurrent] = useAtom(selectedSearchItemAtom);
  const currentEpgId = current?.type === "epg" ? current.id : undefined;

  const routes = useRoutes();

  const focus = useFocusable({
    focusKey,
    onArrowPress: (direction) => {
      const currentIndex = epgs.findIndex((epg) => epg.id === currentEpgId);
      const nextIndex = listNavigationArrowPress(
        epgs.length,
        COLUMNS_COUNT,
        currentIndex,
        direction,
        {
          onLeftBoundry: exitLeft,
        },
      );
      if (nextIndex === -1 || typeof nextIndex === "undefined") {
        return true;
      }

      const nextItem = epgs[nextIndex];
      if (!nextItem) {
        return true;
      }
      setCurrent({ type: "epg", id: nextItem.id });
      return false;
    },
    onEnterPress: useAtomCallback(
      useCallback(
        async (get) => {
          const currentEpg = epgs.find((epg) => epg.id === currentEpgId);
          if (!currentEpg) {
            return;
          }

          const log = get(logButtonClickAtom);

          await log.invoke({
            type: "to_epg_item",
            epgId: currentEpg.id,
          });

          routes.details.root({
            assetId: currentEpg.asset.id,
            epgId: currentEpg.id,
          });
        },
        [currentEpgId],
      ),
    ),
  });

  useEffect(() => {
    if (
      epgs.length &&
      (!currentEpgId || !epgs.some((item) => item.id === currentEpgId)) &&
      focus.focused
    ) {
      setCurrent({ type: "epg", id: epgs[0].id });
    }
  }, [epgs, focus.focused, currentEpgId]);
  const rowCount = Math.ceil(epgs.length / COLUMNS_COUNT);

  const virtualizer = useVirtualizer({
    count: rowCount,
    estimateSize: () => ITEM_HEIGHT,
    getScrollElement: () => parentScrollRef.current,
    initialRect: {
      width: SCREEN_WIDTH_IN_PX,
      height: SCREEN_HEIGHT_IN_PX,
    },
    overscan: 1,
  });

  const currentItemIndex = epgs.findIndex((epg) => epg.id === currentEpgId);
  const currentRow = Math.floor(currentItemIndex / COLUMNS_COUNT);
  useEffect(() => {
    if (currentRow === -1 || !focus.focused) {
      return;
    }
    // scroll to the row of the focused item.
    parentScrollRef.current?.scrollTo({
      left: 0,
      top: currentRow * ITEM_HEIGHT,
    });
  }, [currentRow, epgs, focus.focused]);

  return (
    <div
      ref={focus.ref}
      className={styles.outer}
      data-testid="program-search-results"
    >
      <div style={{ height: ITEM_HEIGHT * rowCount }}>
        {virtualizer.getVirtualItems().map((virtualRow) => {
          return (
            <div
              key={virtualRow.key}
              className={styles.inner}
              style={{
                position: "absolute",
                top: virtualRow.index * ITEM_HEIGHT,
              }}
            >
              {epgs
                .slice(
                  virtualRow.index * COLUMNS_COUNT,
                  virtualRow.index * COLUMNS_COUNT + COLUMNS_COUNT,
                )
                .map((item, index) => {
                  return (
                    <ProgramResult
                      key={`row-${virtualRow.key}-${index}`}
                      item={item}
                      position={virtualRow.index * COLUMNS_COUNT + index + 1}
                      focused={focus.focused && item.id === currentEpgId}
                    />
                  );
                })}
            </div>
          );
        })}
      </div>
    </div>
  );
};

function ProgramResult({
  item,
  position,
  focused,
}: {
  item: EPGSearchEntry;
  position: number;
  focused: boolean;
}) {
  // NOTE: Suspsense boundary is needed because when an epg result triggers suspense the whole page is empty. The page will scroll back to top while the focus is somewhere at the bottom of the page.
  // The entire page looks broken then.
  return (
    <Suspense>
      <EpgResult epg={item} data-position={position} focused={focused} />
    </Suspense>
  );
}
