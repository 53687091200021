import { selectAtom } from "jotai/utils";

import { MIN_SEARCH_QUERY_LENGTH } from "./search.const";
import { debouncedQueryAtoms } from "./search.debounce.atom";

/**
 * We do not search actively when we have less than a certain amount of search characters.
 */
export const selectSearchEnabled = selectAtom(
  debouncedQueryAtoms.debouncedValueAtom,
  (query) => !!(query && query.length > MIN_SEARCH_QUERY_LENGTH),
);
