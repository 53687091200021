import { atom } from "jotai";

import { MEMORY_UPDATE_INTERVAL } from "@/modules/memory/constants";
import { buildMemoryUsageState } from "@/modules/memory/helpers/build-memory-usage-state";
import type { MemoryUsageValue } from "@/modules/memory/types";

export const memoryUsageAtom = atom<MemoryUsageValue | null>(null);

memoryUsageAtom.onMount = (set) => {
  function update(): void {
    const state = buildMemoryUsageState();
    if (!state) {
      // When we do not get state, it is disabled.
      clearInterval(intervalId);
      console.warn(
        "Not receiving memory updates, disabling memory monitoring.",
      );
    }
    set(state);
  }

  const intervalId = setInterval(update, MEMORY_UPDATE_INTERVAL);
  update();

  return () => {
    clearInterval(intervalId);
  };
};
