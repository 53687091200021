import { featureAtom } from "@sunrise/feature-flags";

/**
 * Dynamically toggle the visibility of the tags of the currently playing item (imdb 8, +16, 2020, comedy, …) on the player overlay
 */
export const showProgramTagsInPlayerFeatureAtom = featureAtom(
  "program-tags-tv-page",
  false,
  {
    description: `Dynamically toggle the visibility of the tags of the currently playing item (imdb, +16, 2020, comedy, …) on the player overlay.
`,
  },
);
