import { ReactNode } from "react";
import clsx from "clsx";

import { Icon } from "@/components/icon";
import { typography } from "@/styles/typography.css";

import * as styles from "./recording-program-box.css";

export type BulkDeletionCheckboxProps = {
  checked: boolean;
  isBulkDeletionMode: boolean;
  "data-checked": boolean;
} & CommonProps;

export function BulkDeletionCheckbox({
  checked,
  isBulkDeletionMode,
  "data-checked": dataChecked,
  "data-testid": dataTestId = "bulk-deletion-checkbox",
}: BulkDeletionCheckboxProps): ReactNode {
  if (!isBulkDeletionMode) {
    return null;
  }

  return (
    <div
      className={clsx(typography.h7.regular, styles.checkbox)}
      data-testid={dataTestId}
      data-checked={dataChecked}
    >
      {checked && (
        <div className={styles.checkboxIcon}>
          <Icon
            name="done"
            color="dark"
            size={24}
            data-testid={`${dataTestId}.checked`}
          />
        </div>
      )}
    </div>
  );
}
