import { atom } from "jotai";

/**
 * An atom that represents the online/offline state of the browser.
 *
 * Through the browser's `navigator.onLine` property, we can determine if the browser is online or offline.
 */
export const browserOfflineAtom = atom<null | boolean>(null);

browserOfflineAtom.onMount = (setValue) => {
  if (!("window" in globalThis) || !("navigator" in globalThis.window)) return;

  const isOnline = (): void => setValue(false);
  const isOffline = (): void => setValue(true);

  // Set the initial value.
  if (window.navigator.onLine) {
    isOnline();
  } else {
    isOffline();
  }

  window.addEventListener("online", isOnline);

  window.addEventListener("offline", isOffline);

  return () => {
    window.removeEventListener("online", isOnline);
    window.removeEventListener("offline", isOffline);
  };
};
