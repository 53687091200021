import { featureAtom } from "@sunrise/feature-flags";

/**
 * Feature flag to allow bulk deletion to happen or not.
 */
export const bulkDeletionEnabledAtom = featureAtom<boolean>(
  "bulk-deletion-enabled",
  true,
  {
    description: `Feature flag to allow bulk deletion to happen or not on the recordings overview page.`,
  },
);
