import {
  FocusContext,
  doesFocusableExist,
  setFocus,
  useFocusable,
} from "@noriginmedia/norigin-spatial-navigation";
import { actionDialogOpen, dialogAtom } from "@sunrise/dialogs";
import { useTranslator } from "@sunrise/translator";
import {
  bulkDeletionEnabledAtom,
  hasRecordingsAtom,
  isAllSelectedAtom,
  isBulkDeletionModeAtom,
  recordingsMarkedForBulkDeletionAtom,
  recordingsMarkedForBulkDeletionCountAtom,
  recordingsQueryAtom,
  selectedRecordingReferenceAtom,
  useFilteringRecordings,
  useSortRecordings,
} from "@sunrise/yallo-recordings";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import { useAtomCallback } from "jotai/utils";
import { type ReactElement, useCallback, useEffect } from "react";

import { FocusButton, Header } from "@/components";
import { ICON_SIZE_20 } from "@/components/icon/icon.config";
import { RouteLayout } from "@/components/layout/layout";
import { globalFocusKey } from "@/config/focus-key";
import { useMenu } from "@/features/menu/use-menu";
import { RecordingUpsellBanner } from "@/features/recordings/recording-upsell-banner";
import { RecordingsBulkDeleteTitle } from "@/features/recordings/recordings-bulk-delete-title";
import { RecordingsCapacityIndicator } from "@/features/recordings/recordings-capacity-indicator";
import { RecordingsList } from "@/features/recordings/recordings-list";
import { RecordingsNetworkIndicator } from "@/features/recordings/recordings-network-indicator";
import { useRoutes } from "@/features/routing/use-routes";
import { canPullFocusAtom } from "@/modules/ui/can-pull-focus.atom";
import { isArrowLeftKey } from "@/utils/navigation";

import * as styles from "./recordings.css";

export const localWidgetFocusKey = {
  page: globalFocusKey.recordingsPage,
  recordingsButtons: globalFocusKey.recordingsPage + ".buttons",
  recordings: globalFocusKey.recordingsPage + ".recordings",
  selectButton: globalFocusKey.recordingsPage + ".buttons.select-button",
  deleteButton: globalFocusKey.recordingsPage + ".buttons.delete-button",
  cancelButton: globalFocusKey.recordingsPage + ".buttons.cancel-button",
  sortButton: globalFocusKey.recordingsPage + ".buttons.sort-button",
  filterButton: globalFocusKey.recordingsPage + ".buttons.filter-button",
  selectAllButton: globalFocusKey.recordingsPage + ".buttons.select-all-button",
};

const DATA_TEST_ID = "recordings";

/**
 * Could use a refactoring where the logic for bulk deletion is extracted for general re-use. Like with web.
 */
export default function Recordings(): ReactElement {
  const menu = useMenu();
  const t = useTranslator();

  const routes = useRoutes();

  const bulkDeletionEnabled = useAtomValue(bulkDeletionEnabledAtom);
  const isBulkDeletionMode = useAtomValue(isBulkDeletionModeAtom);
  const areAllSelected = useAtomValue(isAllSelectedAtom);

  const { ref, focusKey, focusSelf } = useFocusable({
    focusKey: localWidgetFocusKey.page,
    focusable: true,
    isFocusBoundary: true,
    /**
     * This makes sure that if focusables disappear inside our ref, this focusable gets focused again.
     */
    autoRestoreFocus: true,
    preferredChildFocusKey: isBulkDeletionMode
      ? localWidgetFocusKey.cancelButton
      : localWidgetFocusKey.recordings + ".list",
    onArrowPress: function handleArrowPress(direction) {
      // NOTE: only gets called if there is no RecordingsList rendered
      if (isArrowLeftKey(direction) && isBulkDeletionMode) {
        routes.recordings.root();
      } else {
        menu.expand();
      }
      return false;
    },
    forceFocus: true,
  });

  // Needs to refocus the page whenever we can focus on ourselves again.
  // This is because the modals are not inside the focus tree but outside of it.
  const hasRecordings = useAtomValue(hasRecordingsAtom);
  const canFocusSelf = useAtomValue(canPullFocusAtom);
  useEffect(() => {
    if (canFocusSelf) {
      focusSelf();
    }
  }, [canFocusSelf, focusSelf]);

  const setBulkDeletionMode = useSetAtom(isBulkDeletionModeAtom);
  const [markedForBulkDeletionRecordings, writeMarked] = useAtom(
    recordingsMarkedForBulkDeletionAtom,
  );

  const bulkDeleteRecordings = useAtomCallback(
    useCallback(
      async (get, set) => {
        const count = get(recordingsMarkedForBulkDeletionCountAtom);

        set(
          dialogAtom,
          actionDialogOpen({
            lastFocusKey: "",
            type: "actions",
            title: { key: "confirm_bulk_delete_title" },
            description: {
              key: "confirm_bulk_delete_message",
              params: [count],
              isPlural: count > 1,
            },
            actions: [
              {
                label: { key: "confirm_bulk_delete_button_confirm" },
                action: async () => {
                  await writeMarked({ type: "confirm" });
                },
                key: "confirm",
              },
              {
                label: { key: "button_cancel" },
                action: () => {
                  /* noop */
                },
                key: "cancel",
                initialFocus: true,
              },
            ],
            id: "confirm-bulk-deletion",
          }),
        );
      },
      [writeMarked],
    ),
  );

  const selectAllRecordings = useAtomCallback(
    useCallback(async () => {
      await writeMarked({ type: "select-all" });
    }, [writeMarked]),
  );

  const deselectAllRecordings = useAtomCallback(
    useCallback(async () => {
      await writeMarked({ type: "deselect-all" });
    }, [writeMarked]),
  );

  /**
   * Attempt to focus on what makes sense and what is available.
   * If there are no buttons, just focus on ourselves.
   */
  const doFocusAboveList = (column: number): void => {
    if (
      isBulkDeletionMode &&
      doesFocusableExist(localWidgetFocusKey.cancelButton)
    ) {
      setFocus(localWidgetFocusKey.cancelButton);
      return;
    }

    if (!hasRecordings || !doesFocusableExist(localWidgetFocusKey.sortButton)) {
      setFocus(localWidgetFocusKey.filterButton);
      return;
    }

    switch (column) {
      case 0:
      case 1:
        if (doesFocusableExist(localWidgetFocusKey.filterButton)) {
          setFocus(localWidgetFocusKey.filterButton);
          return;
        }
        break;
      case 2:
        setFocus(localWidgetFocusKey.sortButton);
        break;
      case 3:
        if (doesFocusableExist(localWidgetFocusKey.selectButton)) {
          setFocus(localWidgetFocusKey.selectButton);
          return;
        }
        break;
    }

    if (doesFocusableExist(localWidgetFocusKey.sortButton)) {
      setFocus(localWidgetFocusKey.sortButton);
    } else {
      focusSelf();
    }
  };
  const closeSort = useAtomCallback(
    useCallback((_, set) => {
      // NOTE: after sorting, reset previously focused item (it could be out of screen) and focus list
      set(selectedRecordingReferenceAtom("RecordingsList"), null);
      setFocus(localWidgetFocusKey.recordings);
    }, []),
  );

  const { openSortDialog } = useSortRecordings({
    onClose: closeSort,
  });

  const shouldShowSelectButton = bulkDeletionEnabled && hasRecordings;

  const { filterRecordings, currentRecordingsFilter } =
    useFilteringRecordings();

  return (
    <FocusContext.Provider value={focusKey}>
      <main
        ref={ref}
        tabIndex={0}
        data-testid={DATA_TEST_ID}
        className={styles.root}
      >
        <RouteLayout>
          <Header
            className={styles.header}
            left={
              isBulkDeletionMode ? (
                <RecordingsBulkDeleteTitle
                  className={styles.indicators}
                  data-testid={`${DATA_TEST_ID}.bulk-delete-title`}
                />
              ) : (
                <div className={styles.indicators}>
                  <FocusButton
                    data-testid={`${DATA_TEST_ID}.filter-button`}
                    className={styles.filterButton}
                    text={currentRecordingsFilter}
                    focusKey={localWidgetFocusKey.filterButton}
                    onEnterPress={filterRecordings}
                    onArrowPress={(direction) => {
                      if (isArrowLeftKey(direction)) {
                        menu.expand();
                        return false;
                      }
                      return true;
                    }}
                    iconRight="dropdown-down"
                    iconSize={ICON_SIZE_20}
                    textAlign="left"
                    active={true}
                  />
                  <RecordingsCapacityIndicator />
                  <RecordingsNetworkIndicator dataAtom={recordingsQueryAtom} />
                </div>
              )
            }
            right={
              isBulkDeletionMode ? (
                <div className={styles.buttonsContainer}>
                  <div className={styles.button}>
                    <FocusButton
                      active={false}
                      data-testid={
                        DATA_TEST_ID +
                        (areAllSelected
                          ? ".deselect-all-bulk-deletion"
                          : ".select-all-bulk-deletion")
                      }
                      text={t(
                        areAllSelected
                          ? "recordings_button_deselect_all"
                          : "recordings_button_select_all",
                      )}
                      onEnterPress={
                        areAllSelected
                          ? deselectAllRecordings
                          : selectAllRecordings
                      }
                      focusKey={localWidgetFocusKey.selectAllButton}
                    />
                  </div>
                  <div className={styles.button}>
                    <FocusButton
                      active={false}
                      data-testid={DATA_TEST_ID + ".delete-bulk-deletion"}
                      text={t("button_delete")}
                      onEnterPress={bulkDeleteRecordings}
                      iconLeft="remove"
                      focusKey={localWidgetFocusKey.deleteButton}
                      disabled={markedForBulkDeletionRecordings.length === 0} // NOTE: if there are no recordings selected or all are deselected
                    />
                  </div>
                  <div className={styles.button}>
                    <FocusButton
                      active={false}
                      data-testid={DATA_TEST_ID + ".cancel-bulk-deletion"}
                      text={t("button_cancel")}
                      onEnterPress={() => setBulkDeletionMode(false)}
                      focusKey={localWidgetFocusKey.cancelButton}
                    />
                  </div>
                </div>
              ) : (
                <div className={styles.buttonsContainer}>
                  {hasRecordings && (
                    <FocusButton
                      data-testid={DATA_TEST_ID + ".change-sort"}
                      text={t("recording_sort")}
                      onEnterPress={() => openSortDialog()}
                      iconLeft="sort"
                      focusKey={localWidgetFocusKey.sortButton}
                    />
                  )}
                  {shouldShowSelectButton && (
                    <FocusButton
                      className={styles.button}
                      data-testid={DATA_TEST_ID + ".select-bulk-deletion"}
                      text={t("recordings_button_start_selection")}
                      onEnterPress={() => setBulkDeletionMode(true)}
                      iconLeft="select"
                      focusKey={localWidgetFocusKey.selectButton}
                    />
                  )}
                </div>
              )
            }
          />
          <RecordingUpsellBanner />
          <RecordingsList
            hasContent={!!hasRecordings}
            focusKey={localWidgetFocusKey.recordings}
            onExitUp={doFocusAboveList}
            onExitLeft={() => {
              if (isBulkDeletionMode) {
                setBulkDeletionMode(false);
              } else {
                menu.expand();
              }
            }}
          />
        </RouteLayout>
      </main>
    </FocusContext.Provider>
  );
}
