import { selectPlanUpgradeAvailableAtom } from "@sunrise/jwt";
import { useTranslator } from "@sunrise/translator";
import { useAtomValue } from "jotai";
import type { ReactNode } from "react";

import { background } from "./recording-upsell-banner.css";

export function RecordingUpsellBanner(): ReactNode {
  const upgradeAvailable = useAtomValue(selectPlanUpgradeAvailableAtom);
  const t = useTranslator();

  if (!upgradeAvailable) return null;

  return (
    <div className={background}>
      <div>{t("description_upsell_recordings_free_user_message")}</div>
    </div>
  );
}
