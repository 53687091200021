import { isTizen } from "@sunrise/utils";
import { atom } from "jotai";
import { isNil } from "lodash-es";

import { getTizenNetwork } from "./system.service";

export const tizenOfflineAtom = atom<null | boolean>(null);
tizenOfflineAtom.onMount = (setValue) => {
  if (!isTizen()) {
    return;
  }

  let listenerId: number;
  getTizenNetwork().then((network) => {
    setValue(network ? !network.isConnectedToGateway() : null);

    if (!network) return;
    listenerId = network.addNetworkStateChangeListener(function (value) {
      if (value == network.NetworkState.GATEWAY_DISCONNECTED) {
        setValue(true);
      } else if (value == network.NetworkState.GATEWAY_CONNECTED) {
        setValue(false);
      } else {
        return;
      }
    });
  });

  return () => {
    if (listenerId && !isNil(window.webapis)) {
      window.webapis.network.removeNetworkStateChangeListener(listenerId);
    }
  };
};
