import { featureAtom } from "@sunrise/feature-flags";

// TODO: Perhaps map this to https://developer.mozilla.org/en-US/docs/Web/CSS/@media/prefers-reduced-motion instead and not have it be an atom.
export const disableAnimationsAtom = featureAtom(
  "disable_animations",
  // By default we want to disable the animations.
  true,
  {
    description: `Whether to disable animations or not.`,
  },
);
