import clsx from "clsx";

import { FocusButton, FocusButtonProps } from "@/components";

import { button } from "./details-button.css";

type DetailsButtonProps = CommonProps & {
  text: string;
  focusKey?: string;
} & NonNullable<
    Pick<FocusButtonProps, "iconLeft" | "iconSize" | "onEnterPress">
  >;

export function DetailsButton(props: DetailsButtonProps): JSX.Element {
  return (
    <FocusButton
      iconLeft={props.iconLeft}
      iconSize={props.iconSize}
      data-testid={props["data-testid"]}
      text={props.text}
      active={false}
      className={clsx(props.className, button)}
      onEnterPress={props.onEnterPress}
    />
  );
}
