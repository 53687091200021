import type { Nullable } from "@sunrise/utils";
import areEqual from "fast-deep-equal";
import { atom } from "jotai";
import { atomFamily } from "jotai/utils";

import {
  continueWatchingInfiniteQueryAtom,
  continueWatchingKnownItemsAtom,
} from "./continue-watching-infinite-query.atom";
import type { EPGEntryId, RecordingId } from "@sunrise/backend-types-core";
import type { ContinueWatchingItem } from "@sunrise/backend-types";

type Filter = {
  epgId: EPGEntryId;
  recordingId: RecordingId | null;
};

/**
 * Should return the continue watching item for the given EPG entry or recording.
 */
export const continueWatchingByEpgIdOrRecordingIdAtom = atomFamily(
  ({ epgId, recordingId }: Filter) => {
    const innerAtom = atom(async (get) => {
      const allItems = get(continueWatchingKnownItemsAtom);

      let found: Nullable<ContinueWatchingItem> = null;
      if (recordingId) {
        found = allItems.find(
          (item) =>
            item.type === "recording" && item.recording.id === recordingId,
        );
      } else {
        found = allItems.find(
          (item) => item.epg_entry.id === epgId && item.type === "replay",
        );
      }

      if (!found) {
        const paged = get(continueWatchingInfiniteQueryAtom);

        if (paged.hasNextPage && !paged.isFetchingNextPage) {
          paged.fetchNextPage();
        }
      }

      return found ?? null;
    });
    innerAtom.debugLabel = `continueWatchingByEpgIdOrRecordingIdAtom(${epgId},${recordingId})`;
    return innerAtom;
  },
  areEqual,
);
