import { useCallback } from "react";
import {
  doesFocusableExist,
  setFocus,
} from "@noriginmedia/norigin-spatial-navigation";
import { Virtualizer } from "@tanstack/react-virtual";
import { useAtomValue } from "jotai";

import { disableAnimationsAtom } from "@sunrise/yallo-settings";

import { localWidgetFocusKey } from "@/utils/virtualizer";

type VirtualizerAlignment = "start" | "center" | "end" | "auto";

type VirtualizerNavigationProps = {
  virtualizer: Virtualizer<HTMLDivElement, Element>;
  focusKey: string;
  align?: VirtualizerAlignment;
};

type HandleGoToColProps = {
  onLimitReachCb: () => void;
  rowIdx: number;
  colIdx: number;
  /** index at which the handler is not executed */
  preventAtIdx: number;
  /** `1` forward, `-1` back */
  delta: 1 | -1;
};

type VirtualizerNavigationReturn = {
  handleGoToRow: (
    rowIdx: number,
    limitIdx: number,
    delta: 1 | -1,
  ) => () => void;
  handleGoToCol: (o: HandleGoToColProps) => void;
};

export const useVirtualizerNavigation = ({
  virtualizer,
  focusKey,
  align = "start",
}: VirtualizerNavigationProps): VirtualizerNavigationReturn => {
  const disableAnimations = useAtomValue(disableAnimationsAtom);

  const handleGoToRow = useCallback(
    (rowIdx: number, limitIdx: number, delta: 1 | -1) => () => {
      if (rowIdx === limitIdx) return;
      const nextIdx = rowIdx + delta;
      const nextFocusKey = localWidgetFocusKey.row(focusKey, nextIdx);

      virtualizer.scrollToIndex(nextIdx, {
        align,
        behavior: disableAnimations ? "auto" : "smooth",
      });

      if (doesFocusableExist(nextFocusKey)) {
        setFocus(nextFocusKey);
      }
    },
    [disableAnimations, focusKey, virtualizer, align],
  );

  const handleGoToCol = useCallback(
    (o: HandleGoToColProps) => {
      const isAtLimit = o.colIdx === o.preventAtIdx;
      if (isAtLimit) {
        o.onLimitReachCb();
        return;
      }

      const nextIdx = o.colIdx + o.delta;
      const nextFocusKey = localWidgetFocusKey.col(focusKey, o.rowIdx, nextIdx);

      virtualizer.scrollToIndex(nextIdx, {
        align,
        behavior: disableAnimations ? "auto" : "smooth",
      });

      if (doesFocusableExist(nextFocusKey)) {
        setFocus(nextFocusKey);
      }
    },
    [disableAnimations, focusKey, virtualizer, align],
  );

  return {
    handleGoToRow,
    handleGoToCol,
  };
};
