import { ArrowPressHandler } from "@noriginmedia/norigin-spatial-navigation";
import {
  ffwdCurrentAdBreakAtom,
  skipFfwdAdButtonVisibleAtom,
} from "@sunrise/ads";
import { playerDateTimeConverterAtom } from "@sunrise/player";
import { useTranslator } from "@sunrise/translator";
import { getPlayerManager } from "@sunrise/yallo-common-player-manager";
import { useAtomValue } from "jotai";
import { useAtomCallback } from "jotai/utils";
import { type ReactNode } from "react";

import { FocusButton } from "@/components";

type SkipFfwdAdsButtonProps = {
  focusKey?: string;
  onArrowPress?: ArrowPressHandler;
  onEnter?: () => void;
} & Pick<CommonProps, "className" | "data-testid">;

export function SkipFfwdAdsButton({
  ...props
}: SkipFfwdAdsButtonProps): ReactNode {
  const skipAdBreakVisible = useAtomValue(skipFfwdAdButtonVisibleAtom);

  const onEnter = useAtomCallback((get) => {
    async function trigger(): Promise<void> {
      const currentBreak = get(ffwdCurrentAdBreakAtom);
      const converter = get(playerDateTimeConverterAtom);
      if (!converter || !currentBreak) {
        return;
      }

      // NOTE: Seeking past the end of an ad break will trigger ad playout after the seek has happened.
      await getPlayerManager().seekToInCurrentPlayRequest(
        converter.fromDate(currentBreak.end),
      );

      props.onEnter?.();
    }

    void trigger();
  });

  const t = useTranslator();

  if (!skipAdBreakVisible) {
    return null;
  }

  const sharedProps = {
    className: props.className,
    variant: "outlined" as const,
    text: t("skip_brake"),
    "data-testid": props["data-testid"],
  };

  return (
    <FocusButton
      {...sharedProps}
      focusKey={props.focusKey}
      onEnterPress={onEnter}
      onArrowPress={props.onArrowPress}
    />
  );
}
