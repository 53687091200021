import type { PlayerAudioTrack } from "../player.types";

export const formatAudioLabel = (
  label: string,
  track: PlayerAudioTrack,
  audioTracks: PlayerAudioTrack[],
) => {
  const sameLangTracks = audioTracks.filter((t) => t.lang === track.lang);

  // Check if there are both Dolby and Stereo tracks for the same language
  const hasDolby = sameLangTracks.some((t) => t.format === "Dolby");
  const hasStereo = sameLangTracks.some((t) => t.format === "Stereo");

  if (hasDolby && hasStereo) {
    if (track.format === "Dolby") {
      label += " 5.1";
    } else if (track.format === "Stereo") {
      label += " Stereo";
    }
  }

  return {
    label,
    value: track.id,
  };
};
