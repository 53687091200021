import { pagedAtom } from "@sunrise/backend-ng-core";
import { channelsForGivenChannelGroupIdQueryNgAtom } from "./queries/channels-for-given-channel-group-id.query.ng.atom";
import { atomFamily } from "jotai/utils";
import type { ChannelGroupId } from "@sunrise/backend-types-core";

export const pagedChannelsForGivenChannelGroupIdNgAtom = atomFamily(
  (channelGroupId: ChannelGroupId) => {
    return pagedAtom(channelsForGivenChannelGroupIdQueryNgAtom(channelGroupId));
  },
);
