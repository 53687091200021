import Cookies from "js-cookie";

import { isSSR } from "@sunrise/utils";

export const cookieStorage = {
  getItem: (key: string) => {
    const storedValue = Cookies.get(key);
    if (storedValue) {
      try {
        const value = JSON.parse(storedValue);
        return value;
      } catch (error: unknown) {
        return;
      }
    }
  },
  setItem: (key: string, value: string) => {
    if (isSSR()) return;
    if (!value) {
      return Cookies.remove(key);
    }

    Cookies.set(key, JSON.stringify(value), { expires: 365 });
  },
  removeItem: (key: string) => {
    if (isSSR()) return;
    Cookies.remove(key);
  },
};
