import { captureMessage, close } from "@sentry/react";
import { selectProcessIsBackgrounded } from "@sunrise/process-visibility";
import { atomEffect } from "jotai-effect";

import { memoryStatusAtom } from "@/modules/memory/memory-status.atom";
import { intentToCloseAtom } from "@/modules/ui/intent-to-close.atom";

export const sentryLogOOMKillsAtom = atomEffect((get) => {
  const killListener = (): void => {
    // Check if the user intended to close.
    const intendedToClose = get(intentToCloseAtom);
    const backgrounded = get(selectProcessIsBackgrounded);

    if (intendedToClose || backgrounded) {
      return;
    }

    // check memory. if it is full, it may very well be an OOM kill. Could also be just a kill because the app got backgrounded.
    const status = get(memoryStatusAtom);
    if (status === "full") {
      // Just logging a simple string here because we do not want to consume more memory.
      captureMessage("OutOfMemoryKill");
      // Instantly flush & close Sentry.
      close(0);
    }
  };

  window.addEventListener("beforeunload", killListener);

  return () => {
    window.removeEventListener("beforeunload", killListener);
  };
});
