import type { RecordingType } from "@sunrise/backend-types";
import type {
  RecordingGroupId,
  RecordingId,
} from "@sunrise/backend-types-core";
import { type Nullable } from "@sunrise/utils";
import { atom } from "jotai";
import { atomFamily } from "jotai/utils";

export type SelectedRecordingReference = {
  rowIdx: number;
  colIdx: number;
  id: RecordingId | RecordingGroupId;
  type: RecordingType;
};

export const selectedRecordingReferenceAtom = atomFamily((name: string) => {
  const innerAtom = atom<Nullable<SelectedRecordingReference>>(null);
  innerAtom.debugLabel = `selectedRecordingReferenceAtom(${name})`;
  return innerAtom;
});
atom<Nullable<SelectedRecordingReference>>(null);
