import { atom } from "jotai";

const KEEPALIVE_INTERVAL_IN_MS = 1000 * 60 * 3;

/**
 * Can be configured to a different value if needed. Else it would be hardcoded.
 *
 * Contains the amount of MS between us sending ping events to the backend. This is necessary because else the socket will be terminated due to inactivity.
 * In the end, the backend will always terminate a socket if it runs too long. But this way it doesn't terminate it too quickly at least.
 */
export const keepAliveIntervalInMsAtom = atom<number>(KEEPALIVE_INTERVAL_IN_MS);
