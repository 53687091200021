import { ReactNode } from "react";

import { RecordingProgramBox } from "@/components/boxes";

export function DebugRecordingBoxes(): ReactNode {
  const longTitle =
    "Very long movie title for a very long time LONG long title very long lots of words Very long movie title for a very long time LONG long title very long lots of words";
  const longSubtitle =
    "Very long subtitle for a very long time LONG long subtitle very long lots of words Very long subtitle for a very long time LONG long subtitle very long lots of words";
  const shortTitle = "Short title";
  const shortSubtitle = "short subtitle";
  const liveProgress = 68;
  const replayProgress = 43;
  const coverImageUrl =
    "https://t4.ftcdn.net/jpg/03/76/25/05/360_F_376250596_JSflOKCNDwwYOmQ6VfuUU7rfO5KlPugZ.jpg";
  const logoUrl =
    "https://media.idownloadblog.com/wp-content/uploads/2018/01/Netflix-Logo.png";
  return (
    <div style={{ margin: "3%" }}>
      <h2>Large program boxes</h2>
      <div style={{ display: "flex" }}>
        <div>
          <RecordingProgramBox
            title={shortTitle}
            subtitle={longSubtitle}
            liveProgress={liveProgress}
            logoUrl={logoUrl}
            coverImageUrl={coverImageUrl}
            seasonAndEpisode="S1E1"
            type="episode"
            active
            airTime="Yesterday"
            start="13:00"
            end="14:00"
            recordingState={"recorded"}
            isBulkDeletionMode={false}
          />
          <RecordingProgramBox
            title={shortTitle}
            subtitle={longSubtitle}
            liveProgress={liveProgress}
            logoUrl={logoUrl}
            coverImageUrl={coverImageUrl}
            seasonAndEpisode="S1E1"
            type="episode"
            expiresIn={2}
            airTime="Tue 02.01.24"
            start="13:00"
            end="14:00"
            recordingState={"recording"}
            isBulkDeletionMode={false}
          />
        </div>
        <div>
          <RecordingProgramBox
            title={longTitle}
            subtitle={shortSubtitle}
            liveProgress={liveProgress}
            replayProgress={replayProgress}
            logoUrl={logoUrl}
            coverImageUrl={coverImageUrl}
            focused
            airTime="Today"
            start="13:00"
            end="14:00"
            recordingState={"recorded"}
            isBulkDeletionMode={false}
          />
        </div>
        <div>
          <RecordingProgramBox
            title={longTitle}
            subtitle={shortSubtitle}
            liveProgress={liveProgress}
            replayProgress={replayProgress}
            logoUrl={logoUrl}
            coverImageUrl={coverImageUrl}
            focused
            airTime="Today"
            start="13:00"
            end="14:00"
            recordingState={"recorded"}
            isBulkDeletionMode={true}
          />
          <RecordingProgramBox
            title={longTitle}
            subtitle={shortSubtitle}
            liveProgress={liveProgress}
            replayProgress={replayProgress}
            logoUrl={logoUrl}
            coverImageUrl={coverImageUrl}
            focused
            airTime="Today"
            start="13:00"
            end="14:00"
            recordingState={"recorded"}
            isBulkDeletionMode={true}
            isMarkedForDeletion
          />
        </div>
      </div>
    </div>
  );
}
