import { isNil } from "@sunrise/utils";

type TizenRemoteKey =
  | "Back"
  | "Caption"
  | "ChannelDown"
  | "ChannelList"
  | "ChannelUp"
  | "ColorF0Red"
  | "ColorF1Green"
  | "ColorF2Yellow"
  | "ColorF3Blue"
  | "Exit"
  | "Guide"
  | "Info"
  | "MediaAdvance"
  | "MediaFastForward"
  | "MediaPause"
  | "MediaPlay"
  | "MediaPlayPause"
  | "MediaRecord"
  | "MediaRewind"
  | "MediaStop"
  | "MediaTrackNext"
  | "MediaTrackPrevious"
  | "Menu"
  | "PictureSize"
  | "PreviousChannel"
  | "Search"
  | "Source"
  | "Teletext"
  | "0"
  | "1"
  | "2"
  | "3"
  | "4"
  | "5"
  | "6"
  | "7"
  | "8"
  | "9";

type TizenRemoteKeyIdKeyCodes = Record<TizenRemoteKey, string>;

export const tizenRemoteKeyIdToKeyboardEventKeyDict = {
  Back: "XF86Back",
  ChannelDown: "XF86LowerChannel",
  ChannelUp: "XF86RaiseChannel",
  ColorF0Red: "XF86Red",
  ColorF1Green: "XF86Green",
  ColorF2Yellow: "XF86Yellow",
  ColorF3Blue: "XF86Blue",
  Info: "XF86Info",
  MediaAdvance: "XF86AudioNext",
  MediaFastForward: "XF86AudioRewind",
  MediaPause: "XF86AudioPause",
  MediaPlay: "XF86AudioPlay",
  MediaRewind: "XF86AudioRewind",
  MediaTrackNext: "XF86AudioNext",
  MediaTrackPrevious: "XF86AudioPrev",
  Menu: "XF86Menu",
  PictureSize: "XF86AspectRatio",
  PreviousChannel: "XF86PreviousChannel",
  Search: "XF86Search",
  Source: "XF86Source",
  Teletext: "XF86Teletext",
  Exit: "XF86Exit",
  Guide: "XF86ChannelGuide",
  Caption: "XF86Caption",
  ChannelList: "XF86ChannelList",
  MediaPlayPause: "XF86PlayBack",
  MediaRecord: "XF86AudioRecord",
  MediaStop: "XF86AudioStop",
  "0": "0",
  "1": "1",
  "2": "2",
  "3": "3",
  "4": "4",
  "5": "5",
  "6": "6",
  "7": "7",
  "8": "8",
  "9": "9",
} satisfies TizenRemoteKeyIdKeyCodes;

type ReadyToRegisterKeys = {
  correct: string[];
  incorrect: string[];
};

/**
 * NOTE: The application cannot register the mandatory keys (ArrowLeft, ArrowRight, ArrowUp, ArrowDown, Enter, and Back).
 * @see https://docs.tizen.org/application/web/guides/text-input/input-device/
 */
export function makeKeysToRegister(
  supportedKeys: string[],
): ReadyToRegisterKeys {
  const EXCEPTION_KEYS = [
    "ArrowLeft",
    "ArrowRight",
    "ArrowUp",
    "ArrowDown",
    "Enter",
    "Back",
  ];

  return Object.keys(
    tizenRemoteKeyIdToKeyboardEventKeyDict,
  ).reduce<ReadyToRegisterKeys>(
    (acc, k) => {
      if (EXCEPTION_KEYS.includes(k)) return acc;
      if (supportedKeys.includes(k)) acc.correct.push(k);
      else acc.incorrect.push(k);
      return acc;
    },
    { correct: [], incorrect: [] },
  );
}

export function initTvRemote(o: {
  onKeyRegistrationError: (err: Error) => void;
  onValidationError: (message: string) => void;
  registerKeys: (r: TizenRemoteKeyIdKeyCodes) => void;
}): void {
  const tizen = window.tizen;
  if (isNil(tizen) || isNil(tizen.tvinputdevice)) return;

  const supportedKeys = tizen.tvinputdevice
    .getSupportedKeys()
    .map((k) => (typeof k === "string" ? k : k.name));

  const filteredKeys = makeKeysToRegister(supportedKeys);

  tizen.tvinputdevice.registerKeyBatch(
    filteredKeys.correct,
    () => o.registerKeys(tizenRemoteKeyIdToKeyboardEventKeyDict),
    o.onKeyRegistrationError,
  );

  const didFailedToRegisterSomeKeys = !!filteredKeys.incorrect.length;
  if (didFailedToRegisterSomeKeys) {
    o.onValidationError(makeValidationErrorMessage(filteredKeys.incorrect));
  }
}

export function makeValidationErrorMessage(keys: string[]): string {
  return `TV remote keys '${keys.join(", ")}' are not supported`;
}
