import { atomEffect } from "jotai-effect";

import { socketAtom } from "@sunrise/yallo-websocket";

import { pageContentAtom } from "./page-content.atom";
import { shouldBlockPageAnalyticsAtom } from "./should-block-page-analytics.atom";

/**
 * Automatically sends the page analytics when the page content changes.
 */
export const pageAnalyticsRunEffect = atomEffect((get) => {
  const socket = get(socketAtom);

  if (!socket) {
    return;
  }

  // Under some conditions we need to block the page analytics.
  // For Tizen we do this when the menu is open. Then the page view does not yet count for analytics.
  const shouldBlockPageAnalytics = get(shouldBlockPageAnalyticsAtom);
  if (shouldBlockPageAnalytics) {
    return;
  }

  const content = get(pageContentAtom);
  if (!content) {
    return;
  }

  socket.logNavigation(
    content.id === "detail_page" || content.id === "series_recording_page"
      ? {
          pageId: content.id,
          assetId: content.assetId,
          epgEntryId: content.epgEntryId,
        }
      : {
          pageId: content.id,
        },
  );
});
pageAnalyticsRunEffect.debugLabel = "pageAnalyticsRunEffect";
