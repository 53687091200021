import { hostsAtom, httpClientAtom } from "@sunrise/http-client";
import { selectJwtUserToken } from "@sunrise/jwt";
import {
  atomWithMutation,
  atomWithQuery,
  queryClientAtom,
} from "jotai-tanstack-query";
import { atomFamily } from "jotai/utils";
import { isNil } from "lodash";

import { flushRecordingsState } from "../flush-recordings-state";
import { recordingStatusByEpgIdAtom } from "../recording-status-by-epg-id.atom";
import {
  createRecordingSchedule,
  deleteRecordingSchedule,
  haveRecordingSchedules,
} from "../recordings.service";
import {
  queryKeys,
  type RecordingSchedule,
  type RecordingScheduleType,
  type RecordingStatus,
} from "@sunrise/backend-types";
import type {
  AssetId,
  EPGEntryId,
  RecordingId,
} from "@sunrise/backend-types-core";

export const recordingSchedulesMutationAtom = atomWithMutation<
  RecordingSchedule,
  {
    epgEntryId: EPGEntryId;
    providerName: string;
    type: RecordingScheduleType;
  }
>((get) => {
  const host = get(hostsAtom).api;
  if (isNil(host)) throw new Error("Host is not set");

  const privateApi = get(httpClientAtom).privateApi;
  if (isNil(privateApi)) throw new Error("Private api is not set");

  const queryClient = get(queryClientAtom);
  const queryKey = queryKeys.recordingsStatus(get(selectJwtUserToken));

  return {
    mutationKey: ["recording-schedules"],
    mutationFn: ({ epgEntryId, providerName, type }) =>
      createRecordingSchedule(host, privateApi, epgEntryId, providerName, type),
    onMutate: ({ epgEntryId }) => {
      // NOTE: temporarily add new planned status. For new past recordings we are also using planned initially.
      const statuses = (queryClient.getQueryData(queryKey) ||
        []) as RecordingStatus[];

      statuses.push({
        id: "" as RecordingId,
        status: "planned",
        epg_entry: {
          id: epgEntryId,
        },
      });

      recordingStatusByEpgIdAtom.remove(epgEntryId);
      queryClient.setQueryData(queryKey, statuses);
    },
    onSettled: function (_, err) {
      if (err) {
        // Make sure to remove any temporary status when we error.
        const statuses = queryClient.getQueryData(queryKey) as
          | RecordingStatus[]
          | null;
        if (statuses) {
          queryClient.setQueryData(
            queryKey,
            statuses.filter((s) => !!s.id),
          );
        }
      }
      flushRecordingsState(queryClient, get);
    },
  };
});

export const deleteRecordingScheduleMutationAtom = atomWithMutation<
  void,
  {
    epgEntryId: EPGEntryId;
  },
  unknown
>((get) => {
  const host = get(hostsAtom).api;
  if (isNil(host)) throw new Error("Host is not set");

  const privateApi = get(httpClientAtom).privateApi;
  if (isNil(privateApi)) throw new Error("Private api is not set");

  const queryClient = get(queryClientAtom);
  const queryKey = queryKeys.recordingsStatus(get(selectJwtUserToken));

  return {
    mutationKey: ["delete-recording-schedule"],
    mutationFn: ({ epgEntryId }) =>
      deleteRecordingSchedule(host, privateApi, epgEntryId),
    onMutate: ({ epgEntryId }) => {
      // NOTE: temporarily remove status
      let statuses = (queryClient.getQueryData(queryKey) ||
        []) as RecordingStatus[];
      statuses = statuses.filter((s) => s.epg_entry.id !== epgEntryId);

      recordingStatusByEpgIdAtom.remove(epgEntryId);
      queryClient.setQueryData(queryKey, statuses);
    },
    onSettled: function () {
      flushRecordingsState(queryClient, get);
    },
  };
});

export const haveRecordingSchedulesAtom = atomFamily((assetId: AssetId) =>
  atomWithQuery<boolean>((get) => {
    const hosts = get(hostsAtom);
    const host = hosts.api;
    if (isNil(host)) throw new Error("api host is not defined");

    const privateApi = get(httpClientAtom).privateApi;
    if (isNil(privateApi)) throw new Error("Private api is not set");

    return {
      queryKey: queryKeys.haveRecordingSchedules(
        get(selectJwtUserToken),
        assetId,
      ),
      queryFn: async () => {
        if (isNil(assetId)) return false;

        return haveRecordingSchedules(host, privateApi, assetId);
      },
    };
  }),
);
