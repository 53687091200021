import { searchActiveFilterAtom } from "@sunrise/search";
import { useTranslator } from "@sunrise/translator";
import { useAtom } from "jotai";
import { ReactNode, useCallback } from "react";

import { FocusButton } from "../buttons/focus-button/focus-button";
import * as styles from "./filter-controls.css";

export function FilterControls(props: CommonProps): ReactNode {
  const t = useTranslator();

  const [current, setCurrent] = useAtom(searchActiveFilterAtom);
  const setAvailable = useCallback(() => {
    setCurrent("past");
  }, [setCurrent]);
  const setFuture = useCallback(() => {
    setCurrent("future");
  }, [setCurrent]);

  return (
    <div {...props}>
      <FocusButton
        active={current === "past"}
        data-testid="filter-controls.past"
        className={styles.button}
        text={t("search_title_epg_past")}
        onEnterPress={setAvailable}
      />
      <FocusButton
        active={current === "future"}
        data-testid="filter-controls.future"
        className={styles.button}
        text={t("search_title_epg_future")}
        onEnterPress={setFuture}
      />
    </div>
  );
}
