import { selectPlayerCurrentPlayRequest } from "@sunrise/player";
import { atom } from "jotai";

import { playerManagerAtom } from "./player-manager.atom";

/**
 * Will always return a playRequest as long as something is playing.
 * Will instantly return the value the playerManager is working on (if any) and else delegate to the player's playRequest.
 *
 * We need this because in some cases we want to instantly respond to the user's request to play something. Like zapping.
 */
export const currentlyRequestedPlayRequestAtom = atom((get) => {
  return (
    get(playerManagerAtom).playRequest ?? get(selectPlayerCurrentPlayRequest)
  );
});
