import type { Language } from "@sunrise/backend-types-core";
import { GetTranslationFileFn } from "@sunrise/translator";

export const getTranslationFile = async (
  language: Language,
): ReturnType<GetTranslationFileFn> => {
  switch (language) {
    case "de":
      return (await import("@/static/i18n/de-ch.json")).default;
    case "fr":
      return (await import("@/static/i18n/fr-ch.json")).default;
    case "it":
      return (await import("@/static/i18n/it-ch.json")).default;
    case "en":
    default:
      return (await import("@/static/i18n/en-gb.json")).default;
  }
};
