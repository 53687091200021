import {
  actionPlayerSetAdConfig,
  getAdConfig,
  getAlternativeVideoAds,
  videoAdsAtom,
} from "@sunrise/ads";
import type {
  VideoAdConfig,
  VideoAdPlacementType,
} from "@sunrise/backend-types";
import type { ChannelId, EPGEntryId } from "@sunrise/backend-types-core";
import { hostsAtom, httpClientAtom } from "@sunrise/http-client";
import { currentLanguageAtom } from "@sunrise/i18n";
import {
  type LoadOptions,
  type PlayRequestToAdPlayout,
} from "@sunrise/player-manager";
import { type Store, untilAtomChanged } from "@sunrise/store";
import { isNil, type Nullable } from "@sunrise/utils";
import { epgDetailsForEpgEntryId } from "@sunrise/yallo-epg";
import type { PlayRequest } from "@sunrise/yallo-player-types";
import { disableVideoAdsAtom } from "@sunrise/yallo-settings";

import { PlayerManagerPermissions } from "./yallo-common-player-manager.types";

type AdConfigHandler = (
  adConfig: Nullable<VideoAdConfig>,
) => () => Promise<void>;

/**
 * This will create a function that can be used to play out ads through the PlayerManager.
 * It is of the type PlayRequestToAdPlayout.
 * That means it will receive a playrequest, that will return a promise to a function.
 * This function will then know about all it has to do in order to start playing out ads (if necessary). Let's call this the `AdPlayoutFunction`.
 *
 * The PlayoutFunction will always need to be invoked and it will always return a promise.
 * When there are no ads to play it will instantly return a resolved promise.
 * When there are no ads but there were already other ads playing, it will return a promise to when the already playing ads are done playing.
 * When there are new ads and there are no ads playing yet, it will queue up the ads to be played when invoked and it will return a promise that resolves when these new ads are done playing.
 *
 * This cleanly encapsulates all that the PlayerManager requires to know about ads.
 * When another part of the app needs to know if ads are playing, you can always consult the videoAdsAtom's state.
 */
export function createPlayRequestToAdPlayout(
  store: Store,
  getPermissions: (store: Store) => Nullable<PlayerManagerPermissions>,
): PlayRequestToAdPlayout<PlayRequest> {
  /**
   * When there is no more adConfig then the ads are done playing.
   */
  function createPromiseWhenAdsAreDone(): Promise<void> {
    return untilAtomChanged(store, videoAdsAtom, (state) => !state.adConfig);
  }

  /**
   * This function will look at the current state of ads playout and the new ads that need to play out.
   * It'll then determine if it needs to replace the current ad playback or not.
   *
   * @returns
   *   A promise that resolves as soon as ad playout is done.
   *   Will immediately resolve if there are no ads to be played out.
   *   If there are ads to be playing out already, it will resolve when those ads are done playing.
   *   Basically, it will resolve whenever ad playout is done and the PlayerManager can continue to load up the stream.
   */
  function handleAdTags(
    adConfig: Nullable<VideoAdConfig>,
  ): () => Promise<void> {
    return () => {
      const isPlaying = store.get(videoAdsAtom).isPlaying;

      // Do not override existing ad config. We need to keep playing out the ads.
      if (!isPlaying && adConfig && adConfig.tag_count > 0) {
        // We send the ads to the store, which should result in the ads kicking in.
        store.set(videoAdsAtom, actionPlayerSetAdConfig(adConfig));
        // Wait until ads are done playing out.
        return createPromiseWhenAdsAreDone();
      }

      if (isPlaying) {
        return createPromiseWhenAdsAreDone();
      }

      return Promise.resolve();
    };
  }

  /**
   * Naive implementation since we only have live playout atm.
   *
   * This function will make sure to request the needed ads from the backend related to the playRequest.
   *
   * @throws Throws whatever getAdConfig can throw.
   */
  return async (playRequest: PlayRequest, options: Nullable<LoadOptions>) => {
    const disableVideoAds = store.get(disableVideoAdsAtom);

    if (!disableVideoAds) {
      if (
        (playRequest.type === "live" || playRequest.type === "recording") &&
        getPermissions(store)?.showAds
      ) {
        return handlePreAds(store, handleAdTags, playRequest.type);
      }

      if (
        playRequest.type === "replay" &&
        getPermissions(store)?.showReplayAlternativeAds &&
        // We only want to show ads when we are at the start of the replay stream.
        // Either because the user started the replay from the start or because the user seeked to the start.
        (options?.originatingAction === "play-from-start" ||
          options?.originatingAction === "seek-to-start")
      ) {
        return handleReplayAds(
          store,
          playRequest.channelId,
          playRequest.epgId,
          handleAdTags,
        );
      }
    }

    return () => Promise.resolve();
  };
}

async function handleReplayAds(
  store: Store,
  channelId: ChannelId,
  epgId: EPGEntryId,
  handleAdTags: AdConfigHandler,
): Promise<() => Promise<void>> {
  // TODO: Do not load the epgDetails if we received the startTime in the playRequest.
  const epgDetails = await store.get(epgDetailsForEpgEntryId({ epgId }));

  const host = store.get(hostsAtom).api;
  if (isNil(host)) throw new Error("No host found");

  const privateApi = store.get(httpClientAtom).privateApi;
  if (!privateApi) throw new Error("No privateApi found");

  const ads = await getAlternativeVideoAds({
    privateApi,
    host,
    channelId,
    startTime: new Date(epgDetails.actual_start),
    type: "replay",
  });

  return handleAdTags(ads);
}

async function handlePreAds(
  store: Store,
  handleAdTags: AdConfigHandler,
  placement: VideoAdPlacementType,
): Promise<() => Promise<void>> {
  const language = store.get(currentLanguageAtom);

  const host = store.get(hostsAtom).api;
  if (isNil(host)) throw new Error("No host found");

  const privateApi = store.get(httpClientAtom).privateApi;
  if (!privateApi) throw new Error("No privateApi found");

  const adConfig = await getAdConfig({
    privateApi,
    host,
    placement,
    language,
  });

  return handleAdTags(adConfig);
}
