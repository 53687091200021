import { atom } from "jotai";

import { skipFfwdAdButtonVisibleAtom } from "./skip-ffwd-ad-button-visible.atom";
import { skipVideoAdButtonVisibleAtom } from "./skip-video-ad-button.visible.atom";
import { skipWhyAdsButtonVisibleAtom } from "./skip-why-ads-button-visible.atom";

export const adControlsVisible = atom(
  (get) =>
    get(skipVideoAdButtonVisibleAtom) ||
    get(skipFfwdAdButtonVisibleAtom) ||
    get(skipWhyAdsButtonVisibleAtom),
);
