import {
  IPoeTranslation,
  PoeTranslation,
  type Translations,
  translationsParser,
} from "translations";

import type { Language } from "@sunrise/backend-types-core";

import { GetTranslationFileFn } from "./translator.types";

export function parseTranslation(data: IPoeTranslation[]): Translations {
  const mappedTranslations = data.map(
    (translation) => new PoeTranslation(translation),
  );

  return translationsParser.fromExternalTranslation(mappedTranslations);
}

export async function loadTranslation(
  language: Language,
  getTranslationFile: GetTranslationFileFn,
): Promise<Translations> {
  const data = await getTranslationFile(language);
  return parseTranslation(data);
}
