import { JSX, ReactElement, type ReactNode, useEffect } from "react";
import {
  FocusContext,
  useFocusable,
} from "@noriginmedia/norigin-spatial-navigation";
import * as Sentry from "@sentry/react";
import { useBrowserLocation } from "wouter/use-browser-location";

import { TranslationProvider, useTranslator } from "@sunrise/translator";

import { FocusButton } from "@/components";
import { DebugVersion } from "@/components/debug/debug-version";
import { globalFocusKey } from "@/config/focus-key";
import { rawRoute } from "@/config/route";
import { useKeyboardNavigation } from "@/modules/keyboard-navigation";
import { closeApp } from "@/modules/platform/system.service";

import * as styles from "./error-boundary.css";

const localWidgetFocusKey = {
  errorPage: globalFocusKey.errorPage,
  closeButton: globalFocusKey.errorPage + "error-button-close",
};

export type MonitoringErrorBoundaryProps = {
  children: JSX.Element;
  fallback?: Sentry.FallbackRender;
};

function getErrorMessage(error: unknown): string | null {
  if (error instanceof Error) {
    return error.message;
  }

  if (typeof error === "string") {
    return error;
  }

  return null;
}

const fallback: Sentry.FallbackRender = ({ error, eventId }): ReactElement => {
  // TODO: Inject a QR code that contains the eventId for easy copy/pasta.
  // TODO: YALLOTV-13290 implement error page
  return (
    <TranslationProvider>
      <div className={styles.container}>
        <h1 className={styles.header}>oh snap!</h1>
        <p className={styles.description}>{getErrorMessage(error)}</p>
        <p className={styles.trait}>eventId: {eventId}</p>
        <p className={styles.trait}>at: {new Date().toISOString()}</p>
        <DebugVersion />
        <FallbackButtons />
      </div>
    </TranslationProvider>
  );
};

function FallbackButtons(): ReactNode {
  const [, navigate] = useBrowserLocation();

  const navigateBack = (): void => {
    navigate(rawRoute.home);
    // the router etc is not available in this context, so we need to reload the page to recover
    window.location.reload();
  };

  useKeyboardNavigation({
    onBack: navigateBack,
    isEnabled: true,
  });

  return <CloseButton className={styles.button} />;
}

export function ErrorBoundary(
  props: MonitoringErrorBoundaryProps,
): JSX.Element {
  return (
    <Sentry.ErrorBoundary
      fallback={props.fallback ?? fallback}
      onError={
        import.meta.env.MODE !== "production"
          ? (e) => console.error(e)
          : undefined
      }
    >
      {props.children}
    </Sentry.ErrorBoundary>
  );
}

export const CloseButton = ({
  className,
}: CommonProps): ReactElement | undefined => {
  const { focusKey, ref, focusSelf } = useFocusable({
    focusKey: localWidgetFocusKey.errorPage,
    focusable: true,
    preferredChildFocusKey: localWidgetFocusKey.closeButton,
  });

  const t = useTranslator();

  const onEnter = (): void => {
    closeApp?.();
  };

  useEffect(() => {
    focusSelf();
  }, [focusSelf]);

  return (
    <div ref={ref} className={className}>
      <FocusContext.Provider value={focusKey}>
        <FocusButton
          text={t("button_close_app")}
          onEnterPress={onEnter}
          focusKey={localWidgetFocusKey.closeButton}
          block
        />
      </FocusContext.Provider>
    </div>
  );
};
