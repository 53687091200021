import type { Language } from "@sunrise/backend-types-core";
import { isSSR } from "@sunrise/utils";
import { atomWithStorage } from "jotai/utils";

import { getBrowserLanguage } from "./get-browser-language";

/**
 * Stores the current language of the application.
 */
export const currentLanguageAtom = atomWithStorage<Language>(
  "current-language",
  getBrowserLanguage(isSSR() ? "de" : window.navigator.language), // if window is undefined, means we're running on a server
);
