import { Suspense, useCallback, useEffect, useRef } from "react";
import {
  doesFocusableExist,
  FocusContext,
  getCurrentFocusKey,
  setFocus,
  useFocusable,
} from "@noriginmedia/norigin-spatial-navigation";
import { atom, useAtomValue } from "jotai";
import { useAtomCallback } from "jotai/utils";

import { debouncedQueryAtoms } from "@sunrise/search";
import {
  addSearchHistoryMutationAtom,
  hasSearchHistoryAtom,
} from "@sunrise/search-history";

import { Header, RouteLayout } from "@/components";
import { FilterControls } from "@/components/search/filter-controls";
import { useMenu } from "@/features/menu/use-menu";
import { ChannelSearchResults } from "@/features/search/channel-search-results";
import { ProgramSearchResults } from "@/features/search/program-search-results";
import { SearchHistory } from "@/features/search/search-history";
import { SearchInput } from "@/features/search/search-input/search-input";
import { selectedSearchItemAtom } from "@/features/search/search-list.atom";
import { useKeyboardNavigation } from "@/modules/keyboard-navigation";
import { isArrowDownKey, isArrowLeftKey } from "@/utils/navigation";

import * as styles from "./search.css";

const localWidgetFocusKey = {
  searchInput: "search.input",
  page: "search.page",
  list: "search.list",
  channels: "search.channels",
  history: "search.history",
};

const searchInitialFocusAtom = atom((get) => {
  const selected = get(selectedSearchItemAtom);
  if (selected?.type === "channel") {
    return localWidgetFocusKey.channels;
  } else if (selected?.type === "epg") {
    return localWidgetFocusKey.list;
  } else {
    return localWidgetFocusKey.searchInput;
  }
});

export default function Search(): JSX.Element {
  const menu = useMenu({
    expandOnBack: false,
  });

  const preferredChildFocusKey = useAtomValue(searchInitialFocusAtom);
  const { focusKey, focusSelf, ref } = useFocusable({
    focusKey: localWidgetFocusKey.page,
    focusable: true,
    isFocusBoundary: true,
    autoRestoreFocus: true,
    saveLastFocusedChild: true,
    forceFocus: true,
    onArrowPress: function handleArrowPress(direction) {
      if (isArrowLeftKey(direction)) menu.expand();
      return false;
    },
    preferredChildFocusKey,
  });

  const focusMenu = useCallback(() => {
    menu.expand();
  }, [menu]);
  const focusInput = useCallback(() => {
    setFocus(localWidgetFocusKey.searchInput);
  }, []);
  const focusList = useCallback(() => {
    if (doesFocusableExist(localWidgetFocusKey.list)) {
      setFocus(localWidgetFocusKey.list);
    }
  }, []);

  const registerSearchHistory = useAtomCallback(
    useCallback(async (get) => {
      const value = get(debouncedQueryAtoms.currentValueAtom);
      get(addSearchHistoryMutationAtom).mutateAsync(value);
    }, []),
  );

  const focusRelevantResult = useCallback(() => {
    registerSearchHistory();

    if (doesFocusableExist(localWidgetFocusKey.channels)) {
      setFocus(localWidgetFocusKey.channels);
      return;
    }

    focusList();
  }, [focusList, registerSearchHistory]);

  useEffect(
    function delegateFocus() {
      if (menu.isExpanded) return;
      focusSelf();
    },
    [focusSelf, menu.isExpanded],
  );
  const hasSearchHistory = useAtomValue(hasSearchHistoryAtom);

  const onBack = useCallback(() => {
    if (getCurrentFocusKey() !== localWidgetFocusKey.searchInput) {
      focusInput();
    } else {
      menu.expand();
    }
  }, [focusInput, menu]);

  useKeyboardNavigation({
    onBack,
    isEnabled: !menu.isExpanded,
  });

  const parentScrollRef = useRef<HTMLDivElement>(null);

  return (
    <FocusContext.Provider value={focusKey}>
      <main className={styles.root} ref={ref} tabIndex={0} data-testid="search">
        <RouteLayout>
          <Header
            className={styles.headerContainer}
            left={
              <div className={styles.header}>
                <SearchInput
                  focusKey={localWidgetFocusKey.searchInput}
                  onArrowPress={(direction) => {
                    if (isArrowLeftKey(direction)) {
                      onBack();
                      return false;
                    }

                    // check if there is a search history item to focus
                    if (
                      isArrowDownKey(direction) &&
                      doesFocusableExist(localWidgetFocusKey.history) &&
                      hasSearchHistory
                    ) {
                      setFocus(localWidgetFocusKey.history);
                      registerSearchHistory();
                      return false;
                    }

                    registerSearchHistory();

                    return true;
                  }}
                  onEnterPress={focusRelevantResult}
                />
                <FilterControls className={styles.headerButtons} />
              </div>
            }
          />
          <Suspense>
            <div className={styles.verticalScroll} ref={parentScrollRef}>
              <div className={styles.verticalScrollInner}>
                <SearchHistory
                  focusKey={localWidgetFocusKey.history}
                  onHide={focusInput}
                  exitLeft={focusMenu}
                />
                <Suspense>
                  <ChannelSearchResults
                    focusKey={localWidgetFocusKey.channels}
                    exitLeft={focusMenu}
                  />
                  <ProgramSearchResults
                    focusKey={localWidgetFocusKey.list}
                    exitLeft={focusMenu}
                    parentScrollRef={parentScrollRef}
                  />
                </Suspense>
              </div>
            </div>
          </Suspense>
        </RouteLayout>
      </main>
    </FocusContext.Provider>
  );
}
