import { ReactNode } from "react";

import { ChannelBox } from "@/components/boxes";

export function DebugChannelBoxes(): ReactNode {
  const coverImageUrl =
    "https://pixabay.com/get/g8bab5c8ddb1306ea81a56967922e4474da94925ac64913fd0cea02a8842efc9db45d43b97afa3a97b1f09ce43a910335_1280.jpg";
  const logoUrl =
    "https://media.idownloadblog.com/wp-content/uploads/2018/01/Netflix-Logo.png";
  return (
    <div style={{ margin: "3%", width: "70%" }}>
      <div>
        <h2>Channel boxes</h2>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <ChannelBox logoUrl={logoUrl} coverImageUrl={coverImageUrl} />
          <ChannelBox logoUrl={logoUrl} coverImageUrl={coverImageUrl} focused />
          <ChannelBox logoUrl={logoUrl} coverImageUrl={coverImageUrl}>
            {"lock here"}
          </ChannelBox>
        </div>
      </div>
    </div>
  );
}
