import {
  playerAtom,
  selectPlayerCurrentPlayRequest,
  selectPlayerCurrentStream,
  selectPlayerState,
  thumbnailGeneratorAtom,
} from "@sunrise/player";
import {
  selectPlayerSimpleStats,
  selectPlayerStatsConfiguration,
} from "@sunrise/player-stats";
import { getStreamStats } from "@sunrise/yallo-stream";
import { useAtomValue } from "jotai";
import { type ReactNode } from "react";

import { container } from "./player-stats.css";

/**
 * If we generate this selector dynamically in every render it causes a crash with running setState in a useEffect.
 * We need to get rid of all of these dynamic selectors.
 */
const badSelectorForStream = selectPlayerCurrentStream(playerAtom);

export function PlayerStats(): ReactNode {
  const stats = useAtomValue(selectPlayerSimpleStats);
  const config = useAtomValue(selectPlayerStatsConfiguration);
  const playRequest = useAtomValue(selectPlayerCurrentPlayRequest);
  const stream = useAtomValue(badSelectorForStream);
  const state = useAtomValue(selectPlayerState);
  const thumb = useAtomValue(thumbnailGeneratorAtom);

  if (!stats) {
    return null;
  }

  const extra = {
    playRequest,
    stream: getStreamStats(stream),
    state,
    thumbnailGen: thumb?.name,
    config: config
      ? {
          ...config,
          drm: undefined,
        }
      : undefined,
  };

  return (
    <div className={container}>
      <pre>
        {JSON.stringify(stats, null, 2)}
        {"\n\n"}
        {JSON.stringify(extra, null, 2)}
      </pre>
    </div>
  );
}
