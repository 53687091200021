import { featureAtom } from "@sunrise/feature-flags";

export const playerAnalyticsShouldReactToVisibilityChangeAtom = featureAtom(
  "player-analytics-send-stop-on-backgrounded",
  true,
  {
    description:
      "Should the player analytics send a stop event when the app goes in the background?",
  },
);
