import { useAtom, useAtomValue } from "jotai";
import { useCallback, useEffect } from "react";

import { useKeyboardNavigation } from "@/modules/keyboard-navigation";
import {
  actionMenuCollapse,
  actionMenuExpand,
  actionMenuHide,
  actionMenuShow,
  isMenuAllowedAtom,
  menuAtom,
} from "@/modules/menu";

export function useMenu({
  shouldShowMenu = true,
  expandOnBack = true,
  hidden = false,
}: {
  shouldShowMenu?: boolean;
  expandOnBack?: boolean;
  hidden?: boolean;
} = {}): { expand: () => void; isExpanded: boolean } {
  const [{ isExpanded }, dispatchMenu] = useAtom(menuAtom);
  const isAllowed = useAtomValue(isMenuAllowedAtom);

  useEffect(
    function forceVisibility() {
      dispatchMenu(
        // Do not allow showing the menu when it is not allowed.
        shouldShowMenu && !hidden && isAllowed
          ? actionMenuShow()
          : actionMenuHide(),
      );
    },
    [dispatchMenu, shouldShowMenu, hidden, isAllowed],
  );

  useEffect(
    function forceExpansion() {
      if (isExpanded && hidden) dispatchMenu(actionMenuCollapse());
    },
    [isExpanded, hidden, dispatchMenu],
  );

  const expand = useCallback(() => {
    if (!isAllowed) {
      // Do not allow the menu to be expanded when the menu is not allowed.
      return;
    }
    dispatchMenu(actionMenuExpand());
  }, [dispatchMenu, isAllowed]);

  useKeyboardNavigation({
    onBack: expand,
    isEnabled: expandOnBack && !hidden && !isExpanded && isAllowed,
  });

  return {
    expand,
    isExpanded,
  };
}
