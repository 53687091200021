import { errorAtom } from "@sunrise/error";
import { actionPlayerAutoStop, playerAtom } from "@sunrise/player";
import { useAtomValue, useSetAtom } from "jotai";
import { useEffect } from "react";

import { shouldPlayerStopAtom } from "../should-player-stop.atom";

/**
 * We need to listen to the environment to then determine when we need to stop a stream.
 * There's also a system in the player controller itself to unload the player when the user logs out.
 * That is not an autostop though. It's more of a forced stop.
 *
 * Autopstop means that the stream is stopped because of a business rule being hit. And so the user can no longer watch content.
 * It also means the device should no longer attempt to autostart or autorecover.
 *
 * This could also be done as part of an atomEffect should we want to refactor later.
 */
export function useAutostop(): void {
  const setPlayer = useSetAtom(playerAtom);
  const setError = useSetAtom(errorAtom);
  const shouldAutoStop = useAtomValue(shouldPlayerStopAtom);

  // When we reach the usage limit and we are playing something in the player we need to inform the user.
  useEffect(() => {
    if (shouldAutoStop) {
      // We need to stop the player.
      setPlayer(actionPlayerAutoStop());

      // Emit the error we just hit if we hit one.
      if (shouldAutoStop instanceof Error) {
        setError(shouldAutoStop);
      }
    }
  }, [shouldAutoStop, setError, setPlayer]);
}
