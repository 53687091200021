import { type Store } from "@sunrise/store";

import { getVideoAdsElement } from "./get-video-ads-element";
import { PauseAds } from "./pause-ads";
import { VideoAds } from "./video-ads";
import type { VideoAdScreenSize } from "@sunrise/backend-types";

let videoAdsInternal: VideoAds | undefined;

export function skipAd(): void {
  return videoAdsInternal?.manualSkip();
}

export function initVideoAds(
  store: Store,
  getVideoElement: () => HTMLVideoElement,
  showPlayer: () => void,
  allowSkippingAds = false,
  screenSize?: VideoAdScreenSize,
): VideoAds {
  videoAdsInternal = new VideoAds(
    store,
    getVideoElement(),
    getVideoAdsElement(),
    showPlayer,
    screenSize,
  );

  // allow skipping ads w/ a keyboard shortcut
  if (allowSkippingAds) {
    window.addEventListener("keypress", (e) => {
      if (e.defaultPrevented) {
        return;
      }

      if (e.key === "s") {
        videoAdsInternal?.manualSkip();
      }
    });
  }

  return videoAdsInternal;
}

export function initPauseAds(store: Store): PauseAds {
  return new PauseAds(store);
}
