import { errorAtom } from "@sunrise/error";
import { actionJWTClear, actionJWTSetTokens, jwtAtom } from "@sunrise/jwt";
import { type Store } from "@sunrise/store";
import type { Nullable } from "@sunrise/utils";
import { AxiosError } from "axios";
import { isNil } from "lodash";

import { PrivateApiClient } from "./private-api-client";
import type { RefreshTokens, RetryFunction } from "@sunrise/backend-core";

export const createPrivateApi = (
  store: Store,
  fetchRefreshAuthTokens: RefreshTokens,
  handleRetry?: RetryFunction,
  getRetryDelayInSeconds?: () => Nullable<number>,
  isNotKnownUserError?: (error: AxiosError) => boolean,
  onRetry?: (error: AxiosError) => void,
): PrivateApiClient =>
  new PrivateApiClient(
    () => {
      const accessToken = store.get(jwtAtom).accessToken;
      if (isNil(accessToken)) throw new Error("No access token found");
      return accessToken;
    },
    () => {
      const refreshToken = store.get(jwtAtom).refreshToken;
      if (isNil(refreshToken)) throw new Error("No refresh token token found");
      return refreshToken;
    },
    (accessToken, refreshToken) => {
      store.set(jwtAtom, actionJWTSetTokens({ accessToken, refreshToken }));
    },
    (error) => {
      store.set(errorAtom, error);
      store.set(jwtAtom, actionJWTClear());
    },
    fetchRefreshAuthTokens,
    handleRetry,
    getRetryDelayInSeconds,
    isNotKnownUserError,
    onRetry,
  );
