/**
 * Focus keys used for general navigation.
 *
 * @prop `mainMenu` - used by global navigation
 * @prop `activePage` - used by current route
 * @prop `channelList` - used by the channel list
 */
export const globalFocusKey = {
  mainMenu: "MAIN_MENU",
  /**
   * @deprecated
   *   Invent a nice original name for every page. Having all pages named `ACTIVE_PAGE` is not helpful.
   * It confuses the navigation library and is the cause for many focus issues.
   */
  activePage: "ACTIVE_PAGE",
  tvPage: "TV",
  recordingsPage: "RECORDINGS",
  errorPage: "ERROR_PAGE",
  channelList: "CHANNEL_LIST",
  channelGroups: "CHANNEL_GROUPS",
  playerControls: "PLAYER_CONTROLS",
} satisfies Record<string, string>;
