import { isNil } from "lodash";
import {
  DEFAULT_LANGUAGE,
  type Language,
  type Localized,
} from "@sunrise/backend-types-core";

export function getLocalizedValue<T>(
  obj: Localized<T>,
  language: Language,
): T | null {
  const r = obj[language] ?? obj[DEFAULT_LANGUAGE];

  return isNil(r) ? null : r;
}
