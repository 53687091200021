import { currentLanguageAtom } from "@sunrise/i18n";
import type { Language } from "@sunrise/backend-types-core";
import { atom } from "jotai";
import { atomWithQuery } from "jotai-tanstack-query";
import type { Translations } from "translations";

import { loadTranslation } from "./translation-service";
import { GetTranslationFileFn } from "./translator.types";

export const getTranslationFileAtom = atom<{ fn: GetTranslationFileFn }>({
  fn: () => Promise.resolve([]),
});

export const translationQueryAtom = atomWithQuery<
  Translations,
  unknown,
  Translations,
  unknown,
  [unknown, Language]
>((get) => ({
  queryKey: ["translation", get(currentLanguageAtom)],
  queryFn: async ({ queryKey: [, language] }) => {
    return loadTranslation(language, get(getTranslationFileAtom).fn);
  },
  staleTime: Infinity,
  cacheTime: Infinity,
}));
