import { canSwallowError } from "@sunrise/error";
import { hostsAtom, httpClientAtom } from "@sunrise/http-client";
import { selectIsLoggedIn, selectJwtUserToken } from "@sunrise/jwt";
import { atom } from "jotai";
import { atomWithSuspenseQuery } from "jotai-tanstack-query";
import { isNil } from "lodash";

import { CHANNEL_PACKAGES_STALE_TIME } from "../constants";
import { queryKeys, type ChannelReference } from "@sunrise/backend-types";
import type { ChannelId } from "@sunrise/backend-types-core";
import { fetchChannelPackages } from "../channel.service";

/**
 * https://entwicklungspark.atlassian.net/wiki/spaces/WT/pages/2503868432/Channel+Packages
 * @deprecated
 */
export const channelPackagesQueryLegacyAtom = atomWithSuspenseQuery<
  ChannelReference[]
>((get) => {
  const userToken = get(selectJwtUserToken);
  const host = get(hostsAtom).api;
  if (isNil(host)) throw new Error("Host is not set");

  const privateApi = get(httpClientAtom).privateApi;
  if (isNil(privateApi)) throw new Error("Private API is not set");

  const isLoggedIn = get(selectIsLoggedIn);

  return {
    // NOTE: We still depend on the access token since we want the data to reload when the user's token changes.
    queryKey: [...queryKeys.userToken(userToken), "channel-packages"],
    queryFn: async () => {
      try {
        if (!isLoggedIn) {
          return [];
        }

        return await fetchChannelPackages(privateApi, host);
      } catch (e) {
        if (canSwallowError(e)) {
          return [];
        }

        console.error(e);
        throw e;
      }
    },
    staleTime: isLoggedIn ? CHANNEL_PACKAGES_STALE_TIME : Infinity,
  };
});

channelPackagesQueryLegacyAtom.debugLabel = "channelPackagesQueryLegacyAtom";

type ChannelEnabled = Record<ChannelId, true>;

/**
 * @deprecated
 */
export const availableChannelPackagesLegacyAtom = atom(async (get) => {
  const { data } = await get(channelPackagesQueryLegacyAtom);
  return data.reduce<ChannelEnabled>((acc, it) => {
    acc[it.id as ChannelId] = true;
    return acc;
  }, {});
});
