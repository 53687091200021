import { atom } from "jotai";

import { socketAuthenticatedAtom } from "@sunrise/yallo-websocket";

import { CONTINUE_WATCHING_STALE_TIME } from "./constants";

export const continueWatchingStaleTimeAtom = atom((get) => {
  const authenticated = get(socketAuthenticatedAtom);
  return authenticated
    ? CONTINUE_WATCHING_STALE_TIME * 10
    : CONTINUE_WATCHING_STALE_TIME;
});
