import { featureAtom } from "@sunrise/feature-flags";

type PreferredBackend = "ng" | "legacy";

export const preferredBackendAtom = featureAtom<PreferredBackend | null>(
  "preferred-backend",
  "legacy",
  {
    description:
      "Preferred backend for API calls. When set to 'ng' we will attempt to use the 'ng' backend where it is available in the code. For places where the ng backend is not yet configured, we will still use the legacy endpoints.",
    values: ["ng", "legacy"],
  },
);
