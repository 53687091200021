import { featureAtom } from "@sunrise/feature-flags";

/**
 * Indicates whether the fast-forward markers are visible to endusers or not.
 * This does not indicate loading them or not. They may still be loaded.
 */
export const ffwdMarkersVisibleAtom = featureAtom(
  "fast-forward-markers-visible",
  false,
  {
    description: `Indicates whether the fast-forward markers are visible to endusers or not.
This does not indicate loading them or not. They may still be loaded.
But when they are not loaded, (as in, ffwd ads are disabled), they will not be visible to the enduser either.
`,
  },
);
