import { atom } from "jotai";
import { searchHistoryItemsAtom } from "./search-history-items.atom";
import { searchHistoryFeatureAtom } from "./search-history-feature.atom";

export const hasSearchHistoryAtom = atom(async (get) => {
  if (!get(searchHistoryFeatureAtom)) {
    return false;
  }

  const searchHistory = await get(searchHistoryItemsAtom);
  return searchHistory.length > 0;
});
