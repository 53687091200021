import { AxiosInstance } from "axios";

import type {
  PauseAdJsonConfig,
  PauseAnalyticsEventType,
} from "@sunrise/backend-types";
import {
  hostsAtom,
  httpClientAtom,
  PrivateApiClient,
} from "@sunrise/http-client";
import { jwtAtom, selectDecodedPayload } from "@sunrise/jwt";
import {
  playerAtom,
  playerCurrentDateTimeAtom,
  selectPlayerCurrentPlayRequest,
  selectPlayerShouldBePaused,
  selectPlayerState,
} from "@sunrise/player";
import { type Store } from "@sunrise/store";
import { type Nullable } from "@sunrise/utils";

import { getPauseAdsConfig, triggerAdTagEvent } from "./ads.service";
import { PAUSE_ADS_EVENT_MAPPING } from "./constants";
import {
  actionPauseAdsActivate,
  actionPauseAdsEmpty,
  actionPauseAdsErrored,
  actionPauseAdsResumePlayout,
  actionPauseAdsSetAdConfig,
  actionPauseAdsSetAdJsonConfig,
  pauseAdsAtom,
  PauseAdsAtomState,
  selectPauseAdState,
} from "./pause-ads.atom";
import { selectCurrentVideoAdTag } from "./video-ads.atom";

type Timeout = ReturnType<typeof setTimeout>;

/**
 * When the player pauses, we need to look at the play request and check if we need to load additional pause ads.
 *
 * Maybe we can do this with an atomEffect instead....
 *
 * PlayerState.paused && JwtAtom.show_pause_alternative_ads -> load pause ad -> inject into PauseAdsAtom.
 * On failure to load, send error event.
 *
 * selectPauseAdImage(PauseAdsAtom.ad) -> returns URL + dimensions to show it.
 * hook injects PauseAdsAtom.showing true/false depending if it is showing or not.
 *
 * PauseAdsAtom.ad + showing -> start analytics + inject start event.
 * Start timer, after 10s -> inject 10secevent
 *              after 120s -> inject 120sevent
 * On showing: false -> end event + remove ad from state
 */
export class PauseAds {
  private timer10s: Nullable<Timeout> = null;
  private timer120s: Nullable<Timeout> = null;

  constructor(private readonly store: Store) {
    this.store.sub(selectPlayerState, this.onPlayerPauseStateChange);
    this.store.sub(selectPauseAdState, this.onPauseAdsStateChange);
    this.store.sub(selectPlayerCurrentPlayRequest, this.onPlayRequestChange);
    this.store.sub(selectCurrentVideoAdTag(), this.onPlayerPauseStateChange);
  }

  /**
   * The main logic triggers whenever we go into the paused state or when we go from paused to playing.
   */
  private onPlayerPauseStateChange = (): void => {
    const isPaused = this.store.get(selectPlayerState) === "paused";
    const isPlaying = this.store.get(selectPlayerState) === "playing";
    const playRequest = this.store.get(selectPlayerCurrentPlayRequest);
    const videoAdTag = this.store.get(selectCurrentVideoAdTag());

    const loadedAndShouldPause =
      this.store.get(selectPlayerState) === "loaded" &&
      this.store.get(selectPlayerShouldBePaused(playerAtom));

    // When we are paused and our state is still disabled, it means we want to trigger activation.
    // It's also important that we do not trigger this when we have a videoAdTag.
    if (
      !videoAdTag &&
      (isPaused || loadedAndShouldPause) &&
      this.pauseState === "disabled" &&
      this.canShowPauseAds() &&
      playRequest?.type === "replay"
    ) {
      void this.onActivate();
    }

    // When we are playing and we are not in a disabled state we want to trigger resume.
    // When we have a VideoAdTag we should also assume that we want to resume.
    if (!!videoAdTag || (isPlaying && this.pauseState !== "disabled")) {
      void this.onResume();
    }
  };

  /**
   * We need to trigger analytics whenever we start showing an ad.
   * We also need to start timers for the 10s and 120s events.
   *
   * When we error or empty out we need to track it as well.
   */
  private onPauseAdsStateChange = (): void => {
    switch (this.store.get(selectPauseAdState)) {
      case "showing":
        this.clearTimers();
        void this.sendAdTagEvent("show");

        this.timer10s = setTimeout(() => {
          void this.sendAdTagEvent("10s");
        }, 10_000);

        this.timer120s = setTimeout(() => {
          void this.sendAdTagEvent("120s");
        }, 120_000);
        break;
      case "empty":
        void this.sendAdTagEvent("empty");
        break;
      case "error":
        void this.sendAdTagEvent("error");
        break;
      case "terminating":
        this.doTerminate();
        break;
      case "disabled":
      case "active":
        break;
    }
  };

  private onPlayRequestChange = (): void => {
    void this.onResume();
  };

  private async sendAdTagEvent(event: PauseAnalyticsEventType): Promise<void> {
    const { adConfig, jsonConfig } = this.store.get(pauseAdsAtom);
    const { privateApi, publicApi } = this.getApiAndHost();

    const mapped = PAUSE_ADS_EVENT_MAPPING[event];

    await Promise.all([
      adConfig?.tags[0]
        ? triggerAdTagEvent(
            privateApi,
            adConfig.tags[0].tracking,
            mapped.internal,
          )
        : null,
      mapped.external && jsonConfig
        ? triggerAdTagEvent(publicApi, jsonConfig.tracking, mapped.external)
        : null,
    ]);
  }

  private async onActivate(): Promise<void> {
    this.store.set(pauseAdsAtom, actionPauseAdsActivate());
    try {
      await this.loadConfig();
    } catch {
      this.store.set(pauseAdsAtom, actionPauseAdsErrored());
    }
  }

  private async onResume(): Promise<void> {
    const state = this.pauseState;

    if (state === "showing" || state === "terminating") {
      this.clearTimers();

      if (this.store.get(pauseAdsAtom).hasShown) {
        await this.sendAdTagEvent("end");
      }
    }

    if (this.pauseState === "disabled") return;

    this.store.set(pauseAdsAtom, actionPauseAdsResumePlayout());
  }

  /**
   * We need to send an end event if necessary.
   */
  private doTerminate(): void {
    void this.onResume();
  }

  private async loadConfig(): Promise<void> {
    const { privateApi, host, publicApi } = this.getApiAndHost();
    const time = this.store.get(playerCurrentDateTimeAtom);
    if (!time) throw new Error("No time found");

    const channelId = this.store.get(selectPlayerCurrentPlayRequest)?.channelId;
    if (!channelId) throw new Error("No channelId found");

    const config = await getPauseAdsConfig({
      privateApi,
      host,
      channelId,
      time,
    });

    if (!config?.tags[0]) {
      throw new Error("No pause config returned");
    }
    this.store.set(pauseAdsAtom, actionPauseAdsSetAdConfig(config));

    const json = await publicApi.get<PauseAdJsonConfig>(
      config.tags[0].tag_url_json,
    );
    this.store.set(pauseAdsAtom, actionPauseAdsSetAdJsonConfig(json.data));

    if (!json.data.url) {
      this.store.set(pauseAdsAtom, actionPauseAdsEmpty());
    }
  }

  private getApiAndHost(): {
    privateApi: PrivateApiClient;
    publicApi: AxiosInstance;
    host: string;
  } {
    const { privateApi, publicApi } = this.store.get(httpClientAtom);
    const host = this.store.get(hostsAtom).api;

    if (!privateApi) throw new Error("No privateApi found");
    if (!publicApi) throw new Error("No publicApi found");
    if (!host) throw new Error("No host found");

    return { privateApi, host, publicApi };
  }

  private clearTimers(): void {
    if (this.timer10s) {
      window.clearTimeout(this.timer10s);
      this.timer10s = null;
    }

    if (this.timer120s) {
      window.clearTimeout(this.timer120s);
      this.timer120s = null;
    }
  }

  private canShowPauseAds(): boolean {
    const accessToken = selectDecodedPayload(this.store.get(jwtAtom));
    return (
      accessToken?.feature_set.features.show_pause_alternative_ads ?? false
    );
  }

  private get pauseState(): PauseAdsAtomState["state"] {
    return this.store.get(pauseAdsAtom).state;
  }
}
