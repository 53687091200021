import { PropsWithChildren, ReactElement } from "react";
import clsx from "clsx";

import { useTranslator } from "@sunrise/translator";

import { container, header, wrapper } from "./settings-base.css";

type SettingsBaseProps = {
  titleLabel: string;
} & CommonProps &
  PropsWithChildren;

export function SettingsBase({
  "data-testid": dataTestId,
  children,
  titleLabel,
  className,
}: SettingsBaseProps): ReactElement {
  const t = useTranslator();

  return (
    <main
      tabIndex={0}
      data-testid={dataTestId}
      className={clsx([className, wrapper])}
    >
      <div className={header}>{t(titleLabel)}</div>

      <div className={container}>{children}</div>
    </main>
  );
}
