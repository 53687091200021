import { atom } from "jotai";

import type { Nullable } from "./nullable.types";

/**
 * An atom helper which creates an array that can be mutated by writing to it.
 * If you have an array of `[{ id: '1', name: 'foo' }, { id: '2', name: 'baz' }]` and you write to it with `{ id: '1', name: 'bar' }`
 * Then the array will become `[{ id: '1', name: 'bar' }, { id: '2', name: 'baz' }]`.
 *
 * When you write null to it, it will reset the array to an empty array.
 *
 * @param initialValue The initial value of the array.
 * @param options Options to configure the behavior of the atom.
 * @param options.compareFn A function to compare two items in the array. When the function returns true, the items are considered equal.
 * @param options.debugLabel A label to identify the atom in the Jotai devtools.
 */
export function atomWithArrayUpdates<K>(
  initialValue: K[] = [],
  options?: { compareFn?: (a: K, b: K) => boolean; debugLabel?: string },
) {
  const internalAtom = atom<K[]>(initialValue);
  internalAtom.debugLabel = `${
    options?.debugLabel ?? "Unnamed"
  }AtomWithArrayUpdates$internal`;
  const compareFn = options?.compareFn || ((a, b) => a === b);

  const actual = atom(
    (get) => get(internalAtom),
    (get, set, update: Nullable<K>) => {
      if (!update) {
        set(internalAtom, []);
        return;
      }

      const copy = get(internalAtom).slice(0);
      const index = copy.findIndex((item) => compareFn(item, update));

      if (index !== -1) {
        // Replace when we already found the item.
        copy.splice(index, 1, update);
      } else {
        // Else just add it at the end.
        copy.push(update);
      }

      set(internalAtom, copy);
    },
  );

  actual.debugLabel = `${options?.debugLabel ?? "Unnamed"}AtomWithArrayUpdates`;
  return actual;
}
