import { type MutableRefObject, useEffect, useMemo, useState } from "react";

export type InfiniteScrollProps = {
  callback?: () => void;
};

export function useInfiniteScroll<T extends Element>({
  callback,
}: InfiniteScrollProps): {
  setLastRef: MutableRefObject<T | null>;
  setRootRef: MutableRefObject<T | null>;
} {
  /// TODO: Maybe rework to just passing in all the refs. We do not need to build the refs ourselves imo.
  const [rootRef, setRootRefInternal] = useState<T | null>(null);
  const [lastRef, setLastRefInternal] = useState<T | null>(null);

  useEffect(() => {
    if (!lastRef || !callback) {
      return;
    }

    // setup new observer
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries.some((item) => item.isIntersecting)) {
          callback();
        }
      },
      {
        root: rootRef,
        threshold: 0,
        rootMargin: "0px",
      },
    );

    observer.observe(lastRef);

    return () => {
      observer.disconnect();
    };
  }, [callback, lastRef, rootRef]);

  const setLastRef = useMemo(() => {
    let ref: T | null = null;
    return {
      set current(element: T | null) {
        ref = element;
        setLastRefInternal(element);
      },
      get current() {
        return ref;
      },
    };
  }, [setLastRefInternal]);

  const setRootRef = useMemo(() => {
    let ref: T | null = null;
    return {
      set current(element: T | null) {
        ref = element;
        setRootRefInternal(element);
      },
      get current() {
        return ref;
      },
    };
  }, [setRootRefInternal]);

  return {
    setLastRef,
    setRootRef,
  };
}
