import { canSwallowError } from "@sunrise/error";
import { hostsAtom, httpClientAtom } from "@sunrise/http-client";
import { currentLanguageAtom } from "@sunrise/i18n";
import { selectIsLoggedIn, selectJwtUserToken } from "@sunrise/jwt";
import { Nullable } from "@sunrise/utils";
import { settingsAtom } from "@sunrise/yallo-settings";
import { atom } from "jotai";
import {
  atomWithInfiniteQuery,
  atomWithMutation,
  queryClientAtom,
} from "jotai-tanstack-query";
import { isNil } from "lodash";

import {
  currentRecordingsStatusFilterAtom,
  currentRecordingsTypeFilterAtom,
} from "../filtering/current-recordings-filter.atom";
import { flushRecordingsState } from "../flush-recordings-state";
import { recordingsSortAtom } from "../recordings-sort.atom";
import { recordingsStaleTimeAtom } from "../recordings-stale-time.atom";
import {
  bulkDeleteRecordings,
  cancelRecordings,
  deleteRecordings,
  fetchRecordings,
} from "../recordings.service";
import {
  queryKeys,
  RecordingsQueryInfiniteData,
  RecordingsResponse,
  type AscDescSort,
  type RecordingBulkDelete,
  type RecordingsItemsSortSearchParams,
  type RecordingsSort,
} from "@sunrise/backend-types";
import type { AssetId } from "@sunrise/backend-types-core";

export const recordingsQueryAtom = atomWithInfiniteQuery<
  Nullable<RecordingsResponse["data"]>,
  unknown,
  RecordingsQueryInfiniteData,
  ReturnType<typeof queryKeys.recordingsOverview>,
  number
>((get) => {
  const host = get(hostsAtom).api;
  if (isNil(host)) throw new Error("Host is not set");

  const { privateApi } = get(httpClientAtom);
  if (!privateApi) throw new Error("missing privateApi");

  const isLoggedIn = get(selectIsLoggedIn);

  const sort = get(recordingsSortAtom);
  const language = get(currentLanguageAtom);

  const statusFilter = get(currentRecordingsStatusFilterAtom);
  const typeFilter = get(currentRecordingsTypeFilterAtom);

  return {
    initialPageParam: 1,
    staleTime: get(recordingsStaleTimeAtom),
    gcTime: get(recordingsStaleTimeAtom),
    getNextPageParam: (lastPage) => {
      if (isNil(lastPage)) return undefined;

      const { page, pages } = lastPage;
      return page < pages ? page + 1 : undefined;
    },
    // NOTE: We still depend on the access token since we want the data to reload when the user's token changes.
    queryKey: queryKeys.recordingsOverview(
      get(selectJwtUserToken),
      sort,
      language,
      typeFilter,
      statusFilter,
    ),
    queryFn: async ({ pageParam }) => {
      if (isNil(pageParam) || !isLoggedIn) return;

      const sortInformation = recordingsSortFieldMapping[sort];

      const recordingsTypeFilter = await get(recordingsFiltersTypeMappingAtom);

      const pageParams = {
        page: pageParam,
        pagesize: 20,
        [sortInformation.field]:
          sortInformation.field === "order_direction"
            ? sortInformation.direction
            : `${language}:${sortInformation.direction}`,
      };

      // append filters if any is set but not if it's set to "all"
      if (statusFilter && statusFilter !== "all") {
        pageParams["status"] = statusFilter;
      }

      if (recordingsTypeFilter && recordingsTypeFilter !== "all") {
        pageParams["asset_type"] = recordingsTypeFilter;
      }

      try {
        return (await fetchRecordings(host, privateApi, pageParams)).data;
      } catch (e) {
        // When not explicitly set by the error, assume we can actually swallow the error.
        if (!canSwallowError(e, true)) throw e;
        return null;
      }
    },
    keepPreviousData: true,
    suspense: true,
  };
});

export const cancelRecordingsMutationAtom = atomWithMutation<
  void,
  {
    assetId: AssetId;
  },
  unknown
>((get) => {
  const host = get(hostsAtom).api;
  if (isNil(host)) throw new Error("Host is not set");

  const privateApi = get(httpClientAtom).privateApi;
  if (isNil(privateApi)) throw new Error("Private api is not set");

  const queryClient = get(queryClientAtom);

  return {
    mutationKey: ["cancel-recordings"],
    mutationFn: ({ assetId }) => cancelRecordings(host, privateApi, assetId),
    onSettled: function () {
      flushRecordingsState(queryClient, get);
    },
  };
});

export const deleteRecordingsMutationAtom = atomWithMutation<
  void,
  {
    assetId: AssetId;
  },
  unknown
>((get) => {
  const host = get(hostsAtom).api;
  if (isNil(host)) throw new Error("Host is not set");

  const privateApi = get(httpClientAtom).privateApi;
  if (isNil(privateApi)) throw new Error("Private api is not set");

  const queryClient = get(queryClientAtom);

  return {
    mutationKey: ["delete-recordings"],
    mutationFn: ({ assetId }) => deleteRecordings(host, privateApi, assetId),
    onSettled: function () {
      flushRecordingsState(queryClient, get);
    },
  };
});

export const bulkDeleteRecordingsMutationAtom = atomWithMutation<
  void,
  {
    recordings: RecordingBulkDelete[];
  },
  unknown
>((get) => {
  const host = get(hostsAtom).api;
  if (isNil(host)) throw new Error("Host is not set");

  const privateApi = get(httpClientAtom).privateApi;
  if (isNil(privateApi)) throw new Error("Private api is not set");

  const queryClient = get(queryClientAtom);

  return {
    mutationKey: ["bulk-delete-recordings"],
    mutationFn: ({ recordings }) => {
      return bulkDeleteRecordings(host, privateApi, recordings);
    },
    onSettled: function () {
      flushRecordingsState(queryClient, get);
    },
  };
});

const recordingsSortFieldMapping: Record<
  RecordingsSort,
  {
    field: keyof RecordingsItemsSortSearchParams;
    direction: AscDescSort;
  }
> = {
  titleAsc: {
    field: "order_by_title",
    direction: "asc",
  },
  titleDesc: {
    field: "order_by_title",
    direction: "desc",
  },
  dateAsc: {
    field: "order_direction",
    direction: "asc",
  },
  dateDesc: {
    field: "order_direction",
    direction: "desc",
  },
};

// Map the filter type to the asset type from backend
const recordingsFiltersTypeMappingAtom = atom(async (get) => {
  const settings = await get(settingsAtom);
  const moviesValue = settings?.recordings.filters.find(
    (e) => e.title.en === "Films",
  )?.parameters[0]?.asset_type;
  const seriesValue = settings?.recordings.filters.find(
    (e) => e.title.en === "Series",
  )?.parameters[0]?.asset_type;

  const filterType = get(currentRecordingsTypeFilterAtom);

  if (filterType === "movies") {
    return moviesValue;
  }
  if (filterType === "series") {
    return seriesValue;
  }

  // we can return "all" here since that is checked on the query and in that case we fetch all recordings types
  return "all";
});
