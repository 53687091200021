import { atom } from "jotai";
import { showFirstExperienceForMs } from "./enable-first-experience-flag";
import { alreadyShownFirstExperienceAtom } from "./already-shown-first-experience.atom";
import { atomEffect } from "jotai-effect";
import { pageContentAtom } from "@sunrise/analytics";
import { shouldShowPlayerLoadingAtom } from "@/modules/tv/should-show-player-loader.atom";
import { areVideoAdsPlayingAtom } from "@sunrise/ads";
import {
  playerControlsVisibleAtom,
  shouldNotShowPlayerControlsAtom,
} from "@sunrise/yallo-player-controls";
import { isChannelListHiddenAtom } from "@/features/channel-list";
import { nowSecondAtom } from "@sunrise/time";
import { isMenuActiveAtom } from "@/modules/menu/is-menu-active.atom";
import { selectIsDialogOpen } from "@sunrise/dialogs";

const _showFirstExperienceAtom = atom((get) => {
  const ms = get(showFirstExperienceForMs);

  if (!ms) {
    return null;
  }

  const shownAt = get(alreadyShownFirstExperienceAtom);

  if (shownAt) {
    if (get(nowSecondAtom).getTime() - shownAt > ms) {
      // When shown a while ago, return null. So we no longer render the image.
      return null;
    }

    // When shown recently, return false. So we still render the image but just with an opacity of 0.
    return false;
  }

  // TODO: We could make a new atom that returns true when "something is allowed to overlay on the tv page".
  //       That could then also be consulted by the mechanism that opens the channel list and the one that opens the player controls.

  if (get(pageContentAtom)?.id !== "player_page") {
    return false;
  }

  if (get(areVideoAdsPlayingAtom)) {
    return false;
  }

  // TODO: Also do not show when a pause ad is showing?
  //
  // TODO: Perhaps we could make a configurable atom per platform to decide under which other conditions we should hide.
  //       So that it can be extracted into a separate module.

  if (get(shouldShowPlayerLoadingAtom)) {
    return false;
  }

  if (get(playerControlsVisibleAtom)) {
    return false;
  }

  if (get(selectIsDialogOpen)) {
    return false;
  }

  if (!get(isChannelListHiddenAtom)) {
    return false;
  }

  if (get(isMenuActiveAtom)) {
    return false;
  }

  if (get(shouldNotShowPlayerControlsAtom)) {
    return false;
  }

  return true;
});

/**
 * Show we show an image to introduce people to the player controls.
 */
export const showFirstExperienceTVPageAtom = atom((get) => {
  get(firstExperienceAutoHideEffect);

  return get(_showFirstExperienceAtom);
});

const firstExperienceAutoHideEffect = atomEffect((get, set) => {
  const showing = get(_showFirstExperienceAtom);

  if (!showing) {
    return;
  }

  const hide = () => {
    if (!get.peek(alreadyShownFirstExperienceAtom)) {
      set(alreadyShownFirstExperienceAtom, get.peek(nowSecondAtom).getTime());
    }
  };

  const ms = get(showFirstExperienceForMs);
  const timeout = setTimeout(hide, ms);

  return () => {
    hide();
    clearTimeout(timeout);
  };
});
