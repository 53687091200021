"use client";

import debounce from "lodash/debounce";
import { useEffect, useMemo, useState } from "react";

/**
 * When you pass it a value this hook will return null instead of the passed in value if the value changes within the throttle time.
 * If the value does not change within the throttle time it will return the last passed in value.
 *
 * This makes it so the value return from the hook changes less often than the value passed in.
 *
 * @param value any value you want to stabilize.
 * @param throttleInMs how long to wait before updating the value when a new value is received.
 * @returns
 */
export function useStableValue<T>(value: T, throttleInMs = 300): T | null {
  const [stableValue, setStableValue] = useState<T | null>(value ?? null);

  const debouncer = useMemo(() => {
    if (!throttleInMs) return (value: T) => setStableValue(value);

    return debounce(
      (value: T) => {
        setStableValue(value);
      },
      throttleInMs,
      { leading: true, trailing: true },
    );
  }, [throttleInMs]);

  useEffect(() => {
    setStableValue(null);
    debouncer(value);
  }, [value, debouncer]);

  return stableValue;
}
