import {
  FocusContext,
  setFocus,
  useFocusable,
} from "@noriginmedia/norigin-spatial-navigation";
import { useAtom } from "jotai";
import { ReactNode, useEffect, useState } from "react";

import { FocusButton } from "@/components";
import { globalFocusKey } from "@/config/focus-key";
import { useMenu } from "@/features/menu/use-menu";
import { actionMenuExpand, menuAtom } from "@/modules/menu";
import { isArrowLeftKey } from "@/utils/navigation";

import {
  DebugButtons,
  DebugChannelBoxes,
  DebugChannelItems,
  DebugColors,
  DebugDefault,
  DebugDialogs,
  DebugProgramBoxes,
  DebugProgressBar,
  DebugQrCodes,
  DebugRecordingBoxes,
  DebugTags,
} from "../features/design-system-debug";
import { DebugProgramPreview } from "../features/design-system-debug/program-preview";
import { DebugSpinner } from "../features/design-system-debug/spinner";
import * as styles from "./design-system.css";

const PROGRAM_BOX = "program box";
const CHANNEL_BOX = "channel box";
const RECORDING_BOX = "recording box";
const BUTTON = "button";
const CHANNEL_ITEM = "channel item";
const TAG = "tag";
const QR_CODE = "qr code";
const SPINNER = "spinner";
const DIALOG = "dialog";
const PREVIEW = "preview";
const PROGRESS_BAR = "progress bar";
const COLORS = "colors";
export const DEFAULT = "default";

export type DesignSystemState =
  | "program box"
  | "channel box"
  | "recording box"
  | "button"
  | "channel item"
  | "tag"
  | "qr code"
  | "spinner"
  | "dialog"
  | "preview"
  | "progress bar"
  | "colors"
  | "default";

export const designSystemWidgetFocusKey = {
  designSystem: globalFocusKey.activePage + ".design-system",
  firstButton: "first-button",
  dialogButton: "dialog-button",
};

export function DesignSystem(): ReactNode {
  const { focusSelf, ref } = useFocusable({
    focusKey: globalFocusKey.activePage,
    preferredChildFocusKey: designSystemWidgetFocusKey.designSystem,
    isFocusBoundary: true,
    onArrowPress: function handleArrowPress(direction) {
      if (isArrowLeftKey(direction)) dispatchMenu(actionMenuExpand());
      return false;
    },
  });
  const [menu, dispatchMenu] = useAtom(menuAtom);
  const [shouldShow, setShouldShow] = useState<DesignSystemState>(DEFAULT);

  useMenu({ shouldShowMenu: true });

  useEffect(
    function delegateFocus() {
      if (menu.isExpanded) return;
      setFocus(designSystemWidgetFocusKey.firstButton);
    },
    [focusSelf, menu.isExpanded],
  );

  return (
    <div className={styles.root} ref={ref}>
      <h1 style={{ padding: "2%" }}>Design System</h1>
      <FocusContext.Provider value={designSystemWidgetFocusKey.designSystem}>
        <FocusButton
          text={PROGRAM_BOX}
          onEnterPress={() => setShouldShow(PROGRAM_BOX)}
          className={styles.button}
          focusKey={designSystemWidgetFocusKey.firstButton}
          onArrowPress={function handleArrowPress(direction) {
            if (isArrowLeftKey(direction)) dispatchMenu(actionMenuExpand());
            return true;
          }}
        />
        <FocusButton
          text={CHANNEL_BOX}
          onEnterPress={() => setShouldShow(CHANNEL_BOX)}
          className={styles.button}
        />
        <FocusButton
          text={RECORDING_BOX}
          onEnterPress={() => setShouldShow(RECORDING_BOX)}
          className={styles.button}
        />
        <FocusButton
          text={PREVIEW}
          onEnterPress={() => setShouldShow(PREVIEW)}
          className={styles.button}
        />
        <FocusButton
          text={BUTTON}
          onEnterPress={() => setShouldShow(BUTTON)}
          className={styles.button}
        />
        <FocusButton
          text={CHANNEL_ITEM}
          onEnterPress={() => setShouldShow(CHANNEL_ITEM)}
          className={styles.button}
        />
        <FocusButton
          text={TAG}
          onEnterPress={() => setShouldShow(TAG)}
          className={styles.button}
        />
        <FocusButton
          text={QR_CODE}
          onEnterPress={() => setShouldShow(QR_CODE)}
          className={styles.button}
        />
        <FocusButton
          text={SPINNER}
          onEnterPress={() => setShouldShow(SPINNER)}
          className={styles.button}
        />
        <FocusButton
          text={DIALOG}
          onEnterPress={() => setShouldShow(DIALOG)}
          className={styles.button}
          focusKey={designSystemWidgetFocusKey.dialogButton}
        />
        <FocusButton
          text={PROGRESS_BAR}
          onEnterPress={() => setShouldShow(PROGRESS_BAR)}
          className={styles.button}
        />
        <FocusButton
          text={COLORS}
          onEnterPress={() => setShouldShow(COLORS)}
          className={styles.button}
        />
      </FocusContext.Provider>
      {shouldShow === PROGRAM_BOX && <DebugProgramBoxes />}
      {shouldShow === CHANNEL_BOX && <DebugChannelBoxes />}
      {shouldShow === RECORDING_BOX && <DebugRecordingBoxes />}
      {shouldShow === BUTTON && <DebugButtons />}
      {shouldShow === CHANNEL_ITEM && <DebugChannelItems />}
      {shouldShow === TAG && <DebugTags />}
      {shouldShow === QR_CODE && <DebugQrCodes />}
      {shouldShow === SPINNER && <DebugSpinner />}
      {shouldShow === DIALOG && <DebugDialogs setShouldShow={setShouldShow} />}
      {shouldShow === PREVIEW && <DebugProgramPreview />}
      {shouldShow === PROGRESS_BAR && <DebugProgressBar />}
      {shouldShow === COLORS && <DebugColors />}
      {shouldShow === DEFAULT && <DebugDefault />}
    </div>
  );
}
