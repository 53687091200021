import { ReactNode } from "react";

import { ProgressBar } from "@/components";

import * as styles from "./design-system.css";

export function DebugProgressBar(): ReactNode {
  const liveProgress = 68;
  const replayProgress = 43;
  return (
    <div style={{ width: "500px", display: "flex", flexDirection: "column" }}>
      <h3>default size</h3>
      <ProgressBar liveProgress={liveProgress} className={styles.progressBar} />
      <h3>default size + replay</h3>
      <ProgressBar
        liveProgress={liveProgress}
        replayProgress={replayProgress}
        className={styles.progressBar}
      />
      <h3>height 10px</h3>
      <ProgressBar
        liveProgress={liveProgress}
        height={10}
        className={styles.progressBar}
      />
      <h3>height 10px, radius 16px</h3>
      <ProgressBar
        liveProgress={liveProgress}
        replayProgress={replayProgress}
        height={10}
        radius={16}
        className={styles.progressBar}
      />
      <h3>height 10px, radius 16px, 2 breaks</h3>
      <ProgressBar
        liveProgress={liveProgress}
        height={10}
        radius={16}
        breaks={[
          { start: 4, length: 10 },
          { start: 89, length: 8 },
        ]}
        className={styles.progressBar}
      />
    </div>
  );
}
