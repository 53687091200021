import { BaseError } from "@sunrise/error";

const REPLAY_NOT_LONG_ENOUGH = "USER_REPLAY_NOT_ACTIVATED_LONG_ENOUGH";
export type KnownRecordingErrors = typeof REPLAY_NOT_LONG_ENOUGH;

export class RecordingsError extends BaseError {
  constructor(
    message: string,
    opts: { errorCode: KnownRecordingErrors | string; origin?: unknown },
  ) {
    super("RecordingsError", message, opts);
  }

  override get shouldTrace(): boolean {
    return this.errorCode !== REPLAY_NOT_LONG_ENOUGH;
  }

  override get shouldRetry(): boolean {
    return false;
  }
}
