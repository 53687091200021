import type {
  InfiniteData,
  InfiniteQueryObserverResult,
} from "@tanstack/react-query";
import { atom, type Atom } from "jotai";
import type { PageWithItems } from "./types";

type ReturnType<TItem> = { items: TItem[]; fetchNextPage: () => void };

/**
 * A helper which works on an infinite query and it exposes basic attributes.
 * The full list across all pages as well as a method to get more pages if available.
 */
export const pagedAtom = function <
  TInner extends Atom<
    InfiniteQueryObserverResult<
      InfiniteData<PageWithItems<unknown>, unknown>,
      unknown
    >
  >,
>(
  innerAtom: TInner,
): Atom<
  TInner extends Atom<
    InfiniteQueryObserverResult<
      InfiniteData<PageWithItems<infer TItem>, unknown>,
      unknown
    >
  >
    ? ReturnType<TItem>
    : never
> {
  return atom((get) => {
    const data = get(innerAtom);

    const items = data.data?.pages.flatMap((page) => page.items) ?? [];

    const fetchNextPage = () => {
      if (data.hasNextPage && !data.isFetchingNextPage) {
        data.fetchNextPage();
      }
    };

    return { items, fetchNextPage } as TInner extends Atom<
      InfiniteQueryObserverResult<
        InfiniteData<PageWithItems<infer TItem>, unknown>,
        unknown
      >
    >
      ? ReturnType<TItem>
      : never;
  });
};
