import { Atom, atom } from "jotai";
import { selectAtom } from "jotai/utils";

import { areVideoAdsPlayingAtom } from "@sunrise/ads";
import { playerAtom } from "@sunrise/player";

/**
 * The player should always be visible when ads are played out. For Tizen itself the ads are played in our player.
 * For development, the ads are played in the ads iframe but they are layered on top of the video element
 * so there's no problem with the visibility.
 **/
export function playerIsVisibleAtom(currentAtom = playerAtom): Atom<boolean> {
  const isVisibleAtom = selectAtom(
    currentAtom,
    (state) => state.shouldBeVisible,
  );

  const slice = atom((get) => {
    const isVisible = get(isVisibleAtom);
    const adsPlaying = get(areVideoAdsPlayingAtom);

    return adsPlaying ? true : isVisible;
  });

  slice.debugLabel = playerIsVisibleAtom.name;

  return slice;
}
