import type { Translatable } from "@sunrise/i18n";
import { useTranslatable } from "@sunrise/translator";
import type { ReactNode } from "react";

import { eventStyle } from "./error-dialog-description.css";

type ErrorDialogDescriptionProps = {
  eventId: string | undefined;
  description: Translatable;
};

export function ErrorDialogDescription({
  eventId,
  description,
}: ErrorDialogDescriptionProps): ReactNode {
  const t = useTranslatable();
  if (!eventId) {
    return t(description);
  }

  return (
    <div>
      <div>{t(description)}</div>
      <p className={eventStyle}>Event ID: {eventId}</p>
    </div>
  );
}
