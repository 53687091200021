import type { ChannelId } from "@sunrise/backend-types-core";
import { atom } from "jotai";
import { atomFamily } from "jotai/utils";
import type { Nullable } from "@sunrise/utils";
import { selectedChannelGroupWithPaginatedChannels } from "../selected-channel-group-with-paginated-channels.atom";
import { channelForChannelIdQueryAtom } from "./queries/channel-for-id.query.ng.atom";
import type { MappedChannel } from "../types";

/**
 * Grabs basic information about a channel.
 */
export const channelByIdNgAtom = atomFamily((id: ChannelId) => {
  const innerAtom = atom<Promise<Nullable<Omit<MappedChannel, "order">>>>(
    async (get) => {
      const channels = await get(selectedChannelGroupWithPaginatedChannels);
      const found = channels?.channels.find((channel) => channel.id === id);
      if (found) {
        return found;
      }

      const channel = (await get(channelForChannelIdQueryAtom(id))).data;
      if (!channel) {
        return null;
      }
      return {
        id: channel.id as ChannelId,
        name: channel.name,
        stream: channel.stream_service_url,
        logo: channel.logo,
        locked: !channel.is_playable,
        providerName: channel.stream_provider.provider_name,
      };
    },
  );

  innerAtom.debugLabel = `channelByIdNgAtom(${id})`;

  return innerAtom;
});
