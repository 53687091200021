import { ReactNode } from "react";

import { FocusButton } from "@/components";
import { ICON_SIZE_20 } from "@/components/icon/icon.config";
import { useChannelGroupSelector } from "@/modules/channel-group";

import { button } from "./guide-controls.css";

/**
 * Shows a dropdown button with the current channel group name.
 * When clicking it, it will open a list dialog where you can select a different channel group.
 */
export function ChannelGroupSelector(): ReactNode {
  const { current, askToChange } = useChannelGroupSelector();
  if (!current) {
    return null;
  }

  return (
    <FocusButton
      data-testid="grid-select-channel-group"
      className={button}
      text={current.name}
      focusKey="grid-select-channel-group"
      onEnterPress={() => void askToChange()}
      iconRight="dropdown-down"
      iconSize={ICON_SIZE_20}
    />
  );
}
