"use client";

import { throttle } from "lodash";
import { useEffect, useState } from "react";

/**
 * from https://github.com/uidotdev/usehooks
 * @param ms
 */
export const useIdle = (ms: number | undefined): boolean => {
  const [idle, setIdle] = useState(false);

  useEffect(() => {
    let timeoutId: ReturnType<typeof setTimeout>;

    const handleTimeout = (): void => setIdle(true);

    const handleEvent = throttle((): void => {
      setIdle(false);

      window.clearTimeout(timeoutId);

      if (typeof ms === "number") {
        timeoutId = setTimeout(handleTimeout, ms);
      }
    }, 500);

    const handleVisibilityChange = (): void => {
      if (!document.hidden) {
        handleEvent();
      }
    };

    if (typeof ms === "number") {
      timeoutId = setTimeout(handleTimeout, ms);
    }

    window.addEventListener("mousemove", handleEvent);
    window.addEventListener("mousedown", handleEvent);
    window.addEventListener("mouseup", handleEvent);
    window.addEventListener("resize", handleEvent);
    window.addEventListener("keydown", handleEvent);
    window.addEventListener("keyup", handleEvent);
    window.addEventListener("touchstart", handleEvent);
    window.addEventListener("touchend", handleEvent);
    window.addEventListener("wheel", handleEvent);
    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      window.removeEventListener("mousemove", handleEvent);
      window.removeEventListener("mousedown", handleEvent);
      window.removeEventListener("mouseup", handleEvent);
      window.removeEventListener("resize", handleEvent);
      window.removeEventListener("keydown", handleEvent);
      window.removeEventListener("keyup", handleEvent);
      window.removeEventListener("touchstart", handleEvent);
      window.removeEventListener("touchend", handleEvent);
      window.removeEventListener("wheel", handleEvent);
      document.removeEventListener("visibilitychange", handleVisibilityChange);
      window.clearTimeout(timeoutId);
    };
  }, [ms]);

  return idle;
};
