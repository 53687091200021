import { BaseError } from "@sunrise/error";

export class RecordingStatusError extends BaseError {
  constructor(message: string, opts: { errorCode?: string; origin?: unknown }) {
    super("RecordingStatusError", message, opts);
  }

  override get canSwallow(): boolean {
    return true;
  }

  override get shouldRetry(): boolean {
    return false;
  }
}
