import clsx from "clsx";
import { ReactElement } from "react";

import { GUIDE_GRID_TIME_INDICATOR_BAR_HEIGHT_IN_PX } from "@/features/guide/constants";
import * as styles from "@/features/guide/guide-now-bar.css";

type GuideNowBarProps = {
  nowOffset: number;
  size: number;
  testId?: string;
} & CommonProps;

export const GuideNowBar = ({
  nowOffset,
  size,
  className,
  "data-testid": dataTestId = "guide-now-bar",
}: GuideNowBarProps): ReactElement => {
  return (
    <div
      className={clsx([styles.nowBar, className])}
      data-testid={dataTestId}
      style={{
        marginLeft: nowOffset,
        height: size + GUIDE_GRID_TIME_INDICATOR_BAR_HEIGHT_IN_PX * 1.1, // safety factor
      }}
    />
  );
};
