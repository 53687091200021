import { ReactNode } from "react";
import clsx from "clsx";

import * as styles from "./layout.css";

export type LayoutProps = CommonProps & { children: ReactNode };

export function RouteLayout({ className, ...props }: LayoutProps): JSX.Element {
  return <div {...props} className={clsx(className, styles.root)} />;
}

export type EscapeRouteLayoutProps = CommonProps & {
  children: ReactNode;
  withMenu?: boolean;
};

export function EscapeRouteLayout({
  className,
  withMenu,
  ...props
}: EscapeRouteLayoutProps): JSX.Element {
  return (
    <div
      {...props}
      className={clsx(
        styles.escapeLayout,
        withMenu && styles.escapeWithMenu,
        className,
      )}
    />
  );
}
