import { isTizen, isWebOS } from "@sunrise/utils";

import { closeTizenApp } from "@/modules/tizen/system.service";
import { closeWebOSApp } from "@/modules/webos/system.service";

export const closeApp = isTizen()
  ? closeTizenApp
  : isWebOS()
  ? closeWebOSApp
  : null;
