import type { RecordingStatusEvent } from "@sunrise/backend-types";
import { generateRandomString } from "@sunrise/utils";

import type {
  AuthenticationMessage,
  ContinueWatchingEvent,
  DefaultOutboundMessage,
  FullyWatchedEvent,
  LogButtonClick,
  Navigation,
  OutboundMessage,
  Page,
  PlayerStateMessage,
  RecordingCapacityEvent,
} from "./types";

type YalloSocketEvents = {
  raw: [message: string];
  pong: [userId: string];
  authenticated: [isAuthenticated: boolean];
  "recording-capacity": [stats: RecordingCapacityEvent];
  "recording-status": [status: RecordingStatusEvent];
  "continue-watching": [item: ContinueWatchingEvent];
  "fully-watched": [item: FullyWatchedEvent];
  "live-streaming-limit-reached": [];
  subscription_changed: [];
  unhandled: [parsed: unknown];
};

type Listener<T extends Array<unknown>> = (...args: T) => void;

export const WAIT_AFTER_OPEN_IN_MS = 1_000;
export const RECONNECT_TIMEOUT_IN_MS = 10_000;

type AuthenticationArguments = {
  token: string;
  deviceVersion?: string;
  clientVersion?: string;
  deviceId?: string;
  userAgent?: string;
};

export class WebsocketService {
  private page?: Page;
  private assetId?: string;
  private messages: (OutboundMessage & DefaultOutboundMessage)[] = [];
  private keepaliveInterval?: ReturnType<typeof setInterval>;
  private keepaliveIntervalInMs: number;
  private socket?: WebSocket;
  private language?: string;
  private url: string;
  private reconnectTimeout?: ReturnType<typeof setInterval>;
  private reconnectBackoff = 1;

  private lastAuthenticationDetails?: AuthenticationArguments;
  private disconnectReason?: "manual" | "close_or_error";

  private onAuthenticationChange: (authenticated: boolean) => void;
  private onConnectionChange: (connected: boolean) => void;
  private isAuthenticated = false;

  constructor({
    url,
    keepaliveIntervalInMs,
    onAuthenticationChange,
    onConnectionChange,
  }: {
    url: string;
    keepaliveIntervalInMs: number;
    onAuthenticationChange: (authenticated: boolean) => void;
    onConnectionChange: (connected: boolean) => void;
  }) {
    this.url = url;
    this.keepaliveIntervalInMs = keepaliveIntervalInMs;
    this.onAuthenticationChange = onAuthenticationChange;
    this.onConnectionChange = onConnectionChange;
  }

  private connect() {
    this.startSocket();
  }

  private startSocket() {
    if (this.isConnected() || this.isConnecting()) return;

    this.socket = new WebSocket(this.url);
    if (this.reconnectTimeout) {
      clearTimeout(this.reconnectTimeout);
    }

    // Already trigger the reconnect timeout.
    // Since the socket does not come back when connecting fails.
    this.cleanReconnect();

    this.socket.addEventListener("open", this.handleWebSocketOpen);
    this.socket.addEventListener("message", this.handleIncomingMessage);
    this.socket.addEventListener("close", this.handleWebSocketClose);
    this.socket.addEventListener("error", this.handleWebSocketClose);
  }

  private cleanSocket() {
    this.setAuthenticatedStatus(false);
    this.setConnected(false);

    // We need to clear the messages because anything queued up after this will be for the next user.
    this.messages = [];

    if (!this.socket) return;

    this.socket.removeEventListener("open", this.handleWebSocketOpen);
    this.socket.removeEventListener("message", this.handleIncomingMessage);
    this.socket.removeEventListener("close", this.handleWebSocketClose);
    this.socket.removeEventListener("error", this.handleWebSocketClose);
    this.socket.close();
    this.socket = undefined;
  }

  public disconnect() {
    // If anything is still cached, flush it.
    this.flushCache();

    this.disconnectReason = "manual";

    this.cleanupSocketAndReconnect();
  }

  protected handleWebSocketOpen = () => {
    this.setConnected(true);
    this.disconnectReason = undefined;

    if (this.reconnectTimeout) {
      clearTimeout(this.reconnectTimeout);
    }
    this.reconnectBackoff = 1;

    this.keepaliveInterval = setInterval(
      this.sendKeepAlive.bind(this),
      this.keepaliveIntervalInMs,
    );

    // Inject an authentication token if we do not have one.
    if (
      !this.messages.some(
        (m) => "action" in m && m.action === "authenticate",
      ) &&
      this.lastAuthenticationDetails
    ) {
      this.authenticate(this.lastAuthenticationDetails, true);
    }

    // Workaround for YALLOTV-15945.
    // We need to wait a bit before we attempt to authenticate.
    setTimeout(() => this.flushCache(), WAIT_AFTER_OPEN_IN_MS);
  };

  public isConnecting(): boolean {
    return (
      (this.socket && this.socket.readyState === this.socket.CONNECTING) ??
      false
    );
  }

  public isConnected(): boolean {
    return (
      (this.socket && this.socket.readyState === this.socket.OPEN) ?? false
    );
  }

  protected sendKeepAlive(): void {
    if (!this.isConnected()) return;
    // TODO: We can add an expectation to receive a pong message within a certain time frame.
    //       If not, we can trigger a reconect.
    this.send({
      event: "ping",
      followback_reference: generateRandomString(),
      client_timestamp: Date.now(),
    });
  }

  public authenticate(
    {
      token,
      deviceVersion,
      clientVersion,
      deviceId,
      userAgent,
    }: AuthenticationArguments,
    force = false,
  ) {
    if (!force && this.lastAuthenticationDetails?.token === token) {
      return;
    }

    if (!this.isConnected() && !this.isConnecting()) {
      this.connect();
    }

    this.lastAuthenticationDetails = {
      token,
      clientVersion,
      deviceId,
      deviceVersion,
      userAgent,
    };

    const message: AuthenticationMessage = {
      action: "authenticate",
      token,
      device_id: deviceId ?? "unknown",
      client_language: this.language ?? "unknown",
      client_version: clientVersion ?? "unknown",
      device_version: deviceVersion ?? "unknown",
      user_agent: userAgent ?? "unknown",
      client_timestamp: Date.now(),
      followback_reference: generateRandomString(),
    };

    this.queueMessage(message, { atFront: true });
  }

  private setAuthenticatedStatus(isAuthenticated: boolean) {
    this.isAuthenticated = isAuthenticated;

    if (this.eventListeners.authenticated) {
      this.eventListeners.authenticated.forEach((listener) => {
        listener(isAuthenticated);
      });
    }

    this.onAuthenticationChange(isAuthenticated);
    if (isAuthenticated) {
      setTimeout(() => this.flushCache(), WAIT_AFTER_OPEN_IN_MS);
    }
  }

  private setConnected(isConnected: boolean) {
    this.onConnectionChange(isConnected);
  }

  /*
   * Keep outgoing messages in an array so that we can send them when certain
   * events happen, specifically when an access token is set.
   */
  protected queueMessage(
    message: OutboundMessage,
    options: { atFront: boolean } | void,
  ): void {
    if (options?.atFront) {
      this.messages.unshift(message);
    } else {
      this.messages.push(message);
    }

    this.flushCache();
  }

  protected send(message: OutboundMessage & DefaultOutboundMessage): void {
    if (!this.isConnected()) return;
    this.socket?.send(JSON.stringify(message));
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  protected handleIncomingMessage = (event: MessageEvent<any>) => {
    const message = event.data;
    if (this.eventListeners.raw) {
      this.eventListeners.raw.forEach((listener) => {
        listener(message);
      });
    }

    try {
      const parsed = JSON.parse(message) as {
        status: string;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        [key: string]: any;
      };

      switch (parsed.status) {
        case "pong": {
          if (this.eventListeners.pong) {
            this.eventListeners.pong.forEach((listener) => {
              listener(parsed.user_id as string);
            });
          }
          break;
        }
        case "authenticated": {
          this.setAuthenticatedStatus(true);
          break;
        }
        default: {
          const messageType = parsed.envelope?.message_type;
          switch (messageType) {
            case "recording_capacity": {
              if (this.eventListeners["recording-capacity"]) {
                this.eventListeners["recording-capacity"].forEach(
                  (listener) => {
                    listener({
                      available: parsed.message.available_recordings,
                      capacity: parsed.message.capacity_recordings,
                      used: parsed.message.used_recordings,
                    });
                  },
                );
              }
              break;
            }
            case "recording_status": {
              if (this.eventListeners["recording-status"]) {
                this.eventListeners["recording-status"].forEach((listener) => {
                  listener({
                    epgEntryId: parsed.message.epg_entry.id,
                    recordingId: parsed.message.recording_id,
                    status: parsed.message.status,
                  });
                });
              }
              break;
            }
            case "continue_watching_updated":
            case "continue_watching_added": {
              if (this.eventListeners["continue-watching"]) {
                this.eventListeners["continue-watching"].forEach((listener) => {
                  listener(parsed.message);
                });
              }
              break;
            }
            case "continue_watching_deleted": {
              if (this.eventListeners["continue-watching"]) {
                this.eventListeners["continue-watching"].forEach((listener) => {
                  listener({
                    id: parsed.message.id,
                    type: "delete",
                  });
                });
              }
              break;
            }
            case "fully_watched": {
              if (this.eventListeners["fully-watched"]) {
                this.eventListeners["fully-watched"].forEach((listener) => {
                  listener(parsed.message);
                });
              }
              break;
            }
            case "live_streaming_limit_reached": {
              if (this.eventListeners["live-streaming-limit-reached"]) {
                this.eventListeners["live-streaming-limit-reached"].forEach(
                  (listener) => {
                    listener();
                  },
                );
              }
              break;
            }

            case "refresh": {
              if (this.eventListeners["subscription_changed"]) {
                this.eventListeners["subscription_changed"].forEach(
                  (listener) => {
                    listener();
                  },
                );
              }
              break;
            }

            default: {
              if (this.eventListeners.unhandled) {
                this.eventListeners.unhandled.forEach((listener) => {
                  listener(parsed);
                });
              }
            }
          }
        }
      }
    } catch {
      console.warn("failed to parse websocket message", message);
    }
  };

  /**
   * This will clean the socket right before we attempt a reconnect.
   * Every time we attempt this reconnect it is done through a timeout that gets twice as long every time.
   * When we actually connect, this backoff is reset.
   */
  private cleanReconnect() {
    this.reconnectTimeout = setTimeout(() => {
      this.reconnectBackoff *= 2;
      this.cleanSocket();
      this.connect();
    }, RECONNECT_TIMEOUT_IN_MS * this.reconnectBackoff);
  }

  private cleanupSocketAndReconnect = () => {
    clearInterval(this.keepaliveInterval);
    this.keepaliveInterval = undefined;
    this.cleanSocket();

    if (this.disconnectReason === "close_or_error") {
      this.cleanReconnect();
    }
  };

  protected handleWebSocketClose = () => {
    this.disconnectReason = "close_or_error";

    this.cleanupSocketAndReconnect();
  };

  public setLanguage(language: string) {
    this.language = language;
  }

  protected flushCache() {
    if (!this.isConnected()) return;

    // Handle authenticate message
    // only send authenticated messages after we received `authenticated` event
    if (!this.isAuthenticated) {
      const message = this.messages.shift();
      if (message) {
        if ("action" in message && message.action === "authenticate") {
          this.send(message);
        } else {
          this.messages.unshift(message);
        }
      }
      return;
    }

    // handle other messages
    while (this.messages.length > 0) {
      const message = this.messages.shift();
      if (!message) continue;
      this.send(message);
    }
  }

  public logNavigation(page: Navigation, at: Date = new Date()) {
    if (
      (page.pageId === "detail_page" && this.assetId === page.assetId) ||
      (this.page !== "detail_page" && this.page === page.pageId)
    )
      return;
    this.page = page.pageId;

    if (!page.pageId) {
      return;
    }

    this.queueMessage({
      event: "page_loaded",
      page_id: page.pageId,
      asset_id: page.pageId === "detail_page" ? page.assetId : undefined,
      epg_entry_id: page.pageId === "detail_page" ? page.epgEntryId : undefined,
      client_timestamp: at.getTime(),
      followback_reference: generateRandomString(),
    });
  }

  public logError(errorTechnicalName: string, pageId: Page) {
    this.queueMessage({
      event: "error_displayed",
      message: errorTechnicalName,
      located_in: this.page ?? pageId,
    });
  }

  /**
   * @deprecated Use `logButtonClickAtom` instead.
   */
  public logClick(
    { button, located_in, ...props }: LogButtonClick,
    at: Date = new Date(),
  ) {
    this.queueMessage({
      event: "button_clicked",
      button_id: button,
      located_in: located_in ?? this.page ?? null,
      client_timestamp: at.getTime(),
      followback_reference: generateRandomString(),
      ...props,
    });
  }

  public logPlayerState(state: PlayerStateMessage) {
    this.queueMessage(state);
  }

  /**
   * A small typesafe 'EventEmitter'-style interface.
   * We just need to capture the right event names with the right callbacks and then delegate to the stored listeners inside a handler on the socket's message events.
   */
  private readonly eventListeners: {
    [K in keyof YalloSocketEvents]?: Set<Listener<YalloSocketEvents[K]>>;
  } = {};

  public on<K extends keyof YalloSocketEvents>(
    key: K,
    listener: Listener<YalloSocketEvents[K]>,
  ): void {
    const listeners =
      this.eventListeners[key] ??
      (new Set() as Set<Listener<YalloSocketEvents[K]>>);
    listeners.add(listener);
    if (!this.eventListeners[key]) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      this.eventListeners[key] = listeners as any;
    }
  }
}
