import { atom } from "jotai";

import { RecordingSortingOption } from "../types";

/**
 * Controls which sorting to apply for the recordings overview.
 */
export const recordingsOverviewSortAtom = atom<RecordingSortingOption>(
  RecordingSortingOption.EpgStartAscending,
);
