import { format } from "date-fns";
import { ReactElement } from "react";

import * as styles from "@/components/debug/debug-version.css";

export const DebugVersion = (): ReactElement | undefined => {
  const env = import.meta.env;
  if (env.DEV) {
    return;
  }

  const date = env.VITE_APP_BUILD_DATE
    ? new Date(Number(env.VITE_APP_BUILD_DATE))
    : null;

  return (
    <div className={styles.root}>
      <div className={styles.part}>{env.MODE}</div>
      {env.VITE_APP_COMMIT_HASH ? (
        <div className={styles.part}>
          {env.VITE_APP_COMMIT_HASH.slice(0, 7)}
        </div>
      ) : null}
      <div className={styles.part}>
        {env.VITE_APP_VERSION}
        {env.VITE_APP_BUILD_NR ? `(${env.VITE_APP_BUILD_NR})` : null}
      </div>
      {date && (
        <div className={styles.part}>{format(date, "dd.MM.yy - HH:mm")}</div>
      )}
    </div>
  );
};
