import { atom } from "jotai";
import { searchHistoryFeatureAtom } from "./search-history-feature.atom";
import { searchHistoryQueryAtom } from "./search-history-query.atom";

/**
 * Returns all of the search history items.
 * To look up if this is paged or not.
 */
export const searchHistoryItemsAtom = atom(async (get) => {
  const enabled = get(searchHistoryFeatureAtom);
  if (!enabled) {
    return [];
  }

  const query = await get(searchHistoryQueryAtom);

  return query.data?.queries?.slice(0, 5) ?? [];
});
