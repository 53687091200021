import { useTranslator } from "@sunrise/translator";
import { isEmptyObject } from "@sunrise/utils";
import {
  recordingsMarkedForBulkDeletionAtom,
  recordingsMarkedForBulkDeletionCountAtom,
} from "@sunrise/yallo-recordings";
import { useAtomValue } from "jotai";
import type { ReactNode } from "react";

type RecordingsBulkDeleteTitleProps = CommonProps;

export function RecordingsBulkDeleteTitle({
  "data-testid": dataTestId = "bulk-delete-title",
  className,
}: RecordingsBulkDeleteTitleProps): ReactNode {
  const markedForBulkDeletionRecordings = useAtomValue(
    recordingsMarkedForBulkDeletionAtom,
  );
  const t = useTranslator();

  const recordingsCount = useAtomValue(
    recordingsMarkedForBulkDeletionCountAtom,
  );

  const bulkDeletionTitle = isEmptyObject(markedForBulkDeletionRecordings)
    ? t("delete_recordings_initial_title")
    : t("recordings_selected_for_bulk_deletion_num", [recordingsCount]);

  return (
    <div className={className} data-testid={dataTestId}>
      {bulkDeletionTitle}
    </div>
  );
}
