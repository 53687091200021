import { atom } from "jotai";
import { atomWithReducer } from "jotai/utils";

type ExpandLevel = "hidden" | "channels" | "channels-and-groups";

// this state represents the channel list ui component state
// it is used to control if the ui component is hidden/expanded, and if expanded to what level
type ChannelListState = {
  expandLevel: ExpandLevel;
};

type ActionExpand = {
  type: "channel-list/expand";
  payload: {
    expandLevel: ExpandLevel;
  };
};

type ChannelListAction = ActionExpand;

function channelListAtomReducer(
  ps: ChannelListState,
  action: ChannelListAction,
): ChannelListState {
  switch (action.type) {
    case "channel-list/expand": {
      return {
        ...ps,
        expandLevel: action.payload.expandLevel,
      };
    }
  }
}

export const channelListAtom = atomWithReducer<
  ChannelListState,
  ChannelListAction
>(
  {
    expandLevel: "hidden",
  },
  channelListAtomReducer,
);

export function actionChannelListClose(): ActionExpand {
  return {
    type: "channel-list/expand",
    payload: {
      expandLevel: "hidden",
    },
  };
}

export function actionChannelListExpandChannels(): ActionExpand {
  return {
    type: "channel-list/expand",
    payload: {
      expandLevel: "channels",
    },
  };
}

export function actionChannelListExpandChannelsAndGroups(): ActionExpand {
  return {
    type: "channel-list/expand",
    payload: {
      expandLevel: "channels-and-groups",
    },
  };
}

/**
 * True when the channel list or channel group control is entirely hidden.
 */
export const isChannelListHiddenAtom = atom(
  (get) => get(channelListAtom).expandLevel === "hidden",
);

export const isChannelListExpandedAtom = atom(
  (get) => get(channelListAtom).expandLevel === "channels",
);

export const isChannelGroupsExpandedAtom = atom(
  (get) => get(channelListAtom).expandLevel === "channels-and-groups",
);
