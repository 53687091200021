import type { Stream } from "@sunrise/backend-types";
import { type Nullable } from "@sunrise/utils";
import type { PlayRequest } from "@sunrise/yallo-player-types";
import { createDateToCurrentTimeConverter } from "@sunrise/yallo-stream";

/**
 * For replay streams, we want to start at the beginning by default.
 * TODO: PlayRequests will contain a bookmark syntax in the future. This will then need to be mapped to the correct position.
 */
export function getLoadPositionForPlayRequest(
  request: Nullable<PlayRequest>,
  stream: Stream,
): Nullable<number> {
  if (request?.type === "replay") {
    if (request.startTime && "offset" in stream && stream.offset) {
      const converter = createDateToCurrentTimeConverter(stream.offset);
      return converter.fromDate(request.startTime);
    }

    return 0;
  } else if (request?.type === "recording") {
    return request.startAtSeconds ?? null;
  } else {
    return null;
  }
}
