import { adControlsHaveFocusAtom } from "@sunrise/ads";
import {
  selectShouldShowWhatIsNext,
  showWhatIsNextSideBarAtom,
} from "@sunrise/what-is-next";
import { playerControlsVisibleAtom } from "@sunrise/yallo-player-controls";
import { atom } from "jotai";

import { isChannelListHiddenAtom } from "@/features/channel-list";
import { canPullFocusAtom } from "@/modules/ui/can-pull-focus.atom";

/**
 * The idea is that whenever this atom is true, it HAS to pull focus to the TV page.
 * So we are not left with an unfocused TV page.
 */
export const shouldPullFocusToTVAtom = atom((get) => {
  return (
    // When menu is open or modal is open, we should not pull focus.
    get(canPullFocusAtom) &&
    // When channel list is expanded, we should not pull focus.
    get(isChannelListHiddenAtom) &&
    // When the "What is next" is visible, we should not pull focus.
    !get(selectShouldShowWhatIsNext) &&
    // When the "What is next side bar" is visible, we should not pull focus.
    !get(showWhatIsNextSideBarAtom) &&
    // When the player controls are visible, we should not pull focus.
    !get(playerControlsVisibleAtom) &&
    // When an ad control has focus, we should not pull focus.
    !get(adControlsHaveFocusAtom)
  );
});
