import { atom } from "jotai";

import type {
  AssetId,
  ChannelId,
  EPGEntryId,
  RecordingGroupId,
  RecordingId,
} from "@sunrise/backend-types-core";
import { recordingByEpgIdAtom } from "@sunrise/yallo-recordings";
import { socketAtom } from "@sunrise/yallo-websocket";

import { pageContentAtom } from "./page-content.atom";

type HomePageBased = {
  pageId: "home_page";
  categoryId?: string;
  componentType?: "normal" | "large";
};

type SingleRecordingClick = {
  type: "to_single_recording";
  recordingId: RecordingId;
  assetId: AssetId;
  epgEntryId: EPGEntryId;
};

type SeriesRecordingClick = {
  type: "to_series_recording";
  recordingGroupId: RecordingGroupId;
  assetId: AssetId;
};

type EpgItemClick = {
  type: "to_epg_item";
  epgId: EPGEntryId;
};

type ChannelItemClick = {
  type: "to_channel_item";
  channelId: ChannelId;
};

type ChangeLanguageClick = {
  type: "change_language";
  client_language: string;
};

type EpgHomePageClick = EpgItemClick & HomePageBased;

type ChannelHomePageClick = ChannelItemClick & HomePageBased;

type LogoutClick = {
  type: "do_logout";
};

export type LogButtonClickProps =
  // To Details
  | EpgItemClick
  | ChannelItemClick
  | SingleRecordingClick
  | SeriesRecordingClick
  // To Details (Page-specific)
  | EpgHomePageClick
  | ChannelHomePageClick
  // Non-details
  | ChangeLanguageClick
  | LogoutClick;

/**
 * This offers an internal API for ease of use.
 * It will append all the necessary data to the message the backend expects.
 * Like pageId, when, if there is a recording, etc.
 *
 * The invoke object's function will resolve whenever the message should be considered sent.
 * It may do some background work like figure out what recording we are talking about if we do not yet know it.
 *
 * After that, the client is allowed to navigate. If it is done before, the messages may happen out of sync.
 */
export const logButtonClickAtom = atom((get) => {
  const socket = get(socketAtom);

  return {
    invoke: async (props: LogButtonClickProps) => {
      if (!socket) {
        return;
      }

      const at = new Date();
      const page = get(pageContentAtom);
      const isOnHome = "pageId" in props && props.pageId === "home_page";

      switch (props.type) {
        case "to_epg_item": {
          const rec = await get(recordingByEpgIdAtom(props.epgId));
          socket.logClick(
            {
              button: "program_box_button" as const,
              located_in: page?.id,
              recording_id: rec?.id,
              epg_entry_id: props.epgId,
              category_id: isOnHome ? props.categoryId : undefined,
              component_type: isOnHome ? props.componentType : undefined,
            },
            at,
          );
          break;
        }
        case "to_channel_item": {
          socket.logClick(
            {
              button: "channel_box_button",
              channel_id: props.channelId,
              located_in: page?.id,
              category_id: isOnHome ? props.categoryId : undefined,
            },
            at,
          );
          break;
        }
        case "to_single_recording": {
          socket.logClick(
            {
              button: "program_box_button",
              located_in: page?.id,
              recording_id: props.recordingId,
              epg_entry_id: props.epgEntryId,
              asset_id: props.assetId,
              component_type: "normal",
            },
            at,
          );
          break;
        }
        case "to_series_recording": {
          socket.logClick(
            {
              button: "program_box_button",
              located_in: page?.id,
              recording_group_id: props.recordingGroupId,
              asset_id: props.assetId,
              component_type: "series_recording",
            },
            at,
          );
          break;
        }
        case "change_language": {
          socket.logClick(
            {
              button: "language_button",
              client_language: props.client_language,
              located_in: page?.id,
            },
            at,
          );
          break;
        }
        case "do_logout": {
          socket.logClick({
            button: "logout_button",
            located_in: page?.id,
          });
          break;
        }

        default: {
          console.info("not logging", props);
        }
      }
    },
  };
});
