import {
  jwtAtom,
  selectDecodedPayload,
  selectPlanUpgradeAvailable,
} from "@sunrise/jwt";
import type { Store } from "@sunrise/store";
import { userAtom } from "@sunrise/yallo-user";

import { DialogButtonType, type ErrorConfiguration } from "./types";

/**
 * Common errors shared between StreamErrorCode and UpsellErrorCode
 * technical error names are according to https://entwicklungspark.atlassian.net/wiki/spaces/WT/pages/3108339719/Error+Messages#Specific%2FExpected%2FKnown%2Fhandled-Errors
 */

const replayNotEnabled: ErrorConfiguration = {
  title: "title_activate_replay",
  description: [{ key: "player_error_replay_not_enabled" }],
  // NOTE: Let's only enable the replay_settings button when we have the settings present.
  // confirmationButton: DialogButtonType.REPLAY_SETTINGS,
  // rejectionButton: DialogButtonType.OK,
  confirmationButton: DialogButtonType.OK,
  // focusReject: true,
  technicalErrorName: "player_error_replay_not_enabled",
};

const replayNotEnabledLongEnough: ErrorConfiguration = {
  title: "player_error_replay_not_activated_long_enough_title",
  description: [{ key: "player_error_replay_not_activated_long_enough" }],
  // NOTE: Let's only enable the replay_settings button when we have the settings present.
  // confirmationButton: DialogButtonType.REPLAY_SETTINGS,
  // rejectionButton: DialogButtonType.OK,
  confirmationButton: DialogButtonType.OK,
  // focusReject: true,
  technicalErrorName: "player_error_replay_not_activated_long_enough",
};

const outOfReplayWindow: ErrorConfiguration = {
  title: "error_replay_out_of_bounds_title",
  description: [{ key: "error_replay_out_of_bounds_description" }],
  confirmationButton: DialogButtonType.OK,
  technicalErrorName: "error_program_not_in_replay_window",
};

const generalError: ErrorConfiguration = {
  title: "general_error_title",
  description: [{ key: "general_error_message" }],
  confirmationButton: DialogButtonType.OK,
  technicalErrorName: "general_error",
};

const LOCKED_CONTENT: ErrorConfiguration = {
  title: "player_error_title",
  description: [{ key: "player_error_locked_content" }],
  confirmationButton: DialogButtonType.OK,
  technicalErrorName: "player_error_missing_mysports_subscription",
};

const sessionExpired = (technicalErrorName?: string): ErrorConfiguration => ({
  confirmationButton: DialogButtonType.OK,
  title: "error_login_expired_title",
  description: [{ key: "error_login_expired_description" }],
  technicalErrorName: technicalErrorName ?? "session_expired",
});

const createErrorConfigForConcurrentStreams = async (
  store: Store,
): Promise<ErrorConfiguration> => {
  const user = await store.get(userAtom);

  const plan = user.data?.account.plan.display_name;
  const sessionLimit = selectDecodedPayload(store.get(jwtAtom))?.feature_set
    .features.session_limit;
  const planUpgradeAvailable = selectPlanUpgradeAvailable(store.get(jwtAtom));
  const dynamicParams = [plan ?? "", sessionLimit ?? 1];

  return {
    title: "player_error_too_many_concurrent_streams_general",
    confirmationButton: DialogButtonType.OK,
    description: planUpgradeAvailable
      ? [
          {
            key: "player_error_too_many_concurrent_streams_can_upgrade",
            dynamicParams,
          },
        ]
      : [
          {
            key: "player_error_too_many_concurrent_streams_no_upgrade_possible",
            dynamicParams,
          },
        ],
    technicalErrorName: planUpgradeAvailable
      ? "player_error_concurrent_stream_upgrade_available"
      : "player_error_concurrent_stream_no_upgrade_available",
  };
};

/**
 * This maps the possible error codes to the corresponding error configuration.
 */
export const ERROR_MAPPING: Record<
  string,
  ErrorConfiguration | ((store: Store) => Promise<ErrorConfiguration>)
> &
  Record<"GENERAL_ERROR", ErrorConfiguration> = {
  //
  // General (unknown) Error
  //
  GENERAL_ERROR: generalError,
  UPSELL_GENERAL_ERROR: generalError,

  "CHANNELPACKAGECHECK.CHANNEL_NOT_IN_ANY_PACKAGES": LOCKED_CONTENT,
  UPSELL_CHANNEL_LOCKED: LOCKED_CONTENT,

  "GEOCHECK.USER_ABROAD": {
    title: "player_error_title",
    description: [{ key: "player_error_geo_lock" }],
    confirmationButton: DialogButtonType.OK,
    technicalErrorName: "player_error_geo_lock",
  },

  /**
   * When the user has no replay permissions if they attempt to pause a live stream.
   */
  UPSELL_NO_PAUSE: {
    title: "upsell_pause_title",
    description: [{ key: "upsell_pause_description" }],
    confirmationButton: DialogButtonType.OK,
    technicalErrorName: "upsell_pause",
  },

  /**
   * When the user has no replay permissions at all.
   */
  UPSELL_NO_REPLAY: {
    title: "upsell_replay_title",
    description: [{ key: "upsell_replay_description" }],
    confirmationButton: DialogButtonType.OK,
    // TODO: we should differentiate between trying to replay past, live or rewinding.
    technicalErrorName: "upsell_no_replay",
  },

  /**
   * When the user has replay permissions but the stream is outside of the replay window.
   *
   * Technically not really an upsell error. Because there is nothing the user can buy to make it work.
   */
  UPSELL_OUTSIDE_REPLAY_WINDOW: outOfReplayWindow,
  "REPLAYACTIVATIONCHECK.OUT_OF_BOUNDS": outOfReplayWindow,

  /**
   * When the user has replay permissions
   * and the stream is inside the replay window
   * BUT the channel does not have replay enabled
   */
  "REPLAYACTIVATIONCHECK.REPLAY_NOT_ACTIVATED": replayNotEnabled,
  UPSELL_REPLAY_NOT_ENABLED: replayNotEnabled,

  /**
   * When the user has replay permissions
   * and the stream is inside the replay window
   * and the channel has replay enabled
   * BUT the channel did not have replay enabled at the time this content aired.
   */
  UPSELL_REPLAY_NOT_ENABLED_LONG_ENOUGH: replayNotEnabledLongEnough,
  "REPLAYACTIVATIONCHECK.NOT_ACTIVATED_LONG_ENOUGH": replayNotEnabledLongEnough,
  // NOTE: The recordings service returns an error code like this.
  USER_REPLAY_NOT_ACTIVATED_LONG_ENOUGH: replayNotEnabledLongEnough,

  // IDEA: Inject the number of hours the user can stream in total in the title.
  UPSELL_EXCEEDED_STREAM_DURATION_LIMIT: {
    title: "title_upsell_streaming_limit_reached",
    description: [{ key: "description_upsell_streaming_limit_reached" }],
    confirmationButton: DialogButtonType.OK,
    technicalErrorName: "player_error_too_many_concurrent_streams",
  },

  /**
   * When the concurrent streams limit is exceeded.
   */
  "MULTISTREAMCHECK.TOO_MANY_STREAMS": createErrorConfigForConcurrentStreams,

  /**
   * TECHNICAL ERRORS
   */
  error_user_logged_out: {
    confirmationButton: DialogButtonType.OK,
    title: "subscription_inactive_error_title",
    description: [{ key: "subscription_inactive_error_error" }],
    technicalErrorName: "error_user_logged_out",
  },

  session_expired: sessionExpired(),

  // When we are offline
  offline: {
    type: "blocking",
    title: "bad_connection_overlay_title",
    description: [{ key: "bad_connection_overlay_subtitle" }],
    technicalErrorName: "no_internet_connection",
  },

  error_user_not_known: sessionExpired("error_user_not_known"),
};
