import { assetDetailsByIdAtom } from "@sunrise/asset";
import { currentLanguageAtom, getLocalizedValue } from "@sunrise/i18n";
import { atom } from "jotai";

import { playerCurrentEpgItemShownAtom } from "./player-current-epg-item-shown.atom";
import { showProgramTagsInPlayerFeatureAtom } from "./player-show-program-tags-in-player-feature-flag";
import { PlayerCurrentAsset } from "./types";

const EMPTY: Readonly<PlayerCurrentAsset> = {
  ratings: {},
  genres: [],
  productionYear: null,
  ageRecommendation: null,
};

export const playerCurrentTagsShownInPlayerAtom = atom<
  Promise<PlayerCurrentAsset>
>(async (get) => {
  const showProgramTagsInPlayer = get(showProgramTagsInPlayerFeatureAtom);
  if (!showProgramTagsInPlayer) return EMPTY;

  const epg = await get(playerCurrentEpgItemShownAtom);
  if (!epg) return EMPTY;

  const language = get(currentLanguageAtom);
  const asset = await get(assetDetailsByIdAtom(epg.asset.id));

  return {
    ratings:
      asset.data?.ratings.reduce(
        (accumulator, { rating_source, rating_value }) => ({
          ...accumulator,
          [rating_source]: rating_value,
        }),
        {},
      ) ?? {},
    genres:
      asset.data?.genre
        ?.map((genre) =>
          genre.name ? getLocalizedValue(genre.name, language) ?? "" : "",
        )
        .filter(Boolean) ?? [],
    productionYear: asset.data?.production_year.toString() ?? null,
    ageRecommendation: asset.data?.age_recommendation ?? null,
  };
});
