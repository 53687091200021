import { fullyPagedAtom } from "@sunrise/backend-ng-core";
import { channelsForGivenChannelGroupIdQueryNgAtom } from "./queries/channels-for-given-channel-group-id.query.ng.atom";
import type { ChannelGroupId } from "@sunrise/backend-types-core";
import { atomFamily } from "jotai/utils";

/**
 * NOTE: We should try to avoid using this atom as it will instantly load all channels for the current channel group.
 *
 * It's for legacy reasons that we are going with this approach atm. The Guide relies on all channels to be there immediately.
 * I will create a ticket in the migration topic to add pagination support to the guide but it will be a big task.
 */
export const allChannelsForGivenChannelGroupIdNgAtom = atomFamily(
  (channelGroupId: ChannelGroupId) => {
    return fullyPagedAtom(
      channelsForGivenChannelGroupIdQueryNgAtom(channelGroupId),
    );
  },
);
