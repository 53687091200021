import { playerAtom, selectPlayerCurrentPlayRequest } from "@sunrise/player";
import {
  OnDemandSeekService,
  PlayerManagerGuard,
} from "@sunrise/player-manager";
import { Store } from "@sunrise/store";
import { type Nullable } from "@sunrise/utils";
import type { PlayRequest } from "@sunrise/yallo-player-types";

export const createYalloRecordingSeekService: (
  store: Store,
  guard: PlayerManagerGuard<PlayRequest>,
) => OnDemandSeekService<PlayRequest> = (store: Store) => {
  return {
    evaluatePauseSeekTime(progress: Nullable<number>) {
      const playRequest = store.get(selectPlayerCurrentPlayRequest);

      if (!playRequest || !progress) {
        return null;
      }

      return { playRequest, progress };
    },
    /**
     * Looks at what is currently playing and determines what seeking is possible inside.
     * It will also not emit any upsell errors while checking permissions.
     * But it will prevent seeking to a time which you are not permitted to seek to.
     *
     * @param progress
     * @returns
     */
    evaluateSeekTime(progress) {
      const { playRequest, duration } = store.get(playerAtom);

      if (!playRequest || !duration) {
        return null;
      }

      const validateProgress = (progressToValidate: number): number => {
        if (progressToValidate < 0) return 0;
        if (progressToValidate > duration) return duration;
        return progressToValidate;
      };

      return {
        playRequest,
        progress: validateProgress(progress),
      };
    },
  };
};
