import { endpoints, SearchHistoryResult } from "@sunrise/backend-types";
import { PrivateApiClient } from "@sunrise/http-client";

export async function searchHistory(
  host: string,
  httpClient: PrivateApiClient,
) {
  const url = new URL(endpoints.searchHistory(host));
  const { data } = await httpClient.get<SearchHistoryResult>(url.href);
  return data;
}

export async function addSearchHistory(
  host: string,
  httpClient: PrivateApiClient,
  query: string,
) {
  const url = new URL(endpoints.searchHistory(host));
  return (await httpClient.post<SearchHistoryResult>(url.href, { query })).data;
}

export async function deleteSearchHistory(
  host: string,
  httpClient: PrivateApiClient,
) {
  const url = new URL(endpoints.searchHistory(host));
  return (await httpClient.delete<SearchHistoryResult>(url.href)).data;
}
