import { Atom, atom } from "jotai";
import { atomFamily } from "jotai/utils";

import type { RecordingStatus } from "@sunrise/backend-types";
import type { RecordingId } from "@sunrise/backend-types-core";
import { Nullable } from "@sunrise/utils";

import { recordingStatusAtom } from "./recording-status.atom";

/**
 * This returns the RecordingStatus for a specific RecordingId.
 * The RecordingStatus just indicates if there is a recording and if so if it is planned or recorded.
 */
export const recordingStatusByRecordingIdAtom = atomFamily<
  Nullable<RecordingId>,
  Atom<Promise<Nullable<RecordingStatus>>>
>((recordingId: Nullable<RecordingId>) => {
  const innerAtom = atom(async (get) => {
    const data = await get(recordingStatusAtom);
    return data.find((status) => status.id === recordingId);
  });
  innerAtom.debugLabel = `recordingStatusByRecordingIdAtom(${recordingId})`;
  return innerAtom;
});
