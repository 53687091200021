import { hostsAtom } from "@sunrise/http-client";
import { type Nullable } from "@sunrise/utils";
import { atomWithSuspenseQuery } from "jotai-tanstack-query";
import { atomFamily } from "jotai/utils";
import { isNil } from "lodash";
import { fetchEPGEntry } from "./epg.service";

import { queryKeys, type EPGEntry } from "@sunrise/backend-types";
import type { EPGEntryId } from "@sunrise/backend-types-core";

/**
 * Fetches and caches {@link EPGEntry} that's being previewed,
 * not necessary currently playing.
 *
 */
export const epgEntryByIdAtom = atomFamily((epgId: EPGEntryId) =>
  atomWithSuspenseQuery<Nullable<EPGEntry>>((get) => {
    const host = get(hostsAtom).data;
    if (isNil(host)) throw new Error("Host is not set");

    return {
      enabled: !isNil(epgId),
      queryKey: queryKeys.epgById(epgId),
      queryFn: async () => {
        if (!epgId) return null;
        return fetchEPGEntry(host, epgId);
      },
      staleTime: Infinity,
      cacheTime: Infinity,
    };
  }),
);
