import { ReactNode } from "react";

import { Tag } from "@/components/tag/tag";

export function DebugTags(): ReactNode {
  return (
    <div style={{ margin: "3%", width: "50%" }}>
      <h2 style={{ marginBottom: "3%" }}>Tags</h2>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Tag text="hello" />
        <Tag text="done" icon="done" />
        <Tag text="speed" icon="next30" />
        <Tag text="rec" icon="recorded" />
      </div>
    </div>
  );
}
