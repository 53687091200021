import { selectIsLoggedIn } from "@sunrise/jwt";
import { socketAtom } from "@sunrise/yallo-websocket";
import { atomEffect } from "jotai-effect";
import { recordingStatsAtom } from "./recording-stats.atom";
import { recordingStatusAtom } from "./recording-status.atom";
import type { EPGEntryId, RecordingId } from "@sunrise/backend-types-core";
import { recordingsMarkedForDeletionAtom } from "./bulk-deletion/recordings-marked-for-deletion.atom";

export const reactForRecordingsToSocketEffect = atomEffect((get, set) => {
  const ws = get(socketAtom);
  const isLoggedIn = get(selectIsLoggedIn);

  if (!ws || !isLoggedIn) {
    return;
  }

  // Emit the recording capacity (recording stats) to the store.
  ws.on("recording-capacity", (capacity) => {
    set(recordingStatsAtom, {
      used_recordings: capacity.used,
      available_recordings: capacity.available,
      capacity_recordings: capacity.capacity,
    });
  });

  // // Pass recording status updates to the store.
  ws.on("recording-status", (status) => {
    set(recordingStatusAtom, {
      epg_entry: {
        id: status.epgEntryId as EPGEntryId,
      },
      id: status.recordingId as RecordingId,
      status: status.status,
    });

    // recording deletion was completed, update the marked atom
    // NOTE: By doing this we need to make sure to supporess recordings
    //       from the backend when they are marked as deleted in the recordingStatusAtom as well.
    if (status.status === "deleted") {
      const current = get(recordingsMarkedForDeletionAtom);
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { [status.recordingId as RecordingId]: _, ...rest } = current;
      set(recordingsMarkedForDeletionAtom, rest);
    }
  });

  return () => {
    set(recordingStatsAtom, null);
    set(recordingStatusAtom, null);

    // TODO: add a ws.off to the socket.
  };
});
