import { atom } from "jotai";
import { atomFamily } from "jotai/utils";

import type { AssetId, EPGEntryId } from "@sunrise/backend-types-core";
import { channelByIdAtom } from "@sunrise/yallo-channel-group";
import { epgEntryByIdAtom } from "@sunrise/yallo-epg";

import { assetDetailsByIdAtom } from "./asset-details-by-id.atom";
import { DetailsAtomResponse } from "./details.types";
import * as getDetailsForEpgAndAsset from "./helpers/get-details-for-epg-and-asset";

type DetailsAtomArgs = {
  assetId: AssetId;
  epgId: EPGEntryId;
};

export const detailsAtom = atomFamily(({ assetId, epgId }: DetailsAtomArgs) =>
  atom<Promise<DetailsAtomResponse>>(async (get) => {
    const asset = await get(assetDetailsByIdAtom(assetId));
    const epg = await get(epgEntryByIdAtom(epgId));

    const channel = epg.data
      ? await get(channelByIdAtom(epg.data.channel.id))
      : null;

    return getDetailsForEpgAndAsset.getDetailsForEpgAndAsset(
      epg.data,
      asset,
      channel,
    );
  }),
);
