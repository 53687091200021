import { atomEffect } from "jotai-effect";

import { socketAtom } from "./socket.atom";

export const socketLogUnhandledEffect = atomEffect((get) => {
  const ws = get(socketAtom);

  if (!ws) {
    return;
  }

  ws.on("unhandled", (parsed) => {
    console.log("unhandled message from server:", parsed);
  });
});
