import { featureAtom } from "@sunrise/feature-flags";

export const msInBackgroundUntilReloadToRootAtom = featureAtom<number | null>(
  "auto-reload-after-ms-in-background",
  null,
  {
    description: `The number of milliseconds the app should have been in the background
after foregrounding again to decide if we should redirect to the root of the app or not.
Can not happen when background-behaviour is set to "kill-app".`,
  },
);
