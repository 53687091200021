import { atom, SetStateAction } from "jotai";

import { hasLocalStorage } from "./has-local-storage";

/**
 * The problem is that jotai's atomWithStorage initializes with the initialValue and only after it reads from localStorage will it sets thhe correct value.
 * In our code we often depend on this value to be instantly correct. Things like last watched channel and last selected channel group.
 *
 * This wrapper will basically read from localStorage first and then initialize the atom with the correct value.
 * We will run into a issues once we need to have this work on something without localStorage being present (SSR).
 * In that case, we may be able to pass the initial values to use for each key on render through cookies or a header.
 *
 * https://github.com/pmndrs/jotai/discussions/1784
 * https://jotai.org/docs/guides/persistence
 *
 * @param key
 * @param value
 * @returns
 */

export function atomWithStorageWithDefault<T>(key: string, initialValue: T) {
  if (!hasLocalStorage()) {
    return atom(initialValue);
  }

  const getInitialValue = (): T => {
    const item = localStorage.getItem(key);
    if (item !== null) {
      try {
        return JSON.parse(item) as T;
      } catch {
        return initialValue;
      }
    }

    return initialValue;
  };

  const baseAtom = atom(getInitialValue());

  return atom(
    (get) => get(baseAtom),
    (get, set, update: SetStateAction<T>) => {
      set(baseAtom, update);
      const newValue = get(baseAtom);
      localStorage.setItem(key, JSON.stringify(newValue));
    },
  );
}
