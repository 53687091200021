import { atom } from "jotai";
import { queryClientAtom } from "jotai-tanstack-query";

import { flushRecordingsState } from "./flush-recordings-state";

/**
 * When we write to it we will grab the queryClient and flush the recordings cache.
 */
export const flushRecordingsCacheAtom = atom(null, (get) => {
  const queryClient = get(queryClientAtom);
  flushRecordingsState(queryClient, get);
});
