import { forwardRef, ReactNode, Ref } from "react";

import { Button } from "@/components/buttons/button";

type DropdownButtonProps = CommonProps & {
  text: string;
  focused: boolean;
  active: boolean;
  disabled?: boolean;
};

export const DropdownButton = forwardRef<
  HTMLButtonElement,
  DropdownButtonProps
>(function DropdownButton(
  {
    text,
    focused,
    active,
    disabled,
    "data-testid": dataTestId = "dropdownButton",
  }: DropdownButtonProps,
  ref: Ref<HTMLButtonElement>,
): ReactNode {
  return (
    <Button
      text={text}
      focused={focused}
      active={active}
      iconRight="dropdown-down"
      disabled={disabled}
      textAlign="left"
      block
      data-testid={dataTestId}
      ref={ref}
    ></Button>
  );
});
