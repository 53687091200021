import { useCallback, useMemo } from "react";
import { isBefore } from "date-fns";
import { minutesToSeconds } from "date-fns/minutesToSeconds";
import { useAtomValue, useSetAtom } from "jotai";

import type { EPGEntry } from "@sunrise/backend-types";
import { ChannelId, EPGEntryId } from "@sunrise/backend-types-core";
import { nowAtom } from "@sunrise/time";
import { Nullable } from "@sunrise/utils";
import type { ReplayPlayRequest } from "@sunrise/yallo-player-types";
import {
  preferRecordingsAtom,
  recordingByEpgIdAtom,
} from "@sunrise/yallo-recordings";
import { isWithinReplayWindow } from "@sunrise/yallo-replay";

import {
  actionPlayerManagerPlayRecording,
  actionPlayerManagerPlayReplay,
  playerManagerAtom,
} from "../player-manager.atom";
import { getPlayerManager } from "../player-manager.init";
import { useResumeProgram } from "./use-resume-program";

/**
 * This interface accepts the details of an EPGEntry.
 *
 * This is done because we have endpoints like recommendations or recordings
 * where we receive all the necessary data to determine the outcome of this hook.
 *
 * NOTE: This is legacy-specific code. Let's move it to the Tizen workspace.
 *       The way the recordings work is through the epgId which is not what we want.
 *
 * TODO: Extract something (either a hook or an atom) to determine if we will play out the recording or replay.
 *       Since we need the same logic for the CW / FW items. Because we need to know if we should look at the EPG or recording CW items.
 */
export function usePlayProgram(
  epgInformation: Nullable<{
    epgId: EPGEntryId;
    channelId: ChannelId;
    epgStart: EPGEntry["actual_start"];
    epgEnd: EPGEntry["actual_end"];
  }>,
): {
  play: () => void;
  /**
   * Indicates if the program should be replayable if the user would have replay permissions.
   * When the user does not have permissions but the program is in the past and in the replay window, this should still be true.
   * This is because we want to trigger an upsell when a user without permissions attempts to play.
   */
  canPlay: boolean;
  canResume: boolean;
  resume: () => void;
} {
  if (!epgInformation) {
    return {
      play: () => {
        // empty
      },
      canPlay: false,
      canResume: false,
      resume: () => {
        // empty
      },
    };
  }

  const { epgId, channelId, epgStart, epgEnd } = epgInformation;

  const now = useAtomValue(nowAtom);
  const dispatchPlayerManager = useSetAtom(playerManagerAtom);

  const recordingData = useAtomValue(recordingByEpgIdAtom(epgId));

  const { start, end } = useMemo(
    () => ({
      start: new Date(epgStart),
      end: new Date(epgEnd),
    }),
    [epgStart, epgEnd],
  );
  const isSingleRecording = recordingData?.type !== "group";
  const isRecordingPlayable = isSingleRecording && recordingData?.isPlayable;

  const canPlay = useMemo(() => {
    return (
      isRecordingPlayable ||
      (isBefore(start, now) &&
        isWithinReplayWindow(start, now).isInReplayWindow)
    );
  }, [start, now, end, isRecordingPlayable]);

  const preferRecordings = useAtomValue(preferRecordingsAtom);

  const play = useCallback(async () => {
    if (!canPlay) {
      return;
    }

    const playerManager = getPlayerManager();

    const replayRequest: ReplayPlayRequest = {
      type: "replay",
      epgId,
      channelId,
    };

    const canPlayReplay = await playerManager.canPlay(replayRequest, true);

    // If we have a fully recorded recording and it's url and we can't play replay (outside of replay window), then play recording, otherwise play replay.
    if (
      isRecordingPlayable &&
      recordingData?.id &&
      (!canPlayReplay || preferRecordings)
    ) {
      dispatchPlayerManager(
        actionPlayerManagerPlayRecording(
          recordingData.id,
          channelId,
          recordingData.paddingStartMinutes
            ? minutesToSeconds(recordingData.paddingStartMinutes)
            : undefined,
        ),
      );
    } else {
      dispatchPlayerManager(
        actionPlayerManagerPlayReplay(epgId, channelId, start, true),
      );
    }
  }, [
    canPlay,
    epgId,
    channelId,
    recordingData?.id,
    dispatchPlayerManager,
    start,
    preferRecordings,
    isRecordingPlayable,
  ]);

  const { canResume, resume } = useResumeProgram({
    epgId,
    channelId,
  });

  return useMemo(
    () => ({
      play,
      canPlay,
      canResume,
      resume,
    }),
    [play, canPlay, canResume, resume],
  );
}
