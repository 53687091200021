import { init as initSpatialNavigation } from "@noriginmedia/norigin-spatial-navigation";
import {
  addBreadcrumb,
  captureException,
  setTags,
  setUser,
} from "@sentry/react";
import {
  areVideoAdsPlayingAtom,
  enableAdsReporting,
  initPauseAds,
  initVideoAds,
} from "@sunrise/ads";
import { fetchRefreshAuthTokens } from "@sunrise/auth";
import { errorAtom, type BaseError } from "@sunrise/error";
import { growthbookConfigAtom } from "@sunrise/feature-flags";
import {
  createPrivateApi,
  hostsAtom,
  httpClientAtom,
  isRetryableError,
  publicApi,
} from "@sunrise/http-client";
import { currentLanguageAtom } from "@sunrise/i18n";
import { actionLocationNavigate, locationAtom } from "@sunrise/location";
import { initMonitoring } from "@sunrise/monitoring";
import {
  actionPlayerShouldReportAsStopped,
  getVideoElement,
  initVideoPlayer,
  playerAtom,
  playerDelayedBufferSettingsAtom,
  playerLiveBufferSettingsAtom,
  playerShouldDetachConfigAtom,
  selectPlayerCurrentError,
  shakaPlayerAtom,
} from "@sunrise/player";
import {
  actionPlayerStatsSetEnabled,
  actionPlayerStatsToggleEnabled,
  playerStatsAtom,
} from "@sunrise/player-stats";
import {
  backgroundBehaviourAtom,
  isOfflineAtom,
} from "@sunrise/process-visibility";
import { getTranslationFileAtom } from "@sunrise/translator";
import { type Nullable, isTizen } from "@sunrise/utils";
import { initPlayerManager } from "@sunrise/yallo-common-player-manager";
import {
  appVersionAtom,
  platformAtom,
  settingsVersionAtom,
} from "@sunrise/yallo-settings";
import {
  forceLiveTvTimeLimitReachedAtom,
  isNotKnownUserError,
  userAtom,
} from "@sunrise/yallo-user";
import "@sunrise/yallo-websocket";
import {
  deviceIdAtom,
  deviceVersionAtom,
  getClientID,
  socketUrlAtom,
} from "@sunrise/yallo-websocket";
import { atom } from "jotai";
import { queryClientAtom } from "jotai-tanstack-query";
import { isNil } from "lodash";
import { createRoot } from "react-dom/client";

import { route } from "./config/route";
import { SCREEN_HEIGHT_IN_PX, SCREEN_WIDTH_IN_PX, tizenStore } from "@/core";
import {
  GenericDialog,
  QrCodeDialog,
  ScrollableDialog,
} from "@/features/dialogs";
import { ErrorDialog } from "@/features/dialogs/error-dialog/error-dialog";
import { ErrorBoundary } from "@/features/monitoring/error-boundary";
import { makeMonitoringExtraData } from "@/features/monitoring/extra-data";
import { makeMonitoringTags } from "@/features/monitoring/extra-tags";
import { createHandleRetry } from "@/modules/dialogs/create-handle-retry";
import { getTranslationFile } from "@/modules/i18n/get-translation-file";
import {
  actionKeyboardNavigationRegisterMultipleKeyCodes,
  keyboardNavigationAtom,
} from "@/modules/keyboard-navigation";
import { queryClient } from "@/modules/query-provider";
import {
  closeApp,
  readTizenPlatformVersion,
} from "@/modules/tizen/system.service";
import { initTvRemote } from "@/modules/tizen/tv-remote.service";
import "@/styles/fonts.css";
import "@/styles/reset.css";

import { AppProvider } from "./app-provider";
import { isProdMode } from "./is-prod-mode";
import "./main.css";
import { backendRetryDelayInSecondsAtom } from "./modules/backend-retry-delay-in-seconds.atom";
import { doKillAppAtom } from "./modules/process/do-kill-app.atom";
import { tizenProductInfoAtom } from "./modules/tizen";
import { tizenOfflineAtom } from "./modules/tizen/tizen-offline.atom";
import { intentToCloseAtom } from "./modules/ui/intent-to-close.atom";
import { Root } from "./root";
import { languages, type Language } from "@sunrise/backend-types-core";
import { playerIsVisibleAtom } from "./modules/player/player-is-visible.atom";
import { playerShouldDetachAtom } from "./modules/player/player-should-detach.atom";
import { manualRetryAxiosInterceptor } from "@sunrise/backend-core";
import { ngHttpClientConfigAtom } from "@sunrise/backend-ng-core";
import type { AxiosError } from "axios";
import {
  actionJWTClear,
  actionJWTSetTokens,
  jwtAtom,
  selectAccessToken,
  selectRefreshToken,
} from "@sunrise/jwt";

const environment = import.meta.env.MODE;
const appVersion = import.meta.env.VITE_APP_VERSION;

// TODO: Read value from env variable defined on AWS
// We fallback to null when the value is falsey ("") for example.
const dsn = import.meta.env.VITE_SENTRY_DSN || null;

initMonitoring(
  {
    dsn,
    environment,
    isProdMode,
  },
  {
    initialScope: (scope) => {
      scope.setTags(makeMonitoringTags());
      scope.setExtras(makeMonitoringExtraData());
      return scope;
    },
  },
);

function showPlayer(): void {
  tizenStore.set(locationAtom, actionLocationNavigate(route.tv.root()));
}

const shouldReviveStreamSelectorAtom = atom((get) => {
  return !get(areVideoAdsPlayingAtom);
});

const WEBSOCKET_URL = import.meta.env.VITE_WEBSOCKET_ENDPOINT;

/**
 * Initialize libraries
 */
async function initIntegrations(): Promise<void> {
  const onRetry = (error: AxiosError) => {
    addBreadcrumb({
      category: "http-retry",
      message: "Request was retried",
      level: "info",
      data: {
        name: error.name,
        status: error.response?.status,
      },
    });
  };

  // NOTE: This is a temporary solution to avoid having a refresh from ng backend and a refresh from legacy backend at the same time.
  let refreshPromise: Nullable<ReturnType<typeof fetchRefreshAuthTokens>>;
  const doRefreshTokens = async (refreshToken: string) => {
    const host = tizenStore.get(hostsAtom).api;
    if (!host) {
      throw new Error("No host found");
    }

    if (!refreshPromise) {
      refreshPromise = fetchRefreshAuthTokens(host, refreshToken);
    }

    const result = await refreshPromise;
    refreshPromise = null;
    return result;
  };

  /**
   * Should resolve when the user interacted with the page to confirm they want to retry.
   */
  const handleRetry = createHandleRetry(tizenStore);
  const getRetryDelayInSeconds = (): Nullable<number> =>
    tizenStore.get(backendRetryDelayInSecondsAtom);

  tizenStore.set(appVersionAtom, import.meta.env.VITE_APP_VERSION);
  if (WEBSOCKET_URL) {
    tizenStore.set(socketUrlAtom, WEBSOCKET_URL);
  }

  const ngBaseUrl = import.meta.env.VITE_NG_API_ENDPOINT;
  if (ngBaseUrl) {
    tizenStore.set(ngHttpClientConfigAtom, {
      // The real config.
      baseUrl: ngBaseUrl,

      // Stuff for the error interceptor.
      doRefreshTokens,
      isNotKnownUserError,
      onRetry,
      getAccessToken: () => tizenStore.get(selectAccessToken),
      getRefreshToken: () => selectRefreshToken(tizenStore.get(jwtAtom)),

      setTokens: (accessToken: string, refreshToken: string) => {
        tizenStore.set(
          jwtAtom,
          actionJWTSetTokens({ accessToken, refreshToken }),
        );
      },
      resetTokens: (error: BaseError) => {
        tizenStore.set(errorAtom, error);
        tizenStore.set(jwtAtom, actionJWTClear());
      },

      getLanguage: () => tizenStore.get(currentLanguageAtom),

      // Stuff for the manual retry interceptor.
      awaitRetry: handleRetry,
      getRetryDelayInSeconds,
      isRetryableError,
    });
  }

  tizenStore.set(playerShouldDetachConfigAtom, playerShouldDetachAtom);

  // For Tizen we want to override some atoms.
  if (isTizen()) {
    tizenStore.set(isOfflineAtom, tizenOfflineAtom);
    tizenStore.set(
      deviceIdAtom,
      atom(async (get) => (await get(tizenProductInfoAtom)).data?.duid),
    );
    tizenStore.set(deviceVersionAtom, readTizenPlatformVersion());
    tizenStore.set(doKillAppAtom, {
      perform: (instant = false) => {
        tizenStore.set(playerAtom, actionPlayerShouldReportAsStopped());
        tizenStore.set(intentToCloseAtom, true);

        if (instant) {
          closeApp();
          return Promise.resolve();
        }

        return new Promise((resolve) => {
          setTimeout(() => {
            closeApp();
            resolve();
          }, 1000); // timeout to let the websocket send the stop message
        });
      },
    });
  } else {
    tizenStore.set(
      deviceIdAtom,
      atom(Promise.resolve(getClientID("yallo:tizen:client_id"))),
    );
    // On web we do not want to suspend the player or kill the app in the background.
    // NOTE: This does not seem to have much effect tbh .... . whenever the feature flag logic runs it overrides it again.
    tizenStore.set(backgroundBehaviourAtom, null);
  }

  tizenStore.set(httpClientAtom, {
    privateApi: createPrivateApi(
      tizenStore,
      doRefreshTokens,
      handleRetry,
      getRetryDelayInSeconds,
      isNotKnownUserError,
      onRetry,
    ),
    // NOTE: We probably want a `createPublicApi` function as well.
    publicApi: manualRetryAxiosInterceptor(
      publicApi,
      handleRetry,
      isRetryableError,
      getRetryDelayInSeconds,
    ),
  });
  tizenStore.set(platformAtom, "tizen");
  tizenStore.set(settingsVersionAtom, "8");
  tizenStore.set(hostsAtom, {
    api: import.meta.env.VITE_API_ENDPOINT,
    clients: import.meta.env.VITE_CLIENTS_ENDPOINT,
    data: import.meta.env.VITE_DATA_ENDPOINT,
    webSocket: WEBSOCKET_URL,
  });
  tizenStore.set(getTranslationFileAtom, { fn: getTranslationFile });

  initSpatialNavigation({ debug: false, visualDebug: false });
  initPlayerManager(tizenStore, import.meta.env.MODE === "production");
  initVideoAds(
    tizenStore,
    getVideoElement,
    showPlayer,
    import.meta.env.MODE !== "production",
    { width: SCREEN_WIDTH_IN_PX, height: SCREEN_HEIGHT_IN_PX },
  );
  initPauseAds(tizenStore);
  initVideoPlayer(
    tizenStore,
    {
      showPlayer,
      getPlayerBufferSettings: {
        live: () => tizenStore.get(playerLiveBufferSettingsAtom),
        delayed: () => tizenStore.get(playerDelayedBufferSettingsAtom),
      },
      playerVisibleAtom: playerIsVisibleAtom(playerAtom),
      playerShouldDetachAtom: playerShouldDetachAtom,
      // Make sure to inject the player error into the regular error atom so we can catch it before the ErrorBoundary.
      onError: (e) => tizenStore.set(errorAtom, e),
      logError: (e) =>
        captureException(e, {
          level: "info",
          tags: {
            "player.error": "true",
            location: "player-controller",
            errorCode: e.errorCode,
            hidden: true,
          },
          extra: e.extras,
        }),
      isEnabled: !window.disablePlayer,
      /**
       * NOTE: Tizen-TV-only-thing. Never needed on web. Often on the TV.
       */
      shouldReviveStreamSelector: shouldReviveStreamSelectorAtom,
    },
    (playerController) => {
      setTags({
        "player.type": playerController.getPlayerWrapperName(),
      });
    },
  );
  initTvRemote({
    onKeyRegistrationError: (msg) => {
      if (import.meta.env.MODE === "production") captureException(msg);
    },
    onValidationError: (msg) => {
      if (import.meta.env.MODE === "production") captureException(msg);
    },
    registerKeys: (tvRemoteKeyCodes) => {
      tizenStore.set(
        keyboardNavigationAtom,
        actionKeyboardNavigationRegisterMultipleKeyCodes({
          Back: [tvRemoteKeyCodes.Back],
          Exit: [tvRemoteKeyCodes.Exit],
          ChannelDown: [tvRemoteKeyCodes.ChannelDown],
          ChannelUp: [tvRemoteKeyCodes.ChannelUp],
          Info: [tvRemoteKeyCodes.Info],
          Caption: [tvRemoteKeyCodes.Caption],
          Guide: [tvRemoteKeyCodes.Guide],
          MediaAdvance: [tvRemoteKeyCodes.MediaAdvance],
          MediaPause: [tvRemoteKeyCodes.MediaPause],
          MediaPlay: [tvRemoteKeyCodes.MediaPlay],
          MediaPlayPause: [tvRemoteKeyCodes.MediaPlayPause],
          MediaFastForward: [tvRemoteKeyCodes.MediaFastForward],
          MediaRewind: [tvRemoteKeyCodes.MediaRewind],
          ProgrammableF0: [tvRemoteKeyCodes.ColorF0Red],
          ProgrammableF1: [tvRemoteKeyCodes.ColorF1Green],
          ProgrammableF2: [tvRemoteKeyCodes.ColorF2Yellow],
          ProgrammableF3: [tvRemoteKeyCodes.ColorF3Blue],
          "0": [tvRemoteKeyCodes["0"]],
          "1": [tvRemoteKeyCodes["1"]],
          "2": [tvRemoteKeyCodes["2"]],
          "3": [tvRemoteKeyCodes["3"]],
          "4": [tvRemoteKeyCodes["4"]],
          "5": [tvRemoteKeyCodes["5"]],
          "6": [tvRemoteKeyCodes["6"]],
          "7": [tvRemoteKeyCodes["7"]],
          "8": [tvRemoteKeyCodes["8"]],
          "9": [tvRemoteKeyCodes["9"]],
        }),
      );
    },
  });

  if (import.meta.env.MODE === "test") {
    tizenStore.set(currentLanguageAtom, "de" as Language);
  }

  const growthBookApiHost = import.meta.env.VITE_GROWTHBOOK_API_HOST as
    | string
    | undefined;
  const growthBookClientKey = import.meta.env.VITE_GROWTHBOOK_CLIENT_KEY as
    | string
    | undefined;
  if (growthBookApiHost && growthBookClientKey) {
    tizenStore.set(growthbookConfigAtom, {
      enableDevMode: false,
      apiHost: growthBookApiHost,
      clientKey: growthBookClientKey,
    });
  }

  // update sentry context
  async function updateStaticSentryContext(): Promise<void> {
    const info = await tizenStore.get(tizenProductInfoAtom);
    const deviceId = info.data?.duid;

    // We are setting these things as tags since we can search on tags.
    setTags({
      "device.id": deviceId,
      "device.model": info.data?.model,
      "device.model_code": info.data?.modelCode,
      "device.real_model": info.data?.realModel,
      "device.firmware": info.data?.firmware,
    });
  }

  void updateStaticSentryContext();

  async function updateDynamicSentryContext(): Promise<void> {
    const user = await tizenStore.get(userAtom);
    setUser({ id: user.data?.id });
  }

  tizenStore.sub(userAtom, () => {
    void updateDynamicSentryContext();
  });

  // For testing purposes
  if (import.meta.env.MODE !== "production") {
    if (import.meta.env.VITE_DISABLE_AD_REPORTING === "true") {
      tizenStore.set(enableAdsReporting, false);
    }

    if (import.meta.env.VITE_PLAYER_STATS_DEFAULT === "true") {
      tizenStore.set(playerStatsAtom, actionPlayerStatsSetEnabled());
    }

    document.addEventListener("keypress", (event) => {
      switch (event.key) {
        case "i": {
          tizenStore.set(playerStatsAtom, actionPlayerStatsToggleEnabled());
          break;
        }
        case "]":
        case "[": {
          const increase = event.key === "]";
          const currentLanguage = tizenStore.get(currentLanguageAtom);
          const bound = increase
            ? languages[0]
            : // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              languages[languages.length - 1]!;
          const nextLanguage =
            languages[
              languages.indexOf(currentLanguage) + (increase ? 1 : -1)
            ] ?? bound;
          tizenStore.set(currentLanguageAtom, nextLanguage);
          break;
        }
        case "l": {
          if (event.ctrlKey) {
            tizenStore.set(
              forceLiveTvTimeLimitReachedAtom,
              !tizenStore.get(forceLiveTvTimeLimitReachedAtom),
            );
          }
          break;
        }
        case "e": {
          if (event.ctrlKey) {
            // This breaks the player and as soon as it sees a playerError it fixes it again.
            const player = tizenStore.get(shakaPlayerAtom);
            if (!player) {
              return;
            }

            player.configure({
              streaming: { retryParameters: { timeout: 2 } },
            });

            const sub = tizenStore.sub(selectPlayerCurrentError, () => {
              const err = tizenStore.get(selectPlayerCurrentError);
              if (!err) {
                return;
              }

              player.configure({
                streaming: { retryParameters: { timeout: 50_000 } },
              });
              sub();
            });
          }
          break;
        }
      }
    });
  }
}

function logVersion(): void {
  if (!import.meta.env.PROD) {
    return;
  }

  console.info(`mode: ${import.meta.env.MODE}
version: ${appVersion}
commit: ${import.meta.env.VITE_APP_COMMIT_HASH}
build: ${import.meta.env.VITE_APP_BUILD_NR} ${
    import.meta.env.VITE_APP_BUILD_DATE
      ? `(${new Date(
          Number(import.meta.env.VITE_APP_BUILD_DATE),
        ).toLocaleString()})`
      : ""
  }`);
}

window.addEventListener("DOMContentLoaded", () => {
  logVersion();

  const appNode = document.getElementById("app");
  if (isNil(appNode)) throw new Error("App element does not exist");

  initIntegrations();

  createRoot(appNode).render(
    <ErrorBoundary>
      <AppProvider
        storeInstance={tizenStore}
        queryClientAtom={queryClientAtom}
        queryClient={queryClient}
        enableDevTools={!import.meta.env.PROD}
      >
        <Root />
        <QrCodeDialog />
        <ScrollableDialog />
        <ErrorDialog />
        <GenericDialog />
      </AppProvider>
    </ErrorBoundary>,
  );
});
