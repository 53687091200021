import { loadScript } from "@/utils/script";

let loadWebApiPromise: Promise<void> | null = null;
let webApiLoaded = false; // window.webapis is already populated before

export async function loadWebApi(): Promise<void> {
  if (webApiLoaded) return;

  if (!loadWebApiPromise) {
    loadWebApiPromise = loadScript("$WEBAPIS/webapis/webapis.js");
    loadWebApiPromise.then(() => (webApiLoaded = true));
  }

  return loadWebApiPromise;
}
