import type { Nullable } from "@sunrise/utils";
import deepEqual from "fast-deep-equal";
import { atom } from "jotai";
import { atomFamily } from "jotai/utils";

import { fullyWatchedItemsAtom } from "./fully-watched-items.atom";
import type { EPGEntryId } from "@sunrise/backend-types-core";

export const isFullyWatchedByEpgIdAtom = atomFamily(
  ({ epgId }: { epgId?: Nullable<EPGEntryId> }) => {
    const innerAtom = atom(async (get) => {
      const all = await get(fullyWatchedItemsAtom);

      return all.some((item) => item.epg_entry.id === epgId);
    });

    innerAtom.debugLabel = `isFullyWatchedByEpgIdAtom(${epgId})`;

    return innerAtom;
  },
  deepEqual,
);
