import clsx from "clsx";

import { ICON_SIZE_24 } from "@/components/icon/icon.config";
import { typography } from "@/styles/typography.css";

import { Icon, IconProps } from "../icon";
import * as styles from "./tag.css";

type TagProps = CommonProps & {
  text: string;
  icon?: IconProps["name"];
  uppercase?: boolean;
};

export const Tag = ({
  className,
  text,
  icon,
  uppercase = true,
  "data-testid": dataTestid = "tag",
}: TagProps): JSX.Element => {
  return (
    <div
      className={clsx(styles.root, className)}
      data-testid={`${dataTestid}${icon ? `.${icon}` : ""}`}
    >
      <div className={styles.content}>
        {icon && (
          <Icon
            name={icon}
            color="dark"
            className={styles.icon}
            size={ICON_SIZE_24}
          />
        )}
        <span className={typography.h7.bold}>
          {uppercase ? text.toUpperCase() : text}
        </span>
      </div>
    </div>
  );
};
