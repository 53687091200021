import { selectPlayerShouldBePlaying } from "@sunrise/player";
import { useAtomValue } from "jotai";
import { useEffect } from "react";

import { useRecoverPlayout } from "./hooks/use-recover-playout";
import { shouldPlayerAutorecoverAtom } from "./should-player-autorecover.atom";

/**
 * Will attempt to restart the player's playRequest when we detect there's no playout while there should be.
 * It will not happen when we were autostopped. But it will happen when the player is suspended.
 *
 * When to recover can be overridden by passing a custom Atom<boolean> to the `when` parameter.
 * This will be useful for web because there we will want to not have it recover on every visibility change.
 *
 * There's one additional check when autorecovering and that is that we are not offline.
 * It makes no sense to attempt autorecovery when we are offline.
 */
export function useAutorecover(): void {
  const recoverPlayout = useRecoverPlayout();

  const shouldAutoRecover = useAtomValue(shouldPlayerAutorecoverAtom);
  const shouldBePlaying = useAtomValue(selectPlayerShouldBePlaying);

  useEffect(() => {
    // When we became invisible or when the player is not supposed to be playing, we should not do anything.
    if (!shouldAutoRecover) {
      return;
    }

    recoverPlayout({ isPaused: !shouldBePlaying });
  }, [recoverPlayout, shouldAutoRecover, shouldBePlaying]);
}
