import type { ChannelId, EPGEntryId } from "@sunrise/backend-types-core";
import { atom } from "jotai";

export interface SelectedChannelItem {
  type: "channel";
  id: ChannelId;
}

export interface SelectedEPGItem {
  type: "epg";
  id: EPGEntryId;
}

export type SelectedSearchItem = SelectedChannelItem | SelectedEPGItem;

export const selectedSearchItemAtom = atom<SelectedSearchItem | null>(null);
