import {
  actionPlayerSetStream,
  actionPlayerShouldPause,
  actionPlayerShouldPlay,
  getPlayerController,
  playerAtom,
} from "@sunrise/player";
import { LoadOptions, PlayerDispatch } from "@sunrise/player-manager";
import { type Store } from "@sunrise/store";
import { type Nullable, isNil } from "@sunrise/utils";
import { lastWatchedChannelIdAtom } from "@sunrise/yallo-channel";
import { createDateToCurrentTimeConverter } from "@sunrise/yallo-stream";

import {
  actionPlayerManagerSuccess,
  playerManagerAtom,
} from "./player-manager.atom";
import type { Stream } from "@sunrise/backend-types";
import type { PlayRequest } from "@sunrise/yallo-player-types";

export class YalloPlayerDispatch implements PlayerDispatch<PlayRequest> {
  constructor(protected readonly store: Store) {}

  load(
    request: PlayRequest,
    stream: Stream,
    options: Nullable<LoadOptions>,
  ): void {
    // Keep track of the last watched channelId.
    this.store.set(lastWatchedChannelIdAtom, request.channelId);
    const currentTime =
      isNil(options) || isNil(options.currentTime)
        ? null
        : createDateToCurrentTimeConverter(
            "offset" in stream ? stream.offset : null,
          ).fromDate(options.currentTime);

    this.store.set(
      playerAtom,
      actionPlayerSetStream(
        stream,
        request,
        currentTime,
        // When we autostart or autorecover we want to play the stream immediately but we do not want the user to be redirected to the TV-page.
        options?.originatingAction !== "autostart" &&
          options?.originatingAction !== "autorecover",
        options?.ensurePaused ?? false,
      ),
    );
    this.store.set(lastWatchedChannelIdAtom, request.channelId);

    // NOTE: It is important that this only happens AFTER the playerAtom is informed of their new playRequest.
    // We indicate we are successful so other systems know the playerManager is now done with their work.
    this.store.set(playerManagerAtom, actionPlayerManagerSuccess());
  }

  play(): void {
    this.store.set(playerAtom, actionPlayerShouldPlay());
  }

  pause(): void {
    this.store.set(playerAtom, actionPlayerShouldPause());
  }

  seek(time: number): void {
    getPlayerController().setCurrentDate(time);
  }
}
