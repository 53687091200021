import { useEffect, useState } from "react";
import { useAtomValue } from "jotai";

import { currentLanguageAtom } from "@sunrise/i18n";
import { getLiveProgress, nowAtom } from "@sunrise/time";
import { useTranslator } from "@sunrise/translator";
import { type Nullable } from "@sunrise/utils";

import { makeRelativeDayOfWeekTranslation } from "../helpers/make-relative-day-of-week-translation";
import { useReadableEpgSchedulingInfo } from "./use-readable-epg-scheduling-info";

type EpgRelativeTimeArgs = {
  startDate: Nullable<Date>;
  endDate: Nullable<Date>;
  expiresAt: Nullable<Date>;
};
type EpgRelativeTime = {
  formattedStart: Nullable<string>;
  formattedEnd: Nullable<string>;
  airTime: Nullable<string>;
  liveProgress?: Nullable<number>;
  expiresIn?: Nullable<number>;
  isFinished: boolean;
};

export const ONE_DAY_IN_MS = 24 * 60 * 60 * 1000; // one day = hours*minutes*seconds*milliseconds

export const useEpgRelativeTime = ({
  startDate,
  endDate,
  expiresAt: expiresInDate,
}: EpgRelativeTimeArgs): EpgRelativeTime => {
  const [relativeDay, setRelativeDay] = useState("");

  const language = useAtomValue(currentLanguageAtom);
  const t = useTranslator();
  const now = useAtomValue(nowAtom);

  useEffect(() => {
    if (!startDate || !endDate) return;

    const doAsync = async (): Promise<void> => {
      setRelativeDay(
        await makeRelativeDayOfWeekTranslation(
          startDate,
          endDate,
          now,
          language,
          t,
        ),
      );
    };

    void doAsync();
  }, [endDate, language, now, startDate, t]);

  if (!startDate || !endDate)
    return {
      formattedStart: null,
      formattedEnd: null,
      airTime: null,
      liveProgress: null,
      expiresIn: null,
      isFinished: false,
    };

  const expiresIn = expiresInDate
    ? Math.round(
        Math.abs((expiresInDate.getTime() - now.getTime()) / ONE_DAY_IN_MS),
      )
    : null;

  const { formattedEnd, formattedStart } = useReadableEpgSchedulingInfo(
    { startTime: startDate, endTime: endDate },
    t,
    now,
  );

  const liveProgress = getLiveProgress(startDate, endDate, now);
  const isFinished = now >= endDate;

  return {
    formattedStart,
    formattedEnd,
    airTime: relativeDay,
    expiresIn,
    liveProgress,
    isFinished,
  };
};
