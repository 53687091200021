import { hostsAtom, httpClientAtom } from "@sunrise/http-client";
import { atomWithSuspenseQuery } from "jotai-tanstack-query";
import { atomFamily } from "jotai/utils";
import { isNil } from "lodash";

import { fetchWhatIsNext } from "../recordings.service";
import type { RecordingId } from "@sunrise/backend-types-core";

export const whatIsNextAtom = atomFamily((recordingId: RecordingId) => {
  const innerAtom = atomWithSuspenseQuery((get) => {
    const host = get(hostsAtom).api;
    if (isNil(host)) throw new Error("Host is not set");
    const { privateApi } = get(httpClientAtom);
    if (!privateApi) throw new Error("missing privateApi");

    return {
      queryKey: ["whatIsNext", recordingId],
      queryFn: async () => {
        return fetchWhatIsNext(host, privateApi, recordingId);
      },
    };
  });

  innerAtom.debugLabel = `whatIsNextAtom(${recordingId})`;

  return innerAtom;
});
