import { errorAtom } from "@sunrise/error";
import {
  PlayerError,
  selectPlayerCurrentError,
  selectPlayerCurrentPlayRequest,
  selectPlayerIsPlaying,
} from "@sunrise/player";
import deepEqual from "fast-deep-equal";
import { atom } from "jotai";
import { atomEffect } from "jotai-effect";
import { atomWithReducer, selectAtom } from "jotai/utils";

import {
  selectPlayerManagerCurrentError,
  selectPlayerManagerCurrentOptions,
  selectPlayerManagerCurrentPlayRequest,
  selectPlayerManagerIsHandled,
} from "../player-manager.atom";
import type { PlayRequest } from "@sunrise/yallo-player-types";

export type RecoveryState = {
  /**
   * Normally there is an atom that decides automatically when to recover.
   * As soon as we are recovering, we set the state to "recovering".
   *
   * - idle: We are not recovering. Nothing has requested to recover. Other playout is happening.
   * - requested: An effect triggered a request to recover. See `shouldPlayerAutorecoverAtom`.
   *              This means the hook should now trigger a new playRequest with a loadOrigin of `autorecover`.
   *              In this state, we go to the "triggered" state when we see a new playRequest appear in the playerManager with an option of "autorecover".
   *              After 10 seconds in this state, we also trigger as failed. Normally the hook should be implemented and it should be instant.
   *              TODO: If it's all atoms and starting of the recovery is controlled through here then we do not need to rely on the hook being used.
   *                    And then we don't really need that timeout I guess.
   * - triggered: This means the playerManager is now responsible and needs to be monitored.
   *              When the playerManager succeeds the yallo-dispatcher will set the state to "recovering". We know this if the request is handled and there is no error.
   *              When it is handled but we see an error, then we go to the "failed" state.
   * - recovering: The playermanager did its thing. Now we should solely look at the player.
   *               When we see a new playRequest in the player, no error and a playing state, we fo to the "success" state.
   *               When we see a new error in the player, we move to the "failed" state.
   *               // TODO: Maybe we need some kind of timeout effect here as well.
   * - success: Described above how we end up here. When we go to this state, we also make sure to remove the error on the errorAtom if it's the same error as the one we recovered from.
   * - failed: Described above how we end up here. When we go to this state, we leave the error in the errorAtom. But we do clear the error from our own internal state.
   */
  state:
    | "idle"
    | "requested"
    | "triggered"
    | "recovering"
    | "failed"
    | "success";
  /**
   * This is set to a PlayRequest when we are "recovering".
   */
  playRequest: PlayRequest | null;
  /**
   * This is tracked so we know to display the error to the enduser when we see the same error for a second time.
   * The mechanism that displays the error needs to check if we are still recovering. And if so if we are recovering from the error it wants to show.
   * If so, the error is not actually shown. Only until we are no longer recovering and the error is still there.
   */
  recoveringFrom: PlayerError | null;
};

type RecoveryActionStartRecoveryFromError = {
  type: "recovery/recover-from-error";
  error: PlayerError;
};

type RecoveryActionRecoverFromSuspension = {
  type: "recovery/recover-from-suspension";
};

type RecoveryActionTriggered = {
  type: "recovery/triggered";
  playRequest: PlayRequest;
};

type RecoveryActionRecovering = {
  type: "recovery/recovering";
};

type RecoveryActionSuccess = { type: "recovery/success" };

type RecoveryActionFailed = { type: "recovery/failed" };

type RecoveryActionReset = { type: "recovery/reset" };

type RecoveryAction =
  | RecoveryActionStartRecoveryFromError
  | RecoveryActionRecoverFromSuspension
  | RecoveryActionTriggered
  | RecoveryActionRecovering
  | RecoveryActionSuccess
  | RecoveryActionFailed
  | RecoveryActionReset;

const recoveryAtomInternal = atomWithReducer<RecoveryState, RecoveryAction>(
  { state: "idle", recoveringFrom: null, playRequest: null },
  (ps, action) => {
    switch (action?.type) {
      case "recovery/recover-from-error":
        return {
          state: "requested",
          recoveringFrom: action.error,
          playRequest: null,
        };
      case "recovery/recover-from-suspension":
        return {
          state: "requested",
          recoveringFrom: null,
          playRequest: null,
        };
      case "recovery/triggered":
        return {
          ...ps,
          state: "triggered",
          recoveringFrom: ps.recoveringFrom,
          playRequest: action.playRequest,
        };
      case "recovery/recovering":
        return {
          ...ps,
          state: "recovering",
        };
      case "recovery/failed":
        return {
          ...ps,
          state: "failed",
          // We need to flush this. So we don't instantly assume we failed before the player atom has been given the chance to clear the error.
          playRequest: null,
        };
      case "recovery/success":
        return {
          ...ps,
          state: "success",
          playRequest: null,
        };
      case "recovery/reset":
        return {
          state: "idle",
          recoveringFrom: null,
          playRequest: null,
        };
    }

    return ps;
  },
);
recoveryAtomInternal.debugPrivate = true;

export const recoveryAtom: typeof recoveryAtomInternal = atom(
  (get) => {
    get(recoveryIdleEffect);
    get(recoveryRequestedEffect);
    get(recoveryTriggeredEffect);
    get(recoveryRecoveringEffect);

    return get(recoveryAtomInternal);
  },
  (_, set, action?: RecoveryAction) => {
    return set(recoveryAtomInternal, action);
  },
);

const RETURN_TO_IDLE_DISALLOWED: RecoveryState["state"][] = ["idle"];
/**
 * Pushes the state back to idle under the right conditions.
 * Basically, whenever we see a new playRequest appear in the playerManager that is not autorecover.
 * And we are not idle already.
 */
const recoveryIdleEffect = atomEffect((get, set) => {
  // We need to only subscribe to the playrequest changing. Whenever they change -> we need to potentially reset.
  get(selectPlayerManagerCurrentPlayRequest);

  // We peek to see if we have a playrequest that is part of autorecovery. If so, we do not want to reset to idle.
  const isAutoRecover =
    get.peek(selectPlayerManagerCurrentOptions)?.originatingAction ===
    "autorecover";
  if (isAutoRecover) {
    return;
  }

  // Except when the current state does not require us to set. We peek here again because we do not want the effect to evaluate whenever the state changes.
  // Since that may trigger incorrect resets.
  const state = get.peek(recoveryAtom).state;
  if (RETURN_TO_IDLE_DISALLOWED.includes(state)) {
    return;
  }

  set(recoveryAtom, { type: "recovery/reset" });
});

const recoveryRequestedEffect = atomEffect((get, set) => {
  const isRequested = get(selectRecoveryIsRequested);
  const loadOptions = get(selectPlayerManagerCurrentOptions);
  const playRequest = get(selectPlayerManagerCurrentPlayRequest);
  const isHandled = get(selectPlayerManagerIsHandled);

  if (!isRequested) {
    return;
  }

  if (
    !isHandled &&
    loadOptions?.originatingAction === "autorecover" &&
    playRequest
  ) {
    set(recoveryAtom, {
      type: "recovery/triggered",
      playRequest,
    });
  }

  const timeout = setTimeout(() => {
    set(recoveryAtom, { type: "recovery/failed" });
  }, 10_000);

  return () => {
    clearTimeout(timeout);
  };
});

/**
 * This means we look at what the playerManager is doing and we kick it out of the triggered state.
 */
const recoveryTriggeredEffect = atomEffect((get, set) => {
  const isTriggered = get(selectRecoveryIsTriggered);
  if (!isTriggered) {
    return;
  }

  const playerManagerIsHandled = get(selectPlayerManagerIsHandled);
  if (!playerManagerIsHandled) {
    return;
  }
  const playerManagerCurrentPlayRequest = get(
    selectPlayerManagerCurrentPlayRequest,
  );
  const handlingFor = get(selectRecoveringForPlayRequest);

  // NOTE: This needs to be an equality check by reference.
  if (playerManagerCurrentPlayRequest !== handlingFor) {
    return;
  }

  // They need to be at the top since otherwise the effect will not trigger at the right times.
  const playerManagerError = get(selectPlayerManagerCurrentError);

  if (playerManagerError) {
    set(recoveryAtom, {
      type: "recovery/failed",
    });
  } else {
    set(recoveryAtom, {
      type: "recovery/recovering",
    });
  }
});

const recoveryRecoveringEffect = atomEffect((get, set) => {
  const isRecovering = get(selectRecoveryIsRecovering);
  if (!isRecovering) {
    return;
  }

  const playerError = get(selectPlayerCurrentError);
  const isPlaying = get(selectPlayerIsPlaying);

  const recoveringForPlayRequest = get(selectRecoveringForPlayRequest);
  const playerCurrentPlayRequest = get(selectPlayerCurrentPlayRequest);

  // NOTE: Against my expectations we need deepEqual here. To be figured out what is going on. How can it be that the playRequests actually differ.
  if (!deepEqual(recoveringForPlayRequest, playerCurrentPlayRequest)) {
    return;
  }

  if (isPlaying && !playerError) {
    // Clear the error if it is the one we are recovering from.
    const recoveringFrom = get(recoveryAtom).recoveringFrom;
    const error = get(errorAtom);
    if (recoveringFrom === error) {
      set(errorAtom, null);
    }

    set(recoveryAtom, {
      type: "recovery/success",
    });
    return;
  }

  if (playerError) {
    set(recoveryAtom, {
      type: "recovery/failed",
    });
    return;
  }
});

/**
 * ACTIONS
 */

export function actionRecoverFromError(
  error: PlayerError,
): RecoveryActionStartRecoveryFromError {
  return { type: "recovery/recover-from-error", error };
}

export function actionRecoverFromSuspension(): RecoveryActionRecoverFromSuspension {
  return { type: "recovery/recover-from-suspension" };
}

/**
 * SELECTORS
 */

const RECOVERY_ONGOING_STATES: RecoveryState["state"][] = [
  "recovering",
  "triggered",
];

export const selectRecoveryIsOngoing = selectAtom(recoveryAtom, (ps) =>
  RECOVERY_ONGOING_STATES.includes(ps.state),
);

export const selectRecoveryErrorIsSuccessful = selectAtom(
  recoveryAtom,
  (ps) => ps.state === "success",
);

export const selectRecoveryIsRequested = selectAtom(
  recoveryAtom,
  (ps) => ps.state === "requested",
);

export const selectRecoveryIsIdle = selectAtom(
  recoveryAtom,
  (ps) => ps.state === "idle",
);

export const selectRecoveryIsTriggered = selectAtom(
  recoveryAtom,
  (ps) => ps.state === "triggered",
);

export const selectRecoveryIsRecovering = selectAtom(
  recoveryAtom,
  (ps) => ps.state === "recovering",
);

export const selectRecoveringForError = selectAtom(
  recoveryAtom,
  (ps) => ps.recoveringFrom,
);

export const selectRecoveringForPlayRequest = selectAtom(
  recoveryAtom,
  (ps) => ps.playRequest,
);

export const selectRecoveryHasFailed = selectAtom(
  recoveryAtom,
  (ps) => ps.state === "failed",
);
