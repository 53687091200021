import type { ContinueWatchingUpdate } from "@sunrise/backend-types";
import { atomWithArrayUpdates } from "@sunrise/utils";

export const continueWatchingSocketUpdatesAtom =
  atomWithArrayUpdates<ContinueWatchingUpdate>([], {
    compareFn: (a, b) =>
      a.id === b.id ||
      ("epg_entry" in a &&
        "epg_entry" in b &&
        a.epg_entry.id === b.epg_entry.id),
    debugLabel: "contineWatchingSocketUpdates",
  });
