import type { StreamErrorCode } from "@sunrise/backend-types";
import { BaseError } from "@sunrise/error";

export class StreamBlockedByBackendError extends BaseError {
  constructor(readonly code: StreamErrorCode) {
    super(`StreamBlockedByBackendError`, code, { errorCode: code });
  }

  get shouldAutoRecover(): boolean {
    return false;
  }
}
