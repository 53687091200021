import { atomWithReducer } from "jotai/utils";

export type QrCodeDialogAtomState = {
  isOpen: boolean;
  title: string;
  description?: string;
  qrCodeUrl: string;
  link: string;
  confirmButtonLabel: string;
  rejectButtonLabel?: string;
  onConfirm: () => void;
  onReject?: () => void;
  flexDirectionRow?: boolean;
  qrCodeSize?: number;
  lastFocusKey: string;
};

const NOOP = () => {
  // noop
};

export function makeQrCodeDialogAtomDefaultState(
  state?: Partial<QrCodeDialogAtomState>,
): QrCodeDialogAtomState {
  return {
    isOpen: state?.isOpen ?? false,
    title: state?.title ?? "",
    description: state?.description ?? "",
    qrCodeUrl: state?.qrCodeUrl ?? "",
    link: state?.link ?? "",
    confirmButtonLabel: state?.confirmButtonLabel ?? "",
    rejectButtonLabel: state?.rejectButtonLabel ?? "",
    onConfirm: state?.onConfirm ?? NOOP,
    onReject: state?.onReject ?? NOOP,
    flexDirectionRow: state?.flexDirectionRow ?? false,
    qrCodeSize: state?.qrCodeSize ?? 300,
    lastFocusKey: state?.lastFocusKey ?? "",
  };
}

export const initialQrCodeDialogState = makeQrCodeDialogAtomDefaultState();

type ActionOpen = {
  type: "dialog/open";
  payload: Omit<QrCodeDialogAtomState, "isOpen">;
};
type ActionClose = {
  type: "dialog/close";
};
export type DialogAction = ActionOpen | ActionClose;

export const qrCodeDialogAtom = atomWithReducer<
  QrCodeDialogAtomState,
  DialogAction
>(makeQrCodeDialogAtomDefaultState(), qrCodedialogAtomReducer);

export function qrCodedialogAtomReducer(
  previousState: QrCodeDialogAtomState,
  action: DialogAction,
): QrCodeDialogAtomState {
  switch (action.type) {
    case "dialog/open": {
      return {
        ...previousState,
        ...action.payload,
        isOpen: true,
      };
    }
    case "dialog/close": {
      return initialQrCodeDialogState;
    }
  }
}

/*
 *
 * ACTIONS
 *
 */

export function actionQrCodeDialogOpen(
  payload: ActionOpen["payload"],
): ActionOpen {
  return {
    type: "dialog/open",
    payload,
  };
}

export function actionQrCodeDialogClose(): ActionClose {
  return {
    type: "dialog/close",
  };
}
