import { type Nullable } from "@sunrise/utils";
import { atomWithReducer } from "jotai/utils";

type MenuAtomState = {
  isVisible: boolean;
  isExpanded: boolean;
};

export function makeMenuAtomDefaultState(
  state?: Partial<MenuAtomState>,
): MenuAtomState {
  return {
    isVisible: state?.isVisible ?? false,
    isExpanded: state?.isExpanded ?? false,
  };
}

type ActionCollapse = {
  type: "menu/collapse";
};
type ActionExpand = {
  type: "menu/expand";
};
type ActionHide = {
  type: "menu/hide";
};
type ActionShow = {
  type: "menu/show";
};

/**
 * Action for testing purpose **only**
 */
type ActionTestSetState = {
  type: "menu/test/set-state";
  payload: Partial<MenuAtomState>;
};

type MenuAction =
  | ActionCollapse
  | ActionExpand
  | ActionHide
  | ActionShow
  | ActionTestSetState;

export const menuAtom = atomWithReducer<MenuAtomState, MenuAction>(
  makeMenuAtomDefaultState(),
  menuAtomReducer,
);

export function menuAtomReducer(
  ps: MenuAtomState,
  action: Nullable<MenuAction>,
): MenuAtomState {
  switch (action?.type) {
    case "menu/collapse": {
      return { ...ps, isExpanded: false };
    }
    case "menu/expand": {
      return { ...ps, isExpanded: true };
    }
    case "menu/hide": {
      return { ...ps, isVisible: false };
    }
    case "menu/show": {
      return { ...ps, isVisible: true };
    }
    case "menu/test/set-state": {
      return { ...ps, ...action.payload };
    }
    case null:
    case undefined: {
      return ps;
    }
  }
}

/*
 *
 * ACTIONS
 *
 */

export function actionMenuCollapse(): ActionCollapse {
  return {
    type: "menu/collapse",
  };
}

export function actionMenuExpand(): ActionExpand {
  return {
    type: "menu/expand",
  };
}

export function actionMenuHide(): ActionHide {
  return {
    type: "menu/hide",
  };
}

export function actionMenuShow(): ActionShow {
  return {
    type: "menu/show",
  };
}
