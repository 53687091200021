import type { LegacyRecording } from "@sunrise/backend-types";
import type { Language } from "@sunrise/backend-types-core";
import { getLocalizedValue } from "@sunrise/i18n";

import type { GenericRecording } from "../types";

export function legacyRecordingToGenericRecording(
  recording: LegacyRecording,
  language: Language,
): GenericRecording {
  if (!("type" in recording) || recording.type === "recording") {
    const shared = {
      id: recording.id,
      title: getLocalizedValue(recording.asset.title, language),
      subtitle: getLocalizedValue(recording.asset.subtitle, language),
      // TODO: doublecheck this
      isPlayable: recording.status === "recorded",
      assetId: recording.asset.id,
      channelId: recording.epg_entry.channel?.id ?? null,
      epg_end: new Date(recording.epg_entry.actual_end),
      epg_start: new Date(recording.epg_entry.actual_start),
      paddingEndMinutes: recording.padding_end_minutes ?? null,
      paddingStartMinutes: recording.padding_start_minutes ?? null,
      epgEntryId: recording.epg_entry.id,
      expiresAt: recording.expires_at ? new Date(recording.expires_at) : null,
      posterUrl: recording.asset.poster_path,
      streamUrl: recording.stream_url ?? null,
      posterBlurHash: null,
    };

    if (recording.asset.type === "seriesepisode") {
      return {
        type: "episode",
        ...shared,
      };
    }

    return {
      type: "single",
      ...shared,
    };
  } else {
    return {
      type: "group",
      id: recording.id,
      seriesAssetId: recording.asset.id,
      title: getLocalizedValue(recording.asset.title, language),
      subtitle: getLocalizedValue(recording.asset.subtitle, language),
      episodeCount: recording.episode_count,
      posterUrl: recording.asset.poster_path,
      posterBlurHash: null,
    };
  }
}
