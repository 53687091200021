import { nowAtom } from "@sunrise/time";
import { type Nullable, isNil } from "@sunrise/utils";
import { programIsPlayingAtTime } from "@sunrise/yallo-epg";
import {
  type Coordinates,
  type GuideChannel,
  type GuideProgram,
} from "@sunrise/yallo-guide";
import { useAtom, useAtomValue } from "jotai";
import { useCallback, useEffect, useMemo, useRef } from "react";

import { MINUTES_BUFFER_IN_GRID } from "@/features/guide/constants";
import {
  isArrowDownKey,
  isArrowRightKey,
  isHorizontal,
  isVertical,
} from "@/utils/navigation";

import { ViewportCalculator } from "../services/viewport-calculator";
import {
  actionGuideSetOffsetForDate,
  actionGuideSetOffsetForSelection,
  actionGuideSetSelection,
  gridStateAtom,
} from "../store";
import { GuideGridSelectionState } from "../store/types";
import { selectRelevantProgram } from "../utils/select-relevant-program";
import type { ChannelId } from "@sunrise/backend-types-core";

/**
 * It's responsible for:
 * - Re-select an appropriate program when the grid is re-focused when we are in grid offsetPriority.
 * - Scrolling the view into focus when the selected/focused item goes out of view (when we are in selection offsetPriority).
 * - Navigating between programs when the grid is focused.
 */
export function useGridAutoSelectAndScrollAndNavigate({
  channelHeightInPx,
  channelToPixels,
  data,
  height,
  timeToPixels,
  pixelsToTime,
  width,
  isWithinGuideRange,
}: {
  /**
   * The height of a channel in pixels.
   */
  channelHeightInPx: number;
  /**
   * Where does this channelId exist on the y-axis.
   */
  channelToPixels: (channelId: ChannelId) => number;
  /**
   * Given the time, where would that translate to on the x-axis.
   *
   * Returns null if it's out of range.
   */
  timeToPixels: (time: Date) => number;
  pixelsToTime: (pixels: number) => Date;
  /**
   * The data to navigate through. Per channel we will get all the items that are known.
   */
  data: GuideChannel[];
  /**
   * The height of the grid in pixels.
   * Not the total height of all the channels but just how many pixels of grid are shown.
   */
  height: number;
  /**
   * Same as height. How many pixels of the grid are shown for the width / x-axis.
   */
  width: number;
  isWithinGuideRange: (time: Date) => boolean;
}): {
  /**
   * To be called when the navigation can no longer happen. So when the grid is unfocused.
   * @returns
   */
  flush: () => void;
  /**
   * Call this with the direction you would like to navigate in.
   * @param direction - The direction you would like to navigate in.
   * @returns
   * true when we navigated successfully. Follows the same API as useFocusable's onArrowPress.
   */
  navigate: (direction: NavigationDirection) => boolean;
} {
  const [
    {
      selection,
      coordinates: { x: offsetX, y: offsetY },
      offsetPriority,
      isFocused,
      jumpToDate,
    },
    dispatchGridState,
  ] = useAtom(gridStateAtom);
  const now = useAtomValue(nowAtom);

  // These are refs because we don't need any logic to update after this is set. We just need it for internal referencing inside the navigation functions.
  const verticalNavigationReferenceTime = useRef<Date | null>(null);
  const navigationDirection = useRef<NavigationDirection>();

  // Just keep a ref to now as well so we can easily refer to it inside the navigation functions.
  // We do not need to rebuild these whenever the time changes.
  const nowRef = useRef<Date>(now);
  useEffect(() => {
    nowRef.current = now;
  }, [now]);

  /**
   * Little helper function to select a program and channel.
   * Wish we could automatically wire up the actions to dispatch.
   */
  const selectProgramAndChannel = useCallback(
    ({
      channel,
      program,
    }: {
      channel: GuideChannel;
      program: GuideProgram;
    }) => {
      dispatchGridState(actionGuideSetSelection(program, channel.id));
    },
    [dispatchGridState],
  );

  /**
   * Another helper function that jumps to coordinates.
   */
  const jumpToCoordinates = useCallback(
    (coordinates: Coordinates) => {
      dispatchGridState(
        actionGuideSetOffsetForDate(coordinates.x, coordinates.y),
      );
    },
    [dispatchGridState],
  );

  const viewportCalculator = useMemo(() => {
    return new ViewportCalculator(
      channelToPixels,
      timeToPixels,
      pixelsToTime,
      width,
      height,
      channelHeightInPx,
      { x: offsetX, y: offsetY },
      MINUTES_BUFFER_IN_GRID,
    );
  }, [
    channelToPixels,
    timeToPixels,
    pixelsToTime,
    width,
    height,
    offsetX,
    offsetY,
    channelHeightInPx,
  ]);

  /**
   * When the direction is not passed, it means we really want to center the selection. So that means in the middle of the grid.
   * When a direction is passed, we should attempt to change only the offset axis associated with that direction. So left/right means y axis.
   *
   * When the x axis changes, we need to calculate where to put the program in the viewport.
   * We want to ideally show the start of the program as well as the end of the program. In some cases this is not possible and there is a special case to handle this.
   *
   * If we are going right it means we come from the left. And we should try to center the program on the left edge of the grid.
   * If we are going left, it means we come from the right. And we should try to center the program on the right edge of the grid.
   *
   * If the program does not fit in the viewport, we should aim to first reveal the initial edge of the program
   * and on every subsequent navigation in the same direction we should scroll the view further and further to the other edge of the program.
   * The goal is to make sure we show the programs on the channels around the current program.
   *
   * If we scroll on the y axis things are a bit simpler. Since a channel is always the exact same height.
   * When going up, we need to place the channel at the bottom of the grid, when going down, we need to place the channel at the top of the grid.
   */
  const repositionGridForSelection = useCallback(
    (direction?: NavigationDirection) => {
      if (!selection) {
        return;
      }

      const newOffset = viewportCalculator.repositionOffsetForSelection(
        selection.channelId,
        {
          startTime: selection.startTime,
          endTime: selection.endTime,
        },
        direction,
      );

      dispatchGridState(
        actionGuideSetOffsetForSelection(newOffset.x, newOffset.y),
      );
    },
    [selection, dispatchGridState, viewportCalculator],
  );

  // We want to make sure we give jumping to a date a higher priority than auto-scrolling the grid.
  useEffect(() => {
    // When there's a jump date it overrides all other auto-scrolling logic.
    if (jumpToDate) {
      jumpToCoordinates({
        x: viewportCalculator.getCenteredOffsetForDate(jumpToDate),
        y: offsetY,
      });
      return;
    }

    // Don't continue if there's no selection or when we're not in selection offset priority.
    if (!selection || offsetPriority !== "selection") {
      return;
    }

    // When there's no real offset and we are not focused or when we are focused we need to center the selection.
    if ((offsetX === -1 && offsetY === -1 && !isFocused) || isFocused) {
      // When the selection is in the viewport for our current navigation direction, we don't care about re-centering it.
      if (
        // TODO: skip repositioning for long programs ... or assume that they are in the viewport if they are too long.
        viewportCalculator.isInViewportForNavigationDirection(
          selection.channelId,
          selection,
          navigationDirection.current,
        )
      ) {
        return;
      }

      repositionGridForSelection(navigationDirection.current);
    }
  }, [
    selection,
    repositionGridForSelection,
    isFocused,
    offsetX,
    offsetY,
    offsetPriority,
    viewportCalculator,
    jumpToDate,
    jumpToCoordinates,
  ]);

  /**
   * This is the core horizontal navigation.
   */
  const handleHorizontalNavigation = useCallback(
    function (
      channels: GuideChannel[],
      direction: "left" | "right",
      currentChannelIndex: number,
      currentSelection: NonNullable<GuideGridSelectionState>,
    ): boolean {
      // When we go left & right we no longer care about the reference time.
      verticalNavigationReferenceTime.current = null;

      const currentChannel = channels[currentChannelIndex];
      const currentProgramIndex = currentChannel?.items.findIndex(
        (program) => program.id === currentSelection.epgId,
      );

      if (isNil(currentProgramIndex) || currentProgramIndex === -1) {
        // Can't navigate if there's no program selected.
        return false;
      }

      const currentProgram = currentChannel?.items[currentProgramIndex];

      // This is to guard selecting something outside of the guide range.
      if (
        currentProgram &&
        !isWithinGuideRange(
          direction === "left"
            ? currentProgram.startTime
            : currentProgram.endTime,
        )
      ) {
        return false;
      }

      const overNextViewport = currentProgram
        ? viewportCalculator.isProgramOverNextViewport(
            currentProgram,
            direction,
          )
        : null;

      // If the current program ends further than the next viewport, we should scroll the view to the next viewport
      // and not all the way to the start of the next program.
      if (overNextViewport) {
        const newOffset =
          viewportCalculator.getCoordinatesForNextViewport(direction);
        dispatchGridState(
          actionGuideSetOffsetForSelection(newOffset.x, newOffset.y),
        );
        return true;
      }

      // find the next program or previous program ... .
      const targetProgram =
        currentChannel?.items[
          currentProgramIndex + (isArrowRightKey(direction) ? 1 : -1)
        ];

      if (!targetProgram) {
        return false;
      }

      selectProgramAndChannel({
        program: targetProgram,
        channel: currentChannel,
      });
      return true;
    },
    [
      selectProgramAndChannel,
      viewportCalculator,
      dispatchGridState,
      isWithinGuideRange,
    ],
  );

  const handleVerticalNavigation = useCallback(
    function (
      channels: GuideChannel[],
      direction: "up" | "down",
      currentChannelIndex: number,
      currentSelection: NonNullable<GuideGridSelectionState>,
    ): boolean {
      const targetChannel =
        channels[currentChannelIndex + (isArrowDownKey(direction) ? 1 : -1)];

      if (!targetChannel) {
        // Can't navigate if the target channel is not found.
        // There's also no cyclic navigation atm. Although it would be super easy to add here.
        return false;
      }

      // Set the seeker time to be the middle of the current program (unless a previously known reference time is known).
      // Check if the current item is live, if so, change the seeker time to the current time.
      // Keep in mind that while going up and down, if you land on a live playing item, it will switch to the now time for the seekerTime.
      let seekerTime =
        verticalNavigationReferenceTime.current === nowRef.current ||
        programIsPlayingAtTime(
          {
            startTime: currentSelection.startTime,
            endTime: currentSelection.endTime,
          },
          nowRef.current,
        )
          ? nowRef.current
          : // TODO: We need to make sure we clip this to a value that is inside the grid.
            verticalNavigationReferenceTime.current ??
            viewportCalculator.getVerticalScrollAxis(currentSelection);

      // Also clip seekerTime to the start and end of the guide.
      // Since we don't want to select on an axis which is outside of the TV-guide window.
      if (!isWithinGuideRange(seekerTime)) {
        if (isWithinGuideRange(currentSelection.startTime)) {
          seekerTime = currentSelection.startTime;
          // eslint-disable-next-line sonarjs/elseif-without-else
        } else if (isWithinGuideRange(currentSelection.endTime)) {
          seekerTime = currentSelection.endTime;
        }
      }

      // Make sure to set the state if we set a new reference time.
      // (if it's anything other than now or the last known one it's a new reference time)
      if (
        verticalNavigationReferenceTime.current !== seekerTime &&
        verticalNavigationReferenceTime.current !== nowRef.current
      ) {
        verticalNavigationReferenceTime.current = seekerTime;
      }

      // We seek through all the channels in the direction given until we find something that is live.

      // Look up the program that is playing at the seeker time in either the channel above or below.
      const channelsToSearch =
        direction === "down"
          ? channels.slice(currentChannelIndex + 1)
          : channels.slice(0, currentChannelIndex).reverse();

      let channel: GuideChannel | undefined;
      let program: GuideProgram | undefined;
      for (channel of channelsToSearch) {
        program = channel.items.find((item) =>
          programIsPlayingAtTime(item, seekerTime),
        );

        if (program) {
          break;
        }
      }

      if (!program || !channel) {
        return false;
      }

      const isLiveProgram = programIsPlayingAtTime(program, nowRef.current);

      // If the program is live and not in the viewport, we should jump to it.
      if (
        isLiveProgram &&
        !viewportCalculator.isTimeInViewport(nowRef.current, 20)
      ) {
        jumpToCoordinates({
          x: viewportCalculator.getCenteredOffsetForDate(nowRef.current),
          y: offsetY,
        });
      }

      // And select it. Then we're good and we navigated successfully.
      selectProgramAndChannel({
        program: program,
        channel,
      });

      return true;
    },
    [
      selectProgramAndChannel,
      viewportCalculator,
      jumpToCoordinates,
      offsetY,
      isWithinGuideRange,
    ],
  );

  /**
   * This is the core inter-program navigation.
   * Once the grid is selected, this determines what program is selected when pressing the arrow keys.
   *
   * @returns
   * true if the navigation was handled.
   * false if we were not able to navigate successfully.
   */
  const handleNavigation = useCallback(
    (
      direction: string,
      channels: GuideChannel[],
      currentSelection: NonNullable<GuideGridSelectionState>,
    ): boolean => {
      const currentChannelIndex = channels.findIndex(
        (channel) => channel.id === currentSelection.channelId,
      );

      if (currentChannelIndex === -1) {
        return false;
      }

      if (isVertical(direction)) {
        return handleVerticalNavigation(
          channels,
          direction,
          currentChannelIndex,
          currentSelection,
        );
      }

      if (isHorizontal(direction)) {
        return handleHorizontalNavigation(
          channels,
          direction,
          currentChannelIndex,
          currentSelection,
        );
      }

      return false;
    },
    [handleHorizontalNavigation, handleVerticalNavigation],
  );

  const selectProgramInViewport = useCallback(
    (channels: GuideChannel[]): boolean => {
      // We need to delegate to re-select something.
      const succeeded = getProgramAndChannelInViewport(
        true,
        viewportCalculator,
        channels,
        null,
      );

      if (succeeded) {
        selectProgramAndChannel(succeeded);
        return true;
      }

      return false;
    },
    [selectProgramAndChannel, viewportCalculator],
  );

  // When the focus is true, we really need to select a program in the viewport if possible.
  useEffect(() => {
    if (
      !isFocused ||
      offsetPriority === "selection" ||
      (selection &&
        viewportCalculator.isInViewportForNavigationDirection(
          selection.channelId,
          selection,
        ))
    ) {
      return;
    }

    selectProgramInViewport(data);
  }, [
    selection,
    isFocused,
    offsetPriority,
    viewportCalculator,
    selectProgramInViewport,
    data,
  ]);

  return {
    navigate: useCallback(
      (direction: NavigationDirection): boolean => {
        if (!isFocused) {
          return false;
        }

        if (!selection) {
          const succeeded = selectProgramInViewport(data);

          if (succeeded) {
            return true;
          }
        }

        if (selection && handleNavigation(direction, data, selection)) {
          navigationDirection.current = direction;
          return true;
        }

        return false;
      },
      [handleNavigation, data, selection, isFocused, selectProgramInViewport],
    ),
    flush: useCallback((): void => {
      navigationDirection.current = undefined;
      verticalNavigationReferenceTime.current = null;
    }, []),
  };
}

/**
 *
 * @param isFocused
 * @param viewportCalculator
 * @param channels
 * @param selection
 * @returns
 *   If it's a channel + program we should select it and block further processing.
 *   If it's null there is nothing to select.
 */
const getProgramAndChannelInViewport = (
  isFocused: boolean,
  viewportCalculator: ViewportCalculator,
  channels: GuideChannel[],
  selection: Nullable<GuideGridSelectionState>,
): { program: GuideProgram; channel: GuideChannel } | null => {
  if (
    !isFocused ||
    (selection &&
      viewportCalculator.isInViewportForNavigationDirection(
        selection.channelId,
        selection,
      ))
  ) {
    return null;
  }

  // Select a new selection
  // For now all we care about is that it's in the viewport.

  // NOTE: When we have a selection and we get null back we can put the grid back in selection offsetPriority.
  //       When we have no selection? We can try to select any program on any channel.

  // We try to select a program where the start is in the viewport first.
  const initialSelect = selectRelevantProgram(channels, {
    isSelectable: (channel, program) => {
      if (!viewportCalculator.isChannelStartInViewport(channel)) {
        return "skip-channel";
      }

      if (!viewportCalculator.isProgramStartInViewport(program)) {
        return "skip-program";
      }

      return true;
    },
  });

  if (initialSelect) {
    return initialSelect;
  }

  // If there is nothing that really starts in the viewport we need to just select something that is somehow in the viewport.
  // So either the start is in there or the start and ending are outside the viewport.
  // If the end is in the viewport, it also means the start of the next item is in the viewport so that should have been selected earlier and preferred.
  return selectRelevantProgram(channels, {
    isSelectable: (channel, program) => {
      if (!viewportCalculator.isChannelStartInViewport(channel)) {
        return "skip-channel";
      }

      if (!viewportCalculator.isProgramInViewport(program)) {
        return "skip-program";
      }

      return true;
    },
  });
};
