import { atom } from "jotai";
import { atomFamily } from "jotai/utils";

import { selectRecommendationsOffsetColumns } from "@/features/recommendations";

export const recommendationsOffsetForRowAtom = atomFamily(
  (rowIndex: number) => {
    const inner = atom<number>((get) => {
      const offset = get(selectRecommendationsOffsetColumns);
      return offset[rowIndex] ?? 0;
    });

    inner.debugLabel = `recommendationsOffsetForRowAtom[${rowIndex}]`;

    return inner;
  },
);
