import { type ReactNode, forwardRef, useCallback } from "react";

export type TextInputProps = {
  query: string;
  onChange: (query: string) => void;
  onArrowPress?: (direction: NavigationDirection) => void;
  focused: boolean;
  name: string;
  placeholder?: string;
  onFocusChange?: (focused: boolean) => void;
} & CommonProps;

export const TextInput = forwardRef<HTMLInputElement, TextInputProps>(
  function TextInput(
    {
      query,
      onChange,
      className,
      "data-testid": dataTestId = "text-input",
      placeholder,
      onArrowPress,
      onFocusChange,
      focused,
      name,
    }: TextInputProps,
    ref,
  ): ReactNode {
    const onKeyDown = useCallback(
      (e: React.KeyboardEvent<HTMLInputElement>) => {
        switch (e.key) {
          case "ArrowLeft":
            onArrowPress?.("left");
            break;

          case "ArrowRight":
            onArrowPress?.("right");
            break;

          case "ArrowUp":
            onArrowPress?.("up");
            break;

          case "ArrowDown":
            onArrowPress?.("down");
            break;
        }
      },
      [onArrowPress],
    );

    const onFocus = useCallback(() => {
      onFocusChange?.(true);
    }, [onFocusChange]);

    const onBlur = useCallback(() => {
      onFocusChange?.(false);
    }, [onFocusChange]);

    return (
      <input
        className={className}
        data-testid={dataTestId}
        placeholder={placeholder}
        onKeyDown={onKeyDown}
        data-focused={focused}
        type="text"
        ref={ref}
        name={name}
        value={query}
        onFocus={onFocus}
        onBlur={onBlur}
        onChange={(e) => onChange(e.target.value)}
        autoComplete="off"
      />
    );
  },
);
