import type { PageContent } from "@sunrise/analytics";
import { locationAtom } from "@sunrise/location";
import { Page } from "@sunrise/yallo-websocket";

import { rawRoute } from "@/config/route";
import type { AssetId, EPGEntryId } from "@sunrise/backend-types-core";

type routes = keyof typeof rawRoute;
const routeToPage = new Map<
  routes,
  {
    id: Page;
    regex?: RegExp;
    exact?: boolean;
    augment?: (match: RegExpMatchArray) => PageContent;
  }
>();
routeToPage.set("details", {
  id: "detail_page",
  regex: new RegExp("/protected/details/(\\w*)/(\\w*)", "i"),
  augment: (match) => ({
    id: "detail_page",
    assetId: match[2] as AssetId,
    epgEntryId: match[1] as EPGEntryId,
  }),
});
routeToPage.set("detailsSeries", {
  id: "series_recording_page",
  // NOTE: The second parameter here is the recordingGroupId
  regex: new RegExp("/protected/details-series/(\\w*)/\\w*", "i"),
  augment: (match) => ({
    id: "series_recording_page",
    assetId: match[1] as AssetId,
  }),
});
routeToPage.set("tv", { id: "player_page" });
routeToPage.set("guide", { id: "tv_guide_page" });
routeToPage.set("recordings", { id: "recordings_page" });
routeToPage.set("settings", { id: "settings_page" });
routeToPage.set("home", { id: "home_page" });
routeToPage.set("search", { id: "search_page" });
// routeToPage.set("landing", { id: "landing_page", exact: true });

export function locationToPageContent(
  location: ReturnType<typeof locationAtom.read>,
): PageContent | null {
  if (!location.pathname) return null;

  let match: RegExpMatchArray | null;
  for (const [route, { id, regex, exact, augment }] of routeToPage) {
    const hasNoAttributes =
      id !== "detail_page" && id !== "series_recording_page";

    if (exact) {
      if (
        location.pathname === rawRoute[route] &&
        !augment &&
        hasNoAttributes
      ) {
        return { id };
      }
    } else {
      match = location.pathname.match(regex ?? rawRoute[route]);

      if (!match) continue;

      if (augment) {
        return augment(match);
      }

      if (hasNoAttributes) {
        return { id };
      }
    }
  }

  return null;
}
