import { selectPlayerCurrentPlayRequest } from "@sunrise/player";
import { selectedChannelGroupWithPaginatedChannels } from "@sunrise/yallo-channel-group";
import { atomEffect } from "jotai-effect";

/**
 * This effect will page through all the channels as needed and attempt to load the channel list's channels so it contains the currently playing channel.
 * It is best to mount this at a high level so that whenever you attempt to load the data for the current channel it is already loaded.
 *
 * It is necessary to be able to instantly focus on the currently playing channel in the channel list as well as the guide.
 */
export const ensureCurrentlyPlayingChannelLoadedEffect = atomEffect((get) => {
  const currentlyPlayingChannelId = get(
    selectPlayerCurrentPlayRequest,
  )?.channelId;

  if (!currentlyPlayingChannelId) {
    return;
  }

  const evaluate = async () => {
    const data = await get(selectedChannelGroupWithPaginatedChannels);

    if (!data?.fetchNextPage) {
      return;
    }

    const present = data.channels.find(
      (channel) => channel.id === currentlyPlayingChannelId,
    );

    if (present) {
      return;
    }

    data.fetchNextPage();
  };

  evaluate();
});
