import { google as googleNS } from "@alugha/ima";
import { PrivateApiClient, httpClientAtom } from "@sunrise/http-client";
import { selectIsLoggedIn } from "@sunrise/jwt";
import { type Store } from "@sunrise/store";
import { disableVideoAdsAtom } from "@sunrise/yallo-settings";

import { sendAdTagErrorEvent, sendAdTagEvent } from "./ads.service";
import { enableAdsReporting } from "./enable-ads-reporting.atom";
import {
  actionSetAdDuration,
  actionSetAdPod,
  actionSetAdsPlaying,
  actionSetCurrentAdRemainingTime,
  actionSetNextAd,
  actionSingleAdStarted,
  selectCurrentVideoAdTag,
  selectHasNextVideoAd,
  videoAdsAtom,
} from "./video-ads.atom";
import type { AdEventType, VideoAdScreenSize } from "@sunrise/backend-types";

declare global {
  // eslint-disable-next-line no-var
  var google: typeof googleNS;
}

/**
 * It's loosely based on https://github.com/googleads/googleads-ima-html5/tree/main/simple.
 * The idea is that we manually manage the ad playback and that we delegate back to the player after ads are done playing.
 *
 * This class will make sure to inject the next ad tag and to delegate to the player when it should stop / kick back into gear.
 *
 * TODO: What should happen when ads are playing and the user tries to change page? Should we block the menu from opening up?
 */
export class VideoAds {
  private ima?: typeof googleNS.ima;
  private adsLoader?: googleNS.ima.AdsLoader;
  private displayContainer?: googleNS.ima.AdDisplayContainer;
  private displayContainerDestroyed = false;
  private adsManager?: googleNS.ima.AdsManager;
  private privateApi: PrivateApiClient;

  constructor(
    private readonly store: Store,
    /**
     * We need this since we have to pass it to the AdDisplayContainer.
     * It also helps we can listen to the ended event.
     */
    private readonly videoElement: HTMLVideoElement,
    private readonly videoAdElement: HTMLDivElement,
    // TODO; replace player stuff with this.
    private readonly showPlayer: () => void,
    private readonly screenSize: VideoAdScreenSize = { width: -1, height: -1 },
  ) {
    const { privateApi } = this.store.get(httpClientAtom);
    if (!privateApi) throw new Error("No privateApi found");
    this.privateApi = privateApi;

    // Add listener for ads.
    this.store.sub(selectCurrentVideoAdTag(videoAdsAtom), this.onVideoAdConfig);

    this.initializeAds();
  }

  private initializeAds = (): void => {
    if (!this.ima) {
      // The loading of IMA SDK should normally only happen once.
      try {
        this.ima =
          globalThis.google &&
          (globalThis.google.ima as unknown as typeof googleNS.ima | undefined);
        if (!this.ima) {
          throw new Error("IMA SDK not loaded");
        }

        this.buildAdsLoader();
      } catch (e) {
        console.error("error loading ima", e);
        this.store.set(disableVideoAdsAtom, true);
      }
    }
  };

  private buildAdsLoader(): void {
    if (!this.ima) {
      throw new Error("expected ima");
    }

    this.displayContainer = new this.ima.AdDisplayContainer(
      this.videoAdElement,
      this.videoElement,
    );
    this.displayContainer.initialize();

    this.adsLoader = new this.ima.AdsLoader(this.displayContainer);

    this.adsLoader.addEventListener(
      this.ima.AdsManagerLoadedEvent.Type.ADS_MANAGER_LOADED,
      this.onAdsManagerLoaded,
      false,
    );

    this.adsLoader.addEventListener(
      this.ima.AdErrorEvent.Type.AD_ERROR,
      this.onAdError,
      false,
    );
  }

  /**
   * This will basically abort the current ad and pretend that it's skipped.
   * It'll then load the next ad if any.
   *
   * There's also IMA's native ad skipping mechanism.
   * The native skip would only happen when a user clicks on IMA's native ad skip button.
   *
   * Our current solution of skipping works based on information from our backend that says if ad is skippable or not.
   * IMA's skip method (this.adsManager.skip()) can only be invoked if IMA decides that the ad is skippable.
   */

  public manualSkip = (): void => {
    if (!this.adsManager || !this.adsLoader) {
      return;
    }

    this.prepareForNextAdTag();

    this.sendAdTagEvent("skipped");
    this.nextAd();
  };

  private nextAd(): void {
    this.store.set(videoAdsAtom, actionSetNextAd());
  }

  private onAdsManagerLoaded: googleNS.ima.AdsManagerLoadedEvent.Listener = (
    adsManagerLoadedEvent,
  ) => {
    if (!this.ima) {
      return;
    }

    // Get the ads manager.
    const adsRenderingSettings = new this.ima.AdsRenderingSettings();
    adsRenderingSettings.restoreCustomPlaybackStateOnAdBreakComplete = true;
    // videoContent should be set to the content video element.
    this.adsManager = adsManagerLoadedEvent.getAdsManager(
      this.videoElement,
      adsRenderingSettings,
    );

    // Add listeners to the required events.
    this.adsManager.addEventListener(
      this.ima.AdErrorEvent.Type.AD_ERROR,
      this.onAdError,
    );
    this.adsManager.addEventListener(
      this.ima.AdEvent.Type.CONTENT_PAUSE_REQUESTED,
      this.onContentPauseRequested,
    );
    this.adsManager.addEventListener(
      this.ima.AdEvent.Type.AD_PROGRESS,
      this.onAdEvent,
    );
    this.adsManager.addEventListener(
      this.ima.AdEvent.Type.ALL_ADS_COMPLETED,
      this.onAdEvent,
    );
    this.adsManager.addEventListener(
      this.ima.AdEvent.Type.STARTED,
      this.onAdEvent,
    );
    this.adsManager.addEventListener(
      this.ima.AdEvent.Type.COMPLETE,
      this.onAdEvent,
    );
    this.adsManager.addEventListener(
      this.ima.AdEvent.Type.SKIPPED,
      this.onAdEvent,
    );

    // Initialize the ads manager. Ad rules playlist will start at this time.
    // -1 = ima.AdsRenderingSettings.AUTO_SCALE (100%)
    // The player handles resizing automatically if sized with -1 width / height
    this.adsManager.init(
      this.screenSize.width,
      this.screenSize.height,
      this.ima.ViewMode.NORMAL,
    );
    // Call play to start showing the ad. Single video and overlay ads will
    // start at this time; the call will be ignored for ad rules.
    this.adsManager.start();
  };

  /**
   * Called when either the manager or the loader errors.
   * This will send an error event to the backend and then move on to the next ad.
   * It'll also attempt to pull the vast and internal error codes from the error and pass it to the backend request.
   * There are 2 special error codes which we have to map to a special empty event.
   */
  private onAdError: googleNS.ima.AdErrorEvent.Listener = (error): void => {
    const reportingEnabled = this.store.get(enableAdsReporting);
    if (!reportingEnabled) {
      console.info("Ad reporting is disabled", error);
      this.prepareForNextAdTag();
      this.nextAd();
      return;
    }

    const adTag = this.store.get(selectCurrentVideoAdTag(videoAdsAtom));
    const isLoggedIn = this.store.get(selectIsLoggedIn);
    if (!isLoggedIn || !adTag) {
      return;
    }

    const err = error.getError();
    const errorCode = err.getErrorCode();

    const EMPTY_ERRORS = [
      this.ima?.AdError.ErrorCode.VAST_EMPTY_RESPONSE,
      this.ima?.AdError.ErrorCode.VAST_NO_ADS_AFTER_WRAPPER,
    ];

    if (this.ima && EMPTY_ERRORS.includes(err.getErrorCode())) {
      void sendAdTagEvent(this.privateApi, adTag, "empty");
    } else {
      void sendAdTagErrorEvent(
        this.privateApi,
        adTag,
        err.getVastErrorCode(),
        errorCode,
      );
    }

    this.prepareForNextAdTag();
    this.nextAd();
  };

  private sendAdTagEvent = (event: Exclude<AdEventType, "error">): void => {
    const reportingEnabled = this.store.get(enableAdsReporting);
    if (!reportingEnabled) {
      console.info("Ad reporting is disabled", event);
      return;
    }

    const adTag = this.store.get(selectCurrentVideoAdTag(videoAdsAtom));

    const isLoggedIn = this.store.get(selectIsLoggedIn);
    if (!isLoggedIn || !adTag) {
      return;
    }

    void sendAdTagEvent(this.privateApi, adTag, event);
  };

  private onAdEvent: googleNS.ima.AdEvent.Listener = (
    adEvent: {
      type?: googleNS.ima.AdEvent.Type;
    } & googleNS.ima.AdEvent,
  ) => {
    const ad = adEvent.getAd();

    const info = ad?.getAdPodInfo();
    if (info) {
      this.store.set(
        videoAdsAtom,
        actionSetAdPod({
          position: info.getAdPosition(),
          totalAds: info.getTotalAds(),
        }),
      );
    }

    if (!this.ima) {
      return;
    }

    // this event is fired for any single ad within adTag whenever its currentTime changes
    if (adEvent.type === this.ima.AdEvent.Type.AD_PROGRESS && this.adsManager) {
      this.store.set(
        videoAdsAtom,
        actionSetCurrentAdRemainingTime(this.adsManager.getRemainingTime()),
      );
    }

    if (!ad) {
      return;
    }

    switch (adEvent.type) {
      case this.ima.AdEvent.Type.STARTED:
        this.sendAdTagEvent("start");
        this.showPlayer();
        // We need to make sure that previous ad's remaining time is cleared before progress event of new ad occurs.
        this.store.set(videoAdsAtom, actionSetCurrentAdRemainingTime(null));
        this.setAdDuration(ad);
        this.store.set(videoAdsAtom, actionSingleAdStarted(ad.getDuration()));
        break;
      case this.ima.AdEvent.Type.COMPLETE:
        this.sendAdTagEvent("end");
        break;
      case this.ima.AdEvent.Type.SKIPPED:
        this.sendAdTagEvent("skipped");
        break;
      case this.ima.AdEvent.Type.ALL_ADS_COMPLETED:
        this.prepareForNextAdTag();
        this.nextAd();
        break;

      default:
        break;
    }
  };

  private onContentPauseRequested: googleNS.ima.AdEvent.Listener = () => {
    // This will delegate state to the player so it knows to stop / hide itself.
    this.setAdsPlaying(true);
  };

  private hasNextAdTag(): boolean {
    return this.store.get(selectHasNextVideoAd(videoAdsAtom));
  }

  /**
   * this will destroy adsManager and adsLoader, and if no more ads, also destroy the displayContainer and
   * set the adsPlaying state to false
   */
  private prepareForNextAdTag(): void {
    this.adsManager?.destroy();
    this.adsLoader?.contentComplete();

    if (!this.hasNextAdTag()) {
      // We need to make sure to destroy the displayContainer when we're done.
      // This is so Shaka can re-attach to the video element.
      // We can refrain from doing this when we know there will be a next ad.
      this.displayContainer?.destroy();
      this.displayContainerDestroyed = true;
      this.setAdsPlaying(false);
    }
  }

  private setAdsPlaying(playing: boolean): void {
    this.store.set(videoAdsAtom, actionSetAdsPlaying(playing));
  }

  private setAdDuration(ad: googleNS.ima.Ad): void {
    this.store.set(
      videoAdsAtom,
      actionSetAdDuration(
        ad.getAdPodInfo().getTotalAds() > 1
          ? ad.getAdPodInfo().getMaxDuration()
          : ad.getDuration(),
      ),
    );
  }

  private onVideoAdConfig = (): void => {
    const adTag = this.store.get(selectCurrentVideoAdTag(videoAdsAtom));
    if (!adTag || !this.ima || !this.adsLoader) {
      if (adTag) {
        // We should go to the next ad tag so we don't get stuck.
        this.nextAd();
      }

      return;
    }

    // TODO: As part of the adsrequest, we can pass in the resolution video stream as well as the bitrate. That should cause the ads to load quicker for lower bandwidth devices.
    // Play new adTag.
    const r = new this.ima.AdsRequest();
    r.adTagUrl = adTag.tag_url;

    // When it was destroyed earlier we need to re-initialize.
    if (this.displayContainerDestroyed) {
      this.buildAdsLoader();
      this.displayContainerDestroyed = false;
    }

    // TODO: We can optionally figure out the stream resolution in the player and pass that to the ad player. So we stream the ads in a similar quality.
    //       In that case, we need to put the resolutions & kbps on the AdsRequest.
    this.adsLoader.requestAds(r);
  };
}
