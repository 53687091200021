import { type Nullable } from "@sunrise/utils";
import { type Atom } from "jotai";
import { atomWithReducer, selectAtom } from "jotai/utils";
import { isNil } from "lodash";

import { isFFwdAdTag } from "./helpers/is-ffwd-ad-tag";
import { isReplayAdTag } from "./helpers/is-replay-ad-tag";
import type { VideoAdConfig, VideoAdTag } from "@sunrise/backend-types";

type AdPod = {
  position: number;
  totalAds: number;
};

export type VideoAdsAtomState = {
  adConfig: Nullable<VideoAdConfig>;
  currentAdIndex: Nullable<number>;
  pod: Nullable<AdPod>;
  isPlaying: boolean;
  duration: Nullable<number>;
  currentAdRemainingTime: Nullable<number>;
  adsDurations: number[];
};

type ActionSetAdConfig = {
  type: "video-ads/set-ad-config";
  payload: {
    config: VideoAdConfig;
  };
};

type ActionNextAdTag = {
  type: "video-ads/set-next-ad";
};

type ActionSetPod = {
  type: "video-ads/set-ad-pod";
  payload: AdPod;
};

type ActionSetAdPlaying = {
  type: "video-ads/set-ad-playing";
  payload: {
    isPlaying: boolean;
  };
};

type ActionSetAdDuration = {
  type: "video-ads/set-ad-duration";
  payload: {
    duration: Nullable<number>;
  };
};

type ActionSetCurrentAdRemainingTime = {
  type: "video-ads/set-current-ad-remaining-time";
  payload: {
    currentAdRemainingTime: Nullable<number>;
  };
};

type ActionSetSingleAdStarted = {
  type: "video-ads/set-single-ad-started";
  payload: {
    adDuration: number;
  };
};

export type VideoAdsAction =
  | ActionSetAdConfig
  | ActionNextAdTag
  | ActionSetAdPlaying
  | ActionSetAdDuration
  | ActionSetCurrentAdRemainingTime
  | ActionSetPod
  | ActionSetSingleAdStarted;

const DEFAULT_STATE: VideoAdsAtomState = {
  adConfig: null,
  pod: null,
  currentAdIndex: null,
  isPlaying: false,
  duration: null,
  currentAdRemainingTime: null,
  adsDurations: [],
};

function reducer(
  current: VideoAdsAtomState,
  action: VideoAdsAction,
): VideoAdsAtomState {
  switch (action.type) {
    case "video-ads/set-ad-config":
      return {
        ...current,
        adConfig: action.payload.config,
        currentAdIndex: 0,
      };
    case "video-ads/set-next-ad": {
      const { adConfig, currentAdIndex } = current;
      if (
        isNil(currentAdIndex) ||
        !adConfig ||
        isNil(adConfig.tags[currentAdIndex + 1])
      ) {
        return DEFAULT_STATE;
      }

      return {
        ...current,
        pod: null,
        currentAdIndex: currentAdIndex + 1,
        duration: DEFAULT_STATE.duration,
      };
    }
    case "video-ads/set-ad-playing":
      return {
        ...current,
        isPlaying: action.payload.isPlaying,
      };

    case "video-ads/set-ad-duration":
      return {
        ...current,
        duration: action.payload.duration,
      };

    case "video-ads/set-current-ad-remaining-time":
      return {
        ...current,
        currentAdRemainingTime:
          action.payload.currentAdRemainingTime &&
          action.payload.currentAdRemainingTime > 0
            ? action.payload.currentAdRemainingTime
            : null,
      };

    case "video-ads/set-single-ad-started":
      return {
        ...current,
        adsDurations: [...current.adsDurations, action.payload.adDuration],
      };
    case "video-ads/set-ad-pod": {
      return {
        ...current,
        pod: action.payload,
      };
    }
  }
}

export function actionPlayerSetAdConfig(
  config: VideoAdConfig,
): ActionSetAdConfig {
  return {
    type: "video-ads/set-ad-config",
    payload: { config },
  };
}

export function actionSetNextAd(): ActionNextAdTag {
  return {
    type: "video-ads/set-next-ad",
  };
}

export function actionSetAdPod(pod: AdPod): ActionSetPod {
  return {
    type: "video-ads/set-ad-pod",
    payload: pod,
  };
}

export function actionSetAdsPlaying(isPlaying = true): ActionSetAdPlaying {
  return {
    type: "video-ads/set-ad-playing",
    payload: { isPlaying },
  };
}

export function actionSetAdDuration(
  duration: Nullable<number>,
): ActionSetAdDuration {
  return {
    type: "video-ads/set-ad-duration",
    payload: { duration },
  };
}

export function actionSetCurrentAdRemainingTime(
  currentAdRemainingTime: Nullable<number>,
): ActionSetCurrentAdRemainingTime {
  return {
    type: "video-ads/set-current-ad-remaining-time",
    payload: { currentAdRemainingTime },
  };
}

export function actionSingleAdStarted(
  adDuration: number,
): ActionSetSingleAdStarted {
  return {
    type: "video-ads/set-single-ad-started",
    payload: { adDuration },
  };
}

export const videoAdsAtom = atomWithReducer<VideoAdsAtomState, VideoAdsAction>(
  DEFAULT_STATE,
  reducer,
);

// debug label has to be defined because the atom is encapsulated in a factory
videoAdsAtom.debugLabel = "videoAdsAtom";

export function selectCurrentVideoAdTag(
  atom = videoAdsAtom,
): Atom<Nullable<VideoAdTag>> {
  const slice = selectAtom(atom, (state) => {
    const { adConfig, currentAdIndex } = state;
    if (!adConfig || isNil(currentAdIndex)) return null;

    return adConfig.tags[currentAdIndex] ?? null;
  });

  slice.debugLabel = selectCurrentVideoAdTag.name;

  return slice;
}

/**
 * The player may need to stop acting on certain commands if we should start to play ads. This does not mean we are already playing ads.
 */
export function selectShouldVideoAdBePlaying(
  atom = videoAdsAtom,
): Atom<boolean> {
  const slice = selectAtom(atom, (state) => {
    const { adConfig, currentAdIndex } = state;
    return !!(adConfig && !isNil(currentAdIndex));
  });

  slice.debugLabel = selectShouldVideoAdBePlaying.name;

  return slice;
}

/**
 * We need to know when we are playing ads with 100% certainty. So we know when to hide the player.
 */
export const areVideoAdsPlayingAtom = selectAtom(
  videoAdsAtom,
  (state) => state.isPlaying,
);

/**
 * We need to know if there's no next upcoming ad or not so at the end of the final ad we can handle things better.
 */
export function selectHasNextVideoAd(atom = videoAdsAtom): Atom<boolean> {
  const slice = selectAtom(atom, (state) => {
    const { adConfig, currentAdIndex } = state;
    return !!(
      adConfig &&
      !isNil(currentAdIndex) &&
      adConfig.tags[currentAdIndex + 1]
    );
  });
  slice.debugLabel = selectHasNextVideoAd.name;
  return slice;
}

/**
 * The ad mode determines how the ad UI is displayed.
 * For ffwd we should show 1 of x ads.
 * For replay we should show a total duration left countdown.
 * For regular we should show 1 of x ads + a duration left on the playing ad.
 */
export const selectAdMode = selectAtom(videoAdsAtom, ({ adConfig }) => {
  if (!adConfig || !adConfig.tags[0]) return null;

  if (adConfig.request_type === "live") {
    return "live";
  }

  if (isFFwdAdTag(adConfig.tags[0])) {
    return "ffwd";
  }

  if (isReplayAdTag(adConfig.tags[0])) {
    return "replay";
  }

  if (adConfig.request_type === "recording") {
    return "recording";
  }
  // This means pre-roll ads with no special config, showing only the counter and skip button.
  return "regular";
});

export const selectVideoAdsPod = selectAtom(videoAdsAtom, (state) => state.pod);
