import {
  addBreadcrumb,
  captureMessage,
  setMeasurement,
  setTags,
} from "@sentry/react";
import { atomEffect } from "jotai-effect";

import { MEMORY_LEVELS } from "@/modules/memory/constants";
import { determineMemoryLevel } from "@/modules/memory/helpers/determine-memory-level";
import { memoryStatusAtom } from "@/modules/memory/memory-status.atom";
import { memoryUsageAtom } from "@/modules/memory/memory-usage.atom";

/**
 * Will log the memory status to Sentry.
 * It'll only generate an error when we are 'high' or 'full'.
 */
export const sentryMemoryMonitoringAtom = atomEffect((get, set) => {
  const usage = get(memoryUsageAtom);

  const percentUsed = usage ? (usage.used / usage.total) * 100 : 0;

  // Update the Sentry measurements. Reset to 0 if there is no usage.
  setMeasurement("memory.percentUsed", percentUsed, "percent");
  setMeasurement("memory.available", usage?.available ?? 0, "bytes");
  setMeasurement("memory.used", usage?.used ?? 0, "bytes");
  setMeasurement("memory.total", usage?.total ?? 0, "bytes");

  const newLevel = determineMemoryLevel(percentUsed, MEMORY_LEVELS);
  const newStatus = newLevel ? newLevel[0] : null;

  // Update Sentry whenever the status changes.
  if (usage && get(memoryStatusAtom) !== newStatus && newLevel) {
    // We set a tag because we can then more easily check which issues happen when we are high on memory or not etc.
    setTags({
      "memory.status": newStatus,
    });

    if (newLevel[1].breadcrumb) {
      // When we are asked to create a breadcrumb, we do so. This will not create issues in Sentry.
      addBreadcrumb({
        category: "memory",
        level: newLevel[1].severity,
        message: `Memory status changed to ${newStatus}`,
        data: {
          ...usage,
        },
      });
    } else {
      // Log it as a message so we can see it in the Sentry dashboard. This will create issues. Regardless of the Severity.
      captureMessage(
        `Memory status changed to ${newStatus}`,
        newLevel[1].severity,
      );
    }

    // Update the atom so we can compare if it changed before or not.
    set(memoryStatusAtom, newStatus);
  }
});
