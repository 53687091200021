import {
  playerIsHangingAtom,
  selectPlayerCurrentError,
  selectPlayerIsAutoStopped,
  selectPlayerIsSuspended,
} from "@sunrise/player";
import {
  isOfflineAtom,
  selectProcessIsForegrounded,
} from "@sunrise/process-visibility";
import { atom } from "jotai";
import { atomEffect } from "jotai-effect";

import {
  actionRecoverFromError,
  actionRecoverFromSuspension,
  recoveryAtom,
  selectRecoveringForError,
  selectRecoveryErrorIsSuccessful,
  selectRecoveryIsOngoing,
  selectRecoveryIsRequested,
} from "./recovery.atom";

const allowedToAutorecoverAtom = atom((get) => {
  const isOngoing = get(selectRecoveryIsOngoing);
  const isAutoStopped = get(selectPlayerIsAutoStopped);
  const isForegroundend = get(selectProcessIsForegrounded);
  const isOffline = get(isOfflineAtom);

  return !(isOngoing || isAutoStopped || !isForegroundend || isOffline);
});

/**
 * Returns true when we know we need to forcefully autorecover the player.
 * There will be no delay and recovery is instant.
 *
 * We should autorecover when we are foregrounded, the player is not auto-stopped,
 * and we detected a player error that indicates the stream is stale OR we detected no playout while there should be.
 */
export const shouldPlayerAutorecoverAtom = atom((get) => {
  const allowed = get(allowedToAutorecoverAtom);

  // This one is beforehand because it needs to depend on tracking the offline-ness. We need to keep it mounted, even if we can't autorecover.
  get(effectRecoverWhenGoingOnline);
  // This one needs to stay mounted. Since every time we unmount it, and we remount it while we are still hanging, it triggers a new autorecovery.
  get(effectRecoverWhenHanging);

  if (!allowed) {
    return false;
  }

  // These are all the underlying triggers that can force us into recovery.
  get(effectForceAutorecoverOnPlayerError);
  get(effectRecoverWhenSuspended);

  return get(selectRecoveryIsRequested);
});

const effectRecoverWhenHanging = atomEffect((get, set) => {
  const isHanging = get(playerIsHangingAtom);

  if (isHanging) {
    // Emit to the hanging atom that we are dealing with it.
    // It can stop checking for hanging for this stream.
    set(playerIsHangingAtom);
    set(recoveryAtom, actionRecoverFromSuspension());
  }
});

/**
 * When we detect a player error on a stream that is set before the app was forgrounded, which is not a PlayerBackendError
 * We want to set the forcedRecovery to true.
 */
const effectForceAutorecoverOnPlayerError = atomEffect((get, set) => {
  const playerError = get(selectPlayerCurrentError);

  if (!playerError || !playerError.shouldAutoRecover) {
    return;
  }

  const isCompleted = get(selectRecoveryErrorIsSuccessful);

  const recoveringForError = get(selectRecoveringForError);
  const recoveringForSameError =
    recoveringForError &&
    recoveringForError.errorCode === playerError.errorCode;

  if (recoveringForSameError && !isCompleted) {
    return;
  }

  set(recoveryAtom, actionRecoverFromError(playerError));
});

/**
 * Serves as a value store in the effect to determine if the app was previously offline or not.
 */
const _previouslyOfflineAtom = atom<null | boolean>(null);
_previouslyOfflineAtom.debugPrivate = true;

/**
 * Envoke a forced recovery when the app goes online after going offline AND a player error is present.
 * If we go online and there was no player error, we assume the player is already recovering.
 */
const effectRecoverWhenGoingOnline = atomEffect((get, set) => {
  // Since it's an atom in an atom and all that. Feels a bit double.
  const isOffline = get(isOfflineAtom);

  const wasPreviouslyOffline = get.peek(_previouslyOfflineAtom);
  set(_previouslyOfflineAtom, isOffline);
  const allowed = get(allowedToAutorecoverAtom);
  if (!allowed) {
    return;
  }

  const playerError = get(selectPlayerCurrentError);

  if (!isOffline && wasPreviouslyOffline) {
    set(
      recoveryAtom,
      playerError
        ? actionRecoverFromError(playerError)
        : actionRecoverFromSuspension(),
    );
  }
});

/**
 * When we are back in the foreground when we were suspended.
 */
const effectRecoverWhenSuspended = atomEffect((get, set) => {
  const isForegroundend = get(selectProcessIsForegrounded);
  const isSuspended = get(selectPlayerIsSuspended);

  if (isForegroundend && isSuspended) {
    set(recoveryAtom, actionRecoverFromSuspension());
  }
});
