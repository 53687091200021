// TODO: Refactor component, cleanup api, fix styling and separation of concerns
import { forwardRef } from "react";
import clsx from "clsx";

import { useTranslator } from "@sunrise/translator";
import { type Nullable } from "@sunrise/utils";

import {
  ProgramSubtitle,
  ProgramTime,
  ProgramTitle,
  RecordingDeleteWarning,
} from "../box-text-elements/box-text";
import * as programBoxStyles from "../program-box.css";
import { ProgramBoxWrapper } from "../program-box-wrapper";
import {
  ProgramCover,
  type RecordingState,
} from "../program-cover/program-cover";
import { BoxProps } from "../types";
import { BulkDeletionCheckbox } from "./bulk-deletion-checkbox";
import * as styles from "./recording-program-box.css";

export type RecordingProgramBoxProps = CommonProps &
  BoxProps & {
    title: Nullable<string>;
    /**
     * Set to null if not a series or episode.
     */
    type?: Nullable<"series" | "episode">;
    subtitle?: Nullable<string>;
    seasonAndEpisode?: Nullable<string>;
    numberOfEpisodes?: Nullable<number>;
    liveProgress?: Nullable<number>;
    replayProgress?: Nullable<number>;
    expiresIn?: Nullable<number>;
    active?: boolean;
    recordingState?: Nullable<RecordingState>;
    airTime: Nullable<string>;
    start: Nullable<string>;
    end: Nullable<string>;
    startDate?: Date;
    endDate?: Date;
    index?: number;
    isBulkDeletionMode: boolean;
    isMarkedForDeletion?: boolean;
  };

export const RecordingProgramBox = forwardRef<
  HTMLDivElement,
  RecordingProgramBoxProps
>(function ForwardRefProgramBox(
  {
    "data-testid": dataTestId = "recording-box",
    "data-focused": dataFocused,
    startDate,
    endDate,
    focused,
    type,
    title,
    subtitle,
    seasonAndEpisode,
    numberOfEpisodes,
    liveProgress,
    replayProgress,
    active,
    logoUrl,
    coverImageUrl,
    className,
    expiresIn,
    airTime,
    start,
    end,
    recordingState,
    index,
    isBulkDeletionMode,
    isMarkedForDeletion = false,
  },
  ref,
) {
  const isEpisode = type === "episode";
  const isSeries = type === "series";
  const episodeSubtitle = [seasonAndEpisode, subtitle];
  const t = useTranslator();

  const subtitleInfo = isEpisode
    ? episodeSubtitle.filter(Boolean).join(": ")
    : isSeries && numberOfEpisodes
    ? t("recordings_episode_template", [numberOfEpisodes], numberOfEpisodes > 1)
    : null;

  // if recording is going to be deleted in less than 4 days we show warning
  const deleteMessage = expiresIn && expiresIn < 4 && (
    <RecordingDeleteWarning
      expiresIn={expiresIn}
      data-testid={`${dataTestId}.delete-warning`}
    />
  );

  return (
    <ProgramBoxWrapper
      ref={ref}
      className={clsx(className, styles.root)}
      kind="recordings"
      data-testid={dataTestId}
      data-focused={dataFocused}
      focused={focused}
      startDate={startDate}
      endDate={endDate}
      index={index}
    >
      <BulkDeletionCheckbox
        checked={isMarkedForDeletion}
        isBulkDeletionMode={isBulkDeletionMode}
        data-testid={`bulk-deletion-checkbox.${index}`}
        data-checked={isMarkedForDeletion}
      />
      <ProgramTime
        airTime={airTime}
        start={start}
        end={end}
        className={programBoxStyles.airTime}
        data-testid={`${dataTestId}.time`}
        spaceBetween
      />
      <ProgramCover
        data-testid={`${dataTestId}.cover`}
        logoUrl={logoUrl}
        coverImageUrl={coverImageUrl}
        focused={focused}
        kind="recordings"
        liveProgress={liveProgress}
        replayProgress={replayProgress}
        isSeries={isSeries}
        recordingState={recordingState}
        isMarkedForDeletion={isMarkedForDeletion}
      />
      <div className={programBoxStyles.text} data-testid={`${dataTestId}.text`}>
        {title && (
          <ProgramTitle
            title={title}
            active={active}
            data-testid={`${dataTestId}.title`}
          />
        )}
        {subtitleInfo && (
          <ProgramSubtitle
            subtitle={subtitleInfo}
            data-testid={`${dataTestId}.subtitle`}
          />
        )}
        {deleteMessage}
      </div>
    </ProgramBoxWrapper>
  );
});
