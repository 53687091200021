import type { Nullable } from "@sunrise/utils";
import { atom } from "jotai";

import { flushRecordingsCacheAtom } from "./flush-recordings-cache.atom";
import { recordingStatsQueryAtom } from "./query-atoms/recording-stats-query.atom";
import { recordingStatsSocketAtom } from "./recording-stats-socket.atom";
import type { RecordingStats } from "@sunrise/backend-types";

/**
 * To be used when you want an up to date value of the recording stats.
 * Setting the stats on this will set the value on the recordingStatsSocket.
 */
export const recordingStatsAtom = atom(
  async (get) => {
    const socket = get(recordingStatsSocketAtom);
    if (socket) {
      return socket;
    }

    const query = await get(recordingStatsQueryAtom);
    return query.data ?? null;
  },
  (_, set, value: Nullable<RecordingStats>) => {
    set(recordingStatsSocketAtom, value);

    // Make sure to flush the recordings cache because we have new data received through the socket.
    // The old data is probably incorrect.
    set(flushRecordingsCacheAtom);
  },
);
