import { isNil } from "@sunrise/utils";
import { VirtualItem } from "@tanstack/react-virtual";
import clsx from "clsx";
import { format } from "date-fns";
import { CSSProperties, ReactElement } from "react";

import { TimeBlock } from "@/features/guide/guide-grid";
import * as nowBarStyles from "@/features/guide/guide-now-bar.css";
import * as styles from "@/features/guide/time-blocks.bar.css";

type TimeBlocksBarProps = {
  virtualTimeBlocks: VirtualItem[];
  timeBlocks: TimeBlock[];
  nowOffset: number;
  style?: CSSProperties;
} & CommonProps;

export const TimeBlocksBar = ({
  style,
  virtualTimeBlocks,
  timeBlocks,
  nowOffset,
  className,
  "data-testid": dataTestId = "time-blocks-bar",
}: TimeBlocksBarProps): ReactElement => {
  return (
    <div
      className={clsx([styles.timeBlocksBar, className])}
      style={{
        ...style,
      }}
      data-testid={dataTestId}
    >
      <div
        className={clsx([nowBarStyles.nowBar])}
        style={{ marginLeft: nowOffset }}
      ></div>
      {virtualTimeBlocks.map((virtualItem) => {
        const timeBlock = timeBlocks[virtualItem.index];

        if (isNil(timeBlock)) {
          return null;
        }

        // Individual hours at the top row.
        return (
          <div
            data-testid={`${dataTestId}-time-block-${timeBlock.date.toJSON()}`}
            key={virtualItem.key}
            className={styles.hoursBarTimeRange}
            style={{
              width: virtualItem.size,
              // We move the hours so they are all rendered with an offset.
              transform: `translateX(${virtualTimeBlocks[0]?.start}px)`,
              willChange: "transform",
            }}
          >
            <div>{format(timeBlock.date, "HH:mm")}</div>
          </div>
        );
      })}
    </div>
  );
};
