import type { Nullable } from "@sunrise/utils";
import { atom } from "jotai";
import uniqWith from "lodash/uniqWith";

import { flushRecordingsCacheAtom } from "./flush-recordings-cache.atom";
import { recordingStatusQueryAtom } from "./query-atoms/recording-status-query.atom";
import { recordingStatusSocketAtom } from "./recording-status-socket.atom";
import type {
  RecordingStatus,
  RecordingStatusUpdate,
} from "@sunrise/backend-types";

const HIDDEN_STATES = ["deleted", "cancelled", "canceled"];

/**
 * Returns the most up to date recording status list.
 * It also listens to the socket's knowledge of recording status items and it'll update the list accordingly.
 */
export const recordingStatusAtom = atom(
  async (get) => {
    const socket = get(recordingStatusSocketAtom);
    const query = await get(recordingStatusQueryAtom);

    // This will make sure values in the socket are kept over values in the query.
    // When we keep the socket data we may end up with items that are deleted.
    // So we then need to also filter these out to end up with RecordingStatus objects again.
    // Doing this uniq with a chech on epgId also ensures we remove the empty placeholder item in our query result.
    return uniqWith(
      [...socket, ...query.data],
      (a, b) => a.id === b.id || a.epg_entry.id === b.epg_entry.id,
    ).filter((v) => !HIDDEN_STATES.includes(v.status)) as RecordingStatus[];
  },
  (_, set, status: Nullable<RecordingStatusUpdate>) => {
    // Forward to the socket atom.
    set(recordingStatusSocketAtom, status);

    // Make sure to flush the recordings cache because we have new data received through the socket.
    // The old data is probably incorrect.
    set(flushRecordingsCacheAtom);
  },
);
