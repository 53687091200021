/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

/** HTTPValidationError */
export interface HTTPValidationError {
  /** Detail */
  detail?: ValidationError[];
}

/** JobPayload */
export interface JobPayload {
  /** Type */
  type: string;
}

/**
 * Message
 * @example {"message":"Not found"}
 */
export interface Message {
  /** Message */
  message: string;
}

/**
 * RatingOut
 * extends pydantic BaseModel for compatibility with MongoDB's _id field.
 * allows id to be initialized with _id or id.
 * Can return json with an "id" field instead of "_id" from a fastapi endpoint
 * by not using the alias name e.g.:
 * @router.get("/{item_id}", response_model_by_alias=False)
 * @example {"_id":"650454bf17a944a479a9ba56","rating":9.4,"number_of_votes":1337}
 */
export interface RatingOut {
  /**  Id */
  _id: string;
  /** Rating */
  rating: number;
  /** Number Of Votes */
  number_of_votes: number;
}

/** ValidationError */
export interface ValidationError {
  /** Location */
  loc: (string | number)[];
  /** Message */
  msg: string;
  /** Error Type */
  type: string;
}

import { HttpClient, RequestParams } from "@sunrise/backend-ng-core";

export enum ContentType {
  Json = "application/json",
  FormData = "multipart/form-data",
  UrlEncoded = "application/x-www-form-urlencoded",
  Text = "text/plain",
}

/**
 * @title rating
 * @version 1.0.0
 *
 *
 * Kronos: The ultimate IMDB rating API 🚀
 *
 * ## How to use
 *
 * 1. Get the IMDB rating for an asset with the following request:
 *     GET /kronos/assets/<asset_id>/rating
 * 2. Start the job to ingesting the data from the s3 bucket with the jobs endpoint
 *     POST /kronos/jobs
 *     Json body: { "type": "imdb_rating_ingestion" }
 */
export class GeneratedApi {
  http: HttpClient;

  constructor(http: HttpClient) {
    this.http = http;
  }

  rating = {
    /**
     * No description
     *
     * @tags jobs
     * @name RunJobRatingV1JobsPost
     * @summary Run Job
     * @request POST:/rating/v1/jobs
     */
    runJobRatingV1JobsPost: (data: JobPayload, params: RequestParams = {}) =>
      this.http.request<any, HTTPValidationError>({
        path: `/rating/v1/jobs`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Get rating for asset by asset id
     *
     * @tags assets
     * @name GetRatingRatingV1AssetsAssetIdRatingGet
     * @summary Get Rating
     * @request GET:/rating/v1/assets/{asset_id}/rating
     */
    getRatingRatingV1AssetsAssetIdRatingGet: (assetId: string, params: RequestParams = {}) =>
      this.http.request<RatingOut, void | HTTPValidationError>({
        path: `/rating/v1/assets/${assetId}/rating`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name RootRatingV1Get
     * @summary Root
     * @request GET:/rating/v1
     */
    rootRatingV1Get: (params: RequestParams = {}) =>
      this.http.request<Message, any>({
        path: `/rating/v1`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name HealthcheckRatingV1HealthGet
     * @summary Healthcheck
     * @request GET:/rating/v1/health
     */
    healthcheckRatingV1HealthGet: (params: RequestParams = {}) =>
      this.http.request<any, any>({
        path: `/rating/v1/health`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name AuthRatingV1AuthGet
     * @summary Auth
     * @request GET:/rating/v1/auth
     * @secure
     */
    authRatingV1AuthGet: (
      query?: {
        /** Config Suffix */
        config_suffix?: string;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<Message, HTTPValidationError>({
        path: `/rating/v1/auth`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),
  };
}
