import { featureAtom } from "@sunrise/feature-flags";

export const preferredThumbnailGeneratorAtom = featureAtom<
  "shaka" | "dash-manual" | "auto" | null
>("thumbnail-generator", null, {
  values: ["shaka", "dash-manual", "auto", null],
  description: `The preferred thumbnail generator to use.
"shaka" uses the Shaka player to generate thumbnails.
"dash-manual" uses the DASH manifest to generate thumbnails.
"auto" uses the Shaka player if available, otherwise falls back to the DASH manifest.
If null, then no thumbnail generator is used and no thumbnails are visible.`,
});
