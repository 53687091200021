import type { AssetId, RecordingGroupId } from "@sunrise/backend-types-core";
import {
  lastSelectedSeasonAssetBySeriesAssetIdAtom,
  selectedRecordingReferenceAtom,
} from "@sunrise/yallo-recordings";
import deepEqual from "fast-deep-equal";
import { atom } from "jotai";
import { atomFamily } from "jotai/utils";

/**
 * This returns an ATOM from the family. Not an actual value.
 * The atom needs to be re-used and we still need to be able to write to it.
 */
export const recordingsListSelectedAtom = atomFamily(
  ({
    seriesAssetId,
    recordingGroupId,
  }: {
    seriesAssetId: AssetId;
    recordingGroupId: RecordingGroupId;
  }) => {
    const inner = atom(async (get) => {
      const seasonFilter = await get(
        lastSelectedSeasonAssetBySeriesAssetIdAtom({
          seriesAssetId,
          recordingGroupId,
        }),
      );

      return selectedRecordingReferenceAtom(
        seasonFilter === "all" ? recordingGroupId : seasonFilter,
      );
    });

    inner.debugLabel = `recordingsListSelectedAtom(seriesId:${seriesAssetId},recordingGroupId:${recordingGroupId})`;

    return inner;
  },
  deepEqual,
);
