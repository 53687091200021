import { JSX, useEffect, useMemo } from "react";
import { setFocus } from "@noriginmedia/norigin-spatial-navigation";
import clsx from "clsx";
import { useAtomValue, useSetAtom } from "jotai";

import {
  actionScrollableDialogOpen,
  scrollableDialogAtom,
} from "@sunrise/dialogs";
import { actionLocationNavigate, locationAtom } from "@sunrise/location";
import { useTranslator } from "@sunrise/translator";

import { FocusButton } from "@/components";
import { DebugVersion } from "@/components/debug/debug-version";
import { globalFocusKey } from "@/config/focus-key";
import { route } from "@/config/route";
import { useMenu } from "@/features/menu/use-menu";
import { developerModeAtom } from "@/features/settings/developer/developer-mode.atom";
import { SettingsBase } from "@/features/settings/settings-base";
import { canPullFocusAtom } from "@/modules/ui/can-pull-focus.atom";
import {
  buttonContainer,
  root,
  settingsButton,
  withMenu,
} from "@/routes/settings.css";
import { FocusContainer } from "@/utils/focus-container";

const localWidgetFocusKey = {
  page: globalFocusKey.activePage + ".page",
  button(name: string) {
    return `${globalFocusKey.activePage}.button.${name}`;
  },
};

type SettingsItem = {
  to: string;
  label: string;
  id: string;
};

const settingsItems: SettingsItem[] = [
  // TODO add buttons as needed
  // {
  //   to: route.settings.root(),
  //   label: "settingsChannelList",
  //   id: "channel_list",
  // },
  // {
  //   to: route.settings.root(),
  //   label: "title_replay_tv_settings",
  //   id: "replay",
  // },
  // {
  //   to: route.settings.root(),
  //   label: "menu_support",
  //   id: "support",
  // },
  {
    to: route.settings.profile(),
    label: "menu_profile",
    id: "profile",
  },
  // {
  //   to: route.settings.root(),
  //   label: "menu_information",
  //   id: "information",
  // },
  // {
  //   to: route.settings.root(),
  //   label: "menu_tutorial",
  //   id: "tutorial",
  // },
];

export function Settings(): JSX.Element {
  const menu = useMenu();
  const t = useTranslator();
  const dispatchLocation = useSetAtom(locationAtom);
  const dispatchScrollableDialog = useSetAtom(scrollableDialogAtom);
  const developerMode = useAtomValue(developerModeAtom);
  const items = useMemo(() => {
    return !developerMode
      ? settingsItems
      : [
          ...settingsItems,
          {
            to: route.settings.developerTools(),
            label: "Developer Tools",
            id: "developer",
          },
          {
            to: route.settings.featureFlagsRoot(),
            label: "Feature Flags",
            id: "feature-flags",
          },
        ];
  }, [developerMode]);

  const actionButtons = [
    {
      label: t("settings_terms_of_use"),
      handleEnterPress: () =>
        dispatchScrollableDialog(
          actionScrollableDialogOpen({
            text: t("settings_terms_of_use_text"),
            title: t("settings_terms_of_use"),
            lastFocusKey: localWidgetFocusKey.page,
            id: "terms_of_use",
          }),
        ),
      id: "terms_of_use",
    },
  ];

  const canPullFocus = useAtomValue(canPullFocusAtom);
  useEffect(
    function delegateFocus() {
      if (menu.isExpanded || !canPullFocus) return;
      setFocus(localWidgetFocusKey.page);
    },
    [menu.isExpanded, canPullFocus],
  );

  return (
    <SettingsBase
      titleLabel="menu_settings"
      data-testid="settings"
      className={clsx(withMenu, root)}
    >
      <FocusContainer
        className={buttonContainer}
        data-testid="settings.buttons"
        focusKey={localWidgetFocusKey.page}
        onLeft={menu.expand}
      >
        {(handlers) => (
          <>
            {items.map((it) => (
              <FocusButton
                className={settingsButton}
                text={t(it.label)}
                onEnterPress={() =>
                  dispatchLocation(actionLocationNavigate(it.to))
                }
                onArrowPress={handlers.onArrowPress("left")}
                focusKey={localWidgetFocusKey.button(it.id)}
                key={it.id}
                variant="ghost"
                data-testid={`settings.button.${it.id}`}
              />
            ))}
            {actionButtons.map((button) => (
              <FocusButton
                className={settingsButton}
                text={button.label}
                onEnterPress={button.handleEnterPress}
                onArrowPress={handlers.onArrowPress("left")}
                focusKey={localWidgetFocusKey.button(button.id)}
                key={button.id}
                variant="ghost"
                data-testid={`settings.button.${button.id}`}
              />
            ))}
          </>
        )}
      </FocusContainer>
      <DebugVersion />
    </SettingsBase>
  );
}
