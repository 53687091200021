import { type Nullable } from "@sunrise/utils";
import { atom } from "jotai";

import type { ChannelGroupId } from "@sunrise/backend-types-core";
import { preferredChannelGroupIdAtom } from "./preferred-channel-group-id.atom";
import { selectableChannelGroupByChannelGroupIdAtom } from "./selectable-channel-group-by-channel-group-id.atom";
import { selectableChannelGroupsAtom } from "./selectable-channel-groups.atom";

/**
 * Just returns the name and ID of the currently selected channel group.
 *
 * This should always attempt to return a channel group. When the preferred one is not selected or does not exist,
 * it falls back to the first group that is found.
 */
export const currentChannelGroupAtom = atom<
  Promise<
    Nullable<{
      name: string;
      id: ChannelGroupId;
    }>
  >
>(async (get) => {
  const currentChannelGroupId = get(preferredChannelGroupIdAtom);

  // We need to select the first one by default if none is selected.
  const found = currentChannelGroupId
    ? await get(
        selectableChannelGroupByChannelGroupIdAtom(currentChannelGroupId),
      )
    : null;

  if (found) {
    return found;
  }

  const groups = await get(selectableChannelGroupsAtom);

  // Return a non-user group first. Ideally this is the first built-in group with all channels.
  const nonUserGroups = groups.filter((group) => !group.isUser);
  if (nonUserGroups[0]) {
    return nonUserGroups[0];
  }

  return groups.filter((group) => group.isUser)[0] ?? null;
});
