import type { ReactNode } from "react";
import {
  type ArrowPressHandler,
  useFocusable,
} from "@noriginmedia/norigin-spatial-navigation";
import clsx from "clsx";

import type { GenericSingleRecording } from "@sunrise/yallo-recordings";

import { RecordingItemSingle } from "../recordings/recording-item-single";
import { nextRecording } from "./what-is-next.css";

export type WhatIsNextRecordingProps = {
  data: GenericSingleRecording;
  onEnterPress: () => void;
  onFocusChange?: (focused: boolean) => void;
  onArrowPress?: ArrowPressHandler;
  focusKey?: string;
  className?: string;
};

export function WhatIsNextRecording({
  data,
  onEnterPress,
  focusKey,
  className,
  onArrowPress,
}: WhatIsNextRecordingProps): ReactNode {
  const { ref, focused } = useFocusable({
    onEnterPress,
    focusKey,
    onArrowPress,
  });

  return (
    <RecordingItemSingle
      className={clsx(nextRecording, className)}
      ref={ref}
      recording={data}
      focused={focused}
    />
  );
}
