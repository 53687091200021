import { isNil } from "lodash";

import {
  type AdAlternativeAdsType,
  type AdEventType,
  endpoints,
  type PauseAdConfig,
  type VideoAdConfigGeneric,
  type VideoAdPlacementType,
  type VideoAdTag,
} from "@sunrise/backend-types";
import type { ChannelId, Language } from "@sunrise/backend-types-core";
import { PrivateApiClient } from "@sunrise/http-client";
import { type Nullable } from "@sunrise/utils";

import { ALTERNATIVE_AD_MAPPING, FFWD_AD_MAPPING } from "./constants";
import { isFFwdAdTag } from "./helpers/is-ffwd-ad-tag";

export async function getVideoAds(
  privateApi: PrivateApiClient,
  host: string,
  placement: VideoAdPlacementType,
  language: Language,
): Promise<VideoAdConfigGeneric<AdEventType>> {
  const url = new URL(endpoints.videoAds(host, placement));
  url.searchParams.set("language", language);

  const { data } = await privateApi.get<VideoAdConfigGeneric<AdEventType>>(
    url.href,
  );

  return data;
}

/**
 * These video-ads are a little bit different compared to the regular linear video-ads.
 * The VAST features they use are different. And we normally get multiple VAST ADs in a single tag.
 */
export async function getAlternativeVideoAds({
  privateApi,
  host,
  channelId,
  startTime,
  type = "replay",
}: {
  privateApi: PrivateApiClient;
  host: string;
  channelId: ChannelId;
  /**
   * This is the start of the EPG item given that the type is replay.
   * When the type is fast-forward, this is the time that the ad block starts.
   * The backend documentation indicates that it's the current time in the stream but that seems useless to me.
   * I can be at the very start of the EPG program and skip over the fourth ad break in a program. How would that time be useful?
   * It can only be useful if the time is actually the start of the last ad block we are skipping.
   */
  startTime: Date;
  type: "replay" | "fast-forward";
}): Promise<Nullable<VideoAdConfigGeneric<AdAlternativeAdsType>>> {
  const url = new URL(endpoints.alternativeVideoAds(host, type));

  url.searchParams.set("channel_id", channelId);
  url.searchParams.set("stream_absolute_time", startTime.toISOString());

  const { data } = await privateApi.get<
    VideoAdConfigGeneric<AdAlternativeAdsType> | string
  >(url.href);

  if (typeof data === "string") {
    return null;
  }

  return data;
}

export async function getPauseAdsConfig({
  host,
  privateApi,
  channelId,
  time,
}: {
  privateApi: PrivateApiClient;
  host: string;
  channelId: ChannelId;
  time: Date;
}): Promise<Nullable<PauseAdConfig>> {
  const url = new URL(endpoints.alternativePauseAds(host));

  url.searchParams.set("channel_id", channelId);
  url.searchParams.set("stream_absolute_time", time.toISOString());

  const { data } = await privateApi.get<PauseAdConfig>(url.href);

  if (typeof data === "string") {
    return null;
  }

  return data;
}

export async function getAdConfig({
  privateApi,
  host,
  placement,
  language,
}: {
  privateApi: PrivateApiClient;
  host: string;
  placement: VideoAdPlacementType;
  language: Language;
}): Promise<VideoAdConfigGeneric<AdEventType> | null> {
  return getVideoAds(privateApi, host, placement, language);
}

export async function triggerAdTagEvent<T extends string>(
  api: { get: (url: string) => Promise<unknown> },
  tracking: Record<T, string>,
  event: T,
): Promise<void> {
  const url = tracking[event];
  await api.get(url);
}

/**
 * Sends an error event to the backend with optional error codes.
 *
 * https://entwicklungspark.atlassian.net/wiki/spaces/WT/pages/2928869388/Video+ads+API#VAST-errors-tracking
 */
export async function sendAdTagErrorEvent(
  privateApi: PrivateApiClient,
  tag: VideoAdTag,
  vastErrorCode?: number,
  internalErrorCode?: number,
): Promise<void> {
  const url = getUrlForEvent(tag, "error");

  if (!url) {
    console.warn(`No tracking url for event error found.`);
    return;
  }

  if (!isNil(vastErrorCode)) {
    url.searchParams.set("vastErrorCode", vastErrorCode.toString());
  }
  if (!isNil(internalErrorCode)) {
    url.searchParams.set("internalErrorCode", internalErrorCode.toString());
  }

  await privateApi.get(url.toString());
}

/**
 * Sends an event to the backend. Excludes error events as this is handled in {@link sendAdTagErrorEvent}.
 *
 * https://entwicklungspark.atlassian.net/wiki/spaces/WT/pages/2928869388/Video+ads+API#Deliver-tracking-event-to-backend
 *
 * TODO: rework to be generically typed. We pass it the adtag config. And we pass in the event we want to trigger.
 */
export async function sendAdTagEvent(
  privateApi: PrivateApiClient,
  tag: VideoAdTag,
  event: Exclude<AdEventType, "error">,
): Promise<void> {
  const url = getUrlForEvent(tag, event);

  if (!url) {
    console.warn(`No tracking url for event ${event} found.`);
    return;
  }

  await privateApi.get(url.href);
}

function getUrlForEvent(tag: VideoAdTag, event: AdEventType): Nullable<URL> {
  if ("error" in tag.tracking) {
    return ensureUrlOrNull(tag.tracking[event]);
  }

  if (isFFwdAdTag(tag)) {
    return ensureUrlOrNull(tag.tracking[FFWD_AD_MAPPING[event]]);
  }

  return ensureUrlOrNull(tag.tracking[ALTERNATIVE_AD_MAPPING[event]]);
}

function ensureUrlOrNull(url: string): Nullable<URL> {
  if (!url) {
    return null;
  }

  return new URL(url);
}
