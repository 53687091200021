import { atomFamily } from "jotai/utils";
import { atomWithSuspenseQuery } from "jotai-tanstack-query";

import { ngChannelApiAtom } from "@sunrise/backend-ng-channel";
import { ChannelId } from "@sunrise/backend-types-core";
import { currentLanguageAtom } from "@sunrise/i18n";
import { isNil } from "@sunrise/utils";

/**
 * Unused, but it's a good example of how to use the ngChannelApiAtom.
 */
export const channelForChannelIdQueryAtom = atomFamily((id: ChannelId) => {
  return atomWithSuspenseQuery((get) => {
    const api = get(ngChannelApiAtom);
    if (isNil(api)) {
      throw new Error("ngChannelApiAtom is not initialized");
    }

    const language = get(currentLanguageAtom);

    return {
      // TODO: also extract queryKeys to a shared place.
      //       These should actually be shared between ng and legacy. But maybe legacy ones can be prefixed.
      queryKey: ["channels", language, id],
      queryFn: async () => {
        try {
          const r =
            await api.channel.channelDetailChannelV1ChannelsChannelIdGet(id);

          return r.data;
        } catch (e) {
          return null;
        }
      },
    };
  });
});
