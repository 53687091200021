import { PrivateApiClient, publicApi } from "@sunrise/http-client";

import { ChannelPackagesError } from "./channel-packages.error";
import {
  endpoints,
  type AllChannelsResponse,
  type ChannelReference,
} from "@sunrise/backend-types";

/**
 * @deprecated
 */
export async function fetchAllChannels(
  host: string,
): Promise<AllChannelsResponse> {
  return (await publicApi.get<AllChannelsResponse>(endpoints.all(host))).data;
}

/**
 * @deprecated
 */
export async function fetchChannelPackages(
  privateApi: PrivateApiClient,
  host: string,
): Promise<ChannelReference[]> {
  try {
    const response = await privateApi.get<ChannelReference[]>(
      endpoints.channelPackages(host),
    );
    return response.data;
  } catch (e: unknown) {
    if (e instanceof Error) {
      throw new ChannelPackagesError(e.message);
    }

    throw e;
  }
}
