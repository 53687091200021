import { type ReactElement, useCallback } from "react";
import {
  type ArrowPressHandler,
  getCurrentFocusKey,
} from "@noriginmedia/norigin-spatial-navigation";

import { useConfirmationDialog } from "@sunrise/dialogs";
import { useTranslator } from "@sunrise/translator";

import { FocusButton } from "@/components";

export function WhyAdsButton({
  onArrowPress,
}: {
  onArrowPress?: ArrowPressHandler;
}): ReactElement {
  const t = useTranslator();

  const { showDialog } = useConfirmationDialog();
  const onEnter = useCallback(() => {
    showDialog({
      title: { key: "ad_screen_no_more_ads_dialog_title" },
      description: { key: "ad_screen_no_more_ads_dialog_text" },
      lastFocusKey: getCurrentFocusKey(),
      confirmationLabel: { key: "button_ok" },
      id: "no-more-ads",
    });
  }, [showDialog]);

  return (
    <FocusButton
      text={t("ad_screen_no_more_ads_button")}
      onEnterPress={onEnter}
      onArrowPress={onArrowPress}
      focusKey="why-ads-button"
      variant="outlined"
    />
  );
}
