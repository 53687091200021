import { setFocus } from "@noriginmedia/norigin-spatial-navigation";
import { useTranslator } from "@sunrise/translator";
import {
  lastSelectedSeasonAssetBySeriesAssetIdAtom,
  recordingGroupItemsQueryAtom,
  selectHasRecordingsQueryFetchedFirstPage,
  selectRecordingsIsFetchingInitialData,
} from "@sunrise/yallo-recordings";
import clsx from "clsx";
import { useAtomValue } from "jotai";
import { selectAtom } from "jotai/utils";
import { type JSX, useEffect, useMemo } from "react";

import { PageSpinner } from "@/components";
import { useMenu } from "@/features/menu/use-menu";
import { recordingsListSelectedAtom } from "@/modules/details/recordings-list-selected.atom";
import { typography } from "@/styles/typography.css";

import { List } from "./list";
import * as styles from "./recordings-list.css";
import type { AssetId, RecordingGroupId } from "@sunrise/backend-types-core";

export type ConnectedRecordingsSeriesProps = CommonProps & {
  focusKey: string;
  recordingGroupId: RecordingGroupId;
  seriesAssetId: AssetId;
  onExitUp?: (column: number) => void;
};

export function RecordingsSeriesList({
  "data-testid": dataTestId = "RecordingsList",
  focusKey,
  recordingGroupId,
  seriesAssetId,
  onExitUp,
}: ConnectedRecordingsSeriesProps): JSX.Element {
  useMenu({ hidden: true });
  const seasonFilter = useAtomValue(
    lastSelectedSeasonAssetBySeriesAssetIdAtom({
      seriesAssetId,
      recordingGroupId,
    }),
  );

  const dataAtom = useMemo(() => {
    return recordingGroupItemsQueryAtom(
      seasonFilter === "all"
        ? { recordingGroupId, traverseChildGroups: true, seriesAssetId }
        : {
            recordingGroupId: seasonFilter,
            traverseChildGroups: false,
            seriesAssetId,
          },
    );
  }, [recordingGroupId, seasonFilter, seriesAssetId]);

  const { hasContentAtom, isFetchingInitialDataAtom } = useMemo(() => {
    const hasContentAtomInner = selectAtom(
      dataAtom,
      selectHasRecordingsQueryFetchedFirstPage,
    );
    hasContentAtomInner.debugLabel = "hasContentAtom";

    const isFetchingInitialDataAtomInner = selectAtom(
      dataAtom,
      selectRecordingsIsFetchingInitialData,
    );
    isFetchingInitialDataAtomInner.debugLabel = "isFetchingInitialDataAtom";

    return {
      hasContentAtom: hasContentAtomInner,
      isFetchingInitialDataAtom: isFetchingInitialDataAtomInner,
    };
  }, [dataAtom]);

  const hasContent = useAtomValue(hasContentAtom);
  const isFetchingInitialData = useAtomValue(isFetchingInitialDataAtom);

  const selectedAtom = useAtomValue(
    recordingsListSelectedAtom({ seriesAssetId, recordingGroupId }),
  );

  useEffect(() => {
    if (hasContent && !isFetchingInitialData) {
      setFocus(focusKey);
    }
  }, [focusKey, hasContent, isFetchingInitialData]);

  if (isFetchingInitialData) {
    return <PageSpinner data-testid="recording-group-items-spinner" />;
  }

  if (hasContent) {
    return (
      <div className={styles.listContainer}>
        <List
          selectedAtom={selectedAtom}
          focusKey={focusKey}
          data-testid={dataTestId}
          dataAtom={dataAtom}
          seriesAssetId={seriesAssetId}
          onExitUp={onExitUp}
        />
      </div>
    );
  }

  return <EmptyInformation data-testid={dataTestId} />;
}

type EmptyInformationProps = CommonProps;

function EmptyInformation({
  "data-testid": dataTestId,
  className,
  ...props
}: EmptyInformationProps): JSX.Element {
  const t = useTranslator();
  return (
    <p
      {...props}
      className={clsx([className, styles.empty, typography.h6.regular])}
      data-testid={`${dataTestId}.no-recordings`}
    >
      {t("recordings_nothing_found")}
    </p>
  );
}
