import { type Nullable, isNil } from "@sunrise/utils";

export function getSeasonEpisodePrefix(
  seasonNo: Nullable<number>,
  episodeNo: Nullable<number>,
): string | null {
  if (isNil(seasonNo) || !episodeNo) {
    return null;
  }

  if (seasonNo === 0) {
    return `E${episodeNo.toString().padStart(2, "0")}`;
  }

  return `S${seasonNo.toString().padStart(2, "0")} E${episodeNo
    .toString()
    .padStart(2, "0")}`;
}
