import {
  epgDetailsForEpgEntryId,
  getEpgEntryPlayingAtTimeOnChannel,
} from "@sunrise/yallo-epg";
import { recordingByRecordingIdAtom } from "@sunrise/yallo-recordings";
import { atom } from "jotai";

import { selectPlayerCurrentPlayRequest } from "./player.atom";
import { selectPlayerCurrentDateTimePerMinute } from "./select-player-current-date-time-per-minute";
import type { EPGEntry } from "@sunrise/backend-types";

/**
 * Returns the EPG item that is currently playing inside the player.
 * Works for EPG/linear-based streams and recordings.
 *
 * This does NOT take seeking into account.
 */
export const playerCurrentEpgItemAtom = atom<Promise<EPGEntry | null>>(
  async (get) => {
    const playRequest = get(selectPlayerCurrentPlayRequest);
    if (!playRequest) return null;

    if (playRequest.type === "recording") {
      const recording = await get(
        recordingByRecordingIdAtom(playRequest.recordingId),
      );

      if (!recording.data) return null;

      return await get(
        epgDetailsForEpgEntryId({ epgId: recording.data.epg_entry.id }),
      );
    }

    const currentTime = get(selectPlayerCurrentDateTimePerMinute);
    // There's a guard to not blow up stuff when we somehow get an incorrect date.
    // Stuff from 1970 is probably not something that aired.
    if (!currentTime || currentTime.getFullYear() === 1970) return null;

    return get(
      getEpgEntryPlayingAtTimeOnChannel({ channelId: playRequest.channelId }),
    )(currentTime);
  },
);
