import { type Nullable } from "@sunrise/utils";
import {
  continueWatchingDataByEpgIdAtom,
  getContinueWatchingProgress,
} from "@sunrise/yallo-continue-watching";
import {
  isBulkDeletionModeAtom,
  isRecordingMarkedForDeletionAtom,
} from "@sunrise/yallo-recordings";
import areEqual from "fast-deep-equal";
import { atom, useAtomValue } from "jotai";
import { type ReactNode, forwardRef, memo, useMemo } from "react";

import { RecordingProgramBox } from "@/components/boxes";
import { programBoxSize } from "@/config/size";
import { makePosterImgUrl } from "@/utils/image";
import type { AssetId } from "@sunrise/backend-types-core";
import type { Recording } from "@sunrise/backend-types";
import { channelByIdAtom } from "@sunrise/yallo-channel-group";
import { useProgramData } from "@sunrise/yallo-epg";

const NO_DATA_ATOM = atom(null);

export type RecordingItemProps = CommonProps & {
  seriesAssetId: Nullable<AssetId>;
  id: Nullable<Recording["id"]>;
  focused: boolean;
  recording: Recording;
  active?: boolean;
  index?: number;
};

/**
 * Program box specifically for recordings.
 * This does not mean that the data shown on it is actually from the recording.
 * When we show Continue-Watching and we favour playout of replay over recordings ...
 * then the CW data should come from the replay item and not from the recordings item.
 */
export const RecordingItem = memo(
  forwardRef<HTMLDivElement, RecordingItemProps>(function RecordingItem(
    { "data-testid": dataTestId = "RecordingItem", className, ...props },
    ref,
  ): ReactNode {
    const recording = "epg_entry" in props.recording ? props.recording : null;
    const recordingGroup =
      "episode_count" in props.recording ? props.recording : null;

    const channelId =
      props.recording.channel?.id ??
      (recording?.epg_entry.channel ? recording.epg_entry.channel.id : null);
    const channel = useAtomValue(
      channelId ? channelByIdAtom(channelId) : NO_DATA_ATOM,
    );

    const {
      start,
      end,
      airTime,
      title,
      subtitle,
      liveProgress,
      expiresIn,
      type,
      seasonAndEpisode,
      coverImageUrl,
      isFinished,
    } = useProgramData({
      asset: props.recording.asset,
      actual_start: recording?.epg_entry.actual_start,
      actual_end: recording?.epg_entry.actual_end,
      expiresAt: props.recording.expires_at,
      seriesAssetId: props.seriesAssetId,
    });

    const isBulkDeletionMode = useAtomValue(isBulkDeletionModeAtom);
    const isMarkedForDeletion = useAtomValue(
      isRecordingMarkedForDeletionAtom(recordingGroup?.id ?? recording?.id),
    );

    const recordingState =
      type !== "series" ? (isFinished ? "recorded" : "recording") : null;

    const cwData = useAtomValue(
      recording?.epg_entry.id
        ? continueWatchingDataByEpgIdAtom(recording.epg_entry.id)
        : NO_DATA_ATOM,
    );
    const continueWatchingProgress = useMemo(() => {
      if (!recording?.epg_entry) {
        return null;
      }

      return getContinueWatchingProgress({
        continueWatchingItem: cwData?.continueWatchingItem,
        epgEndTime: new Date(recording.epg_entry.actual_end),
        epgStartTime: new Date(recording.epg_entry.actual_start),
        isFullyWatched: cwData?.isFullyWatched ?? false,
        paddingTimesInMinutes:
          "padding_start_minutes" in recording
            ? {
                end: recording.padding_end_minutes ?? 0,
                start: recording.padding_start_minutes ?? 0,
              }
            : null,
      });
    }, [cwData, recording]);

    return (
      <RecordingProgramBox
        ref={ref}
        className={className}
        data-testid={dataTestId}
        data-focused={props.focused}
        index={props.index}
        startDate={recording?.epg_entry.actual_start}
        endDate={recording?.epg_entry.actual_end}
        type={type}
        coverImageUrl={makePosterImgUrl(
          coverImageUrl,
          programBoxSize.recordings.image.width,
          programBoxSize.recordings.image.height,
        )}
        logoUrl={channel?.logo}
        subtitle={subtitle}
        title={title}
        start={start}
        end={end}
        airTime={airTime}
        liveProgress={liveProgress}
        replayProgress={continueWatchingProgress}
        focused={props.focused}
        numberOfEpisodes={recordingGroup?.episode_count}
        expiresIn={expiresIn}
        recordingState={recordingState}
        seasonAndEpisode={seasonAndEpisode}
        isBulkDeletionMode={isBulkDeletionMode}
        isMarkedForDeletion={isMarkedForDeletion}
      />
    );
  }),
  areEqual,
);
