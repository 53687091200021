import { ReactNode, Suspense } from "react";

import { IconButton } from "@/components";
import { IconProps } from "@/components/icon";
import * as styles from "@/features/player-controls/player-controls.css";

export function SuspendButton({
  children,
  iconName,
}: {
  children: ReactNode;
  iconName: IconProps["name"];
}): ReactNode {
  return (
    <Suspense
      fallback={
        <IconButton disabled iconName={iconName} className={styles.button} />
      }
    >
      {children}
    </Suspense>
  );
}
