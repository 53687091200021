import {
  lastSelectedSeasonAssetBySeriesAssetIdAtom,
  recordingGroupItemsQueryAtom,
} from "@sunrise/yallo-recordings";
import { useAtomValue } from "jotai";
import type { ReactNode } from "react";

import { RecordingsNetworkIndicator } from "@/features/recordings/recordings-network-indicator";

import { spinner } from "./details-series.css";
import type { AssetId, RecordingGroupId } from "@sunrise/backend-types-core";

export type DetailsSeriesNetworkIndicatorProps = {
  recordingGroupId: RecordingGroupId;
  seriesAssetId: AssetId;
};

export function DetailsSeriesNetworkIndicator({
  recordingGroupId,
  seriesAssetId,
}: DetailsSeriesNetworkIndicatorProps): ReactNode {
  const seasonFilter = useAtomValue(
    lastSelectedSeasonAssetBySeriesAssetIdAtom({
      seriesAssetId,
      recordingGroupId,
    }),
  );

  // When we have no season selected we want the loader to map to paging over all the items.
  // When we have a specific season selected, we need to map the loader to the loading of the pages for that specific season.
  const loaderDataAtom = recordingGroupItemsQueryAtom(
    seasonFilter === "all"
      ? { recordingGroupId, traverseChildGroups: true, seriesAssetId }
      : {
          recordingGroupId: seasonFilter,
          traverseChildGroups: false,
          seriesAssetId,
        },
  );

  return (
    <RecordingsNetworkIndicator dataAtom={loaderDataAtom} className={spinner} />
  );
}
