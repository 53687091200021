import type { Nullable } from "@sunrise/utils";
import type {
  AssetId,
  EPGEntryId,
  RecordingGroupId,
  UserToken,
} from "@sunrise/backend-types-core";
import type {
  RecordingGroupSort,
  RecordingsSort,
  RecordingStatusFilter,
  RecordingTypeFilter,
} from "./recordings.types";
import type { StaffType } from "./asset.types";
import { SearchQuery } from "./search.types";

const getUserTokenQueryKey = (at: Nullable<UserToken>) => ["at", at] as const;

const recordingsBase = (at: Nullable<UserToken>) =>
  [...getUserTokenQueryKey(at), "recordings"] as const;

/**
 * When knowing the latest state relies on polling for the data use this as the base key.
 */
const recordingsPullBase = (at: Nullable<UserToken>) =>
  [...recordingsBase(at), "pull"] as const;

/**
 * We initially do a pull but then the state is pushed to us through the websocket.
 * So we should not flush it on the same interval as the "pull-based" recordings.
 */
const recordingsPushBase = (at: Nullable<UserToken>) =>
  [...recordingsBase(at), "push"] as const;

export const queryKeys = {
  assetById: (assetId: Nullable<AssetId>) => ["assets", assetId],
  assetStaffById: (assetId: Nullable<AssetId>, types: StaffType[] = []) => [
    "assets",
    assetId,
    "staff",
    types.join(","),
  ],
  assetRatingById: (assetId: Nullable<AssetId>) => [
    "assets",
    assetId,
    "rating",
  ],

  epgById: (epgId: Nullable<EPGEntryId>) => ["epg", epgId],

  userToken: getUserTokenQueryKey,

  user: (at: Nullable<UserToken>) =>
    [...getUserTokenQueryKey(at), "user"] as const,

  channelPackages: (at: Nullable<UserToken>) =>
    [...getUserTokenQueryKey(at), "channel-packages"] as const,

  channelGroups: (at: Nullable<UserToken>) =>
    [...getUserTokenQueryKey(at), "channel-groups"] as const,

  // recordings
  recordingsBase,
  recordingsPullBase,
  recordingStats: (at: Nullable<UserToken>) =>
    [...recordingsPushBase(at), "recording-stats"] as const,
  recordingByRecordingId: (at: Nullable<UserToken>, recordingId: string) =>
    [...recordingsPullBase(at), "recordings-per-id", recordingId] as const,
  recordingsStatus: (at: Nullable<UserToken>) =>
    [...recordingsPushBase(at), "recordings-status"] as const,
  recordingsOverview: (
    at: Nullable<UserToken>,
    sort: RecordingsSort,
    language: string,
    typeFilter: RecordingTypeFilter,
    statusFilter: RecordingStatusFilter,
  ) =>
    [
      ...recordingsPullBase(at),
      "recordings-overview",
      sort,
      language,
      statusFilter,
      typeFilter,
    ] as const,
  haveRecordingSchedules: (at: Nullable<UserToken>, assetId: string) =>
    [...recordingsPullBase(at), "have-recording-schedules", assetId] as const,
  recordingGroupItems: (
    at: Nullable<UserToken>,
    assetId: string,
    sort: RecordingGroupSort,
    traverseChild: boolean,
  ) =>
    [
      ...recordingsPullBase(at),
      "recording-group-items",
      assetId,
      sort.field,
      sort.direction,
      traverseChild ? "traverse" : "no-traverse",
    ] as const,
  recordingItemsByAssetId: (at: Nullable<UserToken>, assetId: string) =>
    [
      ...recordingsPullBase(at),
      "recording-items-by-asset-id",
      assetId,
    ] as const,
  // TODO: doublecheck if that is still used ...
  haveRecordings: (at: Nullable<UserToken>, assetId: string) =>
    [...recordingsPullBase(at), "have-recordings", assetId] as const,
  recordingGroupsByRecordingGroupId: (
    at: Nullable<UserToken>,
    recordingGroupId: RecordingGroupId,
  ) =>
    [
      ...recordingsPullBase(at),
      "recording-groups-by-recording-group-id",
      recordingGroupId,
    ] as const,

  continueWatching: (at: Nullable<UserToken>) =>
    [...getUserTokenQueryKey(at), "continue-watching-paged"] as const,
  fullyWatched: (at: Nullable<UserToken>) =>
    [...getUserTokenQueryKey(at), "fully-watched"] as const,

  searchEpgItems: (filter: SearchQuery["filter"], query: string) =>
    ["search-epg", filter, query] as const,
  searchChannels: (query: string) => ["search-channels", query] as const,
  searchHistory: (at: Nullable<UserToken>) =>
    [...getUserTokenQueryKey(at), "search-history"] as const,
};
