import { selectIsDialogOpen } from "@sunrise/dialogs";
import { selectPlayerIsPlaying } from "@sunrise/player";
import {
  isAllowedToShowAtom,
  showWhatIsNextSideBarAtom,
  whatIsNextEffect,
  whatIsNextResetEffect,
  whatIsNextTimeoutEffect,
} from "@sunrise/what-is-next";
import { selectPlayerManagerIsLoading } from "@sunrise/yallo-common-player-manager";
import { atom } from "jotai";
import { atomEffect } from "jotai-effect";

import { isMenuActiveAtom } from "@/modules/menu/is-menu-active.atom";
import { zappingControlIsVisibleAtom } from "@/modules/zapping/zapping-control.atom";

import { isChannelListHiddenAtom } from "../channel-list";

export const shouldShowWhatIsNextSideBarAtom = atom((get) => {
  get(whatIsNextEffect);
  get(whatIsNextResetEffect);
  get(isAllowedWhatIsNextEffect);
  get(whatIsNextTimeoutEffect);

  return get(isAllowedToShowAtom) && get(showWhatIsNextSideBarAtom);
});

const isAllowedWhatIsNextEffect = atomEffect((get, set) => {
  const isDialogOpen = get(selectIsDialogOpen);
  const isChannelListHidden = get(isChannelListHiddenAtom);
  const isZappingVisible = get(zappingControlIsVisibleAtom);
  const playing = get(selectPlayerIsPlaying);
  const menuOpened = get(isMenuActiveAtom);
  const isPlayerManagerLoading = get(selectPlayerManagerIsLoading);

  const isAllowed =
    !menuOpened &&
    !isDialogOpen &&
    isChannelListHidden &&
    !isZappingVisible &&
    playing &&
    !isPlayerManagerLoading;

  set(isAllowedToShowAtom, isAllowed);
});
