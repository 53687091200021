import { selectIsLoggedIn } from "@sunrise/jwt";
import { useAutostop } from "@sunrise/yallo-common-player-manager";
import { useAtomValue } from "jotai";
import { Suspense, lazy } from "react";
import { Redirect, Route, Switch } from "wouter";

import { rawRoute } from "@/config/route";
import { DeveloperControls } from "@/features/settings/developer/developer-controls";
import { Profile } from "@/features/settings/profile/profile";
import { DesignSystem } from "@/routes/design-system";
import { DetailsSeries } from "@/routes/details-series/details-series";
import { Details } from "@/routes/details/details";
import { Landing } from "@/routes/landing";
import { Login } from "@/routes/login";
import { Register } from "@/routes/register";
import { Settings } from "@/routes/settings";

import { PageSpinner } from "./components";
import { developerModeAtom } from "./features/settings/developer/developer-mode.atom";
import {
  DeveloperFeatureFlagDetails,
  DeveloperFeatureFlags,
} from "./features/settings/developer/feature-flags";
import { RedirectionGuard } from "./redirection-guard";

// dynamic imports
const Tv = lazy(() => import("@/routes/tv"));
const Recordings = lazy(() => import("@/routes/recordings"));
const Guide = lazy(() => import("@/routes/guide"));
const Home = lazy(() => import("@/routes/home"));
const Search = lazy(() => import("@/routes/search"));

export function Router(): JSX.Element {
  const isLoggedIn = useAtomValue(selectIsLoggedIn);
  const developerMode = useAtomValue(developerModeAtom);

  return (
    <Suspense fallback={<PageSpinner />}>
      <RedirectionGuard />
      <Switch>
        {isLoggedIn ? (
          <LoggedInLayout>
            <Route path={rawRoute.home} component={Home} />
            <Route path={rawRoute.search} component={Search} />
            <Route path={rawRoute.guide} component={Guide} />
            <Route path={rawRoute.recordings} component={Recordings} />
            <Route path={rawRoute.settings} component={Settings} />
            <Route path={rawRoute.settingsProfile} component={Profile} />
            {developerMode && (
              <>
                <Route
                  path={rawRoute.settingsDeveloper}
                  component={DeveloperControls}
                />
                <Route
                  path={rawRoute.settingsDeveloperFeatureFlags}
                  component={DeveloperFeatureFlags}
                />
                <Route
                  path={rawRoute.settingsDeveloperFeatureFlagsDetails}
                  component={DeveloperFeatureFlagDetails}
                />
              </>
            )}
            <Route path={rawRoute.tv} component={Tv} />
            <Route path={rawRoute.details} component={Details} />
            <Route path={rawRoute.detailsSeries} component={DetailsSeries} />
            {import.meta.env.MODE !== "production" &&
              import.meta.env.VITE_SHOW_DESIGN_SYSTEM === "true" && (
                <Route path={rawRoute.designSystem} component={DesignSystem} />
              )}
          </LoggedInLayout>
        ) : (
          <>
            <Route path={rawRoute.register} component={Register} />
            <Route path={rawRoute.login} component={Login} />
            <Route path={rawRoute.landing} component={Landing} />
          </>
        )}

        <Route>
          <Redirect to={isLoggedIn ? rawRoute.home : rawRoute.landing} />
        </Route>
      </Switch>
    </Suspense>
  );
}

function LoggedInLayout({
  children,
}: {
  children: React.ReactNode;
}): React.ReactNode {
  // Autostop is at this level since it also needs to stop when users are browsing on the guide for example.
  useAutostop();

  return children;
}
