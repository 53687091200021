import { Nullable } from "@sunrise/utils";
import { format } from "date-fns";

import { PlayerRequestSeekbarReturn, SeekbarProgressResult } from "../types";
import { formatTime } from "./format-time";
import { getLiveElapsedTime } from "@sunrise/time";

/**
 * as separate function to make testing easier (to not depend on the seekbarProgressAtom)
 * @param seekbarProgress
 */
export function composeSeekbarHook(
  seekbarProgress: Nullable<SeekbarProgressResult>,
): Omit<PlayerRequestSeekbarReturn, "seek" | "seekActions"> {
  if (!seekbarProgress) {
    return {
      isSeeking: false,
      currentTime: null,
      progress: null,
      durationLeft: "0:00:00",
      liveProgress: null,
      replayProgress: null,
      elapsed: "00:00:00",
      currentTimeWithoutSeek: null,
      durationLeftWithoutSeek: "0:00:00",
      elapsedWithoutSeek: "00:00:00",
      progressWithoutSeek: null,
    };
  }

  // format duration in milliseconds to H:mm:ss
  const durationLeft = formatTime(seekbarProgress.durationLeft);
  const durationLeftWithoutSeek = formatTime(
    seekbarProgress.durationLeftWithoutSeek,
  );
  const currentTime = seekbarProgress.currentTime
    ? format(seekbarProgress.currentTime, "HH:mm:ss")
    : null;
  const currentTimeWithoutSeek = seekbarProgress.currentTimeWithoutSeek
    ? format(seekbarProgress.currentTimeWithoutSeek, "HH:mm:ss")
    : null;
  const elapsed = getLiveElapsedTime(
    seekbarProgress.startTime,
    seekbarProgress.currentTime,
  );
  const elapsedWithoutSeek = getLiveElapsedTime(
    seekbarProgress.startTime,
    seekbarProgress.currentTimeWithoutSeek,
  );

  return {
    currentTime,
    currentTimeWithoutSeek,
    durationLeft,
    elapsed,
    elapsedWithoutSeek,
    durationLeftWithoutSeek,
    progressWithoutSeek: seekbarProgress.progressWithoutSeek,
    isSeeking: seekbarProgress.isSeeking,
    progress: seekbarProgress.progress,
    liveProgress: seekbarProgress.liveProgress,
    replayProgress: seekbarProgress.replayProgress,
  };
}
