import { ReactNode } from "react";

import type { RecordingState } from "@sunrise/backend-types";
import type { AssetId } from "@sunrise/backend-types-core";
import { Nullable } from "@sunrise/utils";

import { Tag } from "@/components/tag/tag";

import * as styles from "./details-tags.css";
import { ImdbRatingTag } from "./imdb-rating-tag";

export type DetailsTagsProps = {
  assetId: Nullable<AssetId>;
  tags: Nullable<string[]>;
  recordingStatus?: RecordingState | undefined;
} & CommonProps;

export function DetailsTags({
  assetId,
  tags,
  recordingStatus,
  "data-testid": testId,
}: DetailsTagsProps): ReactNode {
  return (
    <div data-testid={testId} className={styles.tags}>
      <ImdbRatingTag assetId={assetId} />
      {tags?.map((tag) => (
        <Tag className={styles.tag} key={tag} text={tag} />
      ))}
      {recordingStatus && (
        <Tag
          className={styles.tag}
          key="recording-status"
          text="REC"
          icon={recordingStatus === "recorded" ? "recorded" : "recording"}
          data-testid={`${testId}.recording-tag`}
        />
      )}
    </div>
  );
}
