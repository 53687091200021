import { atom } from "jotai";
import { atomFamily } from "jotai/utils";

import { AssetId } from "@sunrise/backend-types-core";
import { Nullable } from "@sunrise/utils";
import {
  episodesEpgsBySeriesAssetIdNgAtom,
  MappedEpg,
} from "@sunrise/yallo-epg";

export const seriesEpisodesAtom = atomFamily((seriesId: AssetId) =>
  atom<Promise<Nullable<MappedEpg[]>>>(async (get) => {
    const episodes = await get(episodesEpgsBySeriesAssetIdNgAtom(seriesId));
    return episodes.data ?? [];
  }),
);
