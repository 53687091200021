import { featureAtom } from "@sunrise/feature-flags";

export const confirmZappingAwayAtom = featureAtom(
  "confirm-zapping-away",
  true,
  {
    description:
      "Show a confirmation dialog when zapping away from content when we deem the content to be something the user intended to watch.",
  },
);
