import { hostsAtom, publicApi } from "@sunrise/http-client";
import { atomWithSuspenseQuery } from "jotai-tanstack-query";
import { isNil } from "lodash";

import { endpoints, type Settings } from "@sunrise/backend-types";
import { platformAtom, settingsVersionAtom } from "./settings.store";
import { atom } from "jotai";

const settingsQueryAtom = atomWithSuspenseQuery<Settings>((get) => ({
  queryKey: ["settings"],
  queryFn: async () => {
    const host = get(hostsAtom).clients;
    const platform = get(platformAtom);
    const version = get(settingsVersionAtom);

    if (isNil(host)) throw new Error("Host is not set");
    if (isNil(platform)) throw new Error("Platform is not set");
    if (isNil(version)) throw new Error("Settings version is not set");

    return (
      await publicApi.get<Settings>(endpoints.settings(host, platform, version))
    ).data;
  },
  staleTime: Infinity,
  cacheTime: Infinity,
}));

export const settingsAtom = atom(async (get) => {
  return (await get(settingsQueryAtom)).data;
});
