import { BaseError } from "@sunrise/error";

/**
 * Used to map what to show when we are offline.
 *
 * Set this on the errorAtom to show the user that they are offline.
 */
export class OfflineError extends BaseError {
  constructor() {
    super("OfflineError", "detected-offline", {
      errorCode: "offline",
    });
  }

  override get shouldTrace(): boolean {
    return false;
  }

  override get shouldRetry(): boolean {
    return false;
  }
}
