import { featureAtom } from "@sunrise/feature-flags";

export const playerControlsIdleMarginAtom = featureAtom(
  "player-controls-idle-margin",
  500,
  {
    description:
      "Duration of time margin after the player controls are hidden by idle where we should prevent actions (like zapping).",
  },
);
