import { AxiosInstance } from "axios";

import { Language } from "@sunrise/backend-types-core";

export function injectAcceptedLanguageHeaderInterceptor(
  instance: AxiosInstance,
  getLanguage: () => Language,
) {
  instance.interceptors.request.use((config) => {
    config.headers["Accept-Language"] = getLanguage();
    return config;
  });
}
