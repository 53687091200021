import { ReactNode } from "react";

import { Spinner } from "@/components";

export function DebugSpinner(): ReactNode {
  return (
    <div
      style={{
        width: "100%",
        height: "600px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Spinner />
    </div>
  );
}
