import { atom } from "jotai";
import { atomFamily } from "jotai/utils";

import { isLegacyBackendAtom } from "@sunrise/backend-core";
import type { ChannelId } from "@sunrise/backend-types-core";
import { currentLanguageAtom } from "@sunrise/i18n";
import type { Nullable } from "@sunrise/utils";

import { legacyChannelToSimpleChannel } from "./legacy/channel.utils";
import { channelByIdLegacyAtom } from "./legacy/channel-by-id.legacy.atom";
import { channelByIdNgAtom } from "./ng/channel-by-id.ng.atom";
import type { MappedChannel } from "./types";

/**
 * Grabs basic information about a channel.
 */
export const channelByIdAtom = atomFamily((id: ChannelId) => {
  const innerAtom = atom<
    Promise<Nullable<Omit<MappedChannel, "order" | "locked">>>
  >(async (get) => {
    if (get(isLegacyBackendAtom)) {
      const data = await get(channelByIdLegacyAtom(id));
      if (!data) {
        return null;
      }

      return legacyChannelToSimpleChannel(data, get(currentLanguageAtom));
    }

    return get(channelByIdNgAtom(id));
  });

  innerAtom.debugLabel = `channelByIdAtom(${id})`;

  return innerAtom;
});
