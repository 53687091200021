import { atom } from "jotai";
import { atomFamily } from "jotai/utils";

import { isLegacyBackendAtom } from "@sunrise/backend-core";
import type { EPGEntryId } from "@sunrise/backend-types-core";

import { epgEntryByIdLegacyAtom } from "./legacy/epg-entry-by-id.legacy.atom";
import { epgEntryByIdNgAtom } from "./ng/epg-entry-by-id.ng.atom";

/**
 * Fetches and caches {@link MappedEpg} that's being previewed,
 * not necessary currently playing.
 *
 */
export const epgEntryByIdAtom = atomFamily((epgId: EPGEntryId) => {
  const innerAtom = atom(async (get) => {
    if (get(isLegacyBackendAtom)) {
      return get(epgEntryByIdLegacyAtom(epgId));
    }

    return get(epgEntryByIdNgAtom(epgId));
  });

  innerAtom.debugLabel = `assetDetailsByIdAtom(${epgId})`;

  return innerAtom;
});
